import types from '../types/listings';

const listingsReducer = (
  state = {
    loading: true,
    posts: [],
    error: null,
    appliedFilters: [],
  },
  action,
) => {
  switch (action.type) {
    case types.FETCH_POSTS:
      return { ...state, loading: true };
    case types.FETCH_POSTS_SUCCESS:
      return {
        ...state,
        loading: false,
        posts: action.payload.pagination.first_page
          ? action.payload.data
          : [...state.posts, ...action.payload.data],
        pagination: action.payload.pagination,
      };
    case types.FETCH_POSTS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case types.FETCH_FILTERS_SUCCESS:
      return { ...state, filters: action.payload };
    case types.FETCH_FILTERS_FAILURE:
      return { ...state, error: action.payload };
    case types.APPLY_FILTERS: {
      return { ...state, appliedFilters: action.payload };
    }
    default:
      return state;
  }
};

export default listingsReducer;
