import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import qs from 'qs';
import { showAlert } from '../../actions/alerts';
import {
  fetchPosts,
  fetchFilters,
  fetchCategories,
  applyFilters,
} from '../../actions/listings';
import {
  setSubscribeClicked,
  removeSubscribeClicked,
  setSharingLocationPermission,
  setSharingLocationRadius,
  getCurrentGeoLocation,
  setSharingLocationMunicipal,
  turnOffInitializingLocation,
} from '../../actions/user';
import Listings from './Listings';

import './index.css';

const Homepage = ({
  _fetchPosts,
  _fetchCategories,
  setAppliedFilters,
  location,
  user,
  _getCurrentGeoLocation,
  _setSharingLocationPermission,
  _setSharingLocationRadius,
  _setSharingLocationMunicipal,
  _turnOffInitializingLocation,
  posts,
  _fetchFilters,
  history,
}) => {
  const { appliedFilters } = posts;
  const [page, setPage] = useState(1);
  const [autocompleteCategories, setAutocompleteCategories] = useState([]);
  const [autocompleteEquipmentTypes, seAutocompleteEquipmentTypes] = useState(
    [],
  );

  //const carousel = useRef();

  window.history.replaceState(null, '');

  useEffect(() => {
    // eslint-disable-next-line react/no-unused-state
    //let { post: filters } = this.props;

    async function loadData() {
      const urlFilters = qs.parse(location.search, { ignoreQueryPrefix: true });

      window.addEventListener('scroll', handleScroll);

      window.scrollTo(0, localStorage.getItem('yAxis'));

      const locationPermissionInfo = initLocationPermission();
      const allowSharingLocation =
        user.allowSharingLocation || locationPermissionInfo.sharingPermission;

      const geoLocation =
        user.geoLocation || locationPermissionInfo.currentLocation;
      const locationRadius =
        user.locationRadius || locationPermissionInfo.currentLocationRadius;

      let filters = await loadNewFilters(
        allowSharingLocation,
        geoLocation,
        locationRadius,
      );

      // autocomplete section
      if (!autocompleteCategories.length) {
        const response = await _fetchCategories();

        let categoriesByName = [];
        let equipmentTypesByName = [];
        const categories = response.payload;

        categories.forEach((category) => {
          categoriesByName.push(category.name);
          category.equipment_types.forEach((type) =>
            equipmentTypesByName.push(type.name),
          );
        });
        setAutocompleteCategories(categoriesByName);
        seAutocompleteEquipmentTypes(equipmentTypesByName);
      }

      if (posts.loading) {
        if (Object.entries(urlFilters).length !== 0) {
          applyUrlFilters(filters, urlFilters);
        }
      }
    }

    loadData();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScroll = (event) => {
    localStorage.setItem('yAxis', window.pageYOffset);
  };

  const initLocationPermission = () => {
    const sharingPermission = JSON.parse(
      localStorage.getItem('allowSharingLocation'),
    );
    let currentLocation = null;
    let currentLocationRadius = null;
    let currentMunicipal = null;

    if (sharingPermission) {
      navigator.permissions
        .query({
          name: 'geolocation',
        })
        .then((permissionStatus) => {
          if (permissionStatus.state === 'denied') {
            _getCurrentGeoLocation();
          }
        });

      const locationFromStorage = localStorage.getItem('geoLocation');

      const arr = locationFromStorage.split(',');

      currentLocationRadius = JSON.parse(
        localStorage.getItem('locationRadius'),
      );

      currentMunicipal = localStorage.getItem('municipal');

      currentLocation = {
        lat: +arr[0],
        long: +arr[1],
      };

      _setSharingLocationPermission(
        sharingPermission,
        currentLocation,
        currentLocationRadius,
      );

      _setSharingLocationRadius(currentLocationRadius);

      _setSharingLocationMunicipal(currentMunicipal);
    }
    _turnOffInitializingLocation();

    return { sharingPermission, currentLocation, currentLocationRadius };
  };

  const loadNewFilters = async (allowSharingLocation, geoLocation, radius) => {
    let filters = null;

    if (!filters) {
      filters = (await _fetchFilters(allowSharingLocation, geoLocation, radius))
        .payload;
    }
    return filters;
  };

  const updateAppliedFilters = (filters) => {
    //note: replace with setAppliedFilters function
    if (JSON.stringify(appliedFilters) !== JSON.stringify(filters)) {
      setAppliedFilters(filters);
      setPage(1);
    }
  };

  const applyUrlFilters = (filters, urlFilters) => {
    const updatedFilters = [];

    Object.keys(urlFilters).forEach((key) => {
      const optionsFn = (o) =>
        o.value.toLowerCase() === urlFilters[key].toLowerCase();
      switch (key) {
        case 'location':
          let locations = urlFilters[key].split(',');
          locations = locations?.filter((location) => location.length > 1);
          locations.forEach((location) => {
            updatedFilters.push({
              type: key,
              value: {
                text: location,
                value: filters?.locations?.find(
                  (o) => o.value.toLowerCase() === location.toLowerCase(),
                )?.value,
              },
            });
          });
          break;
        case 'category':
          let categories = urlFilters[key].split(',');
          categories = categories?.filter((category) => category.length > 1);
          categories.forEach((category) => {
            updatedFilters.push({
              type: key,
              value: {
                text: category,
                value: filters?.categories?.find(
                  (o) => o.value.toLowerCase() === category.toLowerCase(),
                )?.value,
              },
            });
          });
          break;
        case 'condition':
          let conditions = urlFilters[key].split(',');
          conditions = conditions?.filter((condition) => condition.length > 1);
          conditions.forEach((condition) => {
            updatedFilters.push({
              type: key,
              value: {
                text: condition,
                value: filters?.conditions?.find(
                  (o) => o.text.toLowerCase() === condition.toLowerCase(),
                )?.value,
              },
            });
          });
          break;
        case 'sorted_by':
          updatedFilters.push({
            type: key,
            value: filters?.sorted_by_options?.find(optionsFn),
          });
          break;
        case 'search_query':
          updatedFilters.push({
            type: key,
            value: {
              text: urlFilters[key],
            },
          });
          break;
        case 'max_price':
          updatedFilters.push({
            type: key,
            value: {
              value: urlFilters[key],
              text: key + ': ' + urlFilters[key],
            },
          });
          break;
        case 'min_price':
          updatedFilters.push({
            type: key,
            value: {
              value: urlFilters[key],
              text: key + ': ' + urlFilters[key],
            },
          });
          break;
        default:
          _fetchPosts();
          break;
      }
    });

    updateAppliedFilters(updatedFilters);
  };

  const addFilter = ({ name, options, value }) => {
    let updatedFilters = [];

    let filterValue = {};

    name === 'condition'
      ? (filterValue = options.find(
          (o) => o.text.toLowerCase() === value.toLowerCase(),
        ))
      : (filterValue = options.find(
          (o) => o.value.toLowerCase() === value.toLowerCase(),
        ));

    const valueIsValid = typeof filterValue !== 'undefined';
    if (valueIsValid) {
      if (name === 'sorted_by') {
        updatedFilters = [
          ...appliedFilters.filter((f) => f.type !== 'sorted_by'),
          {
            type: 'sorted_by',
            value: filterValue,
          },
        ];
      } else if (
        !appliedFilters.find((f) => f.type === name && f.value.value === value)
      ) {
        updatedFilters = [
          ...appliedFilters,
          { type: name, value: filterValue },
        ];
      }

      setAppliedFilters(updatedFilters);
    }
  };

  // min and max at the same time
  const addPriceFilters = (filters) => {
    let updatedFilters = [];

    // Remove old ones
    appliedFilters.forEach((f) => {
      if (f.type !== 'max_price' && f.type !== 'min_price') {
        updatedFilters.push(f);
      }
    });

    // add new ones
    filters.forEach((f) => {
      const filterValue = {
        name: f.name,
        text: f.name + ': ' + f.value,
        value: f.value,
      };

      updatedFilters.push({ type: f.name, value: filterValue });
    });

    updateAppliedFilters(updatedFilters);
  };

  const removeFilter = (data) => {
    let updatedFilters = [];

    if (data.name === 'category') {
      appliedFilters.forEach((f) => {
        if (f.value.value !== data.value) {
          updatedFilters.push(f);
        }
      });
      // onRemove of label
    } else if (data.type === 'category') {
      appliedFilters.forEach((f) => {
        if (f.value.value !== data.content) {
          updatedFilters.push(f);
        }
      });
      // unchecking a checkbox
    } else if (data.name === 'location') {
      appliedFilters.forEach((f) => {
        if (f.value.value !== data.value) {
          updatedFilters.push(f);
        }
      });
      // onRemove of a label
    } else if (data.type === 'location') {
      appliedFilters.forEach((f) => {
        if (f.value.value !== data.content) {
          updatedFilters.push(f);
        }
      });
    } else if (data.name === 'condition') {
      appliedFilters.forEach((f) => {
        if (f.value.text !== data.value) {
          updatedFilters.push(f);
        }
      });
      // onRemove of a label
    } else if (data.type === 'condition') {
      appliedFilters.forEach((f) => {
        if (f.value.text !== data.content) {
          updatedFilters.push(f);
        }
      });
    } else {
      appliedFilters.forEach((f) => {
        if (f.type !== data.name && f.type !== data.type) {
          updatedFilters.push(f);
        }
      });
    }

    updateAppliedFilters(updatedFilters);
  };

  const clearFilter = () => {
    updateAppliedFilters([]);
  };

  const addSearchQuery = (value) => {
    const name = 'search_query';

    let updatedFilters = [];

    // autocomplete search for Categories only
    let index = autocompleteCategories.findIndex((i) => i === value);
    if (index !== -1) {
      if (!appliedFilters.find((f) => f.type === 'category')) {
        updatedFilters = [
          ...appliedFilters,
          {
            type: 'category',
            value: {
              text: value,
              value: value,
              key: index,
            },
          },
        ];
      } else {
        updatedFilters = [
          ...appliedFilters.filter((f) => f.type !== 'category'),
          {
            type: 'category',
            value: {
              text: value,
              value: value,
              key: index,
            },
          },
        ];
      }

      updateAppliedFilters(updatedFilters);

      return;
    }

    // autocomplete search for other keywords
    if (!appliedFilters.find((f) => f.type === name)) {
      if (value !== '') {
        updatedFilters = [
          ...appliedFilters,
          { type: name, value: { text: `"${value}"`, value } },
        ];
      }
    } else {
      updatedFilters = [...appliedFilters.filter((f) => f.type !== name)];

      if (value !== '') {
        updatedFilters.push({
          type: name,
          value: { text: `"${value}"`, value },
        });
      }
    }

    updateAppliedFilters(updatedFilters);
  };

  const pageChange = (page) => {
    setPage(page);
    fetchFilteredPosts(appliedFilters, page);
  };

  const fetchFilteredPosts = (appliedFilters, newPage = page) => {
    const filters = {};
    let urlFilters = '?';

    // FIXME: this can definitely be improved upon, but it works:

    let categoryUrlString = '';
    let locationUrlString = '';
    let conditionUrlString = '';
    let max_priceUrlString = '';
    let min_priceUrlString = '';

    appliedFilters.forEach((f) => {
      if (f.type === 'category') {
        if (filters['category']) {
          filters['category'] += ',' + f.value.value;
        } else {
          filters['category'] = f.value.value;
        }
      } else if (f.type === 'location') {
        if (filters['location']) {
          filters['location'] += ',' + f.value.value;
        } else {
          filters['location'] = f.value.value;
        }
      } else if (f.type === 'max_price') {
        filters['max_price'] = f.value.value;
      } else if (f.type === 'min_price') {
        filters['min_price'] = f.value.value;
      } else if (f.type === 'condition') {
        if (filters['condition']) {
          filters['condition'] += ',' + f.value.text;
        } else {
          filters['condition'] = f.value.text;
        }
      } else {
        filters[f.type] = f.value.value;
      }

      if (typeof f.value.value !== 'undefined' && f.value.value !== '') {
        if (f.type === 'category') {
          categoryUrlString += f.value.value + ',';
        } else if (f.type === 'location') {
          locationUrlString += f.value.value + ',';
        } else if (f.type === 'condition') {
          conditionUrlString += f.value.text + ',';
        } else if (f.type === 'max_price') {
          max_priceUrlString += f.value.value;
        } else if (f.type === 'min_price') {
          min_priceUrlString += f.value.value;
        } else if (f.type === 'condition') {
          min_priceUrlString += f.value.value + ',';
        } else {
          urlFilters += `${f.type}=${f.value.value}&`;
        }
      }
    });

    if (categoryUrlString.length > 0) {
      urlFilters += urlFilters.length >= 2 ? '&category=' : 'category=';
      urlFilters += categoryUrlString;
    }
    if (locationUrlString.length > 0) {
      urlFilters += urlFilters.length >= 2 ? '&location=' : 'location=';
      urlFilters += locationUrlString;
    }
    if (conditionUrlString.length > 0) {
      urlFilters += urlFilters.length >= 2 ? '&condition=' : 'condition=';
      urlFilters += conditionUrlString;
    }
    if (max_priceUrlString.length > 0) {
      urlFilters += urlFilters.length >= 2 ? '&max_price=' : 'max_price=';
      urlFilters += max_priceUrlString;
    }
    if (min_priceUrlString.length > 0) {
      urlFilters += urlFilters.length >= 2 ? '&min_price=' : 'min_price=';
      urlFilters += min_priceUrlString;
    }

    if (urlFilters.charAt(urlFilters.length - 1) === '&') {
      urlFilters = urlFilters.slice(0, -1);
    }

    urlFilters = urlFilters.replace('&&', '&');
    urlFilters = urlFilters.replace('??', '?');

    history.push(urlFilters);

    const queryParams = {
      page: newPage,
      ...filters,
    };

    _fetchPosts(queryParams);
  };

  if (user?.initializingLocation) {
    return <Loader active={true} content="Loading" />;
  }

  return (
    <Listings
      fetchFilteredPosts={(filters, page) => fetchFilteredPosts(filters, page)}
      addSearchQuery={(value) => addSearchQuery(value)}
      autocompleteCategories={autocompleteCategories}
      autocompleteEquipmentTypes={autocompleteEquipmentTypes}
      appliedFilters={appliedFilters}
      updateAppliedFilters={(filters) => updateAppliedFilters(filters)}
      clearFilter={() => clearFilter()}
      addFilter={({ name, options, value }) =>
        addFilter({ name, options, value })
      }
      addPriceFilters={(filters) => addPriceFilters(filters)}
      removeFilter={(data) => removeFilter(data)}
      loadNewFilters={() => loadNewFilters()}
      pageChange={(page) => pageChange(page)}
      location={location}
    />
  );
};

const mapStateToProps = (state) => ({
  posts: state.listings,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  _fetchPosts: (params = { page: 1 }) => {
    dispatch(fetchPosts(params));
  },
  setAppliedFilters: (filters) => dispatch(applyFilters(filters)),
  _fetchFilters: (isAllow, geoLocation, radius) =>
    dispatch(fetchFilters(isAllow, geoLocation, radius)),
  _fetchCategories: () => dispatch(fetchCategories()),
  _showAlert: (options) => dispatch(showAlert(options)),
  _setSubscribeClicked: () => dispatch(setSubscribeClicked()),
  _removeSubscribeClicked: () => dispatch(removeSubscribeClicked()),
  _setSharingLocationPermission: (isAllow, geoLocation) =>
    dispatch(setSharingLocationPermission(isAllow, geoLocation)),
  _setSharingLocationRadius: (radius) =>
    dispatch(setSharingLocationRadius(radius)),
  _getCurrentGeoLocation: () => dispatch(getCurrentGeoLocation()),
  _setSharingLocationMunicipal: (municipal) =>
    dispatch(setSharingLocationMunicipal(municipal)),
  _turnOffInitializingLocation: () => dispatch(turnOffInitializingLocation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
