/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react'
import { connect } from 'react-redux'
import { Button } from 'semantic-ui-react'
import { MODAL_ID } from './index'
import { showModal } from '../../actions/modal'
import ReactGA from 'react-ga'

class NotificationLink extends React.Component {
	componentDidMount() {
		localStorage.getItem('filterTriggerForm') === 'clicked' && this.props._showModal(MODAL_ID)
	}

	render() {
		const clickEvents = () => {
			this.props._showModal(MODAL_ID)

			if (localStorage.getItem('user')) {
				ReactGA.event({
					category: 'User with Account - Already logged in - No Results Filter Btn clicked',
					action: 'click',
					label: 'Subscribe to Notification Btn clicked',
				})

				localStorage.setItem('gaEventFlow', 'filterNoResultLoggedIn')
			}
		}

		return (
			<div>
				<Button primary onClick={() => clickEvents()} {...this.props}>
					Notify Me
				</Button>
			</div>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	_showModal: (name) => dispatch(showModal(name)),
})

export default connect(null, mapDispatchToProps)(NotificationLink)
