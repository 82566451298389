import types from '../types/donate'

const donateReducer = (
  state = {
    donate_page_data: undefined,
    loading: true,
    error: null,
  },
  action
) => {
  switch (action.type) {
    case types.FETCH_DONATE_PAGE_DATA:
      return { ...state, loading: true }
    case types.FETCH_DONATE_PAGE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        donate_page_data: action.payload.donate_page_data,
      }
    case types.FETCH_DONATE_PAGE_DATA_FAILURE:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export default donateReducer
