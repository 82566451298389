import React from 'react';

import { Segment, Message, Header } from 'semantic-ui-react';

const ResentConfirmationMessage = () => (
  <Segment vertical textAlign="center">
    <Header as="h2" textAlign="center">Confirmation Sent</Header>
    <Message positive>
      <p>
        Check your inbox for the new confirmation email. If you do not receive one, please check
        your spam folder or send us a message.
      </p>
    </Message>
  </Segment>
);

export default ResentConfirmationMessage;
