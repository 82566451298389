import React from 'react';

import { Segment, Message, Header } from 'semantic-ui-react';

const ResetEmailSentMessage = () => (
  <Segment vertical textAlign="center">
    <Header as="h2" textAlign="center">Reset Password</Header>
    <Message positive>
      <p>
        Check your inbox for the next steps. If you don&apos;t receive an email, and it&apos;s not
        in your spam folder this could mean you signed up with a different address.
      </p>
    </Message>
  </Segment>
);

export default ResetEmailSentMessage;
