import React from 'react';
import {Button, Segment, Grid, Responsive} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Icon } from 'semantic-ui-react'
import success from '../../images/post_success.png';
import './PostCreateListingModal.css'

const modalHeaderText = {
  color: '#161617', 
  fontWeight: '700', 
  fontFamily: 'Be Vietnam, Arial, Helvetica, sans-serif',
  fontStyle: 'normal',
  fontSize: '24px',
  lineHeight: '35px',
  padding: '11.5px 0'
};

const modalParagraphText = {
  fontWeight: '400', 
  fontFamily: 'Source Sans Pro, Arial, Helvetica, sans-serif',
  fontStyle: 'normal',
  fontSize: '16px',
  lineHeight: '20px',
  letterSpacing: '0.15px',
  marginTop: 0,
  marginBottom: 0,
  padding: 0
};

const modalSaftyButton = {
  color: '#1A659F', 
  fontWeight: '600', 
  fontFamily: 'Source Sans Pro, Arial, Helvetica, sans-serif',
  fontStyle: 'normal',
  fontSize: '16px',
  lineHeight: '20px',
  letterSpacing: '0.15px',
  textAlign: 'center',
  backgroundColor: '#FFFFFF',
  cursor: 'pointer'
};

const modalListingButton = {
  fontWeight: '600', 
  fontFamily: 'Source Sans Pro, Arial, Helvetica, sans-serif',
  fontStyle: 'normal',
  fontSize: '16px',
  lineHeight: '20px',
  letterSpacing: '0.15px',
  textAlign: 'center',
  color: '#FFFFFF',
  backgroundColor: '#1A659F'
};

const PostCreateListingModal = ({handleClickClosePostCreate}) => {
  return (
    <React.Fragment>
      <div style={{display: 'flex', flexFlow: 'row', justifyContent: 'space-between',  alignItems:'center', margin: '0'}}>
          <div style={modalHeaderText}>Your Listing is Active!</div>
          <Button icon onClick={handleClickClosePostCreate}>
            <Icon name="close" size="large" />
          </Button>
      </div>
      {/* divider */}
      <div class="ui divider" style={{margin:'0 -24px 24px -24px'}} ></div>
      <div style={{display: 'flex', flexFlow: 'row', justifyContent: 'center',  alignItems:'center', marginBottom:'16px'}}>
        <img src={success} style={{width: '292px', height: '292px'}}  alt="post_success" />
      </div>  

      <Segment basic style={modalParagraphText}>
        Congratulations, your listing has been successfully submitted.
      </Segment>
      <br/>
      <Segment basic style={modalParagraphText}>
        Take a moment to read our Safety Guidelines, or watch our <a style={{textDecoration: 'underline', color: '#1A659F'}} href='https://www.youtube.com/watch?v=oXBeQA-wG1s'><span style={{whiteSpace: 'nowrap'}}>Safety Video</span></a> to familiarize yourself with best practices for connecting with potential buyers.
      </Segment>

      <div class="ui divider" style={{margin:'24px -24px 17.5px -24px'}} ></div>
      <Grid stackable style={{margin: '17.5px 0', padding:'0px'}}>
        <Grid.Column computer={16} style={{padding:'0px'}}>
          <Responsive minWidth={Responsive.onlyMobile.maxWidth+1}>
            <div style={{display: 'flex', flexFlow: 'row', justifyContent: 'end',  alignItems:'center', margin: '0'}}>
              <div>
                <a className='linkHover' style={{...modalSaftyButton, marginRight: '10px'}} href="/resources/healthandsafety"  >Read Safety Guidelines</a>

                <Button style={modalListingButton} primary onClick={handleClickClosePostCreate}>
                  See My Listing
                </Button>
              </div>
            </div>
          </Responsive>
          <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
            <Button style={modalListingButton} fluid primary onClick={handleClickClosePostCreate}>
              See My Listing
            </Button>            
            <div className='linkHover' style={{...modalSaftyButton, marginTop: '10px'}}><a href="/resources/healthandsafety" >Read Safety Guidelines</a></div>
          </Responsive>
        </Grid.Column>
      </Grid>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  user: state.user,
});


export default connect(mapStateToProps, null)(PostCreateListingModal);
