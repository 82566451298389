import React, {useEffect, useRef} from 'react';
import { Form, Input, Select, Label, Checkbox} from 'semantic-ui-react';
import ReCAPTCHA from 'react-google-recaptcha';

export const InputField = ({
  input,
  type,
  label,
  required,
  disabled,
  placeholder,
  autoComplete,
  meta: { touched, error },
}) => (
  <Form.Field required={required} error={touched && error}>
    <label>{label}</label>
    <input
      {...input}
      style={{color:'#646466', fontFamily: "Karla", fontStyle: "normal", fontWeight: "normal", fontSize: "16px", lineHeight: "24px"}}
      type={type}
      disabled={disabled}
      placeholder={placeholder}
      autoComplete={autoComplete}
    />
    <div style={{height: '17px', marginTop: '8px'}}>
    {touched && error && <Label style={{display: 'inline'}} pointing color="red" basic content={error} />}
    </div>
  </Form.Field>
);

export const TextAreaField = ({
  input,
  label,
  required,
  disabled,
  rows,
  placeholder,
  meta: { touched, error },
}) => (
  <Form.Field required={required} error={touched && error}>
    <label>{label}</label>
    <textarea
      {...input}
      style={{color:'#646466', fontFamily: "Karla", fontStyle: "normal", fontWeight: "normal", fontSize: "16px", lineHeight: "24px"}}
      rows={rows}
      disabled={disabled}
      placeholder={placeholder}
    />
    {touched && error && <Label pointing color="red" basic content={error} />}
  </Form.Field>
);

export class PriceField extends React.Component {
  constructor(props) {
    super(props);

    this.state = { priceDisabled: props.free.input.value };

    this.handlePriceToggle = () => {
      const { priceDisabled } = this.state;
      const { free } = this.props;
      free.input.onChange(!priceDisabled);

      this.setState({ priceDisabled: !priceDisabled });
    };
  }

  render() {
    const { price, free, required } = this.props;
    const { priceDisabled } = this.state;

    const { meta: { touched, error } } = price;

    return (
      <Form.Field>
        <Form.Input fluid label="Price (numbers only)" error={touched && error} required={required}>
          <Input fluid {...price.input} name="price" icon='dollar sign' iconPosition='left' disabled={priceDisabled} />
          <Checkbox
            {...Boolean(free.input)}
            name="free"
            label="Free"
            onChange={this.handlePriceToggle}
            checked={priceDisabled}
            fitted
            style={{ margin: '10px' }}
            fluid
            floated="right"
          />
        </Form.Input>

        <div style={{height: '17px', marginTop: '15px'}}>
        {touched && error && <Label style={{display: 'inline'}} pointing color="red" basic content={error} />}
        </div>
      </Form.Field>
    );
  }
}

export const IconTextField = ({
  label,
  input,
  icon,
  type,
  placeholder,
  autoComplete,
  meta: { touched, error },
  style,
  disabled
}) => (
  <Form.Field>
    <p >{label}</p>
    <Input style={style ? style : {width: '100%'}} disabled={disabled}
            icon={icon} iconPosition="right" {...input} type={type} error={touched && error} placeholder={placeholder} autoComplete={autoComplete} />
    {touched && error && <Label pointing color="red" basic content={error} />}
  </Form.Field>
);

export const SelectField = ({
  input,
  options,
  label,
  placeholder,
  required,
  meta: { touched, error },
}) => (
  <Form.Field required={required}>
    <label>{label}</label>
    <Select
      {...input}
      error={touched && error}
      placeholder={placeholder}
      options={options}
      onChange={(e, { value }) => input.onChange(value)}
    />
    <div style={{height: '17px', marginTop: '15px'}}>
    {touched && error && <Label style={{display: 'inline'}} pointing color="red" basic content={error} />}
    </div>
  </Form.Field>
);

export class RadioField extends React.Component {
  constructor() {
    super();

    this.state = { value: null };

    this.handleChange = ((e, { value }) => this.setState({ value }));
  }

  render() {
    const { input, label, options } = this.props;

    const { value } = this.state;

    return (
      <Form.Field>
        <label>{label}</label>
        <input {...input} type="hidden" />
        {options.map(option => (
          <Form.Radio
            label={option.value.text}
            value={option.value.value}
            checked={value === option.value.value}
            onChange={this.handleChange}
          />
        ))}
      </Form.Field>
    );
  }
}

export const CheckboxField = ({
  input, label, disabled, meta: { touched, error }, position = 'center',
}) => (
  <Form.Field error={touched && error}>
    <Checkbox
      {...input}
      label={label}
      checked={input.value}
      onClick={(e, { checked }) => input.onChange(checked)}
      disabled={disabled}
      style={{ borderRadius: '8px', display: 'flex', justifyContent: { position } }}

    />
    {touched && error && (
      <div>
        <Label pointing color="red" basic content={error} />
      </div>
    )}
  </Form.Field>
);

export const RecaptchaField = ({ input, meta: { touched, error, invalid } }) => {
  const recaptchaRef = useRef(null)
  // https://developers.google.com/recaptcha/docs/faq#id-like-to-run-automated-tests-with-recaptcha.-what-should-i-do
  const recaptchaKey = process.env.REACT_APP_ENABLE_RECAPTCHA === 'true' ?
  "6LcGuPsUAAAAAG2EKZQYZZ9n1Lu9fbPdkjB22MiR" : "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";

  // If Redux Form validation hasn't occred, excute invisible reCaptcha
  useEffect(() => {
    if (invalid) {
      recaptchaRef.current.execute();
    }
    if (touched && error) {
      recaptchaRef.current.execute();
    }
  }, [invalid, touched, error]);

  return (
    <Form.Field>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={recaptchaKey}
        onChange={input.onChange}
        size="invisible"
      />
      {touched && error && <Label pointing color="red" basic content={error} />}
    </Form.Field>
  );
};
