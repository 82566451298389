export const errorPageStyle = {
  body: {
    paddingTop: "24px",
    backgroundColor: "white",
    maxWidth: "736px",
    padding: "0 30px",
    margin: "0 auto",
  },
  icon: {
    color: "#1288C0",
  },
  prev: {
    margin: 0,
    fontSize: "16px",
    color: "#575757",
    fontFamily: "Karla, sans-serif",
    fontWeight: "normal",
  },
  image: {
    height: 320,
    width: 330,
  },
  imageSmall: {
    height: 200,
    width: 200,
  },
  imageContainer: {
    padding: "0",
    marginBottom: "48px",
    marginTop: "48px",
  },
  headingContainer: {
    padding: "0",
    marginBottom: "0",
  },
  headingContainerSmall: {
    padding: "0",
    marginBottom: "8px",
  },
  heading: {
    fontSize: "48px",
    color: "#087CB1",
    fontWeight: "bold",
  },
  headingSmall: {
    fontSize: "32px",
    color: "#087CB1",
    fontWeight: "bold",
  },
  secondaryHeadingContainer: {
    padding: "0",
    marginBottom: "16px",
  },
  secondaryHeadingContainerSmall: {
    padding: "0",
    marginBottom: "8px",
  },
  secondaryHeading: {
    fontSize: "32px",
    color: "#087CB1",
    fontWeight: "bold",
  },
  secondaryHeadingSmall: {
    fontSize: "32px",
    color: "#087CB1",
    fontWeight: "bold",
  },
  text: {
    fontSize: "18px",
    fontWeight: 500,
    color: "#000",
    fontFamily: "Nunito, sans-serif",
    textAlign: "left",
  },
  textContainer: {
    marginBottom: 48,
    padding: 0,
  },
  textContainerSmall: {
    marginBottom: 16,
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "48px",
    padding: 0,
  },
  buttonsSmall: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexDirection: "column",
    width: "100%",
    marginBottom: "24px",
    padding: 10,
  },
  button: {
    backgroundColor: "white",
    border: "none",
    fontSize: "18px",
    fontFamily: "Karla, sans-serif",
    color: "#000",
    fontWeight: 500,
    padding: 0,
    width: "100%",
  },
  buttonSmall: {
    backgroundColor: "white",
    border: "none",
    fontSize: "18px",
    fontFamily: "Karla, sans-serif",
    color: "#000",
    fontWeight: 500,
    padding: 0,
    width: "100%",
    marginBottom: 8,
  },
  helpText: {
    color: "#000",
    fontFamily: "Karla, sans-serif",
    fontWeight: "normal",
    textAlign: "center",
    marginTop: 0,
    width: "100%",
    padding: 0,
    marginBottom: 0,
  },
  helpTextSmall: {
    color: "#000",
    fontFamily: "Karla, sans-serif",
    fontWeight: "normal",
    textAlign: "left",
    marginTop: 0,
    width: "100%",
    padding: 0,
  },
  blueText: {
    color: "#0280BB",
    textAlign: "center",
  },
  blueTextContainer: {
    width: "100%",
    textDecoration: 'underline',
    textDecorationColor: '#1A659F',
    textDecorationThickness: '2px',
  },
  viewAll: {
    color: "#0280BB",
    textAlign: "right",
  },
  viewAllContainer: {
    marginBottom: 16,
    padding: 0,
  },
  viewAllContainerContactUs: {
    marginBottom: 48,
    padding: 0,
  },
  arrow: {
    marginBottom: 10,
  },
  list: {
    marginBottom: 48,
  },
};
