import React, { useState, Fragment } from 'react';
import {
  Modal, Image, Responsive, Button, Icon,
} from 'semantic-ui-react';
import Slider from 'react-slick';
import noImage from '../../images/no_image.png';

const PostImages = ({ post }) => {
  const [isOpen, setOpen] = useState(false);
  const [isDimmer, setDimmer] = useState(true);
  const [activeSlide, setactiveSlide] = useState(0);

  const show = () => {
    setOpen(true);
    setDimmer(false);
  };
  const close = () => setOpen(false);

  const {
    equipment_type, location, category, title, images,
  } = post;
  const imgAlt = `${title}-${equipment_type.name}-${category.name}-${location.name}-assistlist-listing`;

  const customPaging = i => (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a>
      <Image
        src={images[i].small}
        alt={imgAlt.toLowerCase()}
        style={{
          margin: 'auto',
          transform: `rotate(${images[i].rotation_origin}deg)`,
        }}
      />
    </a>
  );

  const Arrows = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          background: 'transparent',
          zIndex: 1,
        }}
        onClick={onClick}
      />
    );
  };

  const setting = {
    customPaging,
    dots: true,
    fade: true,
    prevArrow: <Arrows />,
    nextArrow: <Arrows />,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    dotsClass: 'slick-dots slick-thumb',
  };

  const xSmallSettings = {
    speed: 1000,
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    autoplaySpeed: 3000,
    prevArrow: <Arrows />,
    nextArrow: <Arrows />,
    beforeChange: (current, next) => setactiveSlide(next),
  };

  const smallSettings = {
    speed: 1000,
    autoplay: true,
    pauseOnHover: true,
    autoplaySpeed: 3000,
    ...setting,
  };

  const largeSettings = {
    speed: 500,
    lazyLoad: true,
    ...setting,
  };

  return (
    <Fragment>
      {images && images.length > 0 ? (
        <div className="imageDiv" style={{ cursor: 'pointer', textAlign: 'center' }}>
          <Responsive
            as={Slider}
            arrows={false}
            minWidth={516}
            {...smallSettings}
          >
            {images.map(image => (
              <Image
                onClick={show}
                src={image.small}
                alt={imgAlt.toLowerCase()}
                style={{
                  display: 'block',
                  margin: 'auto',
                  transform: `rotate(${image.rotation_origin}deg)`,
                }}
              />
            ))}
          </Responsive>
          <Responsive
            as={Fragment}
            maxWidth={515}
          >
            <Slider
              {...xSmallSettings}
            >
              {images.map(image => (
                <Image
                  onClick={show}
                  src={image.small}
                  alt={imgAlt.toLowerCase()}
                  style={{
                    display: 'block',
                    transform: `rotate(${image.rotation_origin}deg)`,
                  }}
                />
              ))}
            </Slider>

            <Button
              size="tiny"
              circular
              floated="middle"
              style={{ marginTop: '30px' }}
            >
              <Icon name="image outline" />
              {activeSlide + 1}/{images.length}
            </Button>
          </Responsive>
        </div>
      ) : (
        <Image src={noImage} alt={imgAlt} />
      )}
      <Modal
        basic
        dimmer={isDimmer}
        open={isOpen}
        onClose={close}
      >
        <Modal.Content>
          <Slider {...largeSettings}>
            {images && images.length > 0 ? (
              images.map(image => (
                <div>
                  <Image
                    src={image.large}
                    alt={imgAlt}
                    style={{
                      maxWidth: '100%',
                      maxHeight: '70vh',
                      margin: 'auto',
                      transform: `rotate(${image.rotation_origin}deg)`,
                    }}
                  />
                </div>
              ))
            ) : (
              <Image src={noImage} alt={imgAlt} />
            )}
          </Slider>
        </Modal.Content>
      </Modal>
    </Fragment>
  );
};

export default PostImages;
