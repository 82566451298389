import React from 'react';

import {
  Form, Segment, Header, Divider,
} from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import { IconTextField } from '../../utils/render-input';
import { formValidator } from '../../utils/FormValidator';

const NewPasswordForm = ({ error, handleSubmit, submitting }) => (
  <Segment vertical textAlign="center">
    <Header as="h2">Reset Password</Header>
    Please enter a new password to log in. A confirmation email will be sent.
    <Divider />
    <Form name="newPasswordForm" onSubmit={handleSubmit}>
      <Field component={IconTextField} name="password" type="password" icon="lock" placeholder="Enter your password" autoComplete="new-password" />
      <Field component={IconTextField} name="password_confirmation" type="password" icon="repeat" placeholder="Confirm your password" autoComplete="new-password" />
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <Form.Button
        primary
        fluid
        type="submit"
        content="Reset Password"
        loading={submitting}
      />
    </Form>
  </Segment>
);

export default reduxForm({
  form: 'newPasswordForm',
  validate: formValidator,
})(NewPasswordForm);
