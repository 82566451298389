import React from 'react';
import {
  Icon,
  Popup,
  Container,
} from 'semantic-ui-react';
import {
  isAndroid,
  isChrome,
  isMobileSafari,
} from 'react-device-detect';

import iosShare from '../../images/ios-share.png';

export default class AddToHomeScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = { render: false };
    this.handleDismiss = () => {
      this.setState({ render: false });
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ render: true });
    }, 40000);
  }

  render() {
    let renderComp = false;
    if (this.state.render) {
      renderComp = (
        <div>
          {(isAndroid && isChrome) && (
          <Popup
            open
            basic
            size="tiny"
            style={{ position: 'sticky', bottom: '0px', backgroundImage: 'linear-gradient(to top, #EBEAEA, #CDCDCD, #EBEAEA)' }}
          >
            <Icon
              name="close"
              corner="right"
              onClick={this.handleDismiss}
              style={{
                position: 'relative', right: '-200px', width: '20px', height: '20px',
              }}
            />
            <Container>
              Add AssistList to your home screen: tap
              <span />
              <Icon name="ellipsis vertical" style={{ objectFit: 'contain', width: '10px', height: '10px' }} />
              at the top of the screen and then <b>&quot;Add to home screen&quot;</b>.
            </Container>
          </Popup>
          )}
          {isMobileSafari && (
          <Popup
            open
            basic
            size="tiny"
            style={{ position: 'sticky', bottom: '0px', backgroundImage: 'linear-gradient(to top, #EBEAEA, #CDCDCD, #EBEAEA)' }}
          >
            <Icon
              name="close"
              corner="right"
              onClick={this.handleDismiss}
              style={{
                position: 'relative', right: '-200px', width: '20px', height: '20px',
              }}
            />
            <Container>
              Add AssistList to your home screen: tap
              <span />
              <img src={iosShare} style={{ objectFit: 'contain', width: '15px', height: '15px' }} alt="share" />
              <span />
               at the bottom/top of the screen and then <b>&quot;Add to home screen&quot;</b>.
            </Container>
          </Popup>
          )}
        </div>
      );
    }
    return (
      renderComp
    );
  }
}
