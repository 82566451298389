import React from 'react'
import { connect } from 'react-redux'
import { change } from 'redux-form'
import { Segment, Accordion, Grid, Icon, Button, Responsive } from 'semantic-ui-react'
import './wizard.css'

import { fetchPropFields } from '../../actions/post'
import Tooltip from './Tooltip'
import CategoryDesc from './CategoryDesc'

class EquipmentSelector extends React.Component {
	constructor(props) {
		super(props)

		this.state = { activeIndex: -1 }

		this.handleClick = this.handleClick.bind(this)
		this.handleSelect = this.handleSelect.bind(this)
	}

	handleClick(e, { index }) {
		this.setState({ activeIndex: this.state.activeIndex === index ? -1 : index })
	}

	handleSelect(type) {
		const { select, nextPage, handleFetchId } = this.props
		select(type)
		handleFetchId(type.id)
		nextPage()
	}

	render() {
		const { categories, loading } = this.props.wizard
		const { activeIndex } = this.state

		return (
			<div style={{ marginBottom: 25 }}>
				<Responsive minWidth={990}>
					<Segment loading={loading}>
						<h2> Please select an equipment type.</h2>
						{!loading && (
							<Accordion style={{ margin: '0 auto', width: '85%' }} fluid styled>
								{categories.map((cat, i) => (
									<div key={i}>
										<Accordion.Title active={activeIndex === i} index={i} onClick={this.handleClick}>
											<Icon name="dropdown" />
											{cat.name}
										</Accordion.Title>
										<Accordion.Content active={activeIndex === i}>
											<CategoryDesc category={cat} />
											<Grid stackable>
												{cat.equipment_types.map((equipmentType) => (
													<Grid.Column
														style={{ display: 'flex' }}
														key={equipmentType.id}
														computer={4}
														tablet={8}
														mobile={16}
													>
														<Button
															style={{ fontSize: '14px', width: '180px', height: '48px', padding: '8px' }}
															primary
															fluid
															content={equipmentType.name}
															onClick={() => this.handleSelect(equipmentType)}
														/>

														<div style={{ marginTop: '12px', marginLeft: '8px' }}>
															<Tooltip equipmentType={equipmentType} />
														</div>
													</Grid.Column>
												))}
											</Grid>
										</Accordion.Content>
									</div>
								))}
							</Accordion>
						)}
					</Segment>
				</Responsive>
				<Responsive maxWidth={989}>
					<Segment loading={loading}>
						{!loading && (
							<Accordion style={{ margin: '0 auto', width: '85%' }} fluid styled>
								{categories.map((cat, i) => (
									<div key={cat + i}>
										<Accordion.Title active={activeIndex === i} index={i} onClick={this.handleClick}>
											<Icon name="dropdown" />
											{cat.name}
										</Accordion.Title>
										<Accordion.Content active={activeIndex === i}>
											<CategoryDesc category={cat} />
											<div
												style={
													window.innerWidth > 725
														? {
																display: 'flex',
																flexDirection: 'row',
																flexWrap: 'wrap',
																justifyContent: 'space-between',
														  }
														: null
												}
											>
												{cat.equipment_types.map((equipmentType) => (
													<div
														key={equipmentType.name}
														style={{ marginBottom: '24px', display: 'flex', flexDirection: 'row' }}
													>
														<Button
															style={{
																width: window.innerWidth < 724 && window.innerWidth > 475 ? '330px' : '230px',
															}}
															primary
															fluid
															content={equipmentType.name}
															onClick={() => this.handleSelect(equipmentType)}
														/>

														<div style={{ marginTop: '8px', marginLeft: '16px' }}>
															<Tooltip equipmentType={equipmentType} />
														</div>
													</div>
												))}
											</div>
										</Accordion.Content>
									</div>
								))}
							</Accordion>
						)}
					</Segment>
				</Responsive>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	wizard: state.post.postForm,
})

const mapDispatchToProps = (dispatch) => ({
	handleFetchId: (id) => {
		dispatch(fetchPropFields(id))
	},
	select: (id) => dispatch(change('newPost', 'equipment_type', id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentSelector)
