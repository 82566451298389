import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Segment,
  Form,
  Label,
  Responsive,
  Accordion,
  Modal,
  Icon,
  Dropdown,
} from 'semantic-ui-react';

import SocialMedia from './SocialMedia';

import './index.css';
import FilterList from './Filterlist';
import {
  getCurrentGeoLocation,
  setSharingLocationPermission,
  setSharingLocationRadius,
  setSharingLocationMunicipal,
  setUseCurrentLocation,
} from '../../actions/user';
import GeoLocationFilter from './GeoLocationFilter';

const Filter = ({
  filters,
  clearFilter,
  appliedFilters,
  addFilter,
  loadNewFilters,
  removeFilter,
  addPriceFilters,
  fetchFilteredPosts,
  user,
  _getCurrentGeoLocation,
  _setSharingLocationPermission,
  _setSharingLocationRadius,
  _setSharingLocationMunicipal,
  _setUseCurrentLocation,
}) => {
  const [open, setOpen] = useState(false);

  const handleLocationPermission = () => {
    if (!user.useCurrentLocation) {
      _getCurrentGeoLocation();
    } else {
      _setSharingLocationPermission(false);
      _setUseCurrentLocation(false);
    }
  };

  if (typeof filters === 'undefined') {
    return <Segment loading />;
  }

  const categoryOptions = filters.categories.map((cat, i) => ({
    text: cat.text,
    value: cat.value,
    key: i,
  }));

  categoryOptions.unshift({
    text: 'All Categories',
    value: '',
    key: 'all_categories',
  });

  const locationOptions = filters.locations.map((loc, i) => ({
    text: loc.text,
    value: loc.value,
    key: i,
  }));

  locationOptions.unshift({
    text: 'All Locations',
    value: '',
    key: 'all_locations',
  });

  const filterList = [
    {
      title: 'Categories',
      type: 'category',
      data: filters.categories,
    },
    {
      title: 'Locations',
      type: 'location',
      data: filters.locations,
      children: (
        <GeoLocationFilter
          allowSharingLocation={user.allowSharingLocation}
          municipal={user.municipal}
          handleLocationPermission={handleLocationPermission}
          setSharingLocationRadius={(value) => _setSharingLocationRadius(value)}
          locationRadius={user.locationRadius}
          loadPosts={() => {
            fetchFilteredPosts(appliedFilters, 1);
          }}
          loadFilters={(lat, long) =>
            loadNewFilters(true, { lat, long }, user.locationRadius)
          }
          setSharingLocationPermission={(isAllow, geoLocation) =>
            _setSharingLocationPermission(isAllow, geoLocation)
          }
          setSharingLocationMunicipal={(municipal) =>
            _setSharingLocationMunicipal(municipal)
          }
          useCurrentLocation={user.useCurrentLocation}
          setUseCurrentLocation={(isAllow) => _setUseCurrentLocation(isAllow)}
        />
      ),
    },
    {
      title: 'Price',
      type: 'price',
    },
    {
      title: 'Conditions',
      type: 'condition',
      data: filters.conditions,
    },
  ];

  return (
    <div>
      <Responsive minWidth={Responsive.onlyComputer.minWidth}>
        <div>
          {appliedFilters.length > 0 && (
            <div>
              <h5 style={{ padding: '10px 0 5px 0', margin: 0 }}>
                {appliedFilters.length} Filters Selected
              </h5>
              <Label.Group size="small">
                {appliedFilters.map((filter) => {
                  let labelText = filter.value.text.replace('_', ' ');
                  return (
                    <Label
                      key={labelText}
                      style={{
                        backgroundColor: '#1a659f',
                        color: 'white',
                        textTransform: 'capitalize',
                      }}
                      content={labelText}
                      type={filter.type}
                      onRemove={(e, data) => {
                        removeFilter(data);
                      }}
                    />
                  );
                })}
              </Label.Group>
            </div>
          )}

          <Form>
            <Accordion fluid>
              {filterList.map((item) => (
                <FilterList
                  key={item.type}
                  title={item.title}
                  type={item.type}
                  data={item.data}
                  children={item.children}
                  addFilter={addFilter}
                  removeFilter={removeFilter}
                  appliedFilters={appliedFilters}
                  addPriceFilters={addPriceFilters}
                />
              ))}
            </Accordion>
          </Form>

          <p style={{ color: 'black', fontWeight: 'bold', paddingTop: '56px' }}>
            Stay connected with Assistlist
          </p>
          <SocialMedia style={{ color: 'black' }} />
        </div>
      </Responsive>

      <Responsive maxWidth={Responsive.onlyTablet.maxWidth}>
        <Modal
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
          trigger={
            <Dropdown
              text="Filter"
              icon="filter"
              labeled
              button
              className="icon"
            ></Dropdown>
          }
          style={{ width: '100%', height: '100%' }}
        >
          {appliedFilters.length > 0 && (
            <Modal.Header id="modalHeaderHeight">
              <div>
                <h5 style={{ padding: '10px 0 5px 0', margin: 0 }}>
                  {appliedFilters.length} Filters Selected
                </h5>
                <Label.Group size="small">
                  {appliedFilters.map((filter) => {
                    let labelText = filter.value.text.replace('_', ' ');
                    return (
                      <Label
                        style={{
                          backgroundColor: '#1a659f',
                          color: 'white',
                          textTransform: 'capitalize',
                        }}
                        key={labelText}
                        content={labelText}
                        type={filter.type}
                        onRemove={(e, data) => {
                          removeFilter(data);
                        }}
                      />
                    );
                  })}
                </Label.Group>
              </div>
            </Modal.Header>
          )}

          <Modal.Header
            style={
              appliedFilters.length > 0
                ? { marginTop: '12px' }
                : { marginTop: '36px' }
            }
          >
            <Button
              style={{
                position: 'fixed',
                right: '12px',
                top: '12px',
                color: 'black',
                backgroundColor: 'white',
              }}
              onClick={() => setOpen(false)}
            >
              <Icon
                style={{
                  position: 'absolute',
                  right: '0px',
                  top: '0px',
                  margin: '0px',
                }}
                size="large"
                name="close"
              />
            </Button>

            <h1 style={{ display: 'inline', fontWeight: 'bold' }}>Filters</h1>
            {appliedFilters.length > 0 ? (
              <span
                style={{
                  color: '#1A659F',
                  float: 'right',
                  paddingTop: '9px',
                  cursor: 'pointer',
                  fontSize: '18px',
                }}
                onClick={() => {
                  clearFilter();
                }}
              >
                <u>Clear All</u>
              </span>
            ) : null}
          </Modal.Header>

          <Modal.Content
            scrolling
            style={{ overflowX: 'auto', position: 'fixed', maxHeight: '70vh' }}
          >
            <Form
              style={{
                marginBottom: document.getElementById('modalHeaderHeight')
                  ? document.getElementById('modalHeaderHeight').clientHeight
                  : null,
              }}
            >
              <Accordion fluid>
                {filterList.map((item) => (
                  <FilterList
                    key={item.type}
                    title={item.title}
                    type={item.type}
                    data={item.data}
                    children={item.children}
                    addFilter={addFilter}
                    removeFilter={removeFilter}
                    appliedFilters={appliedFilters}
                    addPriceFilters={addPriceFilters}
                  />
                ))}
              </Accordion>
            </Form>
          </Modal.Content>

          <Modal.Actions
            style={{
              backgroundColor: 'white',
              display: 'flex',
              justifyContent: 'center',
              position: 'absolute',
              bottom: '0px',
              width: '100%',
            }}
          >
            <Button
              primary
              style={{ color: 'white' }}
              onClick={() => setOpen(false)}
            >
              Apply
            </Button>
          </Modal.Actions>
        </Modal>
      </Responsive>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  _getCurrentGeoLocation: () => dispatch(getCurrentGeoLocation()),
  _setSharingLocationPermission: (isAllow, geoLocation) =>
    dispatch(setSharingLocationPermission(isAllow, geoLocation)),
  _setSharingLocationRadius: (value) =>
    dispatch(setSharingLocationRadius(value)),
  _setSharingLocationMunicipal: (municipal) =>
    dispatch(setSharingLocationMunicipal(municipal)),
  _setUseCurrentLocation: (isAllow) => dispatch(setUseCurrentLocation(isAllow)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
