import React from 'react';
import { Header, Grid } from 'semantic-ui-react';
import noImg from '../../images/no_image.png'
import { connect } from "react-redux";
import { fetchRelatedListing } from "../../actions/relatedlistings";

class PostsRelated extends React.Component {

  componentDidMount() {

    const { _fetchRelatedListing } = this.props;
    _fetchRelatedListing(this.props.post.category.id, this.props.user.user_id);
  }


render(){

  let related_listings = []

  if(this.props.relatedlistings.relatedlistings.data){
    related_listings = this.props.relatedlistings.relatedlistings.data;
  }

  if(related_listings.length ){
    return (
      <div style={{width: '100%',borderTop: '0.5px solid #BDBDBD', paddingTop: '24px'}}>
        <h1 style={{paddingLeft: '16px', fontSize: '22px'}}>Similar Listings</h1>
        <Grid doubling columns={4} style={{paddingLeft: '50px'}}>
        {(related_listings && related_listings.length) ? related_listings.map((listing, i) =>
          (
            <Grid.Column>
              <a href={`/posts/${listing.id}`} style={{textDecoration: 'none'}}><div key={i} style={{width: '160px', height: '309px', marginBottom: '32px', textAlign: 'center'}}>
                <img alt='listing' height='160' width='160' src={listing.images ? listing.images.thumbnail : noImg} />
                <p style={{color: '#575757', textAlign: 'left', marginBottom: '8px', marginTop: '16px'}}>{listing.location.name}</p>
                <h5 style={{margin: '0', color: 'black', height: '3em', lineHeight: '1.5em', overflow: 'hidden', textAlign: 'left'}}>{listing.title}</h5>
                <Header className={!listing.price ? 'free' : 'price'} style={{textAlign: 'left', margin: '16px 0px', color: '#128747'}} size="small" content={!listing.price ? 'FREE' : `$${listing.price}`}
              />
              </div></a>
            </Grid.Column>
            )
          )
          : <div></div>}
        </Grid>
      </div>)
  } else {
      return (null)
    }
}
}

const mapStateToProps = (state) => ({
  relatedlistings: state.relatedlistings,
});

const mapDispatchToProps = (dispatch) => ({
  _fetchRelatedListing: (id, user_id) => dispatch(fetchRelatedListing(id, user_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostsRelated);