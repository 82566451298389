import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import Guidelines from "./Guidelines";
import './post.css';


export default class SocialIcons extends Component {
  render() {
    return (
      <Grid className='stickyEnduce' style={{margin: "10px 10px 10px 0px" }} divided="vertically">
        <Grid.Row columns={1}>
            <Guidelines />
        </Grid.Row>
      </Grid>
    );
  }
}
