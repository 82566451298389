import React from 'react'
import { connect } from 'react-redux'
import { Segment, Form, Button, Icon, Responsive, Modal } from 'semantic-ui-react'
import { reduxForm, Field, formValueSelector } from 'redux-form'

import WaitingPeople from './WaitingPeople'
import { InputField, SelectField, TextAreaField } from '../../utils/render-input'
import DetailsTooltip from './DetailsTooltip'

const textStyling = {
	fontFamily: 'Karla, Arial, Helvetica, sans-serif',
	fontSize: '17px',
	lineHeight: '21px',
	width: '100%',
}
const tinyMobile = 377

const StrongDescriptionToolTip = (props) => (
	<Modal
		style={{
			width: window.innerWidth > 500 ? '650px' : '90%',
			height: '380px',
			marginBottom: window.innerWidth > 500 ? '0' : '40%',
		}}
		trigger={<Icon className="toolTip" color="grey" name="question circle" />}
		closeIcon
	>
		<Modal.Content>
			<Modal.Description>
				<h2 className="modalHeader">Strong Description</h2>
				<div className="simpleKarlaText">
					<p>To increase the possibility of your item selling, here are a few tips for a strong description:</p>
					<ul>
						<li>
							{' '}
							Highlight unique features of the equipment – example – &quot;portable&quot;, &quot;rechargeable&quot;,
							&quot;light-weight&quot; etc.
						</li>
						<li> Avoid redundant information if you have already mentioned it in previous sections.</li>
						<li>
							{' '}
							Include details that buyers need to be aware of in advance - example – &quot;any existing damage or
							functionality issue&quot;.
						</li>
						<li> Avoid misleading product information. Always refer product manual for the technical details.</li>
					</ul>
					<p>Try to keep it short and simple. It will help buyers understand if this product is for them.</p>
				</div>
			</Modal.Description>
		</Modal.Content>
	</Modal>
)

const validate = (values) => {
	const errors = {}

	if (!values.description) {
		errors.description = 'Description is required'
	}

	return errors
}

const DetailsForm = ({ wizard: { fields }, handleSubmit, previousPage, equipment_type, waitingPeople }) => {
	return (
		<div style={{ marginBottom: 56 }}>
			<Responsive maxWidth={989}>
				<div>
					<Segment
						style={{ marginBottom: '0px', background: 'white', borderBottom: '1px #CACACA solid' }}
						basic
						clearing
					>
						<h2>Creating new listing for: {equipment_type.name}</h2>
						<p style={textStyling}>Almost Done! Enter item details below.</p>
					</Segment>
					<div style={{ background: 'white', padding: '1em 1em' }}>
						<WaitingPeople waitingPeople={waitingPeople} />
					</div>
				</div>
			</Responsive>
			<Responsive minWidth={990}>
				<div>
					<Segment
						style={{ marginBottom: '0px', background: 'white', borderBottom: '1px #CACACA solid' }}
						basic
						clearing
					>
						<div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
							<h2 style={{ width: window.innerWidth > 989 ? '540px' : '' }}>
								Creating new listing for: {equipment_type.name}
							</h2>
							<p style={textStyling}>Almost Done! Enter item details below.</p>
						</div>
					</Segment>
					<div style={{ background: 'white', padding: '1em 1em' }}>
						<WaitingPeople waitingPeople={waitingPeople} />
					</div>
				</div>
			</Responsive>
			<Form name="newPost" onSubmit={handleSubmit}>
				<Segment clearing basic style={{ background: 'white' }}>
					{fields.map((field) => {
						// TODO: centralize fieldValidator in a common util file
						const validator = {
							required: field.field_required,
							validate: field.field_required && ((value) => !value && 'Field required'),
						}

						let options = []

						if (field.options) {
							options = field.options.map((o) => ({
								text: o.name,
								value: o.name,
							}))
						}

						switch (field.field_type) {
							case 'drop_down':
								return (
									<div style={{ marginBottom: '16px', position: 'relative' }}>
										<DetailsTooltip field={field} />
										<Field
											component={SelectField}
											name={field.field_name}
											label={field.field_name}
											placeholder="Select an option"
											options={options}
											{...validator}
										/>
									</div>
								)
							case 'unit_text_field':
								return (
									<div style={{ marginBottom: '16px', position: 'relative' }}>
										<DetailsTooltip field={field} />
										<Field component={InputField} name={field.field_name} label={field.field_name} {...validator} />
									</div>
								)
							default:
								return (
									<div style={{ marginBottom: '16px', position: 'relative' }}>
										<DetailsTooltip field={field} />
										<Field component={InputField} name={field.field_name} label={field.field_name} {...validator} />
									</div>
								)
						}
					})}
					<div style={{ width: '100%' }}>
						<div style={{ position: 'absolute', right: '15px' }}>
							<StrongDescriptionToolTip />
						</div>
						<Field component={TextAreaField} name="description" label="Description" rows={7} required />
					</div>
					<div style={{ marginTop: '16px', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
						<Button
							type="button"
							className="previousStepBtn"
							style={{
								width: window.innerWidth > tinyMobile ? '' : '140px',
								padding: window.innerWidth > tinyMobile ? '' : '0.6875em 0em .6875em',
							}}
							onClick={previousPage}
							primary
							basic
							floated="left"
						>
							<Icon name="angle left" />
							<b>Previous Step</b>
						</Button>
						<Button
							type="submit"
							className="nextStepBtn"
							primary
							style={{
								width: window.innerWidth > tinyMobile ? '173px' : '140px',
								padding: window.innerWidth > tinyMobile ? '' : '0.6875em 0em .6875em',
							}}
							floated="right"
						>
							Next Step
							<Icon name="angle right" />
						</Button>
					</div>
				</Segment>
			</Form>
		</div>
	)
}

const DetailsContainer = reduxForm({
	form: 'newPost',
	validate,
	onSubmitFail: () => window.scrollTo(0, 0),
	destroyOnUnmount: false,
})(DetailsForm)

const selector = formValueSelector('newPost')

const mapStateToProps = (state) => ({
	wizard: state.post.postForm,
	equipment_type: selector(state, 'equipment_type'),
})

export default connect(mapStateToProps)(DetailsContainer)
