import { SubmissionError } from 'redux-form';
import api, { userHeaders } from '../utils/api';
import types from '../types/notifications';

import { showAlert } from './alerts';
import { hideModal } from './modal';
import { MODAL_ID } from '../components/Notification';

const equipmentTypeOptions = equipmentTypes => equipmentTypes.map(type => (
  { text: type.name, value: type.id }
));

const categoryOptions = categories => categories.map((cat, i) => (
  { text: cat.name, value: i, equipment_types: equipmentTypeOptions(cat.equipment_types) }
));

export const fetchOptions = () => (dispatch) => {
  const options = {};

  return Promise.all([
    api.get('api/posts/categories')
      .then((response) => {
        options.categories = categoryOptions(response.data.data);
      }),
  ])
    .then(() => dispatch({ type: types.FETCH_OPTIONS_SUCCESS, payload: options }))
    .catch(error => dispatch({ type: types.FETCH_OPTIONS_FAILURE, payload: error }));
};

export const fetchAllNotifications = () => (dispatch) => {
  dispatch({ type: types.NOTIFICATION_FETCH_ALL });
  api.get('/notifications')
    .then((response) => {
      dispatch({ type: types.NOTIFICATION_FETCH_ALL_SUCCESS, payload: response.data.data });
    })
    .catch((error) => {
      dispatch({ type: types.NOTIFICATION_FETCH_ALL_FAILURE, payload: error });
    });
};

export const fetchUserNotifications = () => (dispatch) => {
  dispatch({ type: types.NOTIFICATION_USER_FETCH });
  api.get('/user_notifications', userHeaders())
    .then((response) => {
      dispatch({ type: types.NOTIFICATION_USER_FETCH_SUCCESS, payload: response.data.data });
    })
    .catch((error) => {
      dispatch({ type: types.NOTIFICATION_USER_FETCH_FAILURE, payload: error });
    });
};

export const submitNotification = (values, dispatch) => {
  api.post('/notifications', {
    notification: values,
  }, userHeaders())
    .then((response) => {
      dispatch(fetchUserNotifications());
      dispatch({ type: types.NOTIFICATION_SUBMIT_SUCCESS, payload: response.data });
      dispatch(hideModal(MODAL_ID));
      dispatch(showAlert({
        header: 'Notification Created',
        message: 'Your notification has been created successfully',
        type: 'success',
        dismissAfter: 3000,
      }));
    })
    .catch((error) => {
      dispatch({ type: types.NOTIFICATION_SUBMIT_FAILURE });
      dispatch(showAlert({
        header: 'Notification creation unsuccessful',
        flowId: error.response?.data?.data?.flow_id,
        message: 'Your notification was not created successfully',
        type: 'error',
      }));
      dispatch(hideModal(MODAL_ID));
      throw new SubmissionError({
        _error: error.response.data.error,
      });
    });
};

export const deleteNotification = id => dispatch => (
  api.delete(`/notifications/${id}`, userHeaders())
    .then((response) => {
      dispatch({ type: types.NOTIFICATION_DELETE_SUCCESS, payload: response.data });
      dispatch(fetchUserNotifications());
      dispatch(showAlert({
        header: 'Notification Deleted',
        message: 'Your notification has been deleted successfully',
        type: 'success',
        dismissAfter: 2000,
      }));
    })
    .catch((error) => {
      dispatch({ type: types.NOTIFICATION_DELETE_FAILURE, payload: error });
      dispatch(showAlert({
        header: 'Notification Deletion Unsuccessful',
        flowId: error.response?.data?.data?.flow_id,
        message: 'Your notification was not deleted',
        type: 'error',
      }));
    })
);
