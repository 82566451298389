import types from '../types/blog';

const blogReducer = (
  state = {
    blog_posts: [],
    loading: true,
    blog_page_data: undefined,
    page_data_loading: true,
    error: null,
  },
  action
) => {
  switch (action.type) {
    case types.FETCH_BLOG_POSTS:
      return { ...state, loading: true }
    case types.FETCH_BLOG_POSTS_SUCCESS:
      return {
        ...state,
        loading: false,
        blog_posts: action.payload.blog_posts,
      }
    case types.FETCH_BLOG_POSTS_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case types.FETCH_BLOG_PAGE_DATA:
      return { ...state, page_data_loading: true }
    case types.FETCH_BLOG_PAGE_DATA_SUCCESS:
      return {
        ...state,
        page_data_loading: false,
        blog_page_data: action.payload.blog_page_data,
      }
    case types.FETCH_BLOG_PAGE_DATA_FAILURE:
      return { ...state, page_data_loading: false, error: action.payload }
    default:
      return state
  }
}

export default blogReducer;