import React from 'react';
import { Form } from 'semantic-ui-react';

const FilterListRenderer = ({
  type,
  data,
  appliedFilters,
  addFilter,
  removeFilter,
}) => {
  return (
    <>
      <Form.Group grouped>
        {data.map(cat => {
          let itemChecked = appliedFilters.find(
            f =>
              f.type === type &&
              (f.value.name === cat.value || f.value.value === cat.value),
          )
            ? true
            : false;

          return (
            <Form.Checkbox
              className={'filter-checkbox'}
              key={cat.value.toString()}
              checked={itemChecked}
              label={cat.text}
              onChange={(e, { checked }) => {
                if (checked) {
                  addFilter({
                    name: type,
                    options: data,
                    value: type === 'condition' ? cat.text : cat.value,
                  });
                } else {
                  removeFilter({
                    name: type,
                    options: data,
                    value: type === 'condition' ? cat.text : cat.value,
                  });
                }
              }}
            />
          );
        })}
      </Form.Group>
    </>
  );
};

export default FilterListRenderer;
