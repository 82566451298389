const FETCH_BLOG_POSTS = 'blog/FETCH_BLOG_POSTS';
const FETCH_BLOG_POSTS_SUCCESS = 'blog/FETCH_BLOG_POSTS_SUCCESS';
const FETCH_BLOG_POSTS_FAILURE = 'blog/FETCH_BLOG_POSTS_FAILURE';

// Contentful
const FETCH_BLOG_PAGE_DATA = 'blog/FETCH_BLOG_PAGE_DATA'
const FETCH_BLOG_PAGE_DATA_SUCCESS = 'blog/FETCH_BLOG_PAGE_DATA_SUCCESS'
const FETCH_BLOG_PAGE_DATA_FAILURE = 'blog/FETCH_BLOG_PAGE_DATA_FAILURE'

export default {
  FETCH_BLOG_POSTS,
  FETCH_BLOG_POSTS_SUCCESS,
  FETCH_BLOG_POSTS_FAILURE,
  FETCH_BLOG_PAGE_DATA,
  FETCH_BLOG_PAGE_DATA_SUCCESS,
  FETCH_BLOG_PAGE_DATA_FAILURE
};