import React, { useState } from 'react';
import { Container, Icon, Menu, Embed } from 'semantic-ui-react';

import howItWorkPeople from '../../images/howItWork1.png';
import howItWorkCreate from '../../images/howItWork2.png';
import howItWorkPhone from '../../images/howItWork3.png';
import howItWorkBrowse from '../../images/howItWork4.png';
import howItWorkContact from '../../images/howItWork5.png';
import youtubePlaceHolder from '../../images/youtubePlaceHolder.png';

import './resources.css';

const WorksSteps = (props) => (

  <div style={{marginTop: '16px', width: '320px'}}>
    <img alt='how it works steps illustration' height='189' src={props.image} />
    <h3>{props.stepNum}</h3>
    <p style={{margin: '0 32px'}}>{props.stepDescription}</p>
  </div>
)


const HowItWorks = () => {

  const [activeItem, changeItem] = useState('sell')

  function changeMenu (change) {
      changeItem(prevState => prevState = change )
  }

return (
  <div style={{background: 'white', minHeight: 'calc(100vh - 60px)', paddingBottom: '36px'}}>
  <Container style={{margin: (window.innerWidth > 700? '0 auto': '0 5%'), maxWidth: '1120px', paddingBottom: '160px'}} >
    <div style={{paddingTop: '24px'}}>

      <a style={{textDecoration: 'none', color: '#575757', marginBottom: '40px'}} href='/resources'> <Icon name='angle left'/>Back to Resources </a>

      <h2 style={{color: '#0275B3', marginBottom: '16px'}}> How AssistList Works</h2>


      <p>AssistList is simple and easy to use! Follow these simple steps when selling or buying home health medical equipment on our website. </p>

      <div style={{margin: '48px 0px', display: 'flex', justifyContent: 'center'}}>
        <Menu className='howWorksMenu' widths={2}>
          <Menu.Item name='Sell' active={activeItem === 'sell'} onClick={() => changeMenu('sell')}>
            Sell</Menu.Item>
          <Menu.Item name='Find' active={activeItem === 'find'} onClick={() => changeMenu('find')}>
            Find</Menu.Item>
        </Menu>
      </div>

      <div style={{display: 'flex', flexDirection: 'row', textAlign: 'center', marginBottom: '80px', justifyContent: 'center', flexWrap: (window.innerWidth > 700? '' : 'wrap')}}>
        {activeItem === 'sell' && (
        <React.Fragment>
        <WorksSteps
          image={howItWorkCreate}
            stepNum='Step 1'
            stepDescription='Submit your equipment listing(s) for sale or donation on our website'/>
        <WorksSteps
          image={howItWorkPhone}
            stepNum='Step 2'
            stepDescription='Respond to buyers through emails in your personal inbox to coordinate an exchange'/>
        <WorksSteps
          image={howItWorkPeople}
            stepNum='Step 3'
            stepDescription='Meet up with a buyer to facilitate the exchange at a Community Exchange Zone'/>
        </React.Fragment>)}
        {activeItem === 'find' && (
        <React.Fragment>
        <WorksSteps
          image={howItWorkBrowse}
            stepNum='Step 1'
            stepDescription='Browse through the equipment listings on our website'/>
        <WorksSteps
          image={howItWorkContact}
            stepNum='Step 2'
            stepDescription='Contact sellers to learn more about the equipment and to coordinate an exchange'/>
        <WorksSteps
          image={howItWorkPeople}
            stepNum='Step 3'
            stepDescription='Contact sellers to learn more about the equipment and to coordinate an exchange'/>
        </React.Fragment>)}
      </div>

      <div>
      <h2 style={{color: '#0275B3', marginBottom: '16px'}}> Step-by-step Video</h2>
      <p style={{fontFamily: 'Karla'}}>Watch our step-by-step video on how to create equipment listings on Assistlist.</p>

      <Embed
        id='onf7qLvQgPo'
        placeholder={youtubePlaceHolder}
        source='youtube'
      />

      </div>

    </div>
  </Container>
  </div>
  )
};

export default HowItWorks;