const FETCH_ACTIVE_BANNER = "banners/FETCH_ACTIVE_BANNER";
const FETCH_ACTIVE_BANNER_SUCCESSFUL = "banners/FETCH_ACTIVE_BANNER_SUCCESSFUL";
const FETCH_ACTIVE_BANNER_FAILURE = "banners/FETCH_ACTIVE_BANNER_FAILURE";
const TURNOFF_ACTIVE_BANNER = "banners/TURNOFF_ACTIVE_BANNER";

export default {
    FETCH_ACTIVE_BANNER,
    FETCH_ACTIVE_BANNER_SUCCESSFUL,
    FETCH_ACTIVE_BANNER_FAILURE,
    TURNOFF_ACTIVE_BANNER
}