import React, { useState } from 'react';
import { Accordion, Icon, Responsive, Modal } from 'semantic-ui-react';
import InActiveTeamMembers from './InActiveTeamMember';

const PastTeam = () => {
  const index = 0;
  const [activeIndex, setActiveIndex] = useState(-1);

  return (
    <div>
      <Responsive minWidth={820}>
        <Accordion fluid styled>
          <Accordion.Title
            active={activeIndex === 0}
            index={0}
            onClick={() => setActiveIndex(activeIndex === index ? -1 : index)}
          >
            <Icon name='dropdown' />
        Past Volunteers
      </Accordion.Title>
          <Accordion.Content active={activeIndex === 0}>

            <InActiveTeamMembers />

          </Accordion.Content>
        </Accordion>
      </Responsive>
      <Responsive maxWidth={819} >
        <Modal style={{ height: '400px', width: '345px' }}

          trigger={
            <Accordion fluid styled>
              <Accordion.Title
                active={activeIndex === 0}
                index={0}
                onClick={() => setActiveIndex(activeIndex === index ? -1 : index)}
              >
                <Icon name='dropdown' />
        Past Volunteers
      </Accordion.Title>
            </Accordion>} closeIcon >

          <Modal.Content >
            <p style={{
              fontFamily: "Karla, Arial, Helvetica, sans-serif",
              lineHeight: '21px',
              fontWeight: "bold",
              fontSize: "18px"
            }}> Past Volunteers </p>
            <InActiveTeamMembers />
          </Modal.Content>
        </Modal>
      </Responsive>
    </div>
  );
}

export default PastTeam;