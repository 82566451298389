import React from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Segment,
  Item,
  Grid,
  Icon,
  Label,
  Header,
  Pagination,
  Responsive,
} from 'semantic-ui-react';

import PostsListDropdown from '../PostsListDropdown';
import noImage from '../../images/no_image.png';

import '../../stylesheets/postcomponent.css';

const postLabel = {
  width: '8rem',
  textAlign: 'center',
};

const UserListings = ({
  posts: {
    posts,
    pagination,
    error,
    loading,
  },
  handlePageChange,
  profile = false,
}) => (
  <div>
    <Segment disabled={error} loading={loading}>
      {!loading && (
        <Item.Group divided relaxed>
          {posts.map((post) => {
            const imgAlt = `${post.category.name.toLowerCase()}-${post.title.toLowerCase()}`;
            return (
              <Item key={post.id} className="post">
                <Item.Image
                  src={post.images ? post.images.thumbnail : noImage}
                  alt={imgAlt}
                  as={Link}
                  label={{ as: 'a',
                          color: (post.active ? 'blue' : (post.last_feedback ? 'red': 'grey')),
                          content: (post.active ? 'Active' : (post.last_feedback ? 'Sold': 'Inactive')),
                          ribbon: true,}}
                  to={`/posts/${post.id}`}/>

                <Item.Content verticalAlign="top">
                  <Container fluid>
                    <Grid style={{ padding: '0px', margin: '0px' }} className="noPadding">
                      <Grid.Row style={{ padding: '0px 20px 0px 0px', margin: '0px' }}>
                        <Grid.Column floated="left" computer={13} mobile={10} style={{ padding: '0px', margin: '0px' }} as={Link} to={`/posts/${post.id}`}>
                          <Header>
                            {post.title.length > 140 ? `${post.title.substring(0, 70)}...` : post.title}
                          </Header>
                        </Grid.Column>
                        {!profile && (
                        <Grid.Column floated="right" computer={2} mobile={6} textAlign="right">
                          <Label
                            basic
                            size="large"
                            className={post.free ? 'free' : 'price'}
                            content={post.free ? 'FREE' : `$${post.price}`}
                          />
                        </Grid.Column>
                        )}
                        {profile && (
                        <Grid.Column floated="right" computer={2} mobile={6} textAlign="right">
                          <PostsListDropdown
                            post={post}
                            dropdown={{
                              floating: true,
                              color: 'blue',
                              basic: true,
                              button: true,
                              pointing: 'right',
                              text: ' ',
                              className: 'icon',
                              floated: 'right',
                            }}
                          />
                        </Grid.Column>
                        )}
                      </Grid.Row>
                      <Grid.Row style={{ padding: '0px', margin: '0px' }} as={Link} to={`/posts/${post.id}`}>
                        <Grid.Column style={{ padding: '0px' }}>
                          <Icon disabled name="marker" />
                          <span
                            style={{
                              margin: '0.5em 0em 0.5em', fontSize: '1em', lineHeight: '1em', color: 'rgba(0, 0, 0, 0.6)',
                            }}
                          >
                            {`${post.location.name} - ${post.updated_at}`}
                          </span>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row as={Link} to={`/posts/${post.id}`}>
                        <Grid.Column style={{ paddingLeft: '0px', color: 'rgba(0,0,0,1)' }}>
                          {post.description.length > 140 ? `${post.description.substring(0, 140)}...` : post.description}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row style={{ padding: '0px', margin: '0px' }} as={Link} to={`/posts/${post.id}`}>
                        <Grid.Column style={{ padding: '0px' }}>
                          <Label.Group basic size="small">
                            <Label content={post.category.name} style={postLabel} />
                            <Label content={post.equipment_type.name} style={postLabel} />
                            <Label content={post.condition.name} style={postLabel} />
                            {post.user.partner && post.user.partner.name && <Label color="green" icon="check" content={post.user.partner.name} style={{ width: '10rem' }} />}
                          </Label.Group>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Container>
                </Item.Content>
              </Item>
            );
          })}
        </Item.Group>
      )}
      {error && <Header as="h1" content={error.message} />}
    </Segment>
    {!loading && !error && (
      <Container textAlign="center">
        <Responsive {...Responsive.onlyMobile}>
          <Pagination
            totalPages={pagination.total_pages}
            activePage={pagination.current_page}
            onPageChange={(_e, { activePage }) => handlePageChange(activePage)}
            ellipsisItem={null}
            boundaryRange={0}
            siblingRange={1}
          />
        </Responsive>
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          <Pagination
            totalPages={pagination.total_pages}
            activePage={pagination.current_page}
            onPageChange={(_e, { activePage }) => handlePageChange(activePage)}
          />
        </Responsive>
      </Container>
    )}
  </div>
);

export default UserListings;
