import React from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import { Icon, Responsive } from 'semantic-ui-react'

const bannerStyle = {
	background: '#fcdf00',
	color: '#000000',
	display: 'flex',
	position: 'center',
	paddingLeft: '18px',
	paddingRight: '18px',
	paddingTop: '10px',
	paddingBottom: '10px',
	fontSize: '17px',
	borderRadius: '4px',
}

const linkStyle = {
	color: '#000000',
	textDecoration: 'underline',
	fontWeight: '900',
	marginLeft: '5px',
}

const bannerButton = 'Learn\xA0More' // note that \xA0 ensures a non breaking space
const buttonTitle = 'Your account has not been verified.'
const buttonURL = '/account/settings' // update with the URL to the account settings page

function EmailConfirmationBanner() {
	return (
		<div style={bannerStyle}>
			{' '}
			<Icon name="exclamation circle" size="large" />
			<p style={window.innerWidth <= 475 ? { maxWidth: '300px' } : { maxWidth: '1100px' }}>
				<b>
					Your account is <u>not</u> verified yet.{' '}
				</b>{' '}
				{window.innerWidth <= Responsive.onlyMobile.maxWidth ? <br /> : null} We have sent you an email verification,
				please check your personal email inbox.
				<Link
					style={
						window.innerWidth <= Responsive.onlyMobile.maxWidth
							? { ...linkStyle, display: 'block', marginTop: '20px', marginBottom: '15px' }
							: { ...linkStyle }
					}
					title={buttonTitle}
					to={buttonURL}
				>
					{bannerButton}
				</Link>
			</p>
		</div>
	)
}

export default EmailConfirmationBanner
