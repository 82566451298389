const FETCH_OPTIONS_SUCCESS = 'notifications/FETCH_OPTIONS_SUCCESS';
const FETCH_OPTIONS_FAILURE = 'notifications/FETCH_OPTIONS_FAILURE';
const NOTIFICATION_SUBMIT_SUCCESS = 'notifications/NOTIFICATION_SUBMIT_SUCCESS';
const NOTIFICATION_SUBMIT_FAILURE = 'notifications/NOTIFICATION_SUBMIT_FAILURE';
const NOTIFICATION_FETCH_ALL = 'notifications/NOTIFICATION_FETCH_ALL';
const NOTIFICATION_FETCH_ALL_SUCCESS = 'notifications/NOTIFICATION_FETCH_ALL_SUCCESS';
const NOTIFICATION_FETCH_ALL_FAILURE = 'notifications/NOTIFICATION_FETCH_ALL_FAILURE';
const NOTIFICATION_USER_FETCH = 'notifications/NOTIFICATION_USER_FETCH';
const NOTIFICATION_USER_FETCH_SUCCESS = 'notifications/NOTIFICATION_USER_FETCH_SUCCESS';
const NOTIFICATION_USER_FETCH_FAILURE = 'notifications/USER_NOTIFICATIONS_FETCH_FAILURE';
const NOTIFICATION_DELETE_SUCCESS = 'notifications/NOTIFICATION_DELETE_SUCCESS';
const NOTIFICATION_DELETE_FAILURE = 'notifications/NOTIFICATION_DELETE_FAILURE';

export default {
  FETCH_OPTIONS_SUCCESS,
  FETCH_OPTIONS_FAILURE,
  NOTIFICATION_SUBMIT_SUCCESS,
  NOTIFICATION_SUBMIT_FAILURE,
  NOTIFICATION_FETCH_ALL,
  NOTIFICATION_FETCH_ALL_SUCCESS,
  NOTIFICATION_FETCH_ALL_FAILURE,
  NOTIFICATION_USER_FETCH,
  NOTIFICATION_USER_FETCH_SUCCESS,
  NOTIFICATION_USER_FETCH_FAILURE,
  NOTIFICATION_DELETE_SUCCESS,
  NOTIFICATION_DELETE_FAILURE,
};
