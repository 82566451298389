import types from "../types/faq";

const faqReducer = (
  state = {
    loading: true,
    error: null,
    openPanel: false,
    faq_general_categories: [],
    faq_health_safety_categories: [],
    faqPageData: undefined,
    faqContactUsPageData: undefined,
  },
  action
) => {
  switch (action.type) {
    case types.FETCH_QUESTIONS_HEALTH_SAFETY:
      return { ...state, loading: true };
    case types.FETCH_QUESTIONS_HEALTH_SAFETY_SUCCESS:
      return {
        ...state,
        loading: false,
        faq_health_safety_categories: action.payload.faq_categories,
      };
    case types.FETCH_QUESTIONS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case types.FETCH_FAQ_PAGE_DATA:
      return { ...state };
    case types.FETCH_FAQ_PAGE_DATA_SUCCESS:
      return {
        ...state,
        faqPageData: action.payload.faqPageData,
      };
    case types.FETCH_FAQ_PAGE_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case types.FETCH_FAQ_CONTACT_US_PAGE_DATA:
      return {
        ...state,
      };
    case types.FETCH_FAQ_CONTACT_US_PAGE_DATA_SUCCESS:
      return {
        ...state,
        faqContactUsPageData: action.payload.faqContactUsPageData,
      };
    case types.FETCH_FAQ_CONTACT_US_PAGE_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default faqReducer;
