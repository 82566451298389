import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';

import rootReducer from './reducers';

export const history = createBrowserHistory();

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk)),
);
