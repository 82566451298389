/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchTeamMembers } from '../../actions/aboutus';
import './aboutus.css';


const ActiveTeamMember = props => (
  <div style={{
    width: '140px',
    marginLeft: (window.innerWidth > 820 ? '16px' : '0px'),
    marginRight: (window.innerWidth > 820 ? '0px' : '10px')
  }}><p>{props.name}</p>
  </div>
);

const TeamMembers = ({ team, teamMembers, _fetchTeamMembers }) => {

  useEffect(() => {
    _fetchTeamMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
      }}
      >
        {(teamMembers && teamMembers.length > 0) ? teamMembers.map((teamMember, i) => (
          (i === 0 && teamMember.team === team && !teamMember.end_date)
            ? (
              <div key={teamMember.name}>
                <ActiveTeamMember name={teamMember.name} />
              </div>
            ) : (teamMember.team === team && !teamMember.end_date) ? (
              <div key={teamMember.name}>
                <ActiveTeamMember name={teamMember.name} />
              </div>
            ) : (<span key={teamMember.name} />))) : <span />}
      </div>
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  _fetchTeamMembers: () => dispatch(fetchTeamMembers()),
});

const mapStateToProps = state => ({
  teamMembers: state.aboutus.teamMembers,
  loading: state.aboutus.loading,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TeamMembers);
