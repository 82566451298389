import React, { useState } from 'react';
import { Label, Grid } from 'semantic-ui-react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const fontStyle14PX = {
  color: 'gray',
  fontFamily: 'Karla',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '14px',
  lineHeight: '19px',
  borderColor: '#00ACDA',
};

const fontStyle16PX = {
  fontFamily: 'Karla',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '16px',
  lineHeight: '19px',
};

const GeoRangerSlider = ({ updateRadius, radius, municipal }) => {
  const [value, setValue] = useState(radius);

  const marks = {
    10: {
      style: fontStyle14PX,
      fontStyle14PX,
      label: <strong>10km</strong>,
    },
    25: '25',
    40: '40',
    55: '55',
    70: '70',
    85: '85',
    100: {
      style: fontStyle14PX,
      fontStyle14PX,
      label: <strong>100km</strong>,
    },
  };

  function log(value) {
    setValue(value);
    updateRadius(value);
  }

  return (
    <Grid style={{ marginBottom: '20px' }}>
      <Grid.Column
        width={9}
        style={{ paddingBottom: '5px', display: 'flex', alignItems: 'center' }}
      >
        <span style={fontStyle16PX}>Distance from {municipal}</span>
      </Grid.Column>
      <Grid.Column
        width={7}
        floated="right"
        textAlign="right"
        style={{
          paddingBottom: '5px',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Label
          style={
            value === 10
              ? {
                  ...fontStyle16PX,
                  padding: '3px 10px 3px 10px',
                  color: '#AFAFAF',
                  borderColor: '#AFAFAF',
                }
              : {
                  ...fontStyle16PX,
                  padding: '3px 10px 3px 10px',
                  color: '#1A659F',
                  borderColor: '#1A659F',
                }
          }
          basic
        >
          {value} km
        </Label>
      </Grid.Column>
      <Grid.Column width={16} style={{ marginTop: '5px', padding: '0px 16px' }}>
        <Slider
          dots
          min={10}
          marks={marks}
          step={15}
          onChange={log}
          defaultValue={value}
          trackStyle={[
            { backgroundColor: '#00ACDA' },
            { backgroundColor: '#00ACDA' },
          ]}
          handleStyle={{
            borderColor: '##1A659F',
            height: 16,
            width: 16,
            backgroundColor: 'white',
            marginTop: -7,
            opacity: 1,
          }}
          railStyle={{ backgroundColor: '#C8C8C8' }}
          style={{
            paddingTop: '5px',
            paddingBottom: '30px',
          }}
        />
      </Grid.Column>
    </Grid>
  );
};

export default GeoRangerSlider;
