import React from 'react';

import { connect } from 'react-redux';
import { HashLink as Link } from 'react-router-hash-link';

import {
  Loader, Form, Message, Dimmer,
} from 'semantic-ui-react';
import { reduxForm, Field } from 'redux-form';

import { CheckboxField, SelectField } from '../../utils/render-input';
import { fetchOptions, submitNotification } from '../../actions/notifications';

import ReactGA from 'react-ga';

const validate = (values) => {
  const errors = {};

  if (values.category_id == null) {
    errors.category_id = 'Please select a category';
  }

  if (values.equipment_type_id == null) {
    errors.equipment_type_id = 'Please select an equipment type';
  }

  if (!values.termsOfUse) {
    errors.termsOfUse = 'You must agree to the Terms of Use';
  }

  return errors;
};

class NotificationForm extends React.Component {
  constructor() {
    super();

    this.state = { category_id: 0,
                   categoryFlag: true,
                   equipmentFlag: true };
  }

  componentDidMount() {
    const { _fetchOptions, initialValues: { category_id } } = this.props;

    if (category_id) {
      this.setState({ category_id });
    }

    _fetchOptions();

    if(localStorage.getItem('gaEventFlow') === 'notificationBtnLoggedIn'){
      this.setState({gaCategory : 'User with Account - Already logged in - Subscribe to Notification Btn clicked'});
    } else if (localStorage.getItem('gaEventFlow') === 'notificationBtnNotLoggedIn'){
        this.setState({gaCategory : 'User with Account - Login to Notification Btn clicked'});
    } else if(localStorage.getItem('gaEventFlow') === 'notificationBtnNoAccount'){
        this.setState({gaCategory : 'User No Account - Subscribe to Notification Btn clicked'});
    } else if(localStorage.getItem('gaEventFlow') === 'filterNoResultLoggedIn'){
        this.setState({gaCategory : 'User with Account - Already logged in - No Results Filter Btn clicked'})
    } else if(localStorage.getItem('gaEventFlow') === 'filterNoResultNotLoggedIn'){
        this.setState({gaCategory : 'User with Account - Not logged in - No Results Filter Btn clicked'})
    } else if(localStorage.getItem('gaEventFlow') === 'filterNoResultNoAccount'){
        this.setState({gaCategory : 'User No Account - No Results Filter Btn clicked'})
    }
  }

  componentWillUnmount() {
    localStorage.removeItem('filterTriggerForm');
    localStorage.removeItem('filterNotLoggedIn')
  };

  gaEventFunction  (Category, Action, Label){
     ReactGA.event({ category: Category, action: Action, label: Label});
  };

  handleCategoryChange(value) {
    this.setState({ category_id: value });

    if(this.state.categoryFlag){
      this.gaEventFunction(this.state.gaCategory, 'click', 'Category Dropdown Clicked')
      this.setState({categoryFlag: false})
    }
  }

  handleEquipmentChange() {
    if(this.state.equipmentFlag){
      this.gaEventFunction(this.state.gaCategory, 'click', 'Equipment Dropdown Clicked')
      this.setState({equipmentFlag: false})
    }
  }

  checkBoxClicked(event) {
    if(event.target){
      this.gaEventFunction(this.state.gaCategory, 'click', 'Terms of Service Checked')
    }
  }

  notifyMeEvent() {
    this.gaEventFunction(this.state.gaCategory, 'click', 'Notification Created');
    localStorage.removeItem('gaEventFlow');
  }

  render() {
    const { handleSubmit, notifications: { options, optionsLoading } } = this.props;

    const { category_id } = this.state;

    return (
      <div>
        <Message
          icon="bell"
          info
          header="Can't find the item you're searching for?"
          content="We will send you an email when someone lists equipment that you're interested in! You can change your notifications at any time on your Notification Settings page."
        />
        <Dimmer active={optionsLoading} inverted>
          <Loader active={optionsLoading} />
        </Dimmer>
        {!optionsLoading && (
          <Form name="newNotification" onSubmit={handleSubmit(submitNotification)}>
            <Field
              component={SelectField}
              name="category_id"
              label="Category"
              options={options.categories}
              placeholder="Select a category"
              required
              onChange={(e, value) => this.handleCategoryChange(value)}
            />
            <Field
              component={SelectField}
              name="equipment_type_id"
              label="Equipment Type"
              options={options.categories[category_id].equipment_types}
              placeholder="Select an equipment type"
              required
              onChange={(e, value) => this.handleEquipmentChange(value)}
            />
            <Field
              component={CheckboxField}
              name="termsOfUse"
              onChange={(e) => this.checkBoxClicked(e)}
              label={<label>I agree to the <Link to="/policies#terms-of-use" target="_blank"><strong>Terms of Use</strong></Link></label>}
            />
            <Form.Button onClick={() => this.notifyMeEvent()} primary content="Notify Me" />
          </Form>)
        }
      </div>
    );
  }
}

const NotificationFormContainer = reduxForm({
  form: 'newNotification',

  validate,

})(NotificationForm);


const mapDispatchToProps = dispatch => ({
  _fetchOptions: () => dispatch(fetchOptions()),
});

const mapStateToProps = state => ({

  notifications: state.notifications,

});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationFormContainer);
