const apiUrl = process.env.REACT_APP_API_HOST || 'http://localhost:3000';

const activeStorageUrl = `${apiUrl}/rails/active_storage/direct_uploads`;
const facebookOauthUrl = `${apiUrl}/sessions/oauth?type=facebook`;
const googleOauthUrl = `${apiUrl}/sessions/oauth?type=google`;

const FEEDBACK_OPTIONS = {
  exchanged: 'exchanged',
  declined_to_renew: 'declined_to_renew',
};

export default {
  apiUrl,
  activeStorageUrl,
  facebookOauthUrl,
  googleOauthUrl,
  FEEDBACK_OPTIONS,
}
