/* This is an emergency banner placed beneath the main navigation in order to get emergency
information to users fast. The only other page touched is the NavBar.js where it is imported
as the EmergencyBanner. Please comment it out of the code there once no longer required. */

import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { fetchActiveBanner, turnOffBanner } from "../actions/banner";

const bannerStyle = {
  background: "#F8FFFF",
  color: "#276F86",
  display: "flex",
  position: "center",
  paddingLeft: "18px",
  paddingRight: "18px",
  paddingTop: "10px",
  paddingBottom: "10px",
};

const linkStyle = {
  color: "#000000",
  textDecoration: "underline",
  fontWeight: "900",
  marginLeft: "10px",
};

const bannerStyleMobile = {
  position: "absolute",
  right: "20px",
  top: "87px",
  cursor: "pointer",
};

const bannerStyleDesktop = {
  position: "absolute",
  right: "20px",
  cursor: "pointer",
};

const bannerButton = "Browse\xA0Here";
const buttonTitle = "AssistList Health & Safety Guidelines";


function EmergencyBanner({ banner, getActiveBanner, show, turnOffBanner }) {

  useEffect(() => {
    getActiveBanner();
  }, [getActiveBanner])
  
  if (show && banner) {
    return (
      <div style={bannerStyle}>
        <h5
          style={
            window.innerWidth <= 475
              ? { maxWidth: "300px" }
              : { maxWidth: "800px" }
          }
        >
          {`${banner.content}`}
          <a style={linkStyle} title={buttonTitle} href={banner.url} target="_blank" rel="noopener noreferrer">
            {bannerButton}
          </a>      

          <i
            className="x icon"
            style={
              window.innerWidth >= 485 ? bannerStyleDesktop : bannerStyleMobile
            }
            onClick={() => turnOffBanner()}
          />
        </h5>
      </div>
    );
 }
  return null;
}

const mapStateToProps = state => ({
  banner: state.banners.banner,
  show: state.banners.show
});

const mapDispatchToProps = (dispatch) => ({
  turnOffBanner: () => dispatch(turnOffBanner()),
  getActiveBanner: () => dispatch(fetchActiveBanner())
});

export default connect(mapStateToProps, mapDispatchToProps)(EmergencyBanner);
