import React, { useEffect, Fragment } from 'react'
import { Grid, Button, Responsive, Loader } from 'semantic-ui-react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import Testimonials from './Testimonials'
import Images from './Images'
import AboutUsHeader from './AboutUsHeader'
import { fetchAboutUsPageData } from '../../actions/aboutus'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import WireframeLoader from '../WireframeLoader'

import rectangle from '../../images/rectangle.png'
import laptop from '../../images/laptopGuy.svg'
import wheelchair from '../../images/wheelChair.svg'
import walker from '../../images/walker.svg'
import getInvolved from '../../images/Get_Involved.svg'

const headerStyling = {
  fontFamily: "'Nunito', Arial, Helvetica, sans-serif",
  fontWeight: 'bold',
  fontSize: '32px',
  lineHeight: '44px',
  paddingBottom: '40px',
}

const paragraphStyling = {
  fontFamily: 'Karla, Arial, Helvetica, sans-serif',
  fontWeight: 'normal',
  fontSize: '18px',
  color: '#333333',
  margin: '0',
  paddingBottom: '40px',
}

const statisticsStyling = {
  marginBottom: '0px',
  color: '#00ADD8',
}

const overlapPicturesContainer = {
  position: 'relative',
  height: '300px',
  width: '450px',
}

const OurStory = (body) => {
  return (<Fragment>
    <img src={rectangle} alt="" />
    <h1 style={headerStyling}>Our Story</h1>
    <p style={paragraphStyling}>
      { documentToReactComponents(body) }
    </p>
    <Button
      style={{
        width: '224px',
        height: '40px',
        marginTop: '40px',
        marginLeft: window.innerWidth > 660 ? '0' : '15%',
      }}
      primary
      href="/aboutus/ourstory"
    >
      Read Our Story
    </Button>
  </Fragment>)
}

const OurImpact = (body) => (
  <Fragment>
    <img src={rectangle} alt="" />
    <h1 style={headerStyling}>Our Impact</h1>
    <p style={paragraphStyling}>
      { documentToReactComponents(body) }
    </p>
  </Fragment>
)

const LandingAboutUs = ({ aboutUsPageData, _fetchAboutUsPageData }) => {
  useEffect(() => {
    _fetchAboutUsPageData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <AboutUsHeader picture={aboutUsPageData ? aboutUsPageData.title : ''} />
      <Helmet>
        <title>About Us - AssistList</title>
        <meta
          name="description"
          content="AssistList is a non-profit organization that promotes public health by empowering Greater Vancouver residents to buy, sell or donate home health equipment."
        />
      </Helmet>
      {/* OUR STORY */}
      <div style={{ paddingTop: '80px', paddingBottom: '80px' }}>
        <div style={{ margin: window.innerWidth > 660 ? '0 20%' : '0 10%', textAlign: 'center', marginBottom: '56px' }}>
          <div style={headerStyling}>
            {aboutUsPageData ? documentToReactComponents(aboutUsPageData.aboutUsHeading.body) : <WireframeLoader short={true} />}
          </div>
        </div>

        <Responsive minWidth={1400}>
          <Grid style={{ margin: '0 20%' }} stackable>
            <Grid.Column computer={8}>
              <div style={overlapPicturesContainer}>
                <img style={{ position: 'absolute', top: '0', left: '20' }} src={laptop} alt="man_with_laptop" />
                <img
                  style={{
                    position: 'absolute',
                    top: '50px',
                    left: '155px',
                    width: '232px',
                    height: '274px',
                  }}
                  src={wheelchair}
                  alt="woman_in_wheelchair"
                />
              </div>
            </Grid.Column>
            <Grid.Column computer={8}>
              {aboutUsPageData ? OurStory(aboutUsPageData.ourStorySnippet.body) : <WireframeLoader short={true} />}
            </Grid.Column>
          </Grid>
        </Responsive>

        <Responsive maxWidth={1399} minWidth={1023}>
          <Grid style={{ margin: '0 10%' }} stackable>
            <Grid.Column computer={8}>
              <div style={overlapPicturesContainer}>
                <img style={{ position: 'absolute', top: '0', left: '20' }} src={laptop} alt="man_with_laptop" />
                <img
                  style={{
                    position: 'absolute',
                    top: '50px',
                    left: '155px',
                    width: '232px',
                    height: '274px',
                  }}
                  src={wheelchair}
                  alt="woman_in_wheelchair"
                />
              </div>
            </Grid.Column>
            <Grid.Column computer={8}>
              {aboutUsPageData ? OurStory(aboutUsPageData.ourStorySnippet.body) : <WireframeLoader short={true} />}
            </Grid.Column>
          </Grid>
        </Responsive>

        <Responsive minWidth={765} maxWidth={1022}>
          <Grid style={{ margin: '0 5%' }} stackable>
            <Grid.Column computer={8}>
              <div style={overlapPicturesContainer}>
                <img style={{ position: 'absolute', top: '77px', right: '194px' }} src={laptop} alt="man_with_laptop" />
              </div>
            </Grid.Column>
            <Grid.Column computer={8}>
              {aboutUsPageData ? OurStory(aboutUsPageData.ourStorySnippet.body) : <WireframeLoader short={true} />}
            </Grid.Column>
          </Grid>
        </Responsive>

        <Responsive maxWidth={764}>
          <Grid style={{ margin: '0 10%' }} stackable>
            <Grid.Column computer={8}>
              <div
                style={{
                  position: 'relative',
                  height: '200px',
                  width: '300px',
                  margin: '0 auto',
                }}
              >
                <img
                  style={{ position: 'absolute', top: '-66px', right: '138px' }}
                  src={laptop}
                  alt="man_with_laptop"
                />
                <img
                  style={{ position: 'absolute', top: '-21px', left: '91px' }}
                  src={wheelchair}
                  alt="woman_in_wheelchair"
                />
              </div>
            </Grid.Column>
            <Grid.Column computer={8}>
              {aboutUsPageData ? OurStory(aboutUsPageData.ourStorySnippet.body) : <WireframeLoader short={true} />}
            </Grid.Column>
          </Grid>
        </Responsive>
      </div>
      {/* OUR IMPACT */}
      <Responsive minWidth={661}>
        <div style={{ backgroundColor: '#f7f7f7', paddingBottom: '120px', paddingTop: '80px' }}>
          <div style={{ margin: window.innerWidth > 660 ? '0 20%' : '0, 10%' }}>
            <Grid stackable>
              <Grid.Column computer={8}>
                {aboutUsPageData ? OurImpact(aboutUsPageData.ourImpact.body) : <WireframeLoader />}
              </Grid.Column>

              <Grid.Column computer={8}>
                <div style={{ paddingLeft: '20%' }}>
                  <div
                    style={{
                      width: '255px',
                      height: '397px',
                      backgroundColor: '#FFFFFF',
                      boxShadow: '0px 2px 20px rgba(0,0,0, 0.2)',
                      paddingLeft: '49px',
                      paddingTop: '22px',
                      fontFamily: 'Karla, Arial, Helvetica, sans-serif',
                      fontSize: '18px',
                    }}
                  >
                    {aboutUsPageData ? (
                      Object.entries(aboutUsPageData.aboutUsFigures).map(([key, fig]) => (
                        <div key={`stat_${key}`} style={{ marginBottom: '20px' }}>
                          <h1 style={statisticsStyling}>{fig.value}</h1>
                          <p>{fig.label}</p>
                        </div>
                      ))
                    ) : (
                      <Loader active inline />
                    )}
                  </div>
                  <img
                    src={walker}
                    alt=""
                    style={{
                      position: 'absolute',
                      top: window.innerWidth > 768 && window.innerWidth < 1020 ? '' : '280px',
                      left: window.innerWidth > 768 && window.innerWidth < 1020 ? '' : '250px',
                    }}
                  />
                </div>
              </Grid.Column>
            </Grid>
          </div>
        </div>
      </Responsive>
      <Responsive maxWidth={660}>
        <div style={{ backgroundColor: '#f7f7f7', paddingBottom: '120px', paddingTop: '62px' }}>
          <div style={{ margin: window.innerWidth > 660 ? '0 20%' : '0, 10%' }}>
            <Grid stackable>
              <Grid.Column computer={8}>
                <div style={{ position: 'relative', paddingBottom: '220px', zIndex: '2' }}>
                  <div style={{ fontFamily: 'Karla, Arial, Helvetica, sans-serif', fontSize: '18px' }}>
                    {aboutUsPageData ? (
                      Object.entries(aboutUsPageData.aboutUsFigures).map(([key, fig]) => (
                        <div key={`stat_${key}`} style={{ marginBottom: '20px' }}>
                          <h1 style={statisticsStyling}>{fig.value}</h1>
                          <p>{fig.label}</p>
                        </div>
                      ))
                    ) : (
                      <Loader active inline />
                    )}
                  </div>
                  <img
                    src={walker}
                    alt=""
                    style={{
                      zIndex: '-1',
                      position: 'absolute',
                      top: '230px',
                      left: '84px',
                    }}
                  />
                </div>
              </Grid.Column>
              <Grid.Column computer={8}>
                {aboutUsPageData ? OurImpact(aboutUsPageData.ourImpact.body) : <WireframeLoader />}
              </Grid.Column>
            </Grid>
          </div>
        </div>
      </Responsive>
      {/* WORDS FROM OUR COMMUNITY */}
      <div style={{ paddingBottom: '120px', paddingTop: '120px' }}>
        <div style={{ margin: window.innerWidth > 660 ? '0 20%' : '0, 10%' }}>
          <Grid stackable>
            <Grid.Column computer={16}>
              <img src={rectangle} alt="" />
              <h1 style={headerStyling}>Words From Our Community</h1>
              <div>
                <Testimonials />
              </div>
            </Grid.Column>
          </Grid>
        </div>
      </div>
      {/* OUR TEAM */}
      <div style={{ paddingBottom: '120px', paddingTop: '120px', backgroundColor: '#f7f7f7' }}>
        <div style={{ margin: window.innerWidth > 660 ? '0 20%' : '0, 10%' }}>
          <Grid stackable>
            <Grid.Column computer={16}>
              <img src={rectangle} alt="" />
              <h1 style={headerStyling}>Our Team</h1>
              <p style={paragraphStyling}> Meet the team of volunteers who make this organization possible</p>
              <div>
                {aboutUsPageData ? <Images images={aboutUsPageData.aboutUsImages}/> : <WireframeLoader />}
              </div>
              <div style={{ width: '100%', textAlign: 'center', marginTop: window.innerWidth > 660 ? '80px' : '0px' }}>
                <br />
                <br />
                <Button
                  style={{ width: '224px', height: '40px', marginTop: window.innerWidth > 660 ? '40px' : '0px' }}
                  primary
                  href="/aboutus/ourteam"
                >
                  Meet Our Team
                </Button>
              </div>
            </Grid.Column>
          </Grid>
        </div>
      </div>
      {/* GET INVOLVED */}
      <div style={{ paddingTop: '120px' }}>
        <div
          style={{
            margin: window.innerWidth > 660 ? '0 20%' : '0, 10%',
            textAlign: window.innerWidth > 660 ? 'center' : '',
          }}
        >
          <Grid stackable>
            <Grid.Column computer={16}>
              <img src={rectangle} alt="" />
              <h1 style={headerStyling}>Get Involved</h1>
              <div style={{ width: window.innerWidth > 660 ? '56%' : '', display: 'inline-block' }}>
                <p style={paragraphStyling}>
                  Are you looking to give back to your community? We offer volunteer opportunities and would love for
                  enthusiastic and passionate individuals to join our AssistList family. As a registered charity, we
                  also accept donations.
                </p>
              </div>
              <Responsive minWidth={661}>
                <Button style={{ width: '224px', height: '40px', marginTop: '40px' }} primary href="/aboutus/ourteam">
                  I Want To Get Involved
                </Button>
                <Button style={{ width: '224px', height: '40px', marginTop: '40px' }} primary href="/donate">
                  I Wish To Donate
                </Button>
                <div
                  style={{
                    position: 'relative',
                    width: '500px',
                    height: '500px',
                    margin: '0 auto',
                  }}
                >
                  <img
                    style={{
                      position: 'absolute',
                      height: '369px',
                      width: '387px',
                      right: '50px',
                    }}
                    src={getInvolved}
                    alt=""
                  />
                </div>
              </Responsive>

              <Responsive maxWidth={660}>
                <Button style={{ width: '224px', height: '40px', marginLeft: '19%' }} primary href="/aboutus/ourteam">
                  I Want To Get Involved
                </Button>
                <Button
                  style={{
                    width: '224px',
                    height: '40px',
                    marginTop: '40px',
                    marginLeft: '19%',
                  }}
                  primary
                  href="/donate"
                >
                  I Wish To Donate
                </Button>
                <div
                  style={{
                    position: 'relative',
                    width: '350px',
                    height: '350px',
                    margin: '0 auto',
                  }}
                >
                  <img
                    style={{
                      position: 'absolute',
                      height: '339px',
                      width: '357px',
                    }}
                    src={getInvolved}
                    alt=""
                  />
                </div>
              </Responsive>
            </Grid.Column>
          </Grid>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  _fetchAboutUsPageData: () => dispatch(fetchAboutUsPageData()),
})

const mapStateToProps = (state) => ({
  aboutUsPageData: state.aboutus.aboutUsPageData,
})

export default connect(mapStateToProps, mapDispatchToProps)(LandingAboutUs)
