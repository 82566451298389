import React from 'react';
import { Helmet } from 'react-helmet';
import { HashLink as Link } from 'react-router-hash-link';
import {Button, Segment, Header, Grid} from 'semantic-ui-react';
import { connect } from 'react-redux';

const modalHeaderText = {
  color: '#1A659F', 
  fontWeight: 'bold', 
  margin: '0 auto',
  marginTop: '45px'
};

const NewsletterModal = ({handleClickNotNow}) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Stay up-to-date with AssistList</title>
      </Helmet>

        <Segment
          style={{borderRadius: '10px'}} 
          textAlign="center"
        >
          <Header 
            as='h1' 
            style={modalHeaderText}
          >
            Stay up-to-date with <br /> 
            AssistList
          </Header>

          <p style={{ 
            fontSize: '18px',
            marginTop: '40px',
            color: 'black',
            textAlign: 'left',
            paddingLeft: '30px',
            paddingRight: '30px'
          }}>
            AssistList now offers our members the latest tips on how to
            buy, sell, donate home health equipment, and useful advice from
            health professionals. Would you like to subscribe to our e-newsletter?
          </p>

          <Grid 
            columns='equal' style={{
            color: 'black',
            margin: 'auto',
            }}> 
            <Grid.Column width={8}>
                <div onClick={handleClickNotNow}
                style={{
                  color: '#1A659F', 
                  fontWeight: 'bold',
                  marginTop: '10px',
                  cursor: 'pointer'
                }}>
                  Not now
                </div >
            </Grid.Column>
            <Grid.Column width={8}>
                <Button
                  as={Link}
                  to='/account/settings'
                  style={{
                    backgroundColor: '#1A659F',
                    color: 'white',
                    borderRadius: '6px'
                  }}
                >
                  Subscribe
                </Button>
            </Grid.Column>
          </Grid>

          <p style={{ 
            fontSize: '14px',
            marginTop: '32px',
            color: 'black',
            textAlign: 'left',
            paddingLeft: '8px',
            paddingRight: '8px',
            paddingBottom: '16px'
          }}>
            By clicking "Subscribe", you agree to our&nbsp;
            <Link to="/policies#terms-of-use" target="_blank">
              <strong 
                style={{color: 'grey', textDecoration: 'underline'}}>
              Terms of Use
              </strong>
            </Link>.&nbsp;
            Our e-newsletters may also include other materials
            such as surveys to help improve your AssistList experience.
          </p>

        </Segment>
        
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  user: state.user,
});


export default connect(mapStateToProps, null)(NewsletterModal);
