import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Segment, Button } from 'semantic-ui-react';
import { reduxForm, getFormSyncErrors, getFormValues } from 'redux-form';
import EditPage from './EditPage';
import PreviewPage from './PreviewPage';
import {
  fetchPost, fetchEditForm, fetchPropFields, editPost,
} from '../../../actions/post';

const validate = (values) => {
  const errors = {};

  if (!values.title) {
    errors.title = 'Post title is required';
  }

  if (!values.description) {
    errors.description = 'Description is required';
  }

  if (!values.free) {
    if (!values.price || values.price === '0') {
      errors.price = 'Price is required, or did you mean free?';
    } else if (!/^\d+$/.test(values.price)) {
      errors.price = 'Price must be a whole number';
    } else if (values.price > 10000) {
      errors.price = 'Price exceeds limit of 10,000, enter a lower number';
    }
  }

  if (!values.condition_id) {
    errors.condition_id = 'Please select a condition';
  }

  if (!values.location_id) {
    errors.location_id = 'Please select a location';
  }

  if (!values.images || values.images.length === 0) {
    errors.images = 'Image is required';
  }

  return errors;
};

class EditForm extends React.Component {
  constructor() {
    super();

    this.state = {
      postFetched: false,
      uploaded: true,
      editState: 'editing',
    };

    this.setUploaded = uploaded => this.setState({ uploaded });

    this.handleSubmit = (values, dispatch) => {
      const { post: { postForm: { imagesToDelete } } } = this.props;
      return editPost({ ...values, imagesToDelete }, dispatch);
    };

    this.handlePreview = this.handlePreview.bind(this);
  }

  componentDidMount() {
    const { match: { params }, _fetchEditForm, _fetchPost } = this.props;

    _fetchEditForm(params.id);
    _fetchPost(params.id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.post.details.id !== undefined && prevProps.post.details.id !== this.props.post.details.id) {
      this.setState({ postFetched: false });
      window.scrollTo(0, 0);
    }
  }

  handlePreview() {
    window.scrollTo(0, 0);
    this.state.editState === 'editing'
      ? this.setState({ editState: 'previewing' })
      : this.setState({ editState: 'editing' });
  }

  render() {
    const {
      initialize,
      post: {
        details: {
          loading: postLoading,
        },
        postForm: {
          loading: formLoading,
          conditions,
          locations,
        },
      },
      post,
      match: {
        params,
      },
      handleSubmit,
      submitting,
      submitSucceeded,
      synchronousErrors,
      values,
    } = this.props;

    if (submitSucceeded) {
      return <Redirect to={`/posts/${params.id}`} />;
    }

    const loading = postLoading || formLoading;

    const { postFetched, editState } = this.state;

    if (loading) {
      return (
        <div>
          <div style={{ paddingTop: '16px' }}>
            <Button size="tiny" as={Link} to={`/posts/${params.id}`} color="black" icon="left chevron" content="Back to post" />
          </div>
          <Segment loading />
        </div>
      );
    }

    const {
      details: {
        id,
        title,
        description,
        price,
        free,
        condition,
        location,
        properties,
        images,
      },
      postForm: {
        fields,
      },
    } = post;

    if (!postFetched) {
      initialize({
        id,
        title,
        description,
        price,
        free,
        images,
        condition_id: condition.id,
        location_id: location.id,
        ...properties,
      });
      this.setState({ postFetched: true });
    }

    if (editState === 'previewing') {
      const formValues = { ...values };
      const postDetails = { ...post.details };

      const preview = {
        title: formValues.title,
        description: formValues.description,
        price: formValues.price,
        free: formValues.free,
        condition,
        location,
        images,
        properties: postDetails.properties,
        category: postDetails.category,
        equipment_type: postDetails.equipment_type,
        user: postDetails.user,
      };

      if (formValues.free) {
        values.price = 0;
        preview.price = 0;
      }

      if (postDetails.condition.id !== formValues.condition_id) {
        const newCond = { ...post.postForm.conditions.find(con => con.value === formValues.condition_id) };
        preview.condition = { id: newCond.value, name: newCond.text };
      }

      if (postDetails.location.id !== formValues.location_id) {
        const newLoc = { ...post.postForm.locations.find(loc => loc.value === formValues.location_id) };
        preview.location = { id: newLoc.value, name: newLoc.text };
      }

      Object.keys(preview.properties).forEach((key) => {
        if (preview.properties[key] !== formValues[key]) {
          preview.properties[key] = formValues[key];
        }
      });

      if (post.postForm.imagesToDelete.length !== 0) {
        post.postForm.images = post.postForm.images.filter(im => !post.postForm.imagesToDelete.includes(im.image_id));
      }

      preview.images = post.postForm.images.map(im => (im.preview ? {
        small: im.preview, large: im.preview, rotation_origin: im.rotation_origin,
      } : {
          small: im.small, large: im.large, rotation_origin: im.rotation_origin,
        }));

      return (
        <PreviewPage
          post={preview}
          submitting={submitting}
          disabled={!this.state.uploaded}
          onPreview={this.handlePreview}
          onSubmit={handleSubmit(this.handleSubmit)}
        />
      );
    }

    return (
      <EditPage
        params={params}
        loading={loading}
        conditions={conditions}
        locations={locations}
        fields={fields}
        synchronousErrors={synchronousErrors}
        onSetUploaded={this.setUploaded}
        onPreview={this.handlePreview}
      />
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  post: state.post,
  values: getFormValues('editPost')(state),
  synchronousErrors: getFormSyncErrors('editPost')(state),
});

const mapDispatchToProps = dispatch => ({
  _fetchEditForm: id => dispatch(fetchEditForm(id)),
  _fetchPost: id => dispatch(fetchPost(id)),
  _fetchPropFields: id => dispatch(fetchPropFields(id)),
  _editPost: values => dispatch(editPost(values)),
});

const EditFormContainer = connect(mapStateToProps, mapDispatchToProps)(EditForm);

export default reduxForm({
  form: 'editPost',
  validate,
})(EditFormContainer);
