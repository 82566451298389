import React from 'react';

import { Form, Checkbox } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';

import { setNewsletterSubscriptionStatus } from '../../actions/user';

const ToggleField = ({input, input: {checked, onChange}, newsletterSubscribed}) => (
  <Form.Field>
    <Checkbox 
      toggle 
      {...input}
      value={checked}
      onChange={(param, data) => {
        onChange(data.checked);
      }}
      defaultChecked={newsletterSubscribed}
     />
   </Form.Field>
);

const NewsletterSubscriptionForm = ({
  error, newsletterSubscribed, submitting
}) => (
    <Form name="newsletterForm">
      <Field 
        name="newsletterSubscriptionInput"
        component={ToggleField}
        newsletterSubscribed={newsletterSubscribed}
      />
      <p> {newsletterSubscribed ? "Subscribed" : "Not Subscribed"} </p>
    </Form>
);

export default reduxForm({
  form: 'newsletterSubscriptionForm',
  onChange: (values, dispatch, props) => {
    props.submit();
  },
  onSubmit: setNewsletterSubscriptionStatus
})(NewsletterSubscriptionForm);