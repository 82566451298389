import React from 'react'
import { connect } from 'react-redux'
import { Container } from 'semantic-ui-react'
import { Helmet } from 'react-helmet'
import WireframeLoader from '../WireframeLoader'
import './resources.css'

import headerImage from '../../images/resourcesHeader.jpg'
import additionalServiceImage from '../../images/additionalServices.jpg'
import safeExchangeImage from '../../images/safeExchange.jpg'
import howItWorksImage from '../../images/howItWorks.jpg'
import healthAndSafety from '../../images/sanitizermask.jpg'

import { fetchFAQPageData } from '../../actions/faq'

import FaqCategory from './FaqCategory'

const ResourcesHeader = () => (
  <div style={{ color: '#ffffff', height: '300px', background: '#087CB1', position: 'relative' }}>
    <div
      style={{
        backgroundImage: `url(${headerImage})`,
        opacity: '0.1',
        backgroundSize: 'cover',
        backgroundPosition: '50% 50%',
        height: '100%',
        width: '100%,',
      }}
    ></div>
    <h1 className={'header-with-bg'}>Resources</h1>
  </div>
)

const ResourcesImageLink = (props) => (
  <div
    style={{
      color: '#ffffff',
      height: window.innerWidth > 760 ? '500px' : '247px',
      width: window.innerWidth > 760 ? '33%' : '100%',
      borderRadius: '10px',
      background: '#087CB1',
      position: 'relative',
      margin: '16px',
      flex: '0 1 auto',
    }}
  >
    <a style={{ textDecoration: 'none' }} href={props.link}>
      <div
        class="resourcesCategory"
        style={{
          backgroundImage: `url(${props.picture})`,
          opacity: '0.3',
          backgroundSize: 'cover',
          borderRadius: '10px',
          backgroundPosition: '50%, 50% ',
          height: '100%',
        }}
      ></div>
      <h3
        class="aTagIgnore"
        style={{
          textAlign: 'center',
          fontSize: window.innerWidth > 760 ? '24px' : '20px',
          margin: '0',
          position: 'absolute',
          top: window.innerWidth > 760 ? '63%' : '54%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        {props.description}
      </h3>
      <p
        class="aTagIgnore"
        style={{
          fontSize: '18px',
          fontWeight: 'bold',
          position: 'absolute',
          top: window.innerWidth > 760 ? '85%' : '73%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        {' '}
        <u>Read More</u>
      </p>
    </a>
  </div>
)

class ResourcesLanding extends React.Component {
  componentDidMount() {
    const { _fetchFAQPageData } = this.props

    _fetchFAQPageData()
  }

  render() {
    let { faqPageData, openPanel } = this.props

    return (
      <div>
        <Helmet>
          <title>Resources - AssistList</title>
          <meta
            name="description"
            content="Learn how AssistList works, where to find community exchange zones, our additional services, frequently asked questions, and health and safety guidelines."
          />
        </Helmet>

        <ResourcesHeader />
        <Container
          style={{
            margin: window.innerWidth > 760 ? '0 auto' : '0 5%',
            maxWidth: '1120px',
            marginTop: '40px',
            paddingBottom: '160px',
          }}
        >
          <p style={{ fontFamily: 'Karla', fontSize: '18px' }}>
            AssistList fosters relationships with local organizations to fuel our mission of helping simplify the
            exchange of home health equipment for our community. We've listed a collection of resources below for you to
            learn about the services in our community.
          </p>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'center',
              margin: '40px 0',
            }}
          >
            <ResourcesImageLink
              link="/resources/howitworks"
              picture={howItWorksImage}
              description="How AssistList Works"
            />
            <ResourcesImageLink
              link="/resources/exchangezones"
              picture={safeExchangeImage}
              description="Community Exchange Zones"
            />
            <ResourcesImageLink
              link="/resources/additionalservices"
              picture={additionalServiceImage}
              description="Additional Services"
            />
            <ResourcesImageLink
              link="/resources/healthandsafety"
              picture={healthAndSafety}
              description="Health & Safety Guidelines"
            />
          </div>

          <h2 id='faq' style={{ color: '#0275B3', marginBottom: '24px' }}> Frequently Asked Questions</h2>

          {
            faqPageData ?
              Object.entries(faqPageData).map(([key, faq]) => {
                return <FaqCategory
                  key={faq.title}
                  faq_category={faq}
                  openPanel={openPanel}
                />
              }) :
              <WireframeLoader />
          }
        </Container>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  _fetchFAQPageData: () => dispatch(fetchFAQPageData()),
})

const mapStateToProps = (state) => ({
  faqPageData: state.faq.faqPageData,
  openPanel: state.faq.openPanel,
})

export default connect(mapStateToProps, mapDispatchToProps)(ResourcesLanding)
