import {
  reset, SubmissionError, destroy, change,
} from 'redux-form';
import types from '../types/post';
import api, { userHeaders } from '../utils/api';
import { showAlert } from './alerts';
import { history } from '../store';
import { fetchUserActivePosts, fetchUserInactivePosts, fetchUserExchangedPosts } from './profile';

export const fetchPost = id => (dispatch) => {
  dispatch({ type: types.FETCH_POST });
  return (
    api.get(`/posts/${id}`, userHeaders())
      .then(response => dispatch({ type: types.FETCH_POST_SUCCESS, payload: response.data.data.post }))
      .catch(error => {
        if (error.response.status !== 404) {
          dispatch(showAlert({
            header: 'Something went wrong.',
            flowId: error.response?.data?.data?.flow_id,
            message: 'Listing cannot be displayed or was inactivated by the user, please try to Log in',
            type: 'error',
          }));
        }
        dispatch({ type: types.FETCH_POST_FAILURE, payload: error });
      })
  );
};

export const fetchWizard = () => (dispatch) => {
  dispatch({ type: types.FETCH_WIZARD });
  return Promise.all([
    api.get('api/posts/categories')
      .then((response) => {
        dispatch({ type: types.FETCH_CATEGORIES_SUCCESS, payload: response.data.data });
      })
      .catch(error => dispatch({ type: types.FETCH_CATEGORIES_FAILURE, payload: error })),
    api.get('api/posts/conditions')
      .then((response) => {
        dispatch({ type: types.FETCH_CONDITIONS_SUCCESS, payload: response.data.data });
      })
      .catch(error => dispatch({ type: types.FETCH_CONDITIONS_FAILURE, payload: error })),
    api.get('api/posts/locations_wizard')
      .then((response) => {
        dispatch({ type: types.FETCH_LOCATIONS_SUCCESS, payload: response.data.data });
      })
      .catch(error => dispatch({ type: types.FETCH_LOCATIONS_FAILURE, payload: error })),
  ])
    .then(() => dispatch({ type: types.FETCH_WIZARD_SUCCESS }))
    .catch(error => dispatch({ type: types.FETCH_WIZARD_FAILURE, payload: error }));
};

export const fetchEditForm = id => (dispatch) => {
  dispatch({ type: types.FETCH_EDIT_FORM });
  return Promise.all([
    api.get('api/posts/conditions')
      .then((response) => {
        dispatch({ type: types.FETCH_CONDITIONS_SUCCESS, payload: response.data.data });
      })
      .catch(error => dispatch({ type: types.FETCH_CONDITIONS_FAILURE, payload: error })),
    api.get('api/posts/locations_wizard')
      .then((response) => {
        dispatch({ type: types.FETCH_LOCATIONS_SUCCESS, payload: response.data.data });
      })
      .catch(error => dispatch({ type: types.FETCH_LOCATIONS_FAILURE, payload: error })),
    api.get(`/posts/${id}`, userHeaders())
      .then(response => dispatch({ type: types.FETCH_POST_SUCCESS, payload: response.data.data.post }))
      .catch(error => dispatch({ type: types.FETCH_POST_FAILURE, payload: error }))
      .then(({ payload }) => (
        api.get('api/posts/equipment_fields', { params: { id: payload.equipment_type.id } })
          .then(response => (
            dispatch({ type: types.FETCH_PROP_FIELDS_SUCCESS, payload: response.data.data })
          ))
          .catch(error => dispatch({ type: types.FETCH_PROP_FIELDS_FAILURE, payload: error }))
      )),
  ])
    .then(() => dispatch({ type: types.FETCH_EDIT_FORM_SUCCESS }))
    .catch(error => dispatch({ type: types.FETCH_EDIT_FORM_FAILURE, payload: error }));
};

export const fetchPropFields = id => (dispatch) => {
  dispatch({ type: types.FETCH_PROP_FIELDS });
  return (
    api.get('api/posts/equipment_fields', { params: { id } })
      .then((response) => {
        dispatch({ type: types.FETCH_PROP_FIELDS_SUCCESS, payload: response.data.data });
      })
      .catch(error => dispatch({ type: types.FETCH_PROP_FIELDS_FAILURE, payload: error }))
  );
};

export const addImage = image => dispatch => dispatch({ type: types.ADD_IMAGE, payload: image });

export const removeImage = id => dispatch => dispatch({ type: types.REMOVE_IMAGE, payload: id });

export const deleteImage = id => dispatch => dispatch({ type: types.DELETE_IMAGE, payload: id });

export const orderImageLeft = images => (dispatch) => {
  dispatch({ type: types.ORDER_IMAGE_LEFT, payload: images });
};

export const orderImageRight = images => (dispatch) => {
  dispatch({ type: types.ORDER_IMAGE_RIGHT, payload: images });
};

export const rotateImageLeft = images => (dispatch) => {
  dispatch({ type: types.ROTATE_IMAGE_LEFT, payload: images });
};

export const rotateImageRight = images => (dispatch) => {
  dispatch({ type: types.ROTATE_IMAGE_RIGHT, payload: images });
};

export const submitPost = values => (dispatch) => {
  const {
    equipment_type,
    title,
    description,
    price,
    free,
    condition_id,
    location_id,
    images,
    ...rest
  } = values;

  dispatch({ type: types.POST_SUBMIT });
  return (
    api.post('/posts', {
      post: {
        equipment_type_id: equipment_type.id,
        title,
        description,
        price,
        free,
        condition_id,
        location_id,
        images,
        properties: rest,
      },
    }, userHeaders())
      .then((response) => {
        dispatch(showAlert({
          header: 'Congratulations!',
          message: 'Your listing has been submitted successfully',
          type: 'success',
          dismissAfter: 3000,
        }));

        dispatch({ type: types.POST_SUBMIT_SUCCESS, payload: response.data.data.post });
        destroy('newPost');
      })
      .catch((error) => {
        dispatch({ type: types.POST_SUBMIT_FAILURE, payload: error });
        dispatch(showAlert({
          header: 'Listing creation unsuccessful',
          flowId: error.response?.data?.data?.flow_id,
          message: 'Your listing was not created successfully',
          type: 'error',
        }));
        throw new SubmissionError({ _error: error });
      })
  );
};

export const editPost = (values, dispatch) => {
  const {
    id,
    equipment_type_id,
    title,
    description,
    price,
    free,
    condition_id,
    location_id,
    images,
    imagesToDelete,
    ...rest
  } = values;

  return (
    Promise.all([
      ...imagesToDelete.map(img => api.delete(`/posts/image/${img}`, userHeaders())),
      api.put(`/posts/${id}`, {
        post: {
          equipment_type_id,
          title,
          description,
          price,
          free,
          condition_id,
          location_id,
          images,
          properties: rest,
        },
      }, userHeaders())
        .then((response) => {
          dispatch(showAlert({
            header: 'Edit successful!',
            message: 'Your listing has been edited successfully',
            type: 'success',
            dismissAfter: 3000,
          }));
          dispatch({ type: types.POST_EDIT_SUCCESS, payload: response.data });
          destroy('editPost');
        })
        .catch((error) => {
          dispatch({ type: types.POST_EDIT_FAILURE, payload: error });
          dispatch(showAlert({
            header: 'Listing update unsuccessful',
            flowId: error.response?.data?.data?.flow_id,
            message: 'Your listing was not updated successfully',
            type: 'error',
          }));
          throw new SubmissionError({ _error: error });
        }),
    ])
  );
};

export const renewPost = id => (dispatch) => {
  dispatch({ type: types.POST_RENEW });
  return (
    api.post(`/posts/${id}/renew`, {}, userHeaders())
      .then((response) => {
        dispatch(fetchUserActivePosts());
        dispatch(fetchUserInactivePosts());
        dispatch(fetchUserExchangedPosts());
        dispatch({ type: types.POST_RENEW_SUCCESS, payload: response.data });
        dispatch(showAlert({
          header: 'Listing Renewed',
          message: 'Your listing has been renewed for 90 days!',
          type: 'success',
          dismissAfter: 3000,
        }));
      }).catch((error) => {
        dispatch(fetchUserActivePosts());
        dispatch(fetchUserInactivePosts());
        dispatch(fetchUserExchangedPosts());
        dispatch({ type: types.POST_RENEW_FAILURE, payload: error });
        dispatch(showAlert({
          header: 'Listing Renewal Unsuccessful',
          flowId: error.response?.data?.data?.flow_id,
          message: 'Your listing was not renewed. Please try again',
          type: 'error',
        }));
      })
  );
};

export const renewPostFromToken = post_renew_token => (dispatch) => {
  dispatch({ type: types.POST_RENEW });
  return (
    api.post(`/email_renew`, { post_renew_token })
      .then((response) => {
        dispatch({ type: types.POST_RENEW_SUCCESS, payload: response.data });
        dispatch(showAlert({
          header: 'Listing Renewed',
          message: 'Your listing has been renewed for 90 days!',
          type: 'success',
          dismissAfter: 3000,
        }));
      }).catch((error) => {
        dispatch({ type: types.POST_RENEW_FAILURE, payload: error });
        dispatch(showAlert({
          header: 'Listing Renewal Unsuccessful',
          flowId: error.response?.data?.data?.flow_id,
          message: 'Your listing was not renewed. Please try again',
          type: 'error',
        }));
      })
  );
};

export const reportPost = (id, reporting_user, reasons) => (dispatch) => {
  dispatch({ type: types.POST_REPORT });
  return (
    api.post(`/posts/${id}/report/`, { reporting_user, reasons })
      .then((response) => {
        dispatch({ type: types.POST_REPORT_SUCCESS, payload: response.data });
        dispatch(showAlert({
          header: 'Thank you for taking the time to report this listing.',
          message: 'We have been notified and will investigate.',
          type: 'success',
          dismissAfter: 3000,
        }));
      }).catch((error) => {
        dispatch({ type: types.POST_REPORT_FAILURE, payload: error });
        dispatch(showAlert({
          header: 'Listing Reported Unsuccessful',
          flowId: error.response?.data?.data?.flow_id,
          message: 'Report has not been sent, please try again.',
          type: 'error',
        }));
      })
  );
};

export const deletePost = id => (dispatch) => {
  dispatch({ type: types.POST_DELETE });
  return (
    api.delete(`/posts/${id}`, userHeaders())
      .then((response) => {
        dispatch({ type: types.POST_DELETE_SUCCESS, payload: response.data });
        dispatch(fetchUserActivePosts());
        dispatch(fetchUserInactivePosts());
        dispatch(fetchUserExchangedPosts());
        dispatch(showAlert({
          header: 'Listing Deleted Successfully',
          message: 'Your listing has been deleted successfully',
          type: 'success',
          dismissAfter: 3000,
        }));
        history.push('/account/listings');
      })
      .catch((error) => {
        dispatch({ type: types.POST_DELETE_FAILURE, payload: error });
        dispatch(fetchUserActivePosts());
        dispatch(fetchUserInactivePosts());
        dispatch(fetchUserExchangedPosts());
        dispatch(showAlert({
          header: 'Listing deletion unsuccessful',
          flowId: error.response?.data?.data?.flow_id,
          message: 'Your listing was not deleted. Please try again.',
          type: 'error',
        }));
      })
  );
};

export const sendMessage = (values, dispatch) => (
  api.post('/messages', values, userHeaders())
    .then(() => {
      dispatch(reset('postContact'));
      dispatch(change('postContact', 'post_id', values.post_id));
      dispatch(showAlert({
        header: 'Message sent',
        message: 'Your message has been relayed to the seller',
        type: 'success',
        dismissAfter: 5000,
      }));
    })
    .catch((error) => {
      dispatch(change('postContact', 'post_id', values.post_id));
      dispatch(showAlert({
        header: 'Message not sent',
        flowId: error.response?.data?.data?.flow_id,
        message: 'Your message was not sent',
        type: 'error',
      }));
      throw new SubmissionError({
        _error: error.response.data.error,
      });
    })
);