import api from '../utils/api';
import types from '../types/aboutus';
import { 
  getPageData, 
  formatAssetUrls, 
  formatPageData, 
  formatReferenceLink 
} from '../utils/contentful'

export const fetchDirectors = () => (dispatch) => {
  dispatch({ type: types.FETCH_DIRECTORS});
  api.get('/directors')
    .then(response => dispatch(
      { type: types.FETCH_DIRECTORS_SUCCESS, payload: response.data.data })
    )
    .catch((error) => {
      dispatch({ type: types.FETCH_DIRECTORS_FAILURE, payload: error });
    });
};

export const fetchImages = () => (dispatch) => {
  dispatch({ type: types.FETCH_IMAGES });
  api.get('/images')
    .then(response => dispatch(
      { type: types.FETCH_IMAGES_SUCCESS, payload: response.data.data })
    )
    .catch((error) => {
      dispatch({ type: types.FETCH_IMAGES_FAILURE, payload: error });
    });
};

export const fetchStatistics = () => (dispatch) => {
  dispatch({ type: types.FETCH_STATISTICS });
  api.get('/statistics')
    .then(response => dispatch(
      { type: types.FETCH_STATISTICS_SUCCESS, payload: response.data.data })
    )
    .catch((error) => {
      dispatch({ type: types.FETCH_STATISTICS_FAILURE, payload: error });
    });
};

export const fetchTeamMembers = () => (dispatch) => {
  dispatch({ type: types.FETCH_TEAM_MEMBERS });
  api.get('/team_members')
    .then(response => dispatch(
      { type: types.FETCH_TEAM_MEMBERS_SUCCESS, payload: response.data.data })
    )
    .catch((error) => {
      dispatch({ type: types.FETCH_TEAM_MEMBERS_FAILURE, payload: error });
    });
};

export const fetchTeamStats = () => (dispatch) => {
  dispatch({ type: types.FETCH_TEAM_STATS });
  api.get('/team_stats')
    .then(response => dispatch(
      { type: types.FETCH_TEAM_STATS_SUCCESS, payload: response.data.data })
    )
    .catch((error) => {
      dispatch({ type: types.FETCH_TEAM_STATS_FAILURE, payload: error });
    });
};

export const fetchTeamLeads = () => (dispatch) => {
  dispatch({ type: types.FETCH_TEAM_LEADS});
  api.get('/team_leads')
    .then(response => dispatch(
      { type: types.FETCH_TEAM_LEADS_SUCCESS, payload: response.data.data })
    )
    .catch((error) => {
      dispatch({ type: types.FETCH_TEAM_LEADS_FAILURE, payload: error });
    });
};

export const fetchTeams = () => (dispatch) => {
  dispatch({ type: types.FETCH_TEAMS });
  api.get('/teams')
    .then(response => dispatch(
      { type: types.FETCH_TEAMS_SUCCESS, payload: response.data.data })
    )
    .catch((error) => {
      dispatch({ type: types.FETCH_TEAMS_FAILURE, payload: error });
    });
};

export const fetchTestimonials = () => (dispatch) => {
  dispatch({ type: types.FETCH_TESTIMONIALS });
  api.get('/testimonials')
    .then(response => dispatch(
      { type: types.FETCH_TESTIMONIALS_SUCCESS, payload: response.data.data })
    )
    .catch((error) => {
      dispatch({ type: types.FETCH_TESTIMONIALS_FAILURE, payload: error });
    });
};

export const fetchVolunteerInsights = () => (dispatch) => {
  dispatch({ type: types.FETCH_VOLUNTEER_INSIGHTS});
  api.get('/volunteer_insights')
    .then(response => dispatch(
      { type: types.FETCH_VOLUNTEER_INSIGHTS_SUCCESS, payload: response.data.data })
    )
    .catch((error) => {
      dispatch({ type: types.FETCH_VOLUNTEER_INSIGHTS_FAILURE, payload: error });
    });
};

export const fetchOurStoryPageData = () => (dispatch) => {
  dispatch({ type: types.FETCH_OUR_STORY_PAGE_DATA });
  let payload = { ourStoryPageData: undefined };

  getPageData('Our Story')
    .then((pageData) => {
      const data = formatPageData(pageData);
      
      data.ourStoryImages = formatAssetUrls(data.ourStoryImages.images);

      payload.ourStoryPageData = data;

      dispatch({ type: types.FETCH_OUR_STORY_PAGE_DATA_SUCCESS, payload: payload });
    })
    .catch((err) => {
      dispatch({ type: types.FETCH_OUR_STORY_PAGE_DATA_FAILURE, payload: err });
    })
};

export const fetchAboutUsPageData = () => (dispatch) => {
  dispatch({ type: types.FETCH_ABOUT_US_PAGE_DATA });
  let payload = { aboutUsPageData: undefined };

  getPageData('About Us', 3)
    .then((pageData) => {
      const data = formatPageData(pageData);

      data.aboutUsImages = formatAssetUrls(data.aboutUsImages.images);
      data.aboutUsFigures = formatReferenceLink(data.aboutUsFigures.figures);
      data.communityTestaments = formatReferenceLink(data.communityTestaments.testaments);

      payload.aboutUsPageData = data;

      dispatch({ type: types.FETCH_ABOUT_US_PAGE_DATA_SUCCESS, payload: payload });
    })
    .catch((err) => {
      dispatch({ type: types.FETCH_ABOUT_US_PAGE_DATA_FAILURE, payload: err });
    })
};
