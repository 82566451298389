import React from 'react'
import { withRouter, Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { getFormValues, destroy } from 'redux-form'
import { Helmet } from 'react-helmet'
import { Modal } from 'semantic-ui-react'

import WizardPageContainer from './WizardPageContainer'

import Steps from './Steps'

import EquipmentSelector from './EquipmentSelector'
import BasicsForm from './BasicsForm'
import DetailsForm from './DetailsForm'
import ImagesForm from './ImagesForm'
import Preview from './Preview'

import LoginModal from '../Authentication/LoginModal'

import { WizardRoute } from '../../utils/redirect'

import { fetchWizard, submitPost, fetchPropFields } from '../../actions/post'
import { fetchAllNotifications } from '../../actions/notifications'

const wizardPaths = ['/wizard', '/wizard/images', '/wizard/basics', '/wizard/details', '/wizard/preview']

class WizardForm extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			page: 0,
			waitingPeople: 0,
			basicsFilled: false,
			detailsFilled: false,
		}

		this.setPage = this.setPage.bind(this)

		this.nextPage = this.nextPage.bind(this)
		this.previousPage = this.previousPage.bind(this)

		this.submitImages = this.submitImages.bind(this)
		this.submitBasics = this.submitBasics.bind(this)
		this.submitDetails = this.submitDetails.bind(this)

		this.handleClose = this.handleClose.bind(this)
	}

	componentWillMount() {
		const { _destroyForm, _fetchWizard, _fetchAllNotifications } = this.props

		_fetchAllNotifications()

		_destroyForm()

		_fetchWizard()

		this.stepFetch(window.location.href)
	}

	stepFetch(page) {
		switch (page) {
			case 1:
				return 'Step 2 - Upload Images'
			case 2:
				return 'Step 3 - Add Basic Information'
			case 3:
				return 'Step 4 - Fill Additional Information'
			case 4:
				return 'Step 5 - Review Your Post'
			default:
				return 'Step 1 - Select Equipment Type '
		}
	}

	componentDidUpdate(prevProps) {
		const { notifications, values } = this.props

		let waitingPeople = 0
		if (values !== prevProps.values) {
			waitingPeople = notifications.filter((item) => values && item.equipment_type.name === values.equipment_type.name)
				.length
			// eslint-disable-next-line react/no-did-update-set-state
			this.setState({ waitingPeople })
		}
	}

	setPage(page) {
		this.setState({ page })
		this.props.history.push(wizardPaths[page])
	}

	nextPage() {
		const currentPage = this.state.page
		const nextPage = currentPage + 1
		this.setState({ page: nextPage })
		this.props.history.push(wizardPaths[nextPage])
	}

	previousPage() {
		const currentPage = this.state.page
		const previousPage = currentPage - 1
		this.setState({ page: previousPage })
		this.props.history.push(wizardPaths[previousPage])
	}

	submitImages() {
		this.setState({ imagesUploaded: true })
		this.nextPage()
	}

	submitBasics() {
		this.setState({ basicsFilled: true })
		this.nextPage()
	}

	submitDetails() {
		this.setState({ detailsFilled: true })
		this.nextPage()
	}

	handleClose() {
		this.props.history.push('/')
	}

	render() {
		const {
			user,
			values,
			_handlePostSubmit,
			wizard: { fields },
		} = this.props

		return (
			<WizardPageContainer>
				<Helmet>
					<title>Create Listing - AssistList</title>
				</Helmet>
				{window.innerWidth > 989 ? (
					''
				) : (
					<div style={{ textAlign: 'center', marginBottom: '6px' }}>
						<p
							style={{
								fontFamily: 'Nunito',
								fontStyle: 'normal',
								fontWeight: 'bold',
								fontSize: '18px',
								lineHeight: '25px',
							}}
						>
							{this.stepFetch(this.state.page)}
						</p>
					</div>
				)}
				<Steps
					fields={fields}
					values={values}
					basicsFilled={this.state.basicsFilled}
					detailsFilled={this.state.detailsFilled}
					imagesUploaded={this.state.imagesUploaded}
					page={this.state.page}
					setPage={this.setPage}
				/>

				<Switch>
					<Route
						exact
						path="/wizard"
						render={() => <EquipmentSelector getWaiting={this.getWaiting} nextPage={this.nextPage} />}
					/>
					<WizardRoute
						path="/wizard/images"
						component={ImagesForm}
						props={{
							onSubmit: this.submitImages,
							previousPage: this.previousPage,
							waitingPeople: this.state.waitingPeople,
						}}
					/>
					<WizardRoute
						path="/wizard/basics"
						component={BasicsForm}
						props={{
							onSubmit: this.submitBasics,
							previousPage: this.previousPage,
							waitingPeople: this.state.waitingPeople,
						}}
					/>
					<WizardRoute
						path="/wizard/details"
						component={DetailsForm}
						props={{
							onSubmit: this.submitDetails,
							previousPage: this.previousPage,
							waitingPeople: this.state.waitingPeople,
						}}
					/>
					<WizardRoute
						path="/wizard/preview"
						component={Preview}
						values={values}
						props={{
							onSubmit: (formValues) => _handlePostSubmit(formValues),
							previousPage: this.previousPage,
							waitingPeople: this.state.waitingPeople,
						}}
					/>
				</Switch>
				<Modal closeIcon size="small" open={!user.success} closeOnEscape={false} onClose={this.handleClose}>
					<Modal.Content>
						<LoginModal />
					</Modal.Content>
				</Modal>
			</WizardPageContainer>
		)
	}
}

const mapStateToProps = (state) => ({
	values: getFormValues('newPost')(state),
	wizard: state.post.postForm,
	notifications: state.notifications.notifications,
	user: state.user,
})

const mapDispatchToProps = (dispatch) => ({
	_fetchWizard: () => dispatch(fetchWizard()),
	_handlePostSubmit: (values) => dispatch(submitPost(values)),
	_destroyForm: () => dispatch(destroy('newPost')),
	handleFetchId: (id) => {
		dispatch(fetchPropFields(id))
	},
	_fetchAllNotifications: () => dispatch(fetchAllNotifications()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WizardForm))
