import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { Grid, Container, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import noImage from '../../../images/no_image.png';
import { fetchPost, deletePost } from '../../../actions/post';
import Post from '../../PostComponents/PostComponent';
import PostsListDropdown from '../../PostsListDropdown';
import UnconfirmedUserMessage from '../StatusMessages/UnconfirmedUserMessage';
import DeactivatedMessage from '../StatusMessages/DeactivatedMessage';
import SoldMessage from '../StatusMessages/SoldMessage';
import EmailConfirmationBanner from '../../EmailConfirmationBanner';
import ListingNotFound from '../../PageNotFound/RemovedListingErrorPage/components/ListingNotFound';

class PostContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };

    this.showDeleteConfirmation = () => this.setState({ show: true });
    this.handleConfirm = id => this.props._deletePost(id);
    this.handleCancel = () => this.setState({ show: false });
  }

  componentDidMount() {
    const { _fetchPost, match: { params } } = this.props;
    _fetchPost(params.id);
    window.scrollTo(0, 0);
  }

  postStatus(user, post) {
    let status = null;
    if (user.user_id === post.user.id) {
      if (!user.confirmed) {
        status = <UnconfirmedUserMessage />;
      } else if (post.last_feedback) {
        status = <SoldMessage />;
      } else if (!post.active) {
        status = <DeactivatedMessage />;
      }
    }
    return status;
  }

  render() {
    const { user, post } = this.props;

    if (post.error) {
      return <Redirect to="/404" />;
    }

    if (post.deleted_at !== null) {
      return (
        <div>
          {!post.loading && <ListingNotFound post={post} user={user} history={this.props.history} />}
        </div>
      );
    }

    return (
      <Container style={{ backgroundColor: 'white', maxWidth: '1119px', margin: (window.innerWidth > 600 ? '0 auto' : '0 5%') }}>
        <Helmet>
          <title>{`${post.title} - AssistList`}</title>
          <meta property="og:title" content={`${post.title} - AssistList`} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={post.images ? (post.images[0] && post.images[0].large) : noImage} />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:description" content={post.description} />
        </Helmet>
        {user?.confirmed === false ? <EmailConfirmationBanner /> : null}
        <Grid padded columns={2}>
          <Grid.Column onClick={() => (this.props.history.location.key ? this.props.history.goBack() : this.props.history.push('/'))} floated="left" style={{ paddingLeft: '0px' }}>
            <Icon disabled name="chevron left" />
            <span className="subtext" style={{ cursor: 'pointer' }}>Back to Results</span>
          </Grid.Column>
          {!post.loading && user.user_id === post.user.id && (
            <Grid.Column floated="right" textAlign="right">
              <PostsListDropdown
                post={post}
                dropdown={{
                  style: {
                    marginRight: 0,
                    color: '#00ADD8',
                  },
                  text: 'Manage Listing',
                }}
              />
            </Grid.Column>
          )}
          <Grid.Row columns={1}>
            <Grid.Column>
              {!post.loading && this.postStatus(user, post)}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div basic loading={post.loading} style={{ paddingTop: 0, margin: 0 }}>
          {!post.loading && <Post post={post} user={user} />}
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  post: state.post.details,
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  _fetchPost: id => dispatch(fetchPost(id)),
  _deletePost: id => dispatch(deletePost(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PostContainer));
