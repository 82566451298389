import React from 'react';
import { Container } from 'semantic-ui-react';

const WizardPageContainer = props => (

  (window.innerWidth>989? <Container {...props}  style={{ marginTop: '25px' }} /> :
  <Container {...props} fluid style={{ marginTop: '25px' }} /> )
);

export default WizardPageContainer;
