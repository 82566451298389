import React from 'react';
import { connect } from 'react-redux';
import { Container, Grid, Responsive, Button, Icon, Image } from 'semantic-ui-react';

import FaqCategory from './../FaqCategory';
import { fetchQuestionsHealthSafety } from '../../../actions/faq';

import './healthAndSafety.css';

import { HEADER_EXCHANGE_GUIDELINE, HEADER_ALWAYS_REMEMBER, HEADER_FAQ } from './healthAndSafetyContent';
import { PARA_HEALTH_SAFETY, PARA_PLEASE_NOTE, PARA_WASH_HANDS, PARA_DISTANCING, PARA_OUTDOORS, PARA_CLEAN, PARA_TRAVEL } from './healthAndSafetyContent';
import { BTN_MORE_INFO_COVID } from './healthAndSafetyContent';
import { LINK_MORE_INFO_RESTRICTIONS, LINK_MORE_INFO_COVID } from './healthAndSafetyContent';

import covid19BackgroundBottom from '../../../images/covid19BackgroundBottom.png';
import covid19CDCLogo from '../../../images/covid19CDCLogo.png';
import covid19WashHands from '../../../images/covid19WashHands.png';
import covid19Distance from '../../../images/covid19Distance.png';
import covid19Outdoors from '../../../images/covid19Outdoors.png';
import covid19Clean from '../../../images/covid19Clean.png';
import covid19Travel from '../../../images/covid19Travel.png';

const mobileBreakpoint = 768;
const tabletBreakpoint = 1200;

const Action = (props) => {
  const text = props.description;
  return (
    <div className='Div__ActionCovid19'>
      <Image alt='Covid prevention steps illustration' height='170px' src={props.image} centered />
      <div style={{ paddingTop: '15px' }} dangerouslySetInnerHTML={{ __html: text }} />
    </div >
  );
};

const FAQ = ({ faq_health_safety_categories, fetchAnswer, openPanel }) => (
  <div className='Div__Margin--Top--80px'>
    <h3 className='Covid19__H3'>{HEADER_FAQ}</h3>
    {faq_health_safety_categories.map(faq_category => (
      <FaqCategory
        faq_category={faq_category}
        fetchAnswer={fetchAnswer}
        index={faq_category.id}
        openPanel={openPanel}
      />))}
  </div>
);

const Mobile = (props) => (
  <div className='Div__Covid19Page'>
    {/* COVID-19 */}
    <Container className='Div__Container__Covid19SafetyExchange'>
      <div className='Div__Padding--Top--24px'>
        <a className='Covid19__Link__WithIcon' href='/resources'> <Icon name='angle left' />Back to Resources </a>
        <h1 className='Covid19__H1'>{HEADER_EXCHANGE_GUIDELINE}</h1>
        <div className='Div__Margin--Bot--60px'>
          <p className='Covid19__P'>{PARA_HEALTH_SAFETY}</p>
          <p className='Covid19__P'>{PARA_PLEASE_NOTE}</p>
        </div>
      </div>
      <div className='Div__Margin--Top--Bot--80px--Centered'>
        <Button className='Covid19__Button' style={{ height: '60px', lineHeight: '20px', marginTop: '24px' }} href={LINK_MORE_INFO_RESTRICTIONS} target='_blank' rel='noopener noreferrer'>More information on <br /> B.C COVID-19 restrictions</Button>
      </div>
    </Container>

    {/* REMEMBER */}
    <div className='Div__Container__Remember'>
      <h3 className='Covid19__H3'>{HEADER_ALWAYS_REMEMBER}</h3>
      <Responsive>
        <Grid container centered row={5}>
          <Grid.Row className='Grid__Row__Mobile'>
            <Action image={covid19WashHands} description={PARA_WASH_HANDS} />
          </Grid.Row>
          <Grid.Row className='Grid__Row__Mobile'>
            <Action image={covid19Distance} description={PARA_DISTANCING} />
          </Grid.Row>
          <Grid.Row className='Grid__Row__Mobile'>
            <Action image={covid19Outdoors} description={PARA_OUTDOORS} />
          </Grid.Row>
          <Grid.Row className='Grid__Row__Mobile'>
            <Action image={covid19Clean} description={PARA_CLEAN} />
          </Grid.Row>
          <Grid.Row className='Grid__Row__Mobile'>
            <Action image={covid19Travel} description={PARA_TRAVEL} />
          </Grid.Row>
        </Grid>
      </Responsive>
    </div>

    {/* FAQ */}
    <Container>
      <div className='Div__Margin--Top--80px--Bot--96px'>
        <FAQ faq_health_safety_categories={props.faq_health_safety_categories} fetchAnswer={props.fetchAnswer} openPanel={props.openPanel} />
      </div>

      <div style={{ textAlign: 'center', marginBottom: '40px' }}>
        <Image style={{ width: '232px', height: '130px' }} src={covid19CDCLogo} alt='CDC Logo' centered />
        <Button className='Covid19__Button' style={{ marginTop: '24px' }} href={LINK_MORE_INFO_COVID} target='_blank' rel='noopener noreferrer'>{BTN_MORE_INFO_COVID}</Button>
      </div>
    </Container>

    {/* BACKGROUND */}
    <div className='Div__Container__Background'>
      <img style={{ margin: '0px 0px 0px -692px' }} src={covid19BackgroundBottom} alt='Covid-19 Background' />
    </div>
  </div>
);

const Tablet = (props) => (
  <div className='Div__Covid19Page'>
    {/* COVID-19 */}
    <Container className='Div__Container__Covid19SafetyExchange'>
      <div className='Div__Padding--Top--24px'>
        <a className='Covid19__Link__WithIcon' href='/resources'> <Icon name='angle left' />Back to Resources </a>
        <h1 className='Covid19__H1'>{HEADER_EXCHANGE_GUIDELINE}</h1>
        <div className='Div__Margin--Bot--60px'>
          <p className='Covid19__P'>{PARA_HEALTH_SAFETY}</p>
          <p className='Covid19__P'>{PARA_PLEASE_NOTE}</p>
        </div>
      </div>
      <div className='Div__Margin--Top--Bot--80px--Centered'>
        <Button className='Covid19__Button' style={{ height: '60px', lineHeight: '20px', marginTop: '24px' }} href={LINK_MORE_INFO_RESTRICTIONS} target='_blank' rel='noopener noreferrer'>More information on <br /> B.C COVID-19 restrictions</Button>
      </div>
    </Container>

    {/* REMEMBER */}
    <div className='Div__Container__Remember'>
      <h3 className='Covid19__H3'>{HEADER_ALWAYS_REMEMBER}</h3>
      <Grid container centered verticalAlign='middle' row={3} style={{ marginTop: '40px' }}>
        <Grid.Row className='Grid__Row__Tablet'>
          <Action image={covid19WashHands} description={PARA_WASH_HANDS} />
          <span style={{ width: '80px' }} />
          <Action image={covid19Distance} description={PARA_DISTANCING} />
        </Grid.Row>
        <Grid.Row className='Grid__Row__Tablet'>
          <Action image={covid19Outdoors} description={PARA_OUTDOORS} />
        </Grid.Row>
        <Grid.Row className='Grid__Row__Tablet'>
          <Action image={covid19Clean} description={PARA_CLEAN} />
          <span style={{ width: '80px' }} />
          <Action image={covid19Travel} description={PARA_TRAVEL} />
        </Grid.Row>
      </Grid>
    </div>

    {/* FAQ */}
    <Container>
      <div className='Div__Margin--Top--80px--Bot--96px'>
        <FAQ faq_health_safety_categories={props.faq_health_safety_categories} fetchAnswer={props.fetchAnswer} openPanel={props.openPanel} />
      </div>

      <div style={{ textAlign: 'center', marginBottom: '40px' }}>
        <Image style={{ width: '232px', height: '130px' }} src={covid19CDCLogo} alt='CDC Logo' centered />
        <Button className='Covid19__Button' style={{ marginTop: '24px' }} href={LINK_MORE_INFO_COVID} target='_blank' rel='noopener noreferrer'>{BTN_MORE_INFO_COVID}</Button>
      </div>
    </Container>

    {/* BACKGROUND */}
    <div className='Div__Container__Background'>
      <img style={{ margin: '0px 0px 0px -303px' }} src={covid19BackgroundBottom} alt='Covid-19 Background' />
    </div>
  </div>
);

const Desktop = (props) => (
  <div className='Div__Covid19Page'>
    {/* COVID-19 */}
    <Container className='Div__Container__Covid19SafetyExchange'>
      <div className='Div__Padding--Top--24px'>
        <a className='Covid19__Link__WithIcon' href='/resources'> <Icon name='angle left' />Back to Resources </a>
        <h1 className='Covid19__H1'>{HEADER_EXCHANGE_GUIDELINE}</h1>
        <div className='Div__Margin--Bot--60px'>
          <p className='Covid19__P'>{PARA_HEALTH_SAFETY}</p>
          <p className='Covid19__P'>{PARA_PLEASE_NOTE}</p>
        </div>
      </div>
      <div className='Div__Margin--Top--Bot--80px--Centered'>
        <Button className='Covid19__Button' style={{ height: '60px', lineHeight: '20px', marginTop: '24px' }} href={LINK_MORE_INFO_RESTRICTIONS} target='_blank' rel='noopener noreferrer'>More information on <br /> B.C COVID-19 restrictions</Button>
      </div>
    </Container>

    {/* REMEMBER */}
    <div className='Div__Container__Remember'>
      <h3 className='Covid19__H3'>{HEADER_ALWAYS_REMEMBER}</h3>
      <Grid container columns={5} style={{ marginTop: '40px' }}>
        <Grid.Column>
          <Action image={covid19WashHands} description={PARA_WASH_HANDS} />
        </Grid.Column>
        <Grid.Column>
          <Action image={covid19Distance} description={PARA_DISTANCING} />
        </Grid.Column>
        <Grid.Column>
          <Action image={covid19Outdoors} description={PARA_OUTDOORS} />
        </Grid.Column>
        <Grid.Column>
          <Action image={covid19Clean} description={PARA_CLEAN} />
        </Grid.Column>
        <Grid.Column>
          <Action image={covid19Travel} description={PARA_TRAVEL} />
        </Grid.Column>
      </Grid>
    </div>

    {/* FAQ */}
    <Container>
      <div className='Div__Margin--Top--80px--Bot--96px'>
        <FAQ faq_health_safety_categories={props.faq_health_safety_categories} fetchAnswer={props.fetchAnswer} openPanel={props.openPanel} />
      </div>

      <div style={{ textAlign: 'center', marginBottom: '40px' }}>
        <Image style={{ width: '232px', height: '130px' }} src={covid19CDCLogo} alt='CDC Logo' centered />
        <Button className='Covid19__Button' style={{ marginTop: '24px' }} href={LINK_MORE_INFO_COVID} target='_blank' rel='noopener noreferrer'>{BTN_MORE_INFO_COVID}</Button>
      </div>

    </Container>

    {/* BACKGROUND */}
    <Responsive>
      <div className='Div__Container__Background'>
        <Image src={covid19BackgroundBottom} centered />
      </div>
    </Responsive>
  </div>
);

class HealthSafety extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth
    };
  }

  componentDidMount() {
    const { _fetchQuestionsHealthSafety } = this.props;
    _fetchQuestionsHealthSafety();

    if (this.state.width !== window.innerWidth) {
      this.setState({ width: window.innerWidth });
    }
  }

  render() {
    let { faq_health_safety_categories, _fetchAnswer, openPanel } = this.props;

    if (this.state.width < mobileBreakpoint) {
      return <Mobile faq_health_safety_categories={faq_health_safety_categories} fetchAnswer={_fetchAnswer} openPanel={openPanel} />
    } else if (this.state.width < tabletBreakpoint) {
      return <Tablet faq_health_safety_categories={faq_health_safety_categories} fetchAnswer={_fetchAnswer} openPanel={openPanel} />
    } else {
      return <Desktop faq_health_safety_categories={faq_health_safety_categories} fetchAnswer={_fetchAnswer} openPanel={openPanel} />
    }
  }
}

const mapDispatchToProps = dispatch => ({
  _fetchQuestionsHealthSafety: () => dispatch(fetchQuestionsHealthSafety()),
});

const mapStateToProps = state => ({
  faq_health_safety_categories: state.faq.faq_health_safety_categories,
  loading: state.faq.loading,
  openPanel: state.faq.openPanel,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HealthSafety);