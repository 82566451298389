import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import qs from 'qs';

import { renewPostFromToken } from '../actions/post';

const emailRenew = ({ _renewPostFromToken, location }) => {
  const { post_renew_token } = qs.parse(location.search, { ignoreQueryPrefix: true });
  if (post_renew_token) {
    _renewPostFromToken(post_renew_token);
  }
  return (
    <Redirect to="/" />
  );
};

const mapDispatchToProps = dispatch => ({
  _renewPostFromToken: post_renew_token => dispatch(renewPostFromToken(post_renew_token)),
});

export default withRouter(connect(null, mapDispatchToProps)(emailRenew));
