import types from '../types/notifications';

const notificationsReducer = (state = {
  options: [],
  optionsLoading: true,
  notifications: [],
  notificationsLoading: true,
  error: null,
}, action) => {
  switch (action.type) {
    case types.FETCH_OPTIONS_SUCCESS:
      return { ...state, options: action.payload, optionsLoading: false };
    case types.FETCH_OPTIONS_FAILURE:
      return { ...state, error: action.payload, optionsLoading: false };
    case types.NOTIFICATION_SUBMIT_SUCCESS:
      return { ...state, submit: true };
    case types.NOTIFICATION_SUBMIT_FAILURE:
      return { ...state, error: action.payload };
    case types.NOTIFICATION_FETCH_ALL:
      return { ...state, notifications_loading: true };
    case types.NOTIFICATION_FETCH_ALL_SUCCESS:
      return { ...state, notifications: action.payload, notificationsLoading: false };
    case types.NOTIFICATION_FETCH_ALL_FAILURE:
      return { ...state, error: action.payload, notificationsLoading: false };
    case types.NOTIFICATION_USER_FETCH:
      return { ...state, notifications_loading: true };
    case types.NOTIFICATION_USER_FETCH_SUCCESS:
      return { ...state, notifications: action.payload, notificationsLoading: false };
    case types.NOTIFICATION_USER_FETCH_FAILURE:
      return { ...state, error: action.payload, notificationsLoading: false };
    default:
      return state;
  }
};

export default notificationsReducer;
