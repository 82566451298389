import React from 'react';
import { Grid, Form, Header } from 'semantic-ui-react';
import { reduxForm, Field, change } from 'redux-form';
import { HashLink as Link } from 'react-router-hash-link';
import '../../PostComponents/post.css';

import { TextAreaField, CheckboxField } from '../../../utils/render-input';

import { sendMessage } from '../../../actions/post';

import './post.css';

const validate = (values) => {
  const errors = {};

  if (!values.body) {
    errors.body = 'Message is required';
  }

  if (!values.termsOfUse) {
    errors.termsOfUse = 'You must agree to the terms of use';
  }

  return errors;
};

class ContactForm extends React.Component {
  componentDidMount() {
    const { dispatch, postId } = this.props;

    dispatch(change('postContact', 'post_id', postId));
  }

  render() {
    const {
      user, handleSubmit, error, submitting,
    } = this.props;

    return (
      <Grid divided stackable>
        <Grid.Column computer={16} mobile={16}>
          <Header style={{ fontSize: '22px', color: '#087CB1' }} as="h3">
            Contact Seller
          </Header>


          {(user.auth_token) && (
            <Form onSubmit={handleSubmit(sendMessage)}>
              <Field
                component={TextAreaField}
                name="body"
                rows={5}
                disabled={!user.auth_token || !user.confirmed}
                placeholder={
                  (!user.auth_token && 'Please log in to message.')
                  || (!user.confirmed
                    && 'Please verify your email address to message.')
                  || ('Type here to message the seller.')
                }
              />

              <Field
                component={CheckboxField}
                position="left"
                name="termsOfUse"
                disabled={!user.auth_token || !user.confirmed}
                label={(
                  <label className="subtext">
                    I agree to the{' '}
                    <Link
                      to="/policies#terms-of-use"
                      className="subtext-underlined"
                    >
                      Terms of Use
                    </Link>
                  </label>
                )}
              />

              {error && <p style={{ color: 'red' }}>{error}</p>}

              <Form.Button
                style={{ backgroundColor: '#087CB1', color: 'white', fontWeight: 'normal' }}
                type="submit"
                disabled={!user.auth_token || !user.confirmed}
                content="Send message"
                loading={submitting}
              />

            </Form>
          )}
          {/* Sign Up Message */}
          {(!user.auth_token && !user.confirmed) && (
            <p>
              <strong>
                To send a message, please
                <Link to="/auth#login"> Log In</Link> or{' '}
                <Link to="/auth#signup">Create Account</Link>
              </strong>
            </p>
          )}
          {/* Verify Email Message */}
          {(user.auth_token && !user.confirmed) && (
            <p style={{ fontWeight: 'bold', margin: '0.2rem' }}>Please validate your email address by clicking the verification link sent by AssistList to be able to send a message to this seller</p>
          )}
        </Grid.Column>
      </Grid>
    );
  }
}

export default reduxForm({
  form: 'postContact',
  validate,
})(ContactForm);
