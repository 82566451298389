import React, { useState } from 'react';
import { Image, Modal, Transition } from 'semantic-ui-react';
import ReactGA from 'react-ga';
import './partners.css';

const handleOutbound = (eventLabel) => {
  if (eventLabel) {
    ReactGA.event({
      category: 'Outbound',
      action: 'click',
      label: eventLabel,
    });
  }
};

export const Partner = ({ partner }) => {

  const [isShown, setIsShown] = useState(false);

  function partnerToggle() {
    setIsShown(prevState => !prevState)
  }

  const re = new RegExp(' ', 'g');
  const altImg = `${partner.name.replace(re, '-').toLowerCase()}-assistlist-partner`;

  return (

    <div onMouseEnter={() => partnerToggle()} onMouseLeave={() => partnerToggle()} style={{ borderRadius: '10px', border: '0.5px solid #BDBDBD', width: '272px', height: '162px', margin: '8px' }}>

      <Transition visible={!isShown} animation='scale' duration={500}>
        <div>
          {partner.sponsored ? <div className="sponsoredTag">Sponsored</div> : null}
          <Image src={partner.logo.thumbnail} alt={altImg} fluid
            style={{ objectFit: 'contain', width: '272px', height: '162px', padding: '3px', position: 'absolute' }} />
        </div>
      </Transition>

      <Transition visible={isShown} animation='scale' duration={500}>
        <a href={partner.url} onClick={() => handleOutbound(partner.url)} target="_blank" rel="noopener noreferrer">
          <div style={{ position: 'absolute', borderRadius: '10px', width: '272px', height: '162px', background: "#0275B3", fontFamily: 'Karla', color: 'white', padding: '8px 8px 0px 8px', fontSize: '14px' }}>
            <p style={{ fontWeight: 'bold' }}>{partner.name}</p>
            <p style={{ margin: '0' }}>{partner.description}</p>
            <p style={{ margin: '0', float: 'right' }}><u>Learn More</u></p>
          </div>
        </a>
      </Transition>

    </div>
  )
};

const tabletCardStyling = { borderRadius: '10px', border: '0.5px solid #BDBDBD', width: '272px', height: '162px', margin: '8px' }
const mobileCardStyling = { borderRadius: '10px', border: '0.5px solid #BDBDBD', width: '164px', height: '100px', marginBottom: '16px' }

export const MobilePartner = ({ partner }) => {

  const re = new RegExp(' ', 'g');
  const altImg = `${partner.name.replace(re, '-').toLowerCase()}-assistlist-partner`;

  return (
    <Modal style={{ width: (window.innerWidth > 500 ? '440px' : ''), height: (window.innerWidth > 500 ? '220px' : '') }} trigger={
      <div style={window.innerWidth > 700 ? tabletCardStyling : mobileCardStyling}>
        {partner.sponsored ? <div className="sponsoredTagMobile">Sponsored</div> : null}
        <Image
          src={partner.logo.thumbnail}
          alt={altImg}
          fluid
          style={{ width: '100%', height: '100%', objectFit: 'contain', padding: '3px' }} />
      </div>} closeIcon>
      <Modal.Content>
        <Modal.Description>
          <div>
            <p style={{ color: '#087CB1', fontWeight: 'bold' }}>{partner.name}</p>
            <p>{partner.description}</p>
            <a href={partner.url} onClick={() => handleOutbound(partner.url)} target="_blank" rel="noopener noreferrer"><p style={{ margin: '0', color: 'black', float: 'right' }}><b><u>Learn More</u></b></p></a>
          </div>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  )
};

