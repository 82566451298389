import React from 'react'
import { Menu, Grid, Message, Button } from 'semantic-ui-react'
import { connect } from 'react-redux'

import { Link } from 'react-router-dom'
import { fetchUserActivePosts, fetchUserInactivePosts, fetchUserExchangedPosts } from '../../actions/profile'

import UserListings from './UserListings'

import './profile.css'

const DesktopListingsMenu = ({
	totalActivePosts,
	totalInactivePosts,
	totalExchangedPosts,
	activeItem,
	handleItemClick,
}) => (
	<Menu vertical attached="top" style={{ marginBottom: 10 }}>
		<Menu.Item
			name="activeListings"
			style={{ color: '#757575', fontWeight: 'bold' }}
			active={activeItem === 'activeListings'}
			onClick={handleItemClick}
		>
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '0' }}>
				<p style={{ marginBottom: '0px' }}>Active Listings</p>
				<p>{totalActivePosts}</p>
			</div>
		</Menu.Item>
		<Menu.Item
			name="inactiveListings"
			style={{ color: '#757575', fontWeight: 'bold' }}
			active={activeItem === 'inactiveListings'}
			onClick={handleItemClick}
		>
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '0' }}>
				<p style={{ marginBottom: '0px' }}>Inactive Listings</p>
				<p> {totalInactivePosts}</p>
			</div>
		</Menu.Item>
		<Menu.Item
			name="exchangedListings"
			style={{ color: '#757575', fontWeight: 'bold' }}
			active={activeItem === 'exchangedListings'}
			onClick={handleItemClick}
		>
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '0' }}>
				<p style={{ marginBottom: '0px' }}>Sold/Donated Listings</p>
				<p>{totalExchangedPosts}</p>
			</div>
		</Menu.Item>
	</Menu>
)

const MobileListingsMenu = ({
	totalActivePosts,
	totalInactivePosts,
	totalExchangedPosts,
	activeItem,
	handleItemClick,
}) => [
		<div>
			<Button primary attached="bottom" style={{ marginBottom: '32px', borderRadius: '5px' }} as={Link} to="/wizard">
				Create Listing
			</Button>

			<Menu vertical attached="top" style={{ width: '100%', marginBottom: '10' }}>
				<Menu.Item
					name="activeListings"
					style={{ color: '#757575', fontWeight: 'bold' }}
					active={activeItem === 'activeListings'}
					onClick={handleItemClick}
				>
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '0' }}>
						<p style={{ marginBottom: '0px' }}>Active Listings</p>
						<p>{totalActivePosts}</p>
					</div>
				</Menu.Item>
				<Menu.Item
					name="inactiveListings"
					style={{ color: '#757575', fontWeight: 'bold' }}
					active={activeItem === 'inactiveListings'}
					onClick={handleItemClick}
				>
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '0' }}>
						<p style={{ marginBottom: '0px' }}>Inactive Listings</p>
						<p> {totalInactivePosts}</p>
					</div>
				</Menu.Item>
				<Menu.Item
					name="exchangedListings"
					style={{ color: '#757575', fontWeight: 'bold' }}
					active={activeItem === 'exchangedListings'}
					onClick={handleItemClick}
				>
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '0' }}>
						<p style={{ marginBottom: '0px' }}>Sold/Donated Listings</p>
						<p>{totalExchangedPosts}</p>
					</div>
				</Menu.Item>
			</Menu>
		</div>,
	]

const MyPostsList = ({
	profile: {
		active_posts,
		active_posts_pagination,
		active_posts_loading,
		inactive_posts,
		inactive_posts_pagination,
		inactive_posts_loading,
		exchanged_posts,
		exchanged_posts_pagination,
		exchanged_posts_loading,
		error,
	},
	activeItem,
	pageChangeActive,
	pageChangeInactive,
	pageChangeExchanged,
}) => (
	<div>
		{activeItem === 'activeListings' && Object.keys(active_posts).length !== 0 && (
			<UserListings
				posts={{
					posts: active_posts,
					pagination: active_posts_pagination,
					error,
					loading: active_posts_loading,
				}}
				handlePageChange={pageChangeActive}
				profile
			/>
		)}

		{activeItem === 'activeListings' && Object.keys(active_posts).length === 0 && !active_posts_loading && (
			<Message info>
				<Message.Header style={{ textAlign: 'center' }}>
					You have no active listings, click on Inactive Listings to view.
				</Message.Header>
			</Message>
		)}

		{activeItem === 'inactiveListings' && Object.keys(inactive_posts).length !== 0 && (
			<UserListings
				posts={{
					posts: inactive_posts,
					pagination: inactive_posts_pagination,
					error,
					loading: inactive_posts_loading,
				}}
				handlePageChange={pageChangeInactive}
				profile
			/>
		)}

		{activeItem === 'inactiveListings' && Object.keys(inactive_posts).length === 0 && !inactive_posts_loading && (
			<Message info>
				<Message.Header style={{ textAlign: 'center' }}>You have no inactive listings.</Message.Header>
			</Message>
		)}

		{activeItem === 'exchangedListings' && Object.keys(exchanged_posts).length !== 0 && (
			<UserListings
				posts={{
					posts: exchanged_posts,
					pagination: exchanged_posts_pagination,
					error,
					loading: exchanged_posts_loading,
				}}
				handlePageChange={pageChangeExchanged}
				profile
			/>
		)}

		{activeItem === 'exchangedListings' && Object.keys(exchanged_posts).length === 0 && !exchanged_posts_loading && (
			<Message info>
				<Message.Header style={{ textAlign: 'center' }}>You have no sold or donated listings.</Message.Header>
			</Message>
		)}
	</div>
)

class MyListings extends React.Component {
	constructor() {
		super()
		this.state = { activeItem: 'activeListings' }

		this.handleItemClick = this.handleItemClick.bind(this)
		this.pageChangeActive = this.pageChangeActive.bind(this)
		this.pageChangeInactive = this.pageChangeInactive.bind(this)
		this.pageChangeExchanged = this.pageChangeExchanged.bind(this)
	}

	componentDidMount() {
		const { user_id } = this.props.user
		this.props.fetchActivePosts(user_id)
		this.props.fetchInactivePosts(user_id)
		this.props.fetchExchangedPosts(user_id)
	}

	pageChangeActive(page) {
		const { user_id } = this.props.user
		this.props.fetchActivePosts(user_id, page)
	}

	pageChangeInactive(page) {
		const { user_id } = this.props.user
		this.props.fetchInactivePosts(user_id, page)
	}

	pageChangeExchanged(page) {
		const { user_id } = this.props.user
		this.props.fetchExchangedPosts(user_id, page)
	}

	pageChangeDraft(page) {
		const { user_id } = this.props.user
		this.props.fetchDraftPosts(user_id, page)
	}

	handleItemClick(e, { name }) {
		this.setState({ activeItem: name })
	}

	render() {
		const { total_posts: totalActivePosts } = this.props.profile.active_posts_pagination
		const { total_posts: totalInactivePosts } = this.props.profile.inactive_posts_pagination
		const { total_posts: totalExchangedPosts } = this.props.profile.exchanged_posts_pagination
		const { activeItem } = this.state

		return (
			<div>
				<Grid relaxed>
					<Grid.Row>
						<Grid.Column>
							<p
								style={{
									marginTop: '37px',
									marginBottom: 0,
									fontFamily: 'Karla, Arial, Helvetica, sans-serif',
									fontWeight: 'bold',
									fontSize: '20px',
									lineHeight: '21px',
									color: '#575757',
								}}
							>
								{' '}
								Manage your equipment listings here
							</p>
							<p
								style={{
									marginTop: '16px',
									marginBottom: 0,
									fontFamily: 'Karla, Arial, Helvetica, sans-serif',
									fontSize: '18px',
									lineHeight: '19px',
								}}
							>
								Take a moment to read our <a className='linkHover' href="/resources#faq" style={{ color: '#1A659F' }} >Safety Guidelines</a> to familiarize yourself with best practices for connecting with potential buyers.
							</p>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column computer={4} only="computer">
							<Button attached="bottom" primary style={{ borderRadius: '5px', marginTop: '32px' }} as={Link} to="/wizard">
								Create Listing
							</Button>
							<DesktopListingsMenu
								totalActivePosts={totalActivePosts}
								totalInactivePosts={totalInactivePosts}
								totalExchangedPosts={totalExchangedPosts}
								activeItem={activeItem}
								handleItemClick={this.handleItemClick.bind(this)}
							/>
						</Grid.Column>
						<Grid.Column mobile={16} tablet={16} only="mobile tablet">
							<MobileListingsMenu
								totalActivePosts={totalActivePosts}
								totalInactivePosts={totalInactivePosts}
								totalExchangedPosts={totalExchangedPosts}
								activeItem={activeItem}
								handleItemClick={this.handleItemClick.bind(this)}
							/>
						</Grid.Column>
						<Grid.Column computer={12} tablet={16} mobile={16}>
							<MyPostsList
								{...this.props}
								activeItem={this.state.activeItem}
								pageChangeActive={this.pageChangeActive}
								pageChangeInactive={this.pageChangeInactive}
								pageChangeExchanged={this.pageChangeExchanged}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.user,
	profile: state.profile,
})

const mapDispatchToProps = (dispatch) => ({
	fetchActivePosts: (id, page) => dispatch(fetchUserActivePosts(id, page)),
	fetchInactivePosts: (id, page) => dispatch(fetchUserInactivePosts(id, page)),
	fetchExchangedPosts: (id, page) => dispatch(fetchUserExchangedPosts(id, page)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MyListings)
