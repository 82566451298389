export const listItemStyle = {
  cityPriceContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 16,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 'bold',
    fontSize: 18,
  },
  imageContainer: {
    maxHeight: 160,
    overflow: 'hidden',
    marginBottom: 10,
  },
  paragraph: {
    marginBottom: 5,
  },
};
