import qs from 'qs';
import api from '../utils/api';
import types from '../types/listings';

export const fetchPosts =
  (params = { page: 1 }) =>
  (dispatch, getState) => {
    const { allowSharingLocation, geoLocation, locationRadius } =
      getState().user;

    dispatch({ type: types.FETCH_POSTS });

    if (typeof params.page === 'undefined') {
      params.page = 1;
    }

    let queryStringAdd = Object.keys(params).length === 1 ? '?' : '&';

    if (params.category) {
      let categories = params.category.split(',');
      categories = categories.filter((category) => category.length > 1);
      categories.forEach((category) => {
        queryStringAdd += `categories[]=${category}&`;
      });

      delete params.category;
    }

    if (params.location) {
      let locations = params.location.split(',');
      locations = locations.filter((location) => location.length > 1);
      locations.forEach((location) => {
        queryStringAdd += `locations[]=${location}&`;
      });

      delete params.location;
    }
    if (params.condition) {
      let conditions = params.condition.split(',');
      conditions = conditions.filter((condition) => condition.length > 1);
      conditions.forEach((condition) => {
        queryStringAdd += `conditions[]=${condition}&`;
      });

      delete params.condition;
    }
    if (params.max_price || params.min_price) {
      if (params.max_price && params.min_price) {
        queryStringAdd +=
          'price_range[starting_price]=' +
          params.min_price +
          '&price_range[ending_price]=' +
          params.max_price +
          '&';
        delete params.max_price;
        delete params.min_price;
      } else if (params.max_price) {
        queryStringAdd +=
          'price_range[starting_price]=0&price_range[ending_price]=' +
          params.max_price +
          '&';
        delete params.max_price;
        delete params.min_price;
      } else {
        queryStringAdd +=
          'price_range[starting_price]=' +
          params.min_price +
          '&price_range[ending_price]=999999&';
        delete params.max_price;
        delete params.min_price;
      }
    }

    queryStringAdd = queryStringAdd.slice(0, -1);
    let queryString = qs.stringify(params) + queryStringAdd;

    if (allowSharingLocation) {
      queryString +=
        '&allowSharingLocation=' +
        allowSharingLocation +
        '&lat=' +
        geoLocation?.lat +
        '&long=' +
        geoLocation?.long +
        '&radius=' +
        (locationRadius ?? 10);
    }

    return api
      .get(`/posts?${queryString}`)
      .then((response) =>
        dispatch({
          type: types.FETCH_POSTS_SUCCESS,
          payload: {
            data: response.data.data,
            pagination: JSON.parse(response.headers['x-pagination']),
          },
        }),
      )
      .catch((error) =>
        dispatch({ type: types.FETCH_POSTS_FAILURE, payload: error }),
      );
  };

export const fetchFilters =
  (allowSharingLocation, geoLocation, radius) => (dispatch, getState) => {
    const filters = {};

    if (allowSharingLocation === undefined) {
      allowSharingLocation = getState().user.allowSharingLocation;
      geoLocation = getState().user.geoLocation;
      radius = getState().user.locationRadius;
    }

    return Promise.all([
      api.get('api/posts/categories').then((response) => {
        filters.categories = response.data.data;
      }),
      api
        .get(
          allowSharingLocation
            ? `api/posts/locations_filter?allowSharingLocation=${allowSharingLocation}&lat=${
                geoLocation?.lat
              }&long=${geoLocation?.long}&radius=${radius || 10}`
            : `api/posts/locations_filter`,
        )
        .then((response) => {
          filters.locations = response.data.data;
        }),
      api.get('api/posts/sorted_by_options').then((response) => {
        filters.sorted_by_options = response.data.data;
      }),
      api.get('api/posts/conditions').then((response) => {
        filters.conditions = response.data.data;
      }),
    ])
      .then(() =>
        dispatch({ type: types.FETCH_FILTERS_SUCCESS, payload: filters }),
      )
      .catch((error) =>
        dispatch({ type: types.FETCH_FILTERS_FAILURE, payload: error }),
      );
  };

export const fetchCategories = () => (dispatch) => {
  dispatch({ type: types.FETCH_CATEGORIES });
  return api
    .get('api/posts/categories')
    .then((response) =>
      dispatch({
        type: types.FETCH_CATEGORIES_SUCCESS,
        payload: response.data.data,
      }),
    )
    .catch((error) =>
      dispatch({ type: types.FETCH_CATEGORIES_FAILURE, payload: error }),
    );
};

export const applyFilters = (filters) => (dispatch) => {
  dispatch({ type: types.APPLY_FILTERS, payload: filters });
};
