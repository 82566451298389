/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react'
import { Button, Container, Responsive, Modal } from 'semantic-ui-react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import AboutUsHeader from './AboutUsHeader'
import { fetchTeamStats, fetchTeams } from '../../actions/aboutus'
import TeamMembers from './TeamMembers'
import Directors from './Directors'
import TeamLeads from './TeamLeads'
import PastTeam from './PastTeamMembers'
import VolunteerInsights from './VolunteerInsights'
import './aboutus.css'
import rectangle from '../../images/rectangle.png'

const paragraphStyling = {
	fontFamily: 'Karla, Arial, Helvetica, sans-serif',
	fontSize: '18px',
	lineHeight: '21px',
}

const TeamCategories = (props) => (
	<Modal
		style={{ height: '327px', width: '992px' }}
		trigger={
			<div
				style={{
					color: '#ffffff',
					height: '356px',
					width: '224px',
					borderRadius: '10px',
					background: '#00ADD8',
					position: 'relative',
					marginBottom: '8px',
				}}
			>
				<div
					className="teamCategory"
					style={{
						backgroundImage: `url(${props.picture})`,
						opacity: '0.3',
						backgroundSize: 'cover',
						borderRadius: '10px',
						backgroundPosition: '50%, 50% ',
						backgroundRepeat: 'no-repeat',
						height: '100%',
						width: '100%,',
					}}
				/>
				<h3
					style={{
						fontSize: '24px',
						margin: '0',
						position: 'absolute',
						bottom: '20%',
						left: '39%',
						marginLeft: '-70px',
					}}
				>
					{props.team}
				</h3>
				<p
					style={{
						fontSize: '18px',
						fontWeight: 'bold',
						position: 'absolute',
						top: '85%',
						left: '50%',
					}}
				>
					{' '}
					<u>Read More</u>
				</p>
			</div>
		}
		closeIcon
	>
		<Modal.Content>
			<Modal.Description>
				<div style={{ marginBottom: '24px' }}>
					<h1>{props.team.includes('Marketing') ? 'Digital Marketing Team' : props.team} </h1>
				</div>
				<div style={{ marginBottom: '32px' }}>
					<p style={paragraphStyling}>{props.description}</p>
				</div>
				<div
					style={{
						display: 'flex',
						flexFlow: 'row',
						flexWrap: 'wrap',
						justifyContent: 'space-around',
					}}
				>
					<div>
						<TeamMembers team={props.team} />
					</div>
				</div>
			</Modal.Description>
		</Modal.Content>
	</Modal>
)

const TeamCategoriesMobile = (props) => (
	<Modal
		style={{ height: '400px', width: '345px' }}
		trigger={
			<div
				style={{
					textAlign: 'center',
					color: '#ffffff',
					height: '168px',
					width: '155px',
					borderRadius: '10px',
					background: '#00ADD8',
					position: 'relative',
					marginBottom: '16px',
				}}
			>
				<div
					style={{
						backgroundImage: `url(${props.picture})`,
						opacity: '0.3',
						backgroundSize: 'cover',
						borderRadius: '10px',
						backgroundPosition: '50%, 50% ',
						backgroundRepeat: 'no-repeat',
						height: '100%',
						width: '100%,',
					}}
				/>
				<h3
					style={{
						fontSize: '18px',
						margin: '0 auto',
						position: 'absolute',
						left: '15px',
						bottom: '40%',
					}}
				>
					{props.team}
				</h3>
				<p
					style={{
						fontSize: '18px',
						fontWeight: 'bold',
						position: 'absolute',
						top: '79%',
						left: '26%',
					}}
				>
					{' '}
					<u>Read More</u>
				</p>
			</div>
		}
		closeIcon
	>
		<Modal.Content>
			<Modal.Description>
				<div style={{ marginBottom: '24px' }}>
					<h1 style={{ fontSize: '18px', marginTop: '16px' }}>{props.team} </h1>
				</div>
				<div style={{ marginBottom: '32px' }}>
					<p style={paragraphStyling}>{props.description}</p>
				</div>
				<div>
					<TeamMembers team={props.team} />
				</div>
			</Modal.Description>
		</Modal.Content>
	</Modal>
)

const OurTeam = ({ team_stats, teams, _fetchTeamStats, _fetchTeams }) => {
	useEffect(() => {
		_fetchTeamStats()
    _fetchTeams()
    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div>
			<AboutUsHeader picture="Our Team" />

			<Helmet>
				<title>Our Team - AssistList</title>
				<meta
					name="description"
					content="Learn more about our team. Interested? Learn how you can get involved and volunteer with us."
				/>
			</Helmet>

			<Container style={{ margin: '0 auto', maxWidth: '1080px', padding: '0px 40px 40px 40px' }}>
				{/* OUR VOLUNTEER BOARD OF DIRECTORS */}
				<Responsive minWidth={750}>
					<img style={{ paddingTop: '80px' }} src={rectangle} alt="" />
					<h1 style={{ paddingBottom: '30px' }}>Our Volunteer Board of Directors</h1>

					<Directors />

					<div style={{ paddingTop: '30px' }}>
						<img src={rectangle} alt="" />
						{/* TEAM LEADS */}
						<h1>Our Volunteer Managers</h1>
						<div style={{ display: 'flex', flexFlow: 'row', flexWrap: 'wrap' }}>
							<TeamLeads />
						</div>
					</div>

					<div style={{ marginTop: '84px' }}>
						<img src={rectangle} alt="" />
						<h1 style={{ paddingBottom: '30px' }}>Volunteer Team Members</h1>
						<p style={paragraphStyling}>
							AssistList wouldn&apos;t be possible without support from our passionate volunteers. Their enthusiasm and
							commitment have helped us provide a reliable, safe, and easy-to-use resource to support our community.
						</p>

						{/* STATS */}
						<div
							style={{
								display: 'flex',
								flexFlow: 'row',
								justifyContent: 'space-around',
								marginTop: '80px',
							}}
						>
							{team_stats && team_stats.length > 0 ? (
								team_stats.map((statistic) => (
									<div key={statistic.label}>
										<h1
											style={{
												marginBottom: '0px',
												color: '#00ADD8',
											}}
										>
											{statistic.label}
										</h1>
										<p>{statistic.count}</p>
									</div>
								))
							) : (
								<div />
							)}
						</div>

						{/* TEAM CATEGORIES */}
						<div
							style={{
								marginTop: '84px',
								display: 'flex',
								flexFlow: 'row',
								justifyContent: 'space-around',
								flexWrap: 'wrap',
								padding: window.innerWidth > 990 ? (window.innerWidth > 1200 ? '0px 140px' : '0px 0px') : '0px 0px',
							}}
						>
							{teams && teams.length > 0 ? (
								teams.map((team) => (
									<TeamCategories
										picture={team.backdrop_image.thumbnail}
										description={team.description}
										team={team.name}
										key={team.id}
									/>
								))
							) : (
								<div />
							)}
						</div>

						{/* PAST TEAM MEMBERS */}
						<div style={{ marginTop: '84px' }}>
							<img src={rectangle} alt="" />
							<h1 style={{ paddingBottom: '30px' }}>Past Team Members</h1>
							<p style={paragraphStyling}>Thank you to our past team members for volunteering!</p>
							<PastTeam />
						</div>

						<div style={{ marginTop: '84px' }}>
							<img src={rectangle} alt="" />

							{/* GET INVOLVED */}
							<h1 style={{ paddingBottom: '30px' }}>Get Involved</h1>
							<p style={paragraphStyling}>
								Are you looking to volunteer and give back to your community? Volunteering for a not-for-profit is a
								great way to meet people, gain experience and apply your creativity. AssistList has a range of volunteer
								opportunities and would love for enthusiastic and passionate individuals to join our AssistList family!
							</p>
							<p style={paragraphStyling}>
								{' '}
								It is our mission to ensure that you have the support and resources needed to make your volunteer
								experience as educational and rewarding as possible, and our teams consist of professionals that can
								provide industry mentorship.{' '}
							</p>
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<Button
									style={{ width: '306px', height: '40px', marginTop: '56px' }}
									primary
									target="_blank"
									href="https://ca.indeed.com/cmp/Assistlist/about"
								>
									View Volunteer Opportunities
								</Button>
							</div>
						</div>
						<div style={{ padding: '80px 0' }}>
							<VolunteerInsights />
						</div>
					</div>
				</Responsive>
			</Container>

			{/* MOBILE BREAKPOINT */}

			<Container>
				<Responsive maxWidth={749}>
					{/* DIRECTORS */}
					<img style={{ paddingTop: '40px' }} src={rectangle} alt="" />
					<h1 style={{ paddingBottom: '30px' }}>Our Volunteer Board of Directors</h1>

					<Directors />

					<img src={rectangle} alt="" />
					<h1 style={{ paddingBottom: '30px' }}>Our Volunteer Managers</h1>

					{/* TEAM LEADS */}
					<div style={{ display: 'flex', flexFlow: 'row', flexWrap: 'wrap' }}>
						<TeamLeads />
					</div>

					<div style={{ marginTop: '84px' }}>
						<img src={rectangle} alt="" />
						<h1 style={{ paddingBottom: '30px' }}>Volunteer Team Members</h1>
						<p style={paragraphStyling}>
							AssistList wouldn&apos;t be possible without support from our passionate volunteers. Their enthusiasm and
							commitment have helped us provide a reliable, safe, and easy-to-use resource to support our community.
						</p>
					</div>
					<div
						style={{
							display: 'flex',
							flexFlow: 'row',
							justifyContent: 'space-around',
							marginTop: '24px',
						}}
					>
						{team_stats && team_stats.length > 0 ? (
							team_stats.map((statistic) => (
								<div key={statistic.label}>
									<h1
										style={{
											marginBottom: '0px',
											color: '#00ADD8',
										}}
									>
										{statistic.label}
									</h1>
									<p>{statistic.count}</p>
								</div>
							))
						) : (
							<div />
						)}
					</div>
					<div
						style={{
							marginTop: '24px',
							display: 'flex',
							flexFlow: 'row',
							flexWrap: 'wrap',
							justifyContent: 'space-around',
						}}
					>
						{teams && teams.length > 0 ? (
							teams.map((team) => (
								<TeamCategoriesMobile
									picture={team.backdrop_image.thumbnail}
									description={team.description}
									team={team.name}
									key={team.name}
								/>
							))
						) : (
							<div />
						)}
					</div>

					<div style={{ marginTop: '48px' }}>
						<img src={rectangle} alt="" />
						<h1 style={{ paddingBottom: '30px' }}>Past Team Members</h1>
						<p style={paragraphStyling}>Thank you to our past team members for volunteering! </p>
						<PastTeam />
					</div>

					<div style={{ marginTop: '84px' }}>
						<img src={rectangle} alt="" />
						<h1 style={{ paddingBottom: '30px' }}>Get Involved</h1>

						<p style={{ paragraphStyling }}>
							Are you looking to volunteer and give back to your community? Volunteering for a not-for-profit is a great
							way to meet people, gain experience and apply your creativity. AssistList has a range of volunteer
							opportunities and would love for enthusiastic and passionate individuals to join our AssistList family!
						</p>
						<p style={{ paragraphStyling }}>
							It is our mission to ensure that you have the support and resources needed to make your volunteer
							experience as educational and rewarding as possible, and our teams consist of professionals that can
							provide industry mentorship.
						</p>
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<Button
								style={{ width: '306px', height: '40px', marginTop: '40px' }}
								primary
								target="_blank"
								href="https://ca.indeed.com/cmp/Assistlist/about"
							>
								View Volunteer Opportunities
							</Button>
						</div>
					</div>
					<div style={{ padding: '80px 18%' }}>
						<VolunteerInsights />
					</div>
				</Responsive>
			</Container>
		</div>
	)
}

const mapDispatchToProps = (dispatch) => ({
	_fetchTeamStats: () => dispatch(fetchTeamStats()),
	_fetchTeams: () => dispatch(fetchTeams()),
})

const mapStateToProps = (state) => ({
	team_stats: state.aboutus.team_stats,
	teams: state.aboutus.teams,
	loading: state.aboutus.loading,
})

export default connect(mapStateToProps, mapDispatchToProps)(OurTeam)
