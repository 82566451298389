import React from 'react'
import { Button, Grid, Icon } from 'semantic-ui-react'

const SocialMedia = ({ open }) =>
	open ? (
		<Grid container>
			<Grid.Row columns={4}>
				<Grid.Column>
					<Button icon href="https://www.facebook.com/AssistList/">
						<Icon fitted className="primary-color svg" size="big" name="facebook square" />
					</Button>
				</Grid.Column>
				<Grid.Column>
					<Button icon href="https://www.instagram.com/assistlist/">
						<Icon fitted className="primary-color svg" size="big" name="instagram" />
					</Button>
				</Grid.Column>
				<Grid.Column>
					<Button icon href="https://twitter.com/assistlistca">
						<Icon fitted className="primary-color svg" size="big" name="twitter square" />
					</Button>
				</Grid.Column>
				<Grid.Column>
					<Button icon href="https://www.linkedin.com/company/assistlist/">
						<Icon fitted className="primary-color svg" size="big" name="linkedin square" />
					</Button>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	) : (
		<div>
			<Button icon href="https://www.facebook.com/AssistList/">
				<Icon fitted className="primary-color svg" size="large" name="facebook f" />
			</Button>
			<Button icon href="https://www.instagram.com/assistlist/">
				<Icon fitted className="primary-color svg" size="large" name="instagram" />
			</Button>
			<Button icon href="https://twitter.com/assistlistca">
				<Icon fitted className="primary-color svg" size="large" name="twitter" />
			</Button>
			<Button icon href="https://www.linkedin.com/company/assistlist/">
				<Icon fitted className="primary-color svg" size="large" name="linkedin" />
			</Button>
		</div>
	)

export default SocialMedia
