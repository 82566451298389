import { createClient } from "contentful";
import { toCamelCase } from "../utils/formatter";

const client = createClient({
  // local development values are in docker-compose.yml
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT_ID,
});

export const getEntryById = async (id) => {
  try {
    const entry = await client.getEntry(id);

    return entry.fields;
  } catch (err) {
    console.log(`Error fetching entry with ID: ${id}. ERROR: ${err}`);
  }
};

/**
 * multi entry fetch, useful for getting linked assets and references
 *
 * @param { The unique entry title of the content type, sort of like a PK } entryTitle
 * @param { The depth of the referenced fetch, returns only links if too deep } includeDepth
 * @returns Array of matching entries of this contentTypeId with the content title
 */
export const getPageData = async (entryTitle, includeDepth = 1) => {
  try {
    const entries = await client.getEntries({
      content_type: "pageData",
      "fields.title": entryTitle,
      include: includeDepth,
      select: "fields",
      limit: 1, // This should always only return 1
    });

    const sanitizedEntries = entries.items.map((item) => {
      return {
        ...item.fields,
      };
    });

    return sanitizedEntries;
  } catch (err) {
    console.log(`Error fetching ${entryTitle}: ${err}`);
  }
};

export const formatPageData = (pageData) => {
  const formattedData = {};
  formattedData.title = pageData[0].title;
  // api always returns an array
  const data = pageData[0].body;

  data.forEach((content) => {
    const contentKey = toCamelCase(content.fields.title);

    formattedData[contentKey] = content.fields;
  });

  return formattedData;
};

export const formatReferenceLink = (references, asArray = false) => {
  if (asArray) {
    return references.map((ref) => ref.fields);
  }

  // as key val object
  let formattedRefs = {};

  references.forEach((ref) => {
    const key = toCamelCase(ref.fields.title);
    formattedRefs[key] = ref.fields;
  });

  return formattedRefs;
};

export const formatAssetUrls = (assests) => {
  return assests.map((img) => {
    return {
      title: img.fields.title,
      url: `https:${img.fields.file.url}`,
      description: img.fields.description,
    };
  });
};
