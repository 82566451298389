import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { reduxForm, getFormValues, formValueSelector } from 'redux-form'
import { Form, Segment, Message, Button, Icon, Responsive } from 'semantic-ui-react'

import { showAlert } from '../../actions/alerts'

import Post from '../PostComponents/PostDetail'

const tinyMobile = 377

const Preview = (props) => {
	const {
		values,
		submit,
		submitting,
		wizard: { postId, images, locations, categories, conditions },
		previousPage,
		equipment_type,
	} = props

	if (!values.images || values.images.length === 0) {
		return <Message negative>Image is required! Go back to &apos;Images&apos; and upload an image.</Message>
	}

	if (!values.title) {
		return <Message negative>Title is missing! Go back to &apos;Basic Information&apos; to enter a title.</Message>
	}

	if (!values.description) {
		return (
			<Message negative>
				Description is missing! Go back to &apos;Additional Details&apos; to enter a description.
			</Message>
		)
	}

	if (!values.price && !values.free) {
		return <Message negative>Price is required. Go back to the &apos;Basic Information&apos; stepto change it.</Message>
	}
	if (values.price > 10000) {
		return (
			<Message negative>Price exceeds 10,000. Go back to the &apos;Basic Information&apos; step to change it.</Message>
		)
	}
	if (!values.free && !/^\d+$/.test(values.price)) {
		return (
			<Message negative>Price is not a number. Go back to the &apos;Basic Information&apos; step to change it.</Message>
		)
	}
	const properties = (({
		// eslint-disable-next-line no-shadow
		location_id,
		condition_id,
		title,
		description,
		price,
		free,
		location,
		images,
		equipment_type,
		...o
	}) => o)(values)

	let postPreviewValues = {
		properties: { ...properties },
		title: values.title,
		description: values.description,
		price: values.price,
		free: values.free,
		condition: { name: conditions.find((con) => con.value === values.condition_id).text },
		location: { name: locations.find((loc) => loc.value === values.location_id).text },
		category: {
			name: categories.find((cat) => cat.equipment_types.find((equip) => equip.name === equipment_type.name)).name,
		},
		images: images.map((im) => ({
			small: im.preview,
			large: im.preview,
			rotation_origin: im.rotation_origin,
		})),
		equipment_type: values.equipment_type,
		user: {},
	}

	if (postId) {
		return <Redirect to={`/posts/${postId}?submitted=success`} />
	}

	return (
		<div style={{ marginBottom: '56px' }}>
			<Responsive maxWidth={989}>
				<div>
					<Segment
						style={{ marginBottom: '0px', background: 'white', borderBottom: '1px #CACACA solid' }}
						basic
						clearing
					>
						<h2>Creating new listing for: {equipment_type.name}</h2>
						<p className="simpleKarlaText">
							This is a preview of your listing. It is not yet live on the site. To publish it, click on Submit Listing.
						</p>
					</Segment>
				</div>
			</Responsive>
			<Responsive minWidth={990}>
				<div>
					<Segment
						style={{ marginBottom: '0px', background: 'white', borderBottom: '1px #CACACA solid' }}
						basic
						clearing
					>
						<div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
							<h2 style={{ width: window.innerWidth > 989 ? '540px' : '' }}>
								Creating new listing for: {equipment_type.name}
							</h2>

							<p className="simpleKarlaText">
								This is a preview of your listing. It is not yet live on the site. To publish it, click on Submit
								Listing.
							</p>
						</div>
					</Segment>
				</div>
			</Responsive>
			<Segment style={{ marginTop: '0px' }}>
				<Post post={postPreviewValues} shareDisabled={true} reportActive={false} />{' '}
				{/* props for ShareButton and  ReportButton components to be disabled*/}
				<div style={{ marginTop: '24px' }}>
					<Form style={{ width: '100%' }} name="newPost" onSubmit={submit}>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<Button
								type="button"
								className="previousStepBtn"
								style={{
									width: window.innerWidth > tinyMobile ? '' : '140px',
									padding: window.innerWidth > tinyMobile ? '' : '0.6875em 0em .6875em',
								}}
								onClick={previousPage}
								primary
								basic
								floated="left"
							>
								<Icon name="angle left" /> <b>Previous Step</b>{' '}
							</Button>
							<Button
								type="submit"
								className="nextStepBtn"
								primary
								loading={submitting}
								style={{
									width: window.innerWidth > tinyMobile ? '173px' : '140px',
									padding: window.innerWidth > tinyMobile ? '' : '0.6875em 0em .6875em',
								}}
							>
								Submit Listing
							</Button>
						</div>
					</Form>
				</div>
			</Segment>
		</div>
	)
}

const selector = formValueSelector('newPost')

const mapStateToProps = (state) => ({
	values: getFormValues('newPost')(state),
	wizard: state.post.postForm,
	equipment_type: selector(state, 'equipment_type'),
})

const mapDispatchToProps = (dispatch) => ({
	showAlert: (options) => dispatch(showAlert(options)),
})

const PreviewContainer = connect(mapStateToProps, mapDispatchToProps)(Preview)

export default reduxForm({
	form: 'newPost',
	destroyOnUnmount: false,
})(PreviewContainer)
