import types from '../types/recentlistings';
import { showAlert } from './alerts';
import api from '../utils/api';

export const fetchRecentListing = (post_ids, user_id) => (dispatch) => {
  dispatch({ type: types.FETCH_RECENT_LISTING });
  return (
    api.get('api/posts/recent', { params: {post_ids, user_id} } )
      .then(response => dispatch({ type: types.FETCH_RECENT_LISTING_SUCCESS, payload: response.data }))
      .catch(error => {
        dispatch(showAlert({
          header: 'Something went wrong.',
          flowId: error.response?.data?.data?.flow_id,
          message: 'Listing cannot be displayed',
          type: 'error',
        }));
        dispatch({ type: types.FETCH_RECENT_LISTING_FAILURE, payload: error });
      })
  );
};
