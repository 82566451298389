import api from '../utils/api';
import types from '../types/resources';

export const fetchSafeExchangeZones = () => (dispatch) => {
  dispatch({ type: types.FETCH_SAFE_EXCHANGE_ZONES });
  api.get('/safe_exchange_zones')
    .then(response => dispatch(
      { type: types.FETCH_SAFE_EXCHANGE_ZONES_SUCCESS, payload: response.data.data })
    )
    .catch((error) => {
      dispatch({ type: types.FETCH_SAFE_EXCHANGE_ZONES_FAILURE, payload: error });
    });
};

export const fetchServices = () => (dispatch) => {
  dispatch({ type: types.FETCH_SERVICES});
  api.get('/services')
    .then(response => dispatch(
      { type: types.FETCH_SERVICES_SUCCESS, payload: response.data.data })
    )
    .catch((error) => {
      dispatch({ type: types.FETCH_SERVICES_FAILURE, payload: error });
    });
};
