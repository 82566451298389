import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { Segment, Form, Button, Message, Icon, Responsive } from 'semantic-ui-react'

import WaitingPeople from './WaitingPeople'
import ImageDropzone from '../ImageDropzone'

const tinyMobile = 377

class ImagesForm extends React.Component {
	constructor() {
		super()

		this.state = {
			uploaded: true,
		}

		this.setUploaded = (uploaded) => this.setState({ uploaded })
	}

	render() {
		const { handleSubmit, previousPage, equipment_type, valid, submitFailed, waitingPeople } = this.props

		return (
			<div style={{ marginBottom: 56 }}>
				<Responsive maxWidth={989}>
					<Segment
						style={{ marginTop: '0px', marginBottom: '0px', background: 'white', borderBottom: '1px #CACACA solid' }}
						basic
						clearing
					>
						<h2>Creating new listing for: {equipment_type.name}</h2>
						<p className="simpleKarlaText" style={{ width: '100%' }}>
							Please upload some images of your item.
						</p>
					</Segment>
					<div style={{ background: 'white', padding: '1em 1em' }}>
						<WaitingPeople waitingPeople={waitingPeople} />

						{!valid && submitFailed && (
							<Message negative>
								<Message.Header>Image is required</Message.Header>
							</Message>
						)}
					</div>
				</Responsive>
				<Responsive minWidth={990}>
					<Segment
						style={{ marginBottom: '0px', background: 'white', borderBottom: '1px #CACACA solid' }}
						basic
						clearing
					>
						<div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
							<h2 style={{ width: window.innerWidth > 989 ? '540px' : '' }}>
								Creating new listing for: {equipment_type.name}
							</h2>
							<p className="simpleKarlaText" style={{ width: '100%' }}>
								Please upload some images of your item.
							</p>
						</div>
					</Segment>
					<div style={{ background: 'white', padding: '1em 1em' }}>
						<WaitingPeople waitingPeople={waitingPeople} />
						{!valid && submitFailed && (
							<Message negative>
								<Message.Header>Image is required</Message.Header>
							</Message>
						)}
					</div>
				</Responsive>
				<Field required name="images" component={ImageDropzone} setUploaded={this.setUploaded} />
				<Segment style={{ backgroundColor: 'white', marginTop: '0px' }} basic clearing>
					<Form name="newPost" onSubmit={handleSubmit}>
						<Button
							style={{
								width: window.innerWidth > tinyMobile ? '' : '140px',
								padding: window.innerWidth > tinyMobile ? '' : '0.6875em 0em .6875em',
							}}
							onClick={previousPage}
							primary
							basic
							floated="left"
						>
							<Icon name="angle left" /> <b>Previous Step</b>{' '}
						</Button>
						<Button
							type="submit"
							className="nextStepBtn"
							primary
							style={{
								width: window.innerWidth > tinyMobile ? '173px' : '140px',
								padding: window.innerWidth > tinyMobile ? '' : '0.6875em 0em .6875em',
							}}
							floated="right"
						>
							{' '}
							Next Step <Icon name="angle right" />
						</Button>
					</Form>
				</Segment>
			</div>
		)
	}
}

const validate = (values) => {
	const errors = {}
	if (!(values.images && values.images.length > 0)) {
		errors.images = 'Image is required'
	}
	return errors
}

const ImagesContainer = reduxForm({
	form: 'newPost',
	validate,
	destroyOnUnmount: false,
	onSubmitFail: () => window.scrollTo(0, 0),
})(ImagesForm)

const selector = formValueSelector('newPost')

const mapStateToProps = (state) => ({
	equipment_type: selector(state, 'equipment_type'),
})

export default connect(mapStateToProps)(ImagesContainer)
