import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Container, Segment, Header, Button, Form, Message,
} from 'semantic-ui-react';

import { Field, Fields } from 'redux-form';

import {
  InputField, TextAreaField, PriceField, SelectField,
} from '../../../utils/render-input';

import ImageDropzone from '../../ImageDropzone';


export default class EditPage extends Component {
  render() {
    const {
      params,
      loading,
      conditions,
      locations,
      fields,
      synchronousErrors,
      onSetUploaded,
      onPreview,
    } = this.props;

    return (
      <Container style={{ margin: (window.innerWidth > 700 ? '0 auto' : '0 5%'), maxWidth: '1120px', paddingBottom: '160px' }}>
        <div style={{ paddingTop: '16px' }}>
          <Button size="tiny" as={Link} to={`/posts/${params.id}`} color="black" icon="left chevron" content="Back to listing" />
        </div>
        <Segment loading={loading} clearing>
          {!loading && (
            <Form>
              <Header as="h2" content="Basic Information" />
              <Field component={InputField} fluid name="title" label="Post Title" required />
              <Field component={TextAreaField} name="description" label="Description" rows={10} required />
              <Form.Group widths="equal">
                <Fields names={['price', 'free']} component={PriceField} required />
                <Field component={SelectField} name="condition_id" label="Condition" placeholder="Select a condition" options={conditions} required />
                <Field component={SelectField} name="location_id" label="Location" placeholder="Select a location" options={locations} required />
              </Form.Group>
              {fields.length > 0 && (
                <div>
                  <Header as="h2" content="Additional Details" />
                  {fields.map((field) => {
                    const validator = {
                      required: field.field_required,
                      validate: (field.field_required && (value => (!value && 'Field required'))),
                    };

                    let options = [];

                    if (field.options) {
                      options = field.options.map(o => ({
                        text: o.name,
                        value: o.name,
                      }));
                    }

                    switch (field.field_type) {
                      case 'drop_down':
                        return (
                          <Field
                            component={SelectField}
                            name={field.field_name}
                            label={field.field_name}
                            placeholder="Select an option"
                            options={options}
                            {...validator}
                          />
                        );
                      case 'unit_text_field':
                        return (
                          <Field
                            component={InputField}
                            name={field.field_name}
                            label={field.field_name}
                            {...validator}
                          />
                        );
                      default:
                        return (
                          <Field
                            component={InputField}
                            name={field.field_name}
                            label={field.field_name}
                            {...validator}
                          />
                        );
                    }
                  })}
                </div>
              )}
              <div>
                <div style={{ marginTop: '25px' }} />
                <Header as="h2" content="Images" />
                {synchronousErrors.images
                  && (
                    <Message negative>
                      <Message.Header>Image is required</Message.Header>
                      <Message.Content>Please upload images using dropzone below.</Message.Content>
                    </Message>
                  )
                }
                <Field name="images" component={ImageDropzone} setUploaded={onSetUploaded} />
              </div>

              <Button as={Link} to={`/posts/${params.id}`} secondary content="Cancel" floated="left" />
              <Button primary content="Preview" floated="right" onClick={onPreview} />

            </Form>
          )}
        </Segment>
      </Container>
    );
  }
}
