import React, { useState } from "react";
import { connect } from "react-redux";
import { reportPost } from "../../../actions/post";
import { Image, Modal, Button, Icon } from "semantic-ui-react";
import BullHornIcon from './reportButton.svg';
import './change-button.css';

import ReportReason from './ReportReason.js';


const ReportButton = ({ post, _reportPost, reportActive=true }) => {
  
  const [active, isActive] = useState(false);
  const [reasons, changeReasons] = useState([])

  let otherInfo = ''

  function trigger (){
    isActive(!active)
  }

  function addReasons(add, text){
    (add? changeReasons(prevState => prevState.concat(text))
      : changeReasons(prevState => prevState.filter(element => element !== text))
    )
  }

  function submitReport (){
    trigger()

    if(document.getElementById('reportListingOther')){
        otherInfo = `Other: ${document.getElementById('reportListingOther').value}`
    }

    if(reasons.length || otherInfo){
      if((JSON.parse(localStorage.getItem('user')))){
        _reportPost(post.id, (JSON.parse(localStorage.getItem('user')).email), (otherInfo? reasons.concat(otherInfo): reasons))
      } else {
         _reportPost(post.id, 'no account', (otherInfo? reasons.concat(otherInfo): reasons))
      }
    }

  }

  return (
    <Modal 
      open={reportActive ? active : false }
      onClose={trigger} 
      style={{height:'1138px', width: (window.innerWidth > 777? '777px' : '100%')}} 
      trigger={<div >
                    <div style={{ cursor: reportActive ? 'pointer' : 'default'  }} 
                      onClick={trigger} className="grid-sm">
                      <Icon size='large' name='bullhorn' style={{ opacity: reportActive ? '1' : '0.45'}}/>
                      <p style={{fontFamily: 'Karla', opacity: reportActive ? '1' : '0.45'}}>Report Listing</p>
                    </div> 
                </div>} 
    closeIcon>
      <Modal.Content disabled style={{display: 'flex', justifyContent: 'center'}}>
        <div>
          <div style={{marginTop: '48px', marginBottom: '46px', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
            <Image src={BullHornIcon} /> <h2 style={{margin: '0 28px'}}>Report Problem </h2>
          </div>
          <p style={{fontFamily:'Karla'}}>Why is this listing being reported? <b>Select all that apply.</b> </p>

          <Modal.Description style={{margin: '0 auto'}}>
            <ReportReason addText={addReasons} text='This item has been sold' />
            <ReportReason addText={addReasons} text='This seller has not responded in over 14 days' />
            <ReportReason addText={addReasons} text='This item is miscategorized' />
            <ReportReason addText={addReasons} text='This is a duplicate listing' />
            <ReportReason addText={addReasons} text='This listing looks like a scam' />
            <ReportReason addText={addReasons} text='This listing contains a prohibited item' />
            <ReportReason addText={addReasons} text='This is a commercial advertisement' />
            <ReportReason addText={addReasons} text='This listing contains offensive, obscene, or malicious content' />
            <ReportReason other='true' text='Other' />

            <div style={{textAlign: 'center'}}>
              <Button id='reportBtn' onClick={submitReport} style={{padding: '24px', marginTop: '64px', background: '#00ACDA', color: 'white', width: '100%'}}> Report Problem </Button>
              <p style={{color: '#646466', marginTop: '32px'}}> Your report is confidental</p>
            </div>
          </Modal.Description>
        </div>
      </Modal.Content>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  post: state.post.details,
});

const mapDispatchToProps = (dispatch) => ({
  _reportPost: (id, reporting_user, reasons) => dispatch(reportPost(id, reporting_user, reasons)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportButton);