import React, { useState } from 'react';
import { Icon, Form, TextArea } from "semantic-ui-react";


const ReportReason = (props) => {

  const [active, isActive] = useState(false);

  function trigger (){
    if(!props.other){
      (active? props.addText(false, props.text) : props.addText(true, props.text))
    }
    isActive(!active)
  }

  return (
    <React.Fragment>
      <div onClick={trigger} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '8px', height: '62px', width: '100%', border: '1px solid #CACBCD', background: (active? '#F2F2F2': '#F7F7F7')}}>
        <Icon size='large' style={{marginLeft: '16px', visibility: (active? '': 'hidden')}} color='green' name='check circle'/>
        <p style={{fontFamily: 'Karla', fontSize: (window.innerWidth > 500? '18px' : '14px'), paddingLeft: '24px'}}>{props.text}</p>
      </div>
      {(props.other && active)? <Form> <Form.Field id='reportListingOther' style={{width: '100%', height: '134px'}} control={TextArea} /> </Form> : null}
    </React.Fragment>
  );
};

export default ReportReason;