import React from 'react';
import { Responsive } from 'semantic-ui-react';
import { HashLink as Link } from 'react-router-hash-link';
import facebook from '../images/facebook-white.png';
import twitter from '../images/twitter-white.png';
import instagram from '../images/instagram-white.png';
import linkedin from '../images/linkedin-white.png';

const footerStyleDesktop = {
  background: '#575757',
  color: '#ffffff',
  padding: '20px 0px 25px 0px',
  height: '60px',
  fontSize: '16px',
  textAlign: 'center',
  letterSpacing: '0.015em',
  position: 'absolute',
  right: '0px',
  left: '0px',
};

const footerStyleMobile = {
  background: '#575757',
  color: '#ffffff',
  padding: '25px 5px 25px 5px',
  zIndex: '3',
  height: '160px',
  fontSize: '16px',
  textAlign: 'center',
  letterSpacing: '0.015em',
  position: 'absolute',
  right: '0px',
  left: '0px',
};

const Footer = () => (
  <div>
    <Responsive minWidth={1100}>
      <footer style={footerStyleDesktop}>
        Copyright &copy; {new Date().getFullYear()} AssistList Association. All rights reserved. | View
        our&nbsp;
        <Link
          style={{ color: 'white', textDecoration: 'underline' }}
          to="/policies#terms-of-use"
        >
          Terms of Use & Privacy Policy
        </Link>
        <div style={{float: 'right', marginRight: '4.5em'}} >
          <a href="https://www.facebook.com/AssistList/" target="_blank" rel="noopener noreferrer"> <img style={{height: '16px'}} src={facebook} alt="facebook_link"/></a>
          <a style ={{marginLeft: '1.9em'}} href="https://www.instagram.com/assistlist/" target="_blank" rel="noopener noreferrer"> <img style={{height: '16px'}} src={instagram} alt="instagram_link"/></a>
          <a style ={{marginLeft: '1.9em'}} href="https://twitter.com/assistlistca" target="_blank" rel="noopener noreferrer"> <img style={{height: '16px'}} src={twitter} alt="twitter_link"/></a>
          <a style ={{marginLeft: '1.9em'}} href="https://www.linkedin.com/company/assistlist/" target="_blank" rel="noopener noreferrer"> <img style={{height: '16px'}} src={linkedin} alt="linkedin_link"/></a>
        </div>
      </footer>
    </Responsive>
    <Responsive maxWidth={1099}>
      <footer style={footerStyleMobile}>
        <div>
          <a href="https://www.facebook.com/AssistList/" target="_blank" rel="noopener noreferrer"> <img src={facebook} alt="facebook_link" /></a>
          <a style={{ marginLeft: '2.5em' }} href="https://www.instagram.com/assistlist/" target="_blank" rel="noopener noreferrer"> <img src={instagram} alt="instagram_link" /></a>
          <a style={{ marginLeft: '2.5em' }} href="https://twitter.com/assistlistca" target="_blank" rel="noopener noreferrer"> <img src={twitter} alt="twitter_link" /></a>
          <a style={{ marginLeft: '2.5em' }} href="https://www.linkedin.com/company/assistlist/" target="_blank" rel="noopener noreferrer"> <img src={linkedin} alt="linkedin_link" /></a>
        </div>
        <br />
        Copyright &copy; {new Date().getFullYear()} AssistList Association. All rights reserved. | View
        our&nbsp;
        <Link
          style={{ color: 'white', textDecoration: 'underline' }}
          to="/policies#terms-of-use"
        >
          Terms of Use & Privacy Policy
        </Link>
      </footer>
    </Responsive>
  </div>
);

export default Footer;
