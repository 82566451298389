// @flow
import React from 'react';
import { Grid, Image, Container, Button } from "semantic-ui-react";
import { errorPageStyle } from "./assets/errorPageStyle";
import t from "./assets/t.png";
import { Link } from "react-router-dom";


export const PageNotFound = ({ history }) => {

  const linkPaths = [
    { link: "Listings", path: "/" },
    { link: "Resources", path: "/resources" },
    { link: "About Us", path: "/aboutus" },
    { link: "Contact", path: "/aboutus/contactus" },
    { link: "Donation", path: "/donate" }
  ];

  const linksComponent = linkPaths.map((lp) => {
    return (
      <Grid.Column mobile={16} tablet={3} computer={3}>
        <Link to={lp.path}>
          <Button style={errorPageStyle.button}>
            {lp.link}
          </Button>
        </Link>
      </Grid.Column>
    );
  });

  return (
    <div style={{
      background: 'white',
      margin: '0px'
    }}>

      <Container style={{ backgroundColor: 'white', maxWidth: '736px', margin: (window.innerWidth > 600 ? '0 auto' : '0 5%') }}>
        <Grid>

          <Grid.Row onClick={() => (history.location.key ? history.goBack() : history.push('/'))} floated="left" style={{ paddingLeft: '0px' }}>
          </Grid.Row>
          <Grid.Row style={errorPageStyle.imageContainer} centered>
            <Image style={errorPageStyle.image} src={t} />
          </Grid.Row>
          <Grid.Row style={errorPageStyle.headingContainer} centered>
            <h1 style={errorPageStyle.heading}>Oops!</h1>
          </Grid.Row>
          <Grid.Row style={errorPageStyle.secondaryHeadingContainer} centered>
            <h1 style={errorPageStyle.secondaryHeading}>
              Looks like you're on a lost page
            </h1>
          </Grid.Row>
          <Grid.Row style={errorPageStyle.textContainer}>
            <h4 style={errorPageStyle.text}>
              We’re sorry, we can’t seem to find the page you are looking for. For more options, please try these helpful links:
            </h4>
          </Grid.Row>
          <Grid.Row>
            <Grid style={errorPageStyle.viewAllContainer} centered>
              {linksComponent}
            </Grid>
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  );
}

