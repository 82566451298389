import React from 'react';
import qs from 'qs';

import { Tab } from 'semantic-ui-react';
import { connect } from 'react-redux';

import PageContainer from '../PageContainer';

import { setNewPassword, setResetToken } from '../../actions/user';

import NewPasswordForm from './NewPasswordForm';

class NewPassword extends React.Component {
  constructor() {
    super();

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { _setResetToken, location } = this.props;
    const { reset_password_token } = qs.parse(location.search, { ignoreQueryPrefix: true });
    _setResetToken(reset_password_token);
  }

  handleSubmit(values) {
    this.props._setNewPassword({
      ...values, reset_password_token: this.props.user.reset_password_token,
    });
  }

  render() {
    return (
      <PageContainer text>
        <Tab.Pane>
          <NewPasswordForm onSubmit={this.handleSubmit} />
        </Tab.Pane>
      </PageContainer>
    );
  }
}


const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  _setResetToken: resetToken => dispatch(setResetToken(resetToken)),
  _setNewPassword: values => dispatch(setNewPassword(values)),
});


export default connect(mapStateToProps, mapDispatchToProps)(NewPassword);
