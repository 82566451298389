import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { AuthenticatedRoute } from '../../utils/redirect';

import Show from './Show';
import Edit from './Edit';

const Post = () => (
  <Container fluid style={{backgroundColor: 'white', paddingTop: '25px'}}>
    <Switch>
      <Route exact path="/posts/:id" component={Show} />
      <AuthenticatedRoute path="/posts/:id/edit" component={Edit} />
    </Switch>
  </Container>
);

export default Post;
