import types from '../types/post';

const postDetailsReducer = (state = {
  loading: true,
  error: null,
}, { type, payload }) => {
  switch (type) {
    case types.FETCH_POST:
      return { ...state, loading: true };
    case types.FETCH_POST_SUCCESS:
      return { ...state, loading: false, ...payload };
    case types.FETCH_POST_FAILURE:
      return { ...state, loading: false, error: payload };
    default:
      return state;
  }
};

const postFormReducer = (state = {
  loading: true,
  categories: [],
  conditions: [],
  locations: [],
  postId: null,
  fields: [],
  images: [],
  imagesToDelete: [],
  error: null,
}, { type, payload }) => {
  switch (type) {
    case types.FETCH_WIZARD:
      return {
        ...state,
        loading: true,
        postId: null,
        fields: [],
        images: [],
      };
    case types.FETCH_WIZARD_SUCCESS:
      return { ...state, loading: false };
    case types.FETCH_WIZARD_FAILURE:
      return { ...state, loading: false, error: payload };

    case types.FETCH_EDIT_FORM_SUCCESS:
      return { ...state, loading: false };
    case types.FETCH_EDIT_FORM_FAILURE:
      return { ...state, loading: false, error: payload };

    case types.FETCH_CATEGORIES_SUCCESS:
      return { ...state, loading: false, categories: payload };
    case types.FETCH_CATEGORIES_FAILURE:
      return { ...state, loading: false, error: payload };

    case types.FETCH_CONDITIONS_SUCCESS:
      return { ...state, loading: false, conditions: payload };
    case types.FETCH_CONDITIONS_FAILURE:
      return { ...state, loading: false, error: payload };

    case types.FETCH_LOCATIONS_SUCCESS:
      return { ...state, loading: false, locations: payload };
    case types.FETCH_LOCATIONS_FAILURE:
      return { ...state, loading: false, error: payload };

    case types.FETCH_PROP_FIELDS:
      return state;
    case types.FETCH_PROP_FIELDS_SUCCESS:
      return { ...state, loading: false, fields: payload };
    case types.FETCH_PROP_FIELDS_FAILURE:
      return { ...state, loading: false, error: payload };

    case types.ADD_IMAGE:
      return { ...state, images: [...state.images, payload] };
    case types.REMOVE_IMAGE:
      return { ...state, images: state.images.filter(image => image.id !== payload) };
    case types.DELETE_IMAGE:
      return { ...state, imagesToDelete: [...state.imagesToDelete, payload] };

    case types.ORDER_IMAGE_LEFT:
      return { ...state, images: payload };
    case types.ORDER_IMAGE_RIGHT:
      return { ...state, images: payload };

    case types.ROTATE_IMAGE_LEFT:
      return { ...state, images: payload };
    case types.ROTATE_IMAGE_RIGHT:
      return { ...state, images: payload };

    case types.POST_SUBMIT_SUCCESS:
      return {
        ...state,
        postId: payload.id,
        images: [],
      };
    case types.POST_SUBMIT_FAILURE:
      return { ...state, error: payload };

    case types.POST_EDIT_SUCCESS:
      return { ...state, imagesToDelete: [] };
    case types.POST_EDIT_FAILURE:
      return { ...state, error: payload };

    case types.POST_DELETE:
      return state;
    case types.POST_DELETE_SUCCESS:
      return { ...state, response: payload };
    case types.POST_DELETE_FAILURE:
      return { ...state, error: payload };

    default:
      return state;
  }
};

const postReducer = (state = {
  details: {
    loading: true,
    error: null,
  },
  postForm: {
    loading: true,
    categories: [],
    conditions: [],
    locations: [],
    postId: null,
    fields: [],
    images: [],
    imagesToDelete: [],
    error: null,
  },
}, action) => {
  switch (action.type) {
    case types.FETCH_POST:
      return { ...state, details: postDetailsReducer(state.details, action) };
    case types.FETCH_POST_SUCCESS:
      return {
        ...state,
        details: postDetailsReducer(state.details, action),
        postForm: {
          ...state.postForm,
          images: action.payload.images || [],
        },
      };
    case types.FETCH_POST_FAILURE:
      return { ...state, details: postDetailsReducer(state.details, action) };

    case types.FETCH_WIZARD:
      return { ...state, postForm: postFormReducer(state.postForm, action) };
    case types.FETCH_WIZARD_SUCCESS:
      return { ...state, postForm: postFormReducer(state.postForm, action) };
    case types.FETCH_WIZARD_FAILURE:
      return { ...state, postForm: postFormReducer(state.postForm, action) };

    case types.FETCH_EDIT_FORM_SUCCESS:
      return {
        ...state,
        details: postDetailsReducer(state.details, action),
        postForm: postFormReducer(state.postForm, action),
      };
    case types.FETCH_EDIT_FORM_FAILURE:
      return {
        ...state,
        details: postDetailsReducer(state.details, action),
        postForm: postFormReducer(state.postForm, action),
      };

    case types.FETCH_CATEGORIES_SUCCESS:
      return { ...state, postForm: postFormReducer(state.postForm, action) };
    case types.FETCH_CATEGORIES_FAILURE:
      return { ...state, postForm: postFormReducer(state.postForm, action) };

    case types.FETCH_CONDITIONS_SUCCESS:
      return { ...state, postForm: postFormReducer(state.postForm, action) };
    case types.FETCH_CONDITIONS_FAILURE:
      return { ...state, postForm: postFormReducer(state.postForm, action) };

    case types.FETCH_LOCATIONS_SUCCESS:
      return { ...state, postForm: postFormReducer(state.postForm, action) };
    case types.FETCH_LOCATIONS_FAILURE:
      return { ...state, postForm: postFormReducer(state.postForm, action) };

    case types.FETCH_PROP_FIELDS_SUCCESS:
      return { ...state, postForm: postFormReducer(state.postForm, action) };
    case types.FETCH_PROP_FIELDS_FAILURE:
      return { ...state, postForm: postFormReducer(state.postForm, action) };

    case types.ADD_IMAGE:
      return { ...state, postForm: postFormReducer(state.postForm, action) };

    case types.REMOVE_IMAGE:
      return { ...state, postForm: postFormReducer(state.postForm, action) };

    case types.DELETE_IMAGE:
      return { ...state, postForm: postFormReducer(state.postForm, action) };

    case types.ORDER_IMAGE_LEFT:
      return { ...state, postForm: postFormReducer(state.postForm, action) };
    case types.ORDER_IMAGE_RIGHT:
      return { ...state, postForm: postFormReducer(state.postForm, action) };

    case types.ROTATE_IMAGE_LEFT:
      return { ...state, postForm: postFormReducer(state.postForm, action) };
    case types.ROTATE_IMAGE_RIGHT:
      return { ...state, postForm: postFormReducer(state.postForm, action) };

    case types.POST_SUBMIT_SUCCESS:
      return { ...state, postForm: postFormReducer(state.postForm, action) };
    case types.POST_SUBMIT_FAILURE:
      return { ...state, postForm: postFormReducer(state.postForm, action) };

    case types.POST_EDIT_SUCCESS:
      return { ...state, postForm: postFormReducer(state.postForm, action) };
    case types.POST_EDIT_FAILURE:
      return { ...state, postForm: postFormReducer(state.postForm, action) };

    case types.POST_DELETE_SUCCESS:
      return { ...state, response: action.payload };
    case types.POST_DELETE_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
};

export default postReducer;
