import types from "../types/banner";
import api from '../utils/api';

export const fetchActiveBanner = () => (dispatch) => 
 {
    dispatch({ type: types.FETCH_ACTIVE_BANNER});
    return api.get('api/banners/latest_active').then (
        (response) => {
            dispatch({ type: types.FETCH_ACTIVE_BANNER_SUCCESSFUL, payload: response.data});
        }
    ).catch(err => {
        dispatch({ type: types.FETCH_ACTIVE_BANNER_FAILURE});
    });
 }

 export const turnOffBanner = () => (dispatch) => {
    dispatch({ type: types.TURNOFF_ACTIVE_BANNER});
 }