import uuid from 'uuid/v4';

import types from '../types/alerts';

export const showAlert = (options = {}) => (dispatch) => {
  const {
    flowId = '',
    message = '',
    header = '',
    type = 'info',
    id = uuid(),
  } = options;


  const payload = {
    flowId,
    message,
    header,
    type,
    id,
    ...options,
  };

  dispatch({ type: types.ALERT_SHOW, payload });
};

export const dismissAlert = id => dispatch => (
  dispatch({ type: types.ALERT_DISMISS, payload: id })
);
