import types from '../types/profile';

import api, { userHeaders } from '../utils/api';

export const fetchUserActivePosts = (id, page = 1) => (dispatch) => {
  dispatch({ type: types.FETCH_USER_ACTIVE_POSTS });
  return (
    api.get(`/api/user/posts?page=${page}&type=active`, userHeaders())
      .then(response => dispatch({
        type: types.FETCH_USER_ACTIVE_POSTS_SUCCESS,
        payload: {
          data: response.data.data,
          pagination: JSON.parse(response.headers['x-pagination']),
        },
      }))
      .catch(error => dispatch({ type: types.FETCH_USER_ACTIVE_POSTS_FAILURE, payload: error }))
  );
};

export const fetchUserInactivePosts = (id, page = 1) => (dispatch) => {
  dispatch({ type: types.FETCH_USER_INACTIVE_POSTS });
  return (
    api.get(`/api/user/posts?page=${page}&type=inactive`, userHeaders())
      .then(response => dispatch({
        type: types.FETCH_USER_INACTIVE_POSTS_SUCCESS,
        payload: {
          data: response.data.data,
          pagination: JSON.parse(response.headers['x-pagination']),
        },
      }))
      .catch(error => dispatch({ type: types.FETCH_USER_INACTIVE_POSTS_FAILURE, payload: error }))
  );
};

export const fetchUserExchangedPosts = (id, page = 1) => (dispatch) => {
  dispatch({ type: types.FETCH_USER_EXCHANGED_POSTS });
  return (
    api.get(`/api/user/posts?page=${page}&type=exchanged`, userHeaders())
      .then(response => dispatch({
        type: types.FETCH_USER_EXCHANGED_POSTS_SUCCESS,
        payload: {
          data: response.data.data,
          pagination: JSON.parse(response.headers['x-pagination']),
        },
      }))
      .catch(error => dispatch({ type: types.FETCH_USER_EXCHANGED_POSTS_FAILURE, payload: error }))
  );
};

export const fetchOtherStuff = {};
