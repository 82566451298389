import React from 'react'
import { Message, Container, Grid } from 'semantic-ui-react'
import NotificationLink from '../Notification/NotificationLink'

const NotificationSuggestion = ({ user, onShowModal }) => (
	<Grid verticalAlign="middle" centered style={{ width: '100%', margin: 'auto' }}>
		<Grid.Column>
			<Container fluid textAlign="center">
				<h2>{(user.allowSharingLocation || user.useCurrentLocation) && "Based on your filter selection, there is nothing listed."} Can't find what you're searching for?</h2>
				<p>Receive an email when the equipment you're interested in is listed!</p>

				{!user.auth_token && (
					<Message info>
						<p>
							<u>
								<a
									href="/"
									onClick={(e) => {
										onShowModal(e)
									}}
								>
									Log in
								</a>
							</u>{' '}
							to subscribe for emails when the item you are looking for is available.
						</p>
					</Message>
				)}

				{user.auth_token && <NotificationLink initialValues={{}} />}
			</Container>
		</Grid.Column>
	</Grid>
)

export default NotificationSuggestion
