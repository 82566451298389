import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import qs from 'qs';

import { submitEmailFeedback } from '../actions/feedback';

const emailFeedback = ({ _submitEmailFeedback, location }) => {
  const { feedback_token } = qs.parse(location.search, { ignoreQueryPrefix: true });
  if (feedback_token) {
    _submitEmailFeedback(feedback_token);
  }
  return (
    <Redirect to="/" />
  );
};

const mapDispatchToProps = dispatch => ({
  _submitEmailFeedback: feedback_token => dispatch(submitEmailFeedback(feedback_token)),
});

export default withRouter(connect(null, mapDispatchToProps)(emailFeedback));
