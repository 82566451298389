const FETCH_POSTS = 'listings/FETCH_POSTS';
const FETCH_POSTS_SUCCESS = 'listings/FETCH_POSTS_SUCCESS';
const FETCH_POSTS_FAILURE = 'listings/FETCH_POSTS_FAILURE';

const FETCH_FILTERS_SUCCESS = 'listings/FETCH_FILTERS_SUCCESS';
const FETCH_FILTERS_FAILURE = 'listings/FETCH_FILTERS_FAILURE';

const FETCH_CATEGORIES = 'listings/FETCH_CATEGORIES';
const FETCH_CATEGORIES_SUCCESS = 'listings/FETCH_CATEGORIES_SUCCESS';
const FETCH_CATEGORIES_FAILURE = 'listings/FETCH_CATEGORIES_FAILURE';
const APPLY_FILTERS = 'listings/APPLY_FILTERS';

export default {
  FETCH_POSTS,
  FETCH_POSTS_SUCCESS,
  FETCH_POSTS_FAILURE,

  FETCH_FILTERS_SUCCESS,
  FETCH_FILTERS_FAILURE,

  FETCH_CATEGORIES,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAILURE,

  APPLY_FILTERS,
};
