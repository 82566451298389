import types from '../types/recentlistings';

const recentlistingsReducer = (state = {
  loading: true,
  recentlistings: [],
  error: null,
}, action) => {
  switch (action.type) {
    case types.FETCH_RECENT_LISTING:
      return {...state} ;
    case types.FETCH_RECENT_LISTING_SUCCESS:
      return{
        ...state,
        recentlistings: action.payload
      };
    case types.FETCH_RECENT_LISTING_FAILURE:
    default:
      return state;
  }
};

export default recentlistingsReducer;