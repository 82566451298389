import React from 'react';
import Slider from 'react-slick';

const Images = ({ images }) => {
  return (
    <div className="getinvolved-container">
      <div className="getinvolved-volunteer">
         <div style={{ margin: '0 auto' }} className="picture-box">
          <div className="carousel-text">
            <Slider autoplay dots fade>
              {images ? images.map(image => (
                <div key={image.title}>
                  <img className="carousel-img" src={image.url} alt="Our company" />
                </div>
              )) : <div />}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Images;