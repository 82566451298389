import React from 'react';
import { Link, Switch } from 'react-router-dom';
import { Menu, Responsive, Container } from 'semantic-ui-react';
import { AuthenticatedRoute } from '../../utils/redirect';

import MyListingsPage from './MyListingsPage';
import AccountSettings from './AccountSettings';
import NotificationSettings from './NotificationSettings';


const AccountMenuDesktop = ({ items }) => (
  <Menu compact style={{border: '1px solid #D8D8D8', background: '#FFFFFF', marginBottom: '16px'}} inverted>
    {items.map(item => <Menu.Item style={{background: (item.activeTrick ? '#1A659F' : null), borderRight: '1px solid #D8D8D8', color: (item.activeTrick ? 'white' : 'black' ), fontWeight: 'bold'}} {...item}/> )}
  </Menu>
);

const AccountMenuMobile = ({ items }) => (
  <Menu icon='labeled' compact='true' style={{width: '100%', border: '1px solid #D8D8D8', background: '#FFFFFF', marginBottom: '16px'}} inverted>
    {items.map(item => <Menu.Item style={{fontSize: '14px', width: '33%', padding: '15px 0', background: (item.activeTrick ? '#1A659F' : null), borderRight: item.key === 'settings' ? '' : '1px solid #D8D8D8', color: (item.activeTrick ? 'white' : 'black' ), fontWeight: 'bold'}} {...item}/> )}
  </Menu>
);


const Profile = () => {
  const desktopItems = [
    {
      as: Link,
      icon: 'list',
      to: '/account/listings',
      content: 'My Listings',
      key: 'myListings',
      activeTrick: (window.location.href.includes('listings') ? true : false)
    },
    {
      as: Link,
      icon: 'bell outline',
      to: '/account/notifications',
      content: 'Equipment Notification',
      key: 'notificationSettings',
      activeTrick: (window.location.href.includes('notifications') ? true : false)
    },
    {
      as: Link,
      icon: 'cog',
      to: '/account/settings',
      content: 'Account Settings',
      key: 'settings',
      activeTrick: (window.location.href.includes('settings') ? true : false)
    },
  ];

  const mobileItems = [
    {
      as: Link,
      icon: 'list',
      to: '/account/listings',
      content: 'My Listings',
      key: 'myListings',
      activeTrick: (window.location.href.includes('listings') ? true : false)
    },
    {
      as: Link,
      icon: 'bell outline',
      to: '/account/notifications',
      content: 'Notification',
      key: 'notificationSettings',
      activeTrick: (window.location.href.includes('notifications') ? true : false)
    },
    {
      as: Link,
      icon: 'cog',
      to: '/account/settings',
      content: 'Settings',
      key: 'settings',
      activeTrick: (window.location.href.includes('settings') ? true : false)
    },
  ];



  return (
    <div style={{background: 'white', minHeight: 'calc(100vh - 60px)', paddingBottom: '36px'}}>
    <Container style={{paddingTop: '25px'}}>
      <Responsive minWidth={752}>
        <AccountMenuDesktop items={desktopItems} />
      </Responsive>
      <Responsive maxWidth={751}>
        <AccountMenuMobile items={mobileItems} />
      </Responsive>
      <Switch>
        <AuthenticatedRoute exact path="/account/listings" component={MyListingsPage} />
        <AuthenticatedRoute exact path="/account/notifications" component={NotificationSettings} />
        <AuthenticatedRoute exact path="/account/settings" component={AccountSettings} />
      </Switch>
      </Container>
    </div>
  );
};
export default Profile;
