import React from 'react';
import { connect } from 'react-redux';
import { Container, Icon, Button } from 'semantic-ui-react';
import GoogleMapReact from 'google-map-react';

import { fetchSafeExchangeZones } from '../../actions/resources';
import additionalServiceImage from '../../images/additionalServices.jpg';
import mapPin from '../../images/map-pin.png';

import './resources.css';


class ExchangeZones extends React.Component {

  componentDidMount() {
    const { _fetchSafeExchangeZones } = this.props;
    _fetchSafeExchangeZones();
  }

  render() {
    const { safeExchangeZones } = this.props;

    return (

      <Container style={{margin: (window.innerWidth > 800? '0 auto': '0 5%'), maxWidth: '1120px', paddingBottom: '160px'}} >
        <div style={{paddingTop: '24px'}}>
          <a style={{textDecoration: 'none', color: '#575757', marginBottom: '40px'}} href='/resources'> <Icon name='angle left'/>Back to Resources </a>
          <h2 style={{color: '#0275B3', marginBottom: '16px'}}> Community Exchange Zones </h2>
          <p>At AssistList, we value the safety of our users. That’s why we’ve established Community Exchange Zones with the help of our community partners. When selecting a location for an exchange, we encourage buyers and sellers to meet at one of our designated Community Exchange Zones below. </p>

          <div style={{display: 'flex', flexDirection: 'column', marginTop: window.innerWidth > 800? '80px': ''}}>

          {(safeExchangeZones && safeExchangeZones.length > 0) ? safeExchangeZones.map((location, i) =>
          (<Location  image={additionalServiceImage}
                      totalMapped={safeExchangeZones.length}
                      counter={i}
                      name={location.name}
                      description={location.description}
                      address={location.address.address}
                      website={location.address.website}
                      phone={location.address.phone}
                      lat={location.address.lat}
                      long={location.address.long} />
          ))
            : <div></div>
          }

          </div>

          <div style={{textAlign: 'center', marginTop: '56px'}}>
            <h2 style={{color: '#0275B3'}}> Promote safe exchanges in your community</h2>
            <p style={{margin: '24px 20%'}}>Join our network of Community Exchange Zones to help us facilitate safe exchanges across Metro Vancouver.</p>

            <Button className='connectWithUsBtn' href="/aboutus/contactus" style={{background: 'white', color: '#0280BB', border: '0.5px solid #0280BB' }}>
            Connect With Us</Button>
          </div>

        </div>
      </Container>
    )}
};

let imgDesktop = {width: '272px', height: '272px'}
let imgMobile = {width: '100%', height: '345px' }
const gmapApiKey = "AIzaSyCNUZ3JsuRJ5z1MAmI5w7Z1fbxXw38rP_c";

const MapPin = ({ text }) => <div><img alt='map pin' src={mapPin} /></div>;

const Location = (props) => (

  <div style={{padding: '40px 0px', borderBottom: (props.totalMapped-1 === props.counter? '':'0.5px solid #BDBDBD'), display: 'flex', flexWrap: window.innerWidth > 600? '' : 'wrap'}}>
    <div style={{float: 'left', width: window.innerWidth > 800? '': '100%', marginBottom: window.innerWidth > 600? '': '16px'}}>
      <div style={{height: (window.innerWidth > 600? imgDesktop.height : imgMobile.height), width: (window.innerWidth > 600? imgDesktop.width : imgMobile.width) }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: gmapApiKey }}
          defaultCenter={{
            lat: 49.24,
            lng: -123.06
          }}
          defaultZoom={10}>
          <MapPin
            lat={props.lat}
            lng={props.long}
            text={props.name} />
        </GoogleMapReact>
      </div>
    </div>

    <div style={{float:'right', marginLeft: (window.innerWidth > 600? '40px': '')}}>
      <h3 style={{marginBottom: '8px', color: '#0275B3'}}>{props.name}</h3>
      <p>{props.description}</p>
      <p><Icon color='grey' name='map marker alternate'/>&nbsp;&nbsp;{props.address}</p>
      <p><Icon color='grey' name='globe'/>&nbsp;&nbsp;<a target='_blank' rel="noopener noreferrer" href={`https://${props.website}`}>{props.website}</a></p>
      <p><Icon color='grey' name='phone'/>&nbsp;&nbsp;{props.phone}</p>
    </div>
  </div>
)

const mapDispatchToProps = dispatch => ({
  _fetchSafeExchangeZones: () => dispatch(fetchSafeExchangeZones()),
});

const mapStateToProps = state => ({
  safeExchangeZones: state.resources.safeExchangeZones,
});

export default connect(mapStateToProps, mapDispatchToProps)(ExchangeZones);
