import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Helmet } from 'react-helmet';
import { store } from './store';

import App from './App';

ReactDOM.render(
  (
    <div className="app">
      <Helmet>
        <meta charSet="utf-8" />
        <title>AssistList - Buy, Sell & Donate Home Health Equipment</title>
      </Helmet>
      <Provider store={store}>
        <App />
      </Provider>
    </div>
  ), document.getElementById('root'),
);