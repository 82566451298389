import types from '../types/blog';
import { getPageData, formatAssetUrls, formatPageData } from '../utils/contentful'

export const fetchBlogPageData = () => (dispatch) => {
  dispatch({ type: types.FETCH_BLOG_PAGE_DATA })
  let payload = { blog_page_data: undefined }

  getPageData('Our Blog')
    .then((pageData) => {
      const data = formatPageData(pageData)
      
      data.blogImages = formatAssetUrls(data.blogImages.images)
      data.blogHeaderImage = formatAssetUrls(data.blogHeaderImage.images)

      payload.blog_page_data = data

      dispatch({ type: types.FETCH_BLOG_PAGE_DATA_SUCCESS, payload: payload })
    })
    .catch((err) => {
      dispatch({ type: types.FETCH_BLOG_PAGE_DATA_FAILURE, payload: err })
    })
}

export const fetchBlogPosts = () => (dispatch) => {
  dispatch({ type: types.FETCH_BLOG_POSTS });
  const mediumUrl = 'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@assistlist';
  let payload = { 'blog_posts' : [] };
  let descriptionCutoff = 100;
  let numPosts = 3;
  fetch(mediumUrl)
    .then((response) => response.json())
    .then((data) => {
      let posts = data.items.filter(item => item.categories.length > 0);
      posts = posts.slice(0,numPosts);
      posts.forEach(post => {
          // https://medium.com/@kartikyathakur/getting-those-thumbnails-from-medium-rss-feed-183f74aefa8c
          const thumbnail = post['description'].toString().match(/<img[^>]+src="([^">]+)"/)[1];
          let date = new Date(post.pubDate);
          let months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
          let dateString =  months[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear()
          let description = post.description.replace(/(<([^>]+)>)/gi, "");
          description = description.slice(0,descriptionCutoff) + "...";
          let blogPost = {
            title: post.title,
            pubDate: post.pubDate,
            dateString: dateString,
            link: post.link, 
            guid: post.guid,
            author: post.author,
            thumbnail: thumbnail,
            description: description,
            content: post.content,
            categories: post.categories
          }
          payload.blog_posts.push(blogPost);
      });
      dispatch({ type: types.FETCH_BLOG_POSTS_SUCCESS, payload: payload});
    })
    .catch((error) => {
      dispatch({type: types.FETCH_BLOG_POSTS_FAILURE, payload: error});
    });
};