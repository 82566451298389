import React from 'react';

import { Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { deletePost, fetchPost } from '../../actions/post';
import { hideModal } from '../../actions/modal';

import NotificationFormContainer from './NotificationForm';

export const MODAL_ID = 'notificationModal';

const NotificationModal = ({ modal, initialValues, _hideModal }) => (
  <Modal
    open={modal.name === MODAL_ID}
    onClose={() => _hideModal(MODAL_ID)}
    closeIcon
  >
    <Modal.Header>Subscribe to Equipment Notifications</Modal.Header>
    <Modal.Content>
      <Modal.Description>
        <NotificationFormContainer initialValues={initialValues} />
      </Modal.Description>
    </Modal.Content>
  </Modal>
);


const mapStateToProps = state => ({
  post: state.post.details,
  user: state.user,
  modal: state.modal,
});

const mapDispatchToProps = dispatch => ({
  _fetchPost: id => dispatch(fetchPost(id)),
  _deletePost: id => dispatch(deletePost(id)),
  _hideModal: name => dispatch(hideModal(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationModal);
