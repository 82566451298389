import React, { useEffect } from 'react';
import { Grid, Segment, Modal, Responsive} from 'semantic-ui-react';
import { connect } from 'react-redux';

import PostDetail from './PostDetail';
import { reportPost } from '../../actions/post';
import SocialGroupButtons from './SocialGroupButtons';
import ContactFormMini from '../Post/Show/ContactFormMini';
import PostsRelated from './PostsRelated';
import PostsRecent from './PostsRecent';

import './post.css';

import PostCreateListingModal from './PostCreateListingModal';

const Post = ({ post, user }) => {

//Logic to filter Recent Listings from a local storage object

let tempRecent = []
let tempIDs = []

let img = post.images && post.images[0] && post.images[0].small;
let recentPostObj = {id: post.id,
                     user_id: post.user.id,
                     img: img,
                     location: post.location.name,
                     price: post.price,
                     title: post.title}
const [open, setOpen] = React.useState(false)

// Series of conditionals to construct or update a local storage object with recently viewed posts

// Check if there is a local storage object and if the post is the users own post(if so it is not added)
if(localStorage.getItem('recentPosts') && (recentPostObj.user_id !== user.user_id)){

  // Check if the post is already in recent posts
  tempRecent = JSON.parse(localStorage.getItem('recentPosts'));
  tempRecent.map((post) => tempIDs.push(post.id));

  // Check if recent post container already has 5 posts, if so shift and push new one
  if(tempRecent.length >= 5){

    if(tempRecent.length > 4){
      tempRecent.slice(2)
    }

    if(!tempIDs.includes(recentPostObj.id)){
      tempRecent.shift();
      tempRecent.push(recentPostObj);
      localStorage.setItem('recentPosts', JSON.stringify(tempRecent));
    }
  } else{
      // Check if recent post is already being displayed once
      if(!tempIDs.includes(recentPostObj.id)){
        tempRecent.push(recentPostObj);
      }

      localStorage.setItem('recentPosts', JSON.stringify(tempRecent));
    }
} else{
  // If there is not a local storage object and the user is not viewing their own post
  if(recentPostObj.user_id !== user.user_id){
    localStorage.setItem('recentPosts', JSON.stringify([recentPostObj]));
  }
}

	const handleClickClosePostCreate = () => {
    setOpen(false)
	}
  
  useEffect(() => {
    setTimeout(() => {
      const surfix = "submitted=success"
      if(window.location.href.slice(-surfix.length) === surfix)  {
        setOpen(true)
      }   
    }, 200);
  }, []);  

return(

  <div>
    <Modal open={open} style={{width: window.innerWidth > Responsive.onlyMobile.maxWidth?532:360, height: window.innerWidth > Responsive.onlyMobile.maxWidth?456:675,}} onClose={() => setOpen(false)} size="small" >
      <Modal.Content style={{margin:'0', padding: '0 24px', overflow: 'hidden'}}>
        <PostCreateListingModal handleClickClosePostCreate={handleClickClosePostCreate} />
      </Modal.Content>
		</Modal>
    <Grid divided>
      <Grid.Column computer={10} mobile={16} style={{paddingTop: '0px'}}>
        <PostDetail post={post} />
      </Grid.Column>

      <Grid.Column id='contactSellerComponent' computer={6} mobile={16}>
      {window.innerWidth > 600?
        <React.Fragment>
        {!post.loading && post.user.id !== (user && user.user_id) && (
          <Segment className='stickyEnduce' basic style={{ paddingTop: 0, margin: 0 }}>
            <ContactFormMini postId={post.id} user={user} />
            {post.id && <SocialGroupButtons  />}
          </Segment>
        )}
        {post.user.id === (user && user.user_id) && post.id &&
        <SocialGroupButtons  />}
        </React.Fragment>
        :
        <div>
          {!post.loading && post.user.id !== (user && user.user_id) && (
          <Segment className='stickyEnduce' basic style={{ paddingTop: 0, margin: 0 }}>
            <ContactFormMini postId={post.id} user={user} />
            {post.id && <SocialGroupButtons  />}
          </Segment>
          )}
          {post.user.id === (user && user.user_id) && post.id &&
          <SocialGroupButtons  />}
        </div>
      }
      </Grid.Column>

      <Grid.Row>
        <PostsRelated post={post} user={user}/>
      </Grid.Row>
      <Grid.Row>
        <PostsRecent post={post} user={user} posts={JSON.parse(localStorage.getItem('recentPosts'))}/>
      </Grid.Row>

    </Grid>
  </div>
)
};

const mapDispatchToProps = dispatch => ({
  _reportPost: id => dispatch(reportPost(id)),
});

export default connect(null, mapDispatchToProps)(Post);