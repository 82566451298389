import React, {useState, useEffect} from 'react';
import { Form, Divider, Icon } from 'semantic-ui-react';

import { Field, reduxForm, formValueSelector} from 'redux-form';

import { changePassword } from '../../actions/user';
import { IconTextField } from '../../utils/render-input';
import { connect } from 'react-redux';
import { formValidator } from '../../utils/FormValidator';

const passwordFormStyle = {
  subLabelText: {
    marginBottom: '0.7rem',
  },
  labelText: {
    fontWeight: 'bold',
    fontSize: '14px'
  },
  formButton: {
    margin: 'auto',
    marginTop: '48px',
    backgroundColor: '#1A659F',
    width: '240px'
  },
  inputField: {
    height: '44px',
    borderRadius: '6px'
  },
  disabledInputField: {
    height: '44px',
    backgroundColor: '#a1a1a1',
    borderRadius: '6px'
  },
  disabledFormButton: {
    margin: 'auto',
    marginTop: '48px',
    backgroundColor: 'black',
    width: '240px'
  },
};


const ChangePasswordForm = ({ password, current_password, password_confirmation, error, handleSubmit, submitting }) => {
  const [hide, setHide] = useState(true)

  const toggleHide = () => {
    setHide(!hide)
  }

  useEffect(() => {
    if(!hide) toggleHide()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
  <Form name="changePasswordForm" onSubmit={handleSubmit(changePassword)} style={{marginTop: '20px'}}>
    <label style={passwordFormStyle.labelText}>Current password</label>
    <Field
      component={IconTextField}
      name="current_password"
      type="password"
      autoComplete="password"
      style = {passwordFormStyle.inputField}
    />
    <Divider style={{marginTop: '24px', marginBottom: '26px'}}/>
    <label style={passwordFormStyle.labelText}>New Password</label>
    <Field
      component={IconTextField}
      disabled = {current_password && current_password.length >= 8 ? false : true}
      name="password"
      icon={ hide ? <Icon name ='hide' onClick={toggleHide}  link/> : <Icon name ='unhide' onClick={toggleHide} link/>}
      type={hide ? "password" : "text"}
      autoComplete="password"
      style = {current_password && current_password.length >= 8 ? passwordFormStyle.inputField : passwordFormStyle.disabledInputField }
    />

      <div style={passwordFormStyle.subLabelText}>
        Minimum 8 characters must include: a lower and uppercase letter, a number, and a symbol (!@#$&*)
      </div>

    <br></br>
    <label style={passwordFormStyle.labelText}>Re-enter new password</label>
    <Field
      component={IconTextField}
      disabled = {current_password && current_password.length >= 8 && 
                  password && password.length >=8 
                  && /(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z])/.test(password) ? false : true}
      name="password_confirmation"
      icon={ hide ? <Icon name ='hide' onClick={toggleHide}  link/> : <Icon name ='unhide' onClick={toggleHide} link/>}
      type={hide ? "password" : "text"}
      autoComplete="password"
      style = {current_password && current_password.length >= 8 && password && password.length >=8 
              && /(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z])/.test(password) ?  passwordFormStyle.inputField : passwordFormStyle.disabledInputField}
    />
    {error && <p style={{ color: 'red' }}>{error}</p>}

    <Form.Button
      primary
      fluid
      disabled={!current_password || !password || !password_confirmation}
      type="submit"
      content="Change password"
      style={!current_password || !password || !password_confirmation ? passwordFormStyle.disabledFormButton : passwordFormStyle.formButton }
      loading={submitting}
    />
  </Form>
)};

const selector = formValueSelector('changePasswordForm')
const mapStateToProps = (state) => {
  return {
    current_password: selector(state, 'current_password'),
    password:  selector(state, 'password'),
    password_confirmation: selector(state, 'password_confirmation')
  }
}
export default connect(mapStateToProps, null)(reduxForm({
  form: 'changePasswordForm',
  validate: formValidator,
})(ChangePasswordForm));
