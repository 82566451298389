import React from 'react';
import {
  Grid, Segment, Image,
} from 'semantic-ui-react';

import logo from '../../images/FullLogo_Noclearspace.svg';

const DonateForm = () => (
  <Segment>
    <Grid>
      <Grid.Row only="computer" style={{ justifyContent: 'center' }}>
        <div>
          <Image size="small" src={logo} alt="assistlist-logo" />
        </div>
      </Grid.Row>
      <Grid.Row
        className="logoDonate"
        only="mobile tablet"
        style={{
          justifyContent: 'center',
          paddingBottom: 0,
        }}
      >
        <div>
          <Image size="small" src={logo} alt="assistlist-logo" />
        </div>
      </Grid.Row>
      <Grid.Row style={{ justifyContent: 'center', padding: 0 }}>
        <iframe
          className="donation"
          id="ch_cdn_embed"
          title="Canada Helps Donation Form"
          src="https://www.canadahelps.org/en/dn/m/46562/"
        />
      </Grid.Row>
    </Grid>
  </Segment>
);

export default DonateForm;
