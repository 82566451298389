import types from '../types/user';

const user = JSON.parse(localStorage.getItem('user'));

const initialState = user || { locationRadius: 10, initializingLocation: true };
initialState.allowSharingLocation =
  JSON.parse(localStorage.getItem('allowSharingLocation')) ?? false;
initialState.useCurrentLocation =
  JSON.parse(localStorage.getItem('useCurrentLocation')) ?? false;

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SIGNUP_SUCCESS:
      return { ...state, ...action.payload };
    case types.SIGNUP_FAILURE:
      return { ...state, error: action.payload };

    case types.CONFIRMATION_SUCCESS:
      return { ...action.payload };
    case types.CONFIRMATION_FAILURE:
      return { ...state, error: action.payload };

    case types.LOGIN_SUCCESS:
      return { ...state, ...action.payload };
    case types.LOGIN_FAILURE:
      return { ...state, error: action.payload };

    case types.OAUTH_LOGIN_SUCCESS:
      return { ...action.payload };
    case types.OAUTH_LOGIN_FAILURE:
      return { ...state, error: action.payload };

    case types.ACCOUNT_EXISTS_ERROR:
      return {};
    case types.UNKNOWN_AUTH_ERROR:
      return {};

    case types.LOGOUT_SUCCESS:
      return {};
    case types.LOGOUT_FAILURE:
      return { ...state, error: action.payload };

    case types.SEND_PASSWORD_RESET_SUCCESS:
      return { ...state, resetSent: true };
    case types.SEND_PASSWORD_RESET_FAILURE:
      return { ...state, resetSent: true, error: action.payload };

    case types.RESEND_CONFIRMATION_SUCCESS:
      return { ...state, confirmationResent: true };
    case types.RESEND_CONFIRMATION_FAILURE:
      return { ...state, error: action.payload };

    case types.SET_RESET_PASSWORD_TOKEN:
      return { ...state, reset_password_token: action.payload };

    case types.SET_NEW_PASSWORD_SUCCESS:
      return { ...action.payload };
    case types.SET_NEW_PASSWORD_FAILURE:
      return { ...state, error: action.payload };

    case types.CHANGE_PASSWORD_SUCCESS:
      return { ...state, changedPassword: true };
    case types.CHANGE_PASSWORD_FAILURE:
      return { ...state, error: action.payload };

    case types.FETCH_USER_SUCCESS:
      return { ...state, ...action.payload };
    case types.FETCH_USER_FAILURE:
      return {};

    case types.SET_SUBSCRIBE_CLICKED:
      return { ...state, subscribe_clicked: true };
    case types.REMOVE_SUBSCRIBE_CLICKED:
      return { ...state, subscribe_clicked: false };

    case types.SET_NEWSLETTER_SUCCESS:
      return { ...state, newsletter_subscribed: action.payload };
    case types.SET_NEWSLETTER_FAILURE:
      return { ...state, error: action.payload };

    case types.SET_GEO_LOCATION: {
      return {
        ...state,
        initializingLocation: true,
      };
    }

    case types.SET_GEO_LOCATION_SUCCESS: {
      return {
        ...state,
        allowSharingLocation: true,
        geoLocation: action.payload,
        initializingLocation: false,
        useCurrentLocation: true,
      };
    }

    case types.SET_GEO_LOCATION_FAILURE:
      return {
        ...state,
        allowSharingLocation: false,
        geoLocationError: action.payload,
        initializingLocation: false,
        useCurrentLocation: false,
      };
    case types.SET_SHARING_LOCATION_PERMISSION: {
      if (action.payload.geoLocation) {
        return {
          ...state,
          allowSharingLocation: action.payload.isAllow,
          geoLocation: action.payload.geoLocation,
        };
      }

      return {
        ...state,
        allowSharingLocation: action.payload.isAllow,
      };
    }

    case types.SET_SHARING_LOCATION_RADIUS: {
      return {
        ...state,
        locationRadius: action.payload,
      };
    }

    case types.SET_SHARING_LOCATION_MUNICIPAL: {
      return {
        ...state,
        municipal: action.payload,
      };
    }

    case types.TURN_OFF_INITIALIZING_LOCATION: {
      return {
        ...state,
        initializingLocation: false,
      };
    }
    case types.USE_CURRENT_LOCATION: {
      return {
        ...state,
        useCurrentLocation: action.payload,
      };
    }

    default:
      return state;
  }
};

export default userReducer;
