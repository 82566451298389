// @flow
import * as React from "react";
import { Grid, Image } from "semantic-ui-react";
import { listItemStyle } from "../assets/listItemStyle";

export function ListItem({ id, image, city, price, title, provider }) {
  return (
    <Grid.Column>
      <a href={`/posts/${id}`}>
        <div style={listItemStyle.imageContainer}>
          <Image
            style={{
              width: "160px",
              height: "160px",
              borderRadius: "5px",
              objectFit: "cover",
            }}
            src={image}
            centered
          />
        </div>
        <div style={listItemStyle.cityPriceContainer}>
          <span style={{ color: "#575757" }}>{city}</span>
          <span className={"float-right"}>
            <h5 style={{ color: "#19864A", fontWeight: "bold" }}>
              {!price ? "FREE" : `$${price}`}
            </h5>
          </span>
        </div>
        <div style={listItemStyle.textContainer}>
          <p style={listItemStyle.paragraph}>{title}</p>
        </div>
      </a>
    </Grid.Column>
  );
}
