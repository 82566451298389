import React from 'react'
import { Loader, Image } from 'semantic-ui-react'
import shortParagraphWireframe from '../../src/images/short-paragraph.png'
import paragraphWireframe from '../../src/images/paragraph.png'

const WireframeLoader = ({ short = false }) => {
  return (
    <div>
      <Loader content="Loading" />
      <Image src={short ? shortParagraphWireframe : paragraphWireframe} />
    </div>
  )
}

export default WireframeLoader
