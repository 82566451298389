import React from 'react';
import { connect } from 'react-redux';

import {Responsive} from 'semantic-ui-react';
import ResendConfirmationForm from './ResendConfirmationForm';
import ResentConfirmationMessage from './ResentConfirmationMessage';
import circleBackground from '../../images/assistlist_background_circle.png'

const desktopDivStyle = {
  background: '#ffffff', 
  minHeight: '100vh', 
  backgroundSize: 'cover', 
  backgroundImage: `url(${circleBackground})`
};

const mobileTabletDivStyle = {
  background: '#ffffff', 
  minHeight: '100vh', 
};

const ResendConfirmation = (props) => {
  const { user } = props;

  return (
    <div style={window.innerWidth <= Responsive.onlyTablet.maxWidth ? mobileTabletDivStyle : desktopDivStyle}>
        { !user.confirmationResent && <ResendConfirmationForm /> }
        { user.confirmationResent && <ResentConfirmationMessage /> }
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(ResendConfirmation);
