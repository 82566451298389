import React from 'react';
import { Link } from 'react-router-dom';

import {
  Form, Header, Icon, Grid, Divider, Responsive
} from 'semantic-ui-react';

import { Field, reduxForm } from 'redux-form';
import { IconTextField } from '../../utils/render-input';

import { resendConfirmation } from '../../actions/user';
import '../Authentication/authentication.css'



const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Email address is required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
};

const btnStyle = {
  maxWidth: '130px',
  minHeight: '30px',
  marginBottom: '24px',
  marginTop: '5px',
  backgroundColor: '#1A659F'
};

const linkStyle = {
  color: '#1A659F', 
  fontWeight: 'bold' 
};

const iconStyle = {
  color: '#1A659F', 
  display: 'table', 
  margin: '60px auto 0px',
}

const ResendConfirmationForm = ({
  error, handleSubmit, submitting,
}) => (
  <Grid style={{maxWidth: '100vw'}}>
    <Grid.Row>
      <Icon.Group size='huge' style={iconStyle}>
        <Icon size='big' name='circle outline' />
        <Icon name='mail outline' />
      </Icon.Group>
    </Grid.Row>
    <Grid.Row>
      <Responsive minWidth={Responsive.onlyComputer.minWidth} style={{ margin: 'auto' }}>
        <Grid.Column computer={8} tablet={16} mobile={16}>
          <Header as="h1" style={{ color: '#1A659F' }}>Can&apos;t find your confirmation email?</Header>
          <p style={{ marginBottom: '32px', width: '100%' }}>Enter your email address below and we&apos;ll resend a confirmation email.</p>
          <Form name="resendConfirmation" onSubmit={handleSubmit(resendConfirmation)}>
            <Field component={IconTextField} style={{ marginBottom: '36px' }} name="email" label="Email address" placeholder="example@gmail.com" autoComplete="email" />
            {error && <p style={{ color: 'red' }}>{error}</p>}

            <Form.Button
              primary 
              id='submitButton'
              fluid
              type="submit"
              content="Resend "
              style={btnStyle}
              loading={submitting}
            />
          </Form>
          <br></br>
          <Divider />
          <p style = {{ marginTop: '40px', paddingBottom: '40px'}}> 
            Back to <Link className='linkHover' to="/auth#login" style={linkStyle}>Log In</Link> or <Link className='linkHover' to="/auth#signup" style={linkStyle}>Sign Up</Link>
          </p>
        </Grid.Column>
      </Responsive>
      <Responsive maxWidth={Responsive.onlyTablet.maxWidth}>
        <Grid.Column computer={8} tablet={16} mobile={16} style={{ marginLeft: '40px', width: 'calc(100vw - 40px)' }}>
          <Header as="h1" style={{ color: '#1A659F' }}>Can&apos;t find your confirmation email?</Header>
          <p style={{ marginBottom: '32px'}}>Enter your email address below and we&apos;ll resend a confirmation email.</p>
          <Form name="resendConfirmation" onSubmit={handleSubmit(resendConfirmation)}>
            <Field component={IconTextField} style={window.innerWidth <= Responsive.onlyMobile.maxWidth ? { marginBottom: '36px'} : { marginBottom: '36px', width: '60%' }} name="email" label="Email address" placeholder="example@gmail.com" autoComplete="email" />
            {error && <p style={{ color: 'red' }}>{error}</p>}

            <Form.Button
              primary
              fluid
              type='submit'
              content='Resend' 
              style={btnStyle}
              loading={submitting}
            />
          </Form>
          <br></br>
          <Divider />
          <p style = {{marginTop: '40px', paddingBottom: '40px'}}> 
            Back to <Link className='linkHover' to="/auth#login" style={linkStyle}>Log In</Link> or <Link className='linkHover' to="/auth#signup" style={linkStyle}>Sign Up</Link>
          </p>
        </Grid.Column>
      </Responsive>
    </Grid.Row>
  </Grid>
);


export default reduxForm({
  form: 'resendConfirmation',
  validate,
})(ResendConfirmationForm);
