const FETCH_QUESTIONS_HEALTH_SAFETY = 'faq_questions/FETCH_QUESTIONS_HEALTH_SAFETY';
const FETCH_QUESTIONS_HEALTH_SAFETY_SUCCESS = 'faq_questions/FETCH_QUESTIONS_HEALTH_SAFETY_SUCCESS';
const FETCH_QUESTIONS_FAILURE = 'faq_questions/FETCH_QUESTIONS_FAILURE';

const FETCH_FAQ_PAGE_DATA = 'faq/FETCH_FAQ_PAGE_DATA'
const FETCH_FAQ_PAGE_DATA_SUCCESS = 'faq/FETCH_FAQ_PAGE_DATA_SUCCESS'
const FETCH_FAQ_PAGE_DATA_FAILURE = 'faq/FETCH_FAQ_PAGE_DATA_FAILURE'
const FETCH_FAQ_CONTACT_US_PAGE_DATA = 'faq/FETCH_FAQ_CONTACT_US_PAGE_DATA'
const FETCH_FAQ_CONTACT_US_PAGE_DATA_SUCCESS = 'faq/FETCH_FAQ_CONTACT_US_PAGE_DATA_SUCCESS'
const FETCH_FAQ_CONTACT_US_PAGE_DATA_FAILURE = 'faq/FETCH_FAQ_CONTACT_US_PAGE_DATA_FAILURE'

export default {
  FETCH_QUESTIONS_HEALTH_SAFETY,
  FETCH_QUESTIONS_HEALTH_SAFETY_SUCCESS,
  FETCH_QUESTIONS_FAILURE,
  FETCH_FAQ_PAGE_DATA,
  FETCH_FAQ_PAGE_DATA_SUCCESS,
  FETCH_FAQ_PAGE_DATA_FAILURE,
  FETCH_FAQ_CONTACT_US_PAGE_DATA,
  FETCH_FAQ_CONTACT_US_PAGE_DATA_SUCCESS,
  FETCH_FAQ_CONTACT_US_PAGE_DATA_FAILURE,
};
