import types from '../types/aboutus';

const aboutUsReducer = (
  state = {
    directors: [],
    images: [],
    teamLeads: [],
    teamMembers: [],
    testimonials: [],
    volunteerInsights: [],
    ourStoryPageData: undefined,
    aboutUsPageData: undefined,
		error: null,
  },
  action,
) => {
  switch (action.type) {
    case types.FETCH_DIRECTORS:
    case types.FETCH_IMAGES:
    case types.FETCH_STATISTICS:
    case types.FETCH_TEAMS:
    case types.FETCH_TEAM_STATS:
    case types.FETCH_TEAM_MEMBERS:
    case types.FETCH_TEAM_LEADS:
    case types.FETCH_TESTIMONIALS:
    case types.FETCH_VOLUNTEER_INSIGHTS:
      return { ...state} ;
    case types.FETCH_DIRECTORS_SUCCESS:
      return {
        ...state,
        directors: action.payload,
      };
    case types.FETCH_IMAGES_SUCCESS:
      return {
        ...state,
        images: action.payload,
      };
    case types.FETCH_STATISTICS_SUCCESS:
      return {
        ...state,
        statistics: action.payload,
      };
    case types.FETCH_TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        teamMembers: action.payload,
      };
    case types.FETCH_TEAM_STATS_SUCCESS:
      return {
        ...state,
        team_stats: action.payload,
      };
    case types.FETCH_TEAM_LEADS_SUCCESS:
      return {
        ...state,
        teamLeads: action.payload,
      }
    case types.FETCH_TEAMS_SUCCESS:
      return {
        ...state,
        teams: action.payload,
      };
    case types.FETCH_TESTIMONIALS_SUCCESS:
      return {
        ...state,
        testimonials: action.payload,
      };
    case types.FETCH_VOLUNTEER_INSIGHTS_SUCCESS:
     return {
        ...state,
        volunteerInsights: action.payload,
      };
    case types.FETCH_DIRECTORS_FAILURE:
    case types.FETCH_IMAGES_FAILURE:
    case types.FETCH_STATISTICS_FAILURE:
    case types.FETCH_TEAM_MEMBERS_FAILURE:
    case types.FETCH_TEAM_STATS_FAILURE:
    case types.FETCH_TEAM_LEADS_FAILURE:
    case types.FETCH_TEAMS_FAILURE:
    case types.FETCH_TESTIMONIALS_FAILURE:
    case types.FETCH_VOLUNTEER_INSIGHTS_FAILURE:
      return { ...state, error: action.payload };
    case types.FETCH_OUR_STORY_PAGE_DATA:
      return { ...state }
    case types.FETCH_OUR_STORY_PAGE_DATA_SUCCESS:
      return {
        ...state,
        ourStoryPageData: action.payload.ourStoryPageData,
      }
    case types.FETCH_OUR_STORY_PAGE_DATA_FAILURE:
      return { ...state, error: action.payload }
    case types.FETCH_ABOUT_US_PAGE_DATA:
      return { ...state }
    case types.FETCH_ABOUT_US_PAGE_DATA_SUCCESS:
      return {
        ...state,
        aboutUsPageData: action.payload.aboutUsPageData,
      }
    case types.FETCH_ABOUT_US_PAGE_DATA_FAILURE:
      return { ...state, error: action.payload }
    default:
      return state;
  }
};

export default aboutUsReducer;
