import React, { useEffect } from 'react';
import { Container } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import AboutUsHeader from './AboutUsHeader';
import rectangle from '../../images/rectangle.png';
import comma_top from '../../images/comma_top.png';
import comma_bottom from '../../images/comma_bottom.png';
import { connect } from 'react-redux';
import { fetchOurStoryPageData } from '../../actions/aboutus'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import WireframeLoader from '../WireframeLoader'

const paragraphStyling = {
  fontFamily: 'Karla, Arial, Helvetica, sans-serif',
  fontSize: '18px',
  paddingBottom: '24px',
};

const headerStyling = {
  fontFamily: "'Nunito', Arial, Helvetica, sans-serif",
  fontWeight: 'bold',
  fontSize: '32px',
  lineHeight: '44px',
};


const OurStory = ({ ourStoryPageData, _fetchOurStoryPageData }) => {
  useEffect(() => {
    _fetchOurStoryPageData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const kevinsImage = ourStoryPageData ? ourStoryPageData.ourStoryImages[0] : undefined;

  return (
    <div>
      <AboutUsHeader picture={ourStoryPageData ? ourStoryPageData.title : ''} />
      <Helmet>
        <title>Our Story - AssistList</title>
        <meta name="description" content="AssistList is a volunteer-run, non-profit organization whose mission is to help Greater Vancouver residents connect and exchange home health equipment." />
      </Helmet>
      <Container style={{
        margin: '0 auto',
        maxWidth: '1080px',
        paddingTop: '80px',
        paddingBottom: '120px',
      }}
      >

        <div style={{ margin: '40px', display: 'flex', flexDirection: (window.innerWidth > 1000 ? 'row' : 'column') }}>
          <div>
            <img src={rectangle} alt="" />
            <h1 style={{
              fontFamily: "'Nunito', Arial, Helvetica, sans-serif", fontWeight: 'bold', fontSize: '32px', lineHeight: '44px', paddingBottom: '25px',
            }}
            >
              Our Story
            </h1>
            <p style={paragraphStyling}>
              {ourStoryPageData ? documentToReactComponents(ourStoryPageData.ourStoryPartOne.body) : <WireframeLoader />}
            </p>

            <div style={{ display: 'grid', gridTemplateColumns: '10% 80% 10%', paddingBottom: '40px' }}>
              <img style={{ alignSelf: 'start', height: '25px' }} src={comma_top} alt="comma" />
              <p style={{
                color: '#00ADD8', padding: '20px 0 20px 10px', fontWeight: 'bold', fontSize: '18px', fontFamily: "'Nunito', Arial, Helvetica, sans-serif",
              }}
              >
                {ourStoryPageData ? documentToReactComponents(ourStoryPageData.aboutUsQuote.body) : <WireframeLoader short={true} />}
              </p>
              <img style={{ alignSelf: 'end', height: '25px' }} src={comma_bottom} alt="comma" />
            </div>

            <p style={paragraphStyling}>
              {ourStoryPageData ? documentToReactComponents(ourStoryPageData.ourStoryPartTwo.body) : <WireframeLoader />}
            </p>
          </div>
          <div style={{ margin: (window.innerWidth > 1000 ? '160px 60px 0px 100px' : '60px 0px 0px 0px'), textAlign: 'center' }}>
            <img style={{ height: '288px' }} src={kevinsImage && kevinsImage.url} alt="Kevin profile" />
            <h2 style={headerStyling}>{kevinsImage && kevinsImage.title}</h2>
            <p style={{ color: '#575757' }}>{kevinsImage && kevinsImage.description}</p>
          </div>
        </div>


      </Container>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  _fetchOurStoryPageData: () => dispatch(fetchOurStoryPageData()),
});

const mapStateToProps = (state) => ({
  ourStoryPageData: state.aboutus.ourStoryPageData,
});

export default connect(mapStateToProps, mapDispatchToProps)(OurStory);
