import React from 'react'
import { connect } from 'react-redux'
import { Segment, Form, Button, Icon, Modal, Responsive } from 'semantic-ui-react'
import { reduxForm, Field, Fields, formValueSelector } from 'redux-form'

import WaitingPeople from './WaitingPeople'
import { InputField, PriceField, SelectField } from '../../utils/render-input'

const tinyMobile = 377

const validate = (values) => {
	const errors = {}

	if (!values.title) {
		errors.title = 'Post title is required'
	}
	if (!values.free) {
		if (!values.price || values.price === '0') {
			errors.price = 'Price is required, or did you mean free?'
		} else if (!/^\d+$/.test(values.price)) {
			errors.price = 'Price must be a whole number'
		} else if (values.price > 10000) {
			errors.price = 'Price exceeds limit of 10,000, enter a lower number'
		}
	}

	if (values.condition_id == null) {
		errors.condition_id = 'Please select a condition'
	}

	if (values.location_id == null) {
		errors.location_id = 'Please select a location'
	}

	return errors
}

const StrongTitleToolTip = (props) => (
	<Modal
		style={{ width: window.innerWidth > 500 ? '420px' : '90%', height: '441px' }}
		trigger={<Icon className="toolTip" color="grey" name="question circle" />}
		closeIcon
	>
		<Modal.Content>
			<Modal.Description>
				<h2 className="modalHeader">Strong Titles</h2>
				<div className="simpleKarlaText">
					<p>In order to increase the possibility of your item selling, here are a few tips for a strong title:</p>
					<ul>
						<li> Write what the item is first – example - &quot;Wheelchair&quot; or &quot;Scooter&quot; </li>
						<li>
							{' '}
							Next you can add the brand name or a prominent feature – example – &quot;foldable&quot; or
							&quot;Helio&quot;
						</li>
						<li>
							{' '}
							Finally, you can add something descriptive like &quot;Brand new&quot; or &quot;Excellent condition&quot;
						</li>
						<li> Title example: &quot;Scooter – Shoprider, in Excellent Condition&quot;</li>
					</ul>
					<p>Try to keep it short and simple. It will help buyers understand if this product is for them.</p>
				</div>
			</Modal.Description>
		</Modal.Content>
	</Modal>
)

const BasicsForm = ({ handleSubmit, previousPage, conditions, locations, equipment_type, waitingPeople }) => {
	return (
		<div style={{ marginBottom: 56 }}>
			<Responsive maxWidth={989}>
				<div>
					<Segment
						style={{ marginBottom: '0px', background: 'white', borderBottom: '1px #CACACA solid' }}
						basic
						clearing
					>
						<h2>Creating new listing for: {equipment_type.name}</h2>
						<p className="modaleContent">Please enter information below. All fields are required</p>
					</Segment>
				</div>
			</Responsive>
			<Responsive minWidth={990}>
				<div>
					<Segment
						style={{ marginBottom: '0px', background: 'white', borderBottom: '1px #CACACA solid' }}
						basic
						clearing
					>
						<div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
							<h2 style={{ width: window.innerWidth > 989 ? '540px' : '' }}>
								Creating new listing for: {equipment_type.name}
							</h2>
							<p className="modaleContent">Please enter information below. All fields are required</p>
						</div>
					</Segment>
				</div>
			</Responsive>
			<Form name="newPost" onSubmit={handleSubmit}>
				<Segment clearing>
					<Responsive maxWidth={989}>
						<div style={{ background: 'white', marginBottom: '40px' }}>
							<WaitingPeople waitingPeople={waitingPeople} />
						</div>
						<div style={{ position: 'relative', width: '100%' }}>
							<div style={{ position: 'absolute', right: '7px' }}>
								<StrongTitleToolTip />
							</div>
						</div>
					</Responsive>
					<Responsive minWidth={990}>
						<div style={{ background: 'white', marginBottom: '40px' }}>
							<WaitingPeople waitingPeople={waitingPeople} />
						</div>
						<div style={{ position: 'relative', width: '100%' }}>
							<div style={{ position: 'absolute', right: '7px' }}>
								<StrongTitleToolTip />
							</div>
						</div>
					</Responsive>
					{(() => {
						switch (equipment_type.name !== undefined) {
							case false:
								return (
									<Field
										component={InputField}
										fluid
										name="title"
										label="Listing Title"
										required
										className="titleInput"
									/>
								)
							default:
								return (
									<Field
										component={InputField}
										fluid
										name="title"
										label="Listing Title"
										required
										className="titleInput"
									/>
								)
						}
					})()}
					<Form.Group widths="equal">
						<Fields icon="users" names={['price', 'free']} component={PriceField} required />
						<Field
							component={SelectField}
							name="condition_id"
							label="Condition"
							placeholder="Select a condition"
							options={conditions}
							required
						/>
						<Field
							component={SelectField}
							name="location_id"
							label="Location"
							placeholder="Select a location"
							options={locations}
							required
						/>
					</Form.Group>
					<Button
						type="button"
						className="previousStepBtn"
						style={{
							width: window.innerWidth > tinyMobile ? '' : '140px',
							padding: window.innerWidth > tinyMobile ? '' : '0.6875em 0em .6875em',
						}}
						onClick={previousPage}
						primary
						basic
						floated="left"
					>
						<Icon name="angle left" /> <b>Previous Step</b>{' '}
					</Button>
					<Button
						type="submit"
						className="nextStepBtn"
						primary
						style={{
							width: window.innerWidth > tinyMobile ? '173px' : '140px',
							padding: window.innerWidth > tinyMobile ? '' : '0.6875em 0em .6875em',
						}}
						floated="right"
					>
						{' '}
						Next Step <Icon name="angle right" />
					</Button>
				</Segment>
			</Form>
		</div>
	)
}

const BasicsContainer = reduxForm({
	form: 'newPost',
	validate,
	destroyOnUnmount: false,
})(BasicsForm)

const selector = formValueSelector('newPost')

const mapStateToProps = (state) => ({
	fields: state.post.postForm.fields,
	conditions: state.post.postForm.conditions,
	locations: state.post.postForm.locations,
	equipment_type: selector(state, 'equipment_type'),
})

export default connect(mapStateToProps)(BasicsContainer)
