import types from "../types/banner";

const bannerReducer = (
    state = {
      loading: true,
      banner: null,
      show: true
    },
    action,
  ) => {
    switch (action.type) {
        case types.FETCH_ACTIVE_BANNER: {
            return {...state, loading: true};
        }
        case types.FETCH_ACTIVE_BANNER_SUCCESSFUL: {
            return {...state, loading: false, banner: action.payload.data};
        }
        case types.FETCH_ACTIVE_BANNER_FAILURE: {
            return ({ ...state, loading: false});
        }
        case types.TURNOFF_ACTIVE_BANNER: {
          return ({ ...state, show: false})
        }
      default:
        return state;
    }
  };
  
  export default bannerReducer;