import { SubmissionError, reset } from 'redux-form';

import qs from 'qs';

import types from '../types/user';

import { showAlert } from './alerts';

import api, { userHeaders } from '../utils/api';
import { history } from '../store';
import ReactGA from 'react-ga';

export const signup = (values, dispatch) =>
  api
    .post('/auth/', {
      user: values,
    })
    .then((response) => {
      let data = response.data.data;
      localStorage.setItem('user', JSON.stringify(data));

      if (
        localStorage.getItem('gaEventFlow') === 'notificationBtnNotLoggedIn'
      ) {
        ReactGA.event({
          category: 'User No Account - Subscribe to Notification Btn clicked',
          action: 'click',
          label: 'Account Created',
        });
        localStorage.setItem('gaEventFlow', 'notificationBtnNoAccount');
      } else if (localStorage.getItem('filterNotLoggedIn') === 'No') {
        ReactGA.event({
          category: 'User No Account - No Results Filter Btn clicked',
          action: 'click',
          label: 'Account Created',
        });
        localStorage.setItem('gaEventFlow', 'filterNoResultNoAccount');
      } else {
        ReactGA.event({
          category: 'User No Account - Regular Signup',
          action: 'click',
          label: 'Account Created',
        });
        localStorage.removeItem('filterTriggerForm');
        localStorage.removeItem('gaEventFlow');
      }

      dispatch(
        showAlert({
          header: 'Welcome!',
          message: 'You have signed up successfully',
          type: 'success',
          dismissAfter: 5000,
        }),
      );

      if (!data.confirmed) {
        dispatch(
          showAlert({
            header: 'Unconfirmed user',
            message:
              'Your account has not been confirmed. Please check your email for details',
            type: 'warning',
          }),
        );
        localStorage.setItem('justSignedUp', true);
      }

      return dispatch({ type: types.SIGNUP_SUCCESS, payload: data });
    })
    .catch((error) => {
      dispatch(
        showAlert({
          header: 'Sign up unsuccessful',
          message: 'Sign up failed, please try again',
          type: 'error',
        }),
      );
      dispatch({ type: types.SIGNUP_FAILURE, payload: error.response.data });
      throw new SubmissionError({
        ...error.response.data.errors,
        _error: error.response.data.error,
      });
    });

export const confirmUser = (confirmation_token) => (dispatch) => {
  api
    .get(`/auth/confirmation/?${qs.stringify({ confirmation_token })}`)
    .then((response) => {
      localStorage.setItem('user', JSON.stringify(response.data));

      dispatch(
        showAlert({
          header: 'Welcome!',
          message:
            'You have successfully confirmed your account. You may make a listing now',
          type: 'success',
          dismissAfter: 5000,
        }),
      );
      return dispatch({
        type: types.CONFIRMATION_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch(
        showAlert({
          header: 'Confirmation Failed',
          flowId: error.response?.data?.data?.flow_id,
          message:
            'Your confirmation link is invalid. Please try again or request a new confirmation link',
          type: 'error',
        }),
      );
      dispatch({ type: types.CONFIRMATION_FAILURE, payload: error });
    });
};

export const resendConfirmation = (values, dispatch) =>
  api
    .post('/auth/confirmation', {
      user: values,
    })
    .then((response) =>
      dispatch({
        type: types.RESEND_CONFIRMATION_SUCCESS,
        payload: response.data,
      }),
    )
    .catch((error) => {
      dispatch({
        type: types.RESEND_CONFIRMATION_FAILURE,
        payload: error.response.data,
      });
      throw new SubmissionError({
        ...error.response.data.errors,
        _error: error.response.data.error,
      });
    });

export const resendCurrentUserConfirmation = (values, dispatch) => {
  const { email } = JSON.parse(localStorage.getItem('user'));
  return resendConfirmation({ email }, dispatch);
};

export const login = (values, dispatch) =>
  api
    .post('/auth/login', { user: values })
    .then((response) => {
      localStorage.setItem('user', JSON.stringify(response.data));

      if (localStorage.getItem('filterNotLoggedIn') === 'No') {
        ReactGA.event({
          category:
            'User with Account - Not logged in - No Results Filter Btn clicked',
          action: 'click',
          label: 'Subscribe to Notification Btn clicked',
        });
        localStorage.setItem('gaEventFlow', 'filterNoResultNotLoggedIn');
      } else if (
        localStorage.getItem('gaEventFlow') === 'notificationBtnNotLoggedIn'
      ) {
        ReactGA.event({
          category:
            'User with Account - Not logged in - Subscribe to Notification Btn clicked',
          action: 'click',
          label: 'Subscribe to Notification Btn clicked',
        });
      }
      dispatch(
        showAlert({
          header: 'Welcome back!',
          message: 'You have logged in successfully',
          type: 'success',
          dismissAfter: 5000,
        }),
      );

      if (!response.data.confirmed) {
        dispatch(
          showAlert({
            header: 'Unconfirmed user',
            message:
              'Your account has not been confirmed. Please check your email for details',
            type: 'warning',
          }),
        );
      }

      return dispatch({ type: types.LOGIN_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: types.LOGIN_FAILURE });
      dispatch(
        showAlert({
          header: 'We are unable to log you in.',
          message: 'Please check your email and password.',
          type: 'warning',
        }),
      );
      throw new SubmissionError({
        _error: error.response.data.error,
      });
    });

export const oauthLogin = (auth_token, email) => (dispatch) =>
  api
    .get('/api/user', {
      headers: { 'X-User-Token': auth_token, 'X-User-Email': email },
    })
    .then((response) => {
      localStorage.setItem('user', JSON.stringify(response.data));

      dispatch(
        showAlert({
          header: 'Welcome!',
          message: 'You have logged in successfully',
          type: 'success',
          dismissAfter: 5000,
        }),
      );

      if (!response.data.confirmed) {
        dispatch(
          showAlert({
            header: 'Unconfirmed user',
            message:
              'Your account has not been confirmed. Please check your email for details',
            type: 'warning',
          }),
        );
      }

      return dispatch({
        type: types.OAUTH_LOGIN_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) =>
      dispatch({ type: types.OAUTH_LOGIN_FAILURE, payload: error }),
    );

export const setAuthError = (error) => (dispatch) => {
  switch (error) {
    case 'account_exists':
      dispatch(
        showAlert({
          header: 'Account already exists',
          message:
            'Please sign in using the original registration method using your email, Google or Facebook account',
          type: 'error',
          dismissAfter: 8000,
        }),
      );
      return dispatch({ type: types.ACCOUNT_EXISTS_ERROR });

    case 'unknown':
      dispatch(
        showAlert({
          header: 'Unknown Error',
          message: 'Please try again or contact our support team',
          type: 'error',
          dismissAfter: 5000,
        }),
      );
      return dispatch({ type: types.UNKNOWN_AUTH_ERROR });

    default:
      dispatch(
        showAlert({
          header: 'Unknown Error',
          message: 'Please try again or contact our support team',
          type: 'error',
          dismissAfter: 5000,
        }),
      );
      return dispatch({ type: types.UNKNOWN_AUTH_ERROR });
  }
};

export const resetPassword = (values, dispatch) =>
  api
    .post('/auth/password', {
      user: values,
    })
    .then((response) =>
      dispatch({
        type: types.SEND_PASSWORD_RESET_SUCCESS,
        payload: response.data,
      }),
    )
    .catch((error) => {
      dispatch({
        type: types.SEND_PASSWORD_RESET_FAILURE,
        payload: error.response.data,
      });
    });

export const setResetToken = (values) => ({
  type: types.SET_RESET_PASSWORD_TOKEN,
  payload: values,
});

export const setNewPassword = (values) => (dispatch) =>
  api
    .put('/auth/password', {
      user: values,
    })
    .then((response) => {
      localStorage.setItem('user', JSON.stringify(response.data));

      dispatch(
        showAlert({
          header: 'Welcome back!',
          message: 'You have set a new password and we have you logged in.',
          type: 'success',
          dismissAfter: 5000,
        }),
      );

      return dispatch({
        type: types.SET_NEW_PASSWORD_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      if (error.response.data.errors.reset_password_token) {
        dispatch(
          showAlert({
            header:
              'This password reset link has been used and is no longer valid.',
            message:
              'If you are still unable to login, please try resetting your password again.',
            type: 'error',
            dismissAfter: 5000,
          }),
        );
        history.push('/auth');
      }

      dispatch({
        type: types.SET_NEW_PASSWORD_FAILURE,
        payload: error.response.data,
      });
    });

export const changePassword = (values, dispatch) =>
  api
    .put(
      '/auth',
      {
        user: values,
      },
      userHeaders(),
    )
    .then((response) => {
      dispatch(reset('changePasswordForm'));
      return dispatch({
        type: types.CHANGE_PASSWORD_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: types.CHANGE_PASSWORD_FAILURE,
        payload: error.response.data,
      });
      throw new SubmissionError({
        ...error.response.data.errors,
        _error: error.response.data.error,
      });
    });

export const logout = () => (dispatch) =>
  api
    .delete('/auth/logout', userHeaders())
    .then(() => {
      localStorage.removeItem('user');
      localStorage.removeItem('filterTriggerForm');
      localStorage.removeItem('filterNotLoggedIn');
      localStorage.removeItem('gaEventFlow');
      history.push('/');
      dispatch(
        showAlert({
          message: 'You have logged out successfully',
          type: 'success',
          dismissAfter: 5000,
        }),
      );
      return dispatch({ type: types.LOGOUT_SUCCESS });
    })
    .catch((error) => dispatch({ type: types.LOGOUT_FAILURE, payload: error }));

export const fetchUser = (id) => (dispatch) =>
  api
    .get(`/users/${id}`)
    .then((response) => {
      localStorage.setItem('user', JSON.stringify(response.data));
      return dispatch({
        type: types.FETCH_USER_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) =>
      dispatch({ type: types.FETCH_USER_FAILURE, payload: error }),
    );

export const setSubscribeClicked = () => (dispatch) =>
  dispatch({ type: types.SET_SUBSCRIBE_CLICKED });

export const removeSubscribeClicked = () => (dispatch) =>
  dispatch({ type: types.REMOVE_SUBSCRIBE_CLICKED });

export const setNewsletterSubscriptionStatus = (values, dispatch) =>
  api
    .post(
      'api/user/set_newsletter_subscribed',
      {
        newsletter_subscribed: values.newsletterSubscriptionInput,
      },
      userHeaders(),
    )
    .then((response) => {
      localStorage.setItem('user', JSON.stringify(response.data));
      dispatch({
        type: types.SET_NEWSLETTER_SUCCESS,
        payload: response.data.newsletter_subscribed,
      });
      if (values.newsletterSubscriptionInput === true) {
        dispatch(
          showAlert({
            header: 'E-newsletter Subscribed',
            message: 'You are now subscribed to our e-newsletter.',
            type: 'success',
            dismissAfter: 5000,
          }),
        );
      } else {
        dispatch(
          showAlert({
            header: 'E-newsletter Unsubscribed',
            message: 'You are now unsubscribed from our e-newsletter.',
            type: 'warning',
            dismissAfter: 5000,
          }),
        );
      }
    })
    .catch((error) => {
      dispatch({ type: types.SET_NEWSLETTER_FAILURE, payload: error });
    });

export const getCurrentGeoLocation = () => (dispatch) => {
  dispatch({
    type: types.SET_GEO_LOCATION,
  });

  if (navigator.geolocation) {
    navigator.permissions
      .query({ name: 'geolocation' })
      .then((permissionStatus) => {
        if (permissionStatus.state === 'denied') {
          alert('Please go to browser >> setting to allow location access.');
          window.location.href = 'app-settings:location';
          dispatch({
            type: types.SET_GEO_LOCATION_FAILURE,
            payload: 'denied',
          });
          localStorage.setItem('allowSharingLocation', false);
          localStorage.setItem('useCurrentLocation', false);
        } else {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              dispatch({
                type: types.SET_GEO_LOCATION_SUCCESS,
                payload: {
                  lat: position.coords.latitude,
                  long: position.coords.longitude,
                },
              });

              localStorage.setItem('allowSharingLocation', true);
              localStorage.setItem('useCurrentLocation', true);
              localStorage.setItem('geoLocation', [
                position.coords.latitude,
                position.coords.longitude,
              ]);

              api
                .get(
                  `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${process.env.REACT_APP_GEOCODE_API_KEY}`,
                )
                .then((response) => {
                  if (
                    response.data &&
                    response.data.results &&
                    response.data.results.length > 0
                  ) {
                    let municipal =
                      response.data.results[0].address_components.filter(
                        (add) =>
                          add.types.includes('locality') &&
                          add.types.includes('political'),
                      );
                    if (municipal.length > 0) {
                      setSharingLocationMunicipal(municipal[0].short_name);
                    }

                    if (municipal.length > 0) {
                      localStorage.setItem(
                        'municipal',
                        municipal[0].short_name,
                      );
                      dispatch({
                        type: types.SET_SHARING_LOCATION_MUNICIPAL,
                        payload: municipal[0].short_name,
                      });
                    }
                  }
                });
            },
            (error) => {
              dispatch({
                type: types.SET_GEO_LOCATION_FAILURE,
                payload: error,
              });
              localStorage.setItem('allowSharingLocation', false);
              localStorage.setItem('useCurrentLocation', false);
            },
          );
        }
      });
  } else {
    alert('Geolocation is not supported in your browser.');
    dispatch({
      type: types.SET_GEO_LOCATION_FAILURE,
      payload: 'Geolocation is not supported in your browser.',
    });
  }
};

export const setSharingLocationPermission =
  (isAllow, geoLocation) => (dispatch) => {
    localStorage.setItem('allowSharingLocation', isAllow);
    if (geoLocation) {
      localStorage.setItem('geoLocation', [geoLocation.lat, geoLocation.long]);
    }
    dispatch({
      type: types.SET_SHARING_LOCATION_PERMISSION,
      payload: { isAllow, geoLocation },
    });
  };

export const setSharingLocationRadius = (radius) => (dispatch) => {
  localStorage.setItem('locationRadius', radius || 10);
  dispatch({
    type: types.SET_SHARING_LOCATION_RADIUS,
    payload: radius,
  });
};

export const setSharingLocationMunicipal = (municipal) => (dispatch) => {
  localStorage.setItem('municipal', municipal);
  dispatch({
    type: types.SET_SHARING_LOCATION_MUNICIPAL,
    payload: municipal,
  });
};

export const turnOffInitializingLocation = () => (dispatch) => {
  dispatch({
    type: types.TURN_OFF_INITIALIZING_LOCATION,
  });
};

export const setUseCurrentLocation = (isAllow) => (dispatch) => {
  localStorage.setItem('useCurrentLocation', isAllow);
  dispatch({
    type: types.USE_CURRENT_LOCATION,
    payload: isAllow,
  });
};
