import React, { Component } from 'react';
import {
  Container, Segment, Button, Form, Message,
} from 'semantic-ui-react';

import Post from '../../PostComponents/PostDetail';

export default class PreviewPage extends Component {
  render() {
    const {
      post,
      submitting,
      disabled,
      onPreview,
      onSubmit,
    } = this.props;

    return (
      <Container style={{ margin: (window.innerWidth > 700 ? '0 auto' : '0 5%'), maxWidth: '1120px', paddingBottom: '160px' }}>
        {post.images.length === 0
          && (
            <Message negative>
              <Message.Header>Image is required</Message.Header>
              <Message.Content>Please upload images using dropzone on the Edit Page.</Message.Content>
            </Message>
          )
        }
        <Message>
          <p>Here&apos;s a preview of your post!</p>
        </Message>
        <Segment>
          <Post post={post} shareDisabled={true} reportActive={false}/> {/* props for ShareButton and  ReportButton components to be disabled*/}
        </Segment>
        <Segment basic clearing>
          <Form name="editPost" onSubmit={onSubmit}>
            <Button secondary content="Back to Edit" floated="left" onClick={onPreview} />
            <Button type="submit" primary content="Save" loading={submitting} disabled={disabled || post.images.length === 0} floated="right" />
          </Form>
        </Segment>
      </Container>
    );
  }
}
