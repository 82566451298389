import React from 'react';

import { Dropdown, Confirm } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import config from '../config';
import { deletePost, renewPost } from '../actions/post';

import { submitFeedback } from '../actions/feedback';

class PostsListDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = { show: false, confirm: false };
    this.showDeleteConfirmation = () => this.setState({ show: true });
    this.showSoldConfirmation = () => this.setState({ confirm: true });

    this.handleConfirmSold = (postId) => {
      this.props._submitFeedback(postId, config.FEEDBACK_OPTIONS.exchanged);
      this.setState({ confirm: false, show: false });
    };
    this.handleConfirmDelete = (id) => {
      this.props._deletePost(id);
      this.setState({ confirm: false, show: false });
    };
    this.handleCancel = () => (
      this.state.show === true ? this.setState({ show: false }) : this.setState({ confirm: false })
    );
  }

  render() {
    const { user, post: { id, active, last_feedback }, dropdown } = this.props;
    const { show, confirm } = this.state;
    return (
      <Dropdown className="Listing" {...dropdown}>
        <Dropdown.Menu style={{ marginTop: '.75rem' }}>
          { last_feedback !== config.FEEDBACK_OPTIONS.exchanged && <Dropdown.Item icon="edit" as={Link} to={`/posts/${id}/edit`} content="Edit listing" /> }
          { user.confirmed && active && <Dropdown.Item icon="refresh" content="Renew listing" onClick={() => this.props._renewPost(id)} /> }
          { user.confirmed && !active && last_feedback !== config.FEEDBACK_OPTIONS.exchanged
            && <Dropdown.Item icon="refresh" content="Activate listing" onClick={() => this.props._renewPost(id)} /> }
          { user.confirmed && last_feedback !== config.FEEDBACK_OPTIONS.exchanged && <Dropdown.Item icon="check" content="Mark as Sold" onClick={this.showSoldConfirmation} /> }
          <Dropdown.Item icon="delete" content="Delete listing" onClick={this.showDeleteConfirmation} />
          <Confirm
            open={show}
            content="Are you sure you want to delete this listing?"
            onConfirm={() => this.handleConfirmDelete(id)}
            onCancel={this.handleCancel}
          />
          <Confirm
            open={confirm}
            content="Are you sure you want to mark this listing as sold?"
            onConfirm={() => this.handleConfirmSold(id)}
            onCancel={this.handleCancel}
          />
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  _deletePost: id => dispatch(deletePost(id)),
  _renewPost: id => dispatch(renewPost(id)),
  _submitFeedback: (id, option) => dispatch(submitFeedback(id, option)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostsListDropdown);
