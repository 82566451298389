import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import qs from 'qs';

import { oauthLogin } from '../actions/user';

const oauth = ({ _oauthLogin, location }) => {
  const { auth_token, email } = qs.parse(location.search, { ignoreQueryPrefix: true });
  _oauthLogin(auth_token, email);
  return (
    <Redirect to={{pathname: '/', state: { subscribeModal: true }}} />
  );
};

const mapDispatchToProps = dispatch => ({
  _oauthLogin: (auth_token, email) => dispatch(oauthLogin(auth_token, email)),
});

export default withRouter(connect(null, mapDispatchToProps)(oauth));