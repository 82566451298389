export const formValidator = (values, props) => {
  const errors = {};


  if (props.form === 'signup') {
    if (!values.email) {
      errors.email = 'Email address is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
  }

  if (props.form === 'changePasswordForm') {
    if (!values.current_password) {
      errors.current_password = 'Password is required';
    } else if (values.current_password.length < 8) {
      errors.current_password = 'Password must be at least 8 characters long';
    }
  }

  if (!values.password) {
    errors.password = 'Password is required';
  } else if (values.password.length < 8) {
    errors.password = 'Password must be at least 8 characters long';
  } else if (!/(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z])/.test(values.password)) {
    errors.password = 'Password must include: a lower and uppercase letter, a number, and a symbol (!@#$&*)';
  }

  if (values.password_confirmation !== values.password) {
    errors.password_confirmation = 'Passwords do not match';
  }

  if (props.form === 'signup') {
    if (!values.recaptcha) {
      errors.recaptcha = 'Please check the reCAPTCHA box';
    }

    if (!values.termsOfUse) {
      errors.termsOfUse = 'Please agree to the Terms of Use';
    }
  }

  return errors;
};
