import React from 'react';
import { Message } from 'semantic-ui-react';

const SoldMessage = () => (
  <Message warning>
    <Message.Header>This listing is sold.</Message.Header>
  </Message>
);

export default SoldMessage;
