/**
 * This is used to format the dates on the listings to calculate time passed.
 */
export const timeElapseUTCDate = (now, time) => {
	let hoursPassed = (now.getTime() - time.getTime()) / (1000 * 3600)
	let daysPassed
	let timeDisplay = 'Just Now'

	if (hoursPassed >= 24) {
		daysPassed = Math.floor(hoursPassed / 24)

		timeDisplay = daysPassed === 1 ? `${daysPassed} Day Ago` : `${daysPassed} Days Ago`
	} else if (hoursPassed >= 1) {
		hoursPassed = Math.floor(hoursPassed)

		timeDisplay = hoursPassed === 1 ? `${hoursPassed} Hour Ago` : `${hoursPassed} Hours Ago`
	}

	return timeDisplay
}
