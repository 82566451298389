import React from 'react';
import { connect } from 'react-redux';

import {Responsive} from 'semantic-ui-react';
import ResetPasswordForm from './ResetPasswordForm';
import ResetEmailSentMessage from './ResetEmailSentMessage';
import NewPasswordForm from '../NewPassword/NewPasswordForm';
import circleBackground from '../../images/assistlist_background_circle.png'

const desktopDivStyle = {
  background: '#ffffff', 
  minHeight: '100vh', 
  backgroundSize: 'cover', 
  backgroundImage: `url(${circleBackground})`
};

const mobileTabletDivStyle = {
  background: '#ffffff', 
  minHeight: '100vh', 
};

const ResetPassword = (props) => {
  const { user } = props;

  return (
<div style={window.innerWidth <= Responsive.onlyTablet.maxWidth ? mobileTabletDivStyle : desktopDivStyle}>
        { !user.resetSent && <ResetPasswordForm /> }
        { user.resetSent && <ResetEmailSentMessage /> }
        { user.resetToken && <NewPasswordForm /> }
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(ResetPassword);
