import types from '../types/relatedlistings';
import { showAlert } from './alerts';
import api from '../utils/api';

export const fetchRelatedListing = (category_id, user_id) => (dispatch) => {
  dispatch({ type: types.FETCH_RELATED_LISTING });
  return (
    api.get('api/posts/related', { params: {category_id, user_id} } )
      .then(response => dispatch({ type: types.FETCH_RELATED_LISTING_SUCCESS, payload: response.data }))
      .catch(error => {
        dispatch(showAlert({
          header: 'Something went wrong.',
          flowId: error.response?.data?.data?.flow_id,
          message: 'Listing cannot be displayed',
          type: 'error',
        }));
        dispatch({ type: types.FETCH_RELATED_LISTING_FAILURE, payload: error });
      })
  );
};
