const FETCH_USER_ACTIVE_POSTS = 'profile/FETCH_USER_ACTIVE_POSTS';
const FETCH_USER_ACTIVE_POSTS_SUCCESS = 'profile/FETCH_USER_ACTIVE_POSTS_SUCCESS';
const FETCH_USER_ACTIVE_POSTS_FAILURE = 'profile/FETCH_USER_ACTIVE_POSTS_FAILURE';

const FETCH_USER_INACTIVE_POSTS = 'profile/FETCH_USER_INACTIVE_POSTS';
const FETCH_USER_INACTIVE_POSTS_SUCCESS = 'profile/FETCH_USER_INACTIVE_POSTS_SUCCESS';
const FETCH_USER_INACTIVE_POSTS_FAILURE = 'profile/FETCH_USER_INACTIVE_POSTS_FAILURE';

const FETCH_USER_EXCHANGED_POSTS = 'profile/FETCH_USER_EXCHANGED_POSTS';
const FETCH_USER_EXCHANGED_POSTS_SUCCESS = 'profile/FETCH_USER_EXCHANGED_POSTS_SUCCESS';
const FETCH_USER_EXCHANGED_POSTS_FAILURE = 'profile/FETCH_USER_EXCHANGED_POSTS_FAILURE';

export default {
  FETCH_USER_ACTIVE_POSTS,
  FETCH_USER_ACTIVE_POSTS_SUCCESS,
  FETCH_USER_ACTIVE_POSTS_FAILURE,

  FETCH_USER_INACTIVE_POSTS,
  FETCH_USER_INACTIVE_POSTS_SUCCESS,
  FETCH_USER_INACTIVE_POSTS_FAILURE,

  FETCH_USER_EXCHANGED_POSTS,
  FETCH_USER_EXCHANGED_POSTS_SUCCESS,
  FETCH_USER_EXCHANGED_POSTS_FAILURE,
};
