import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import {
  Form, Button, Icon, Header, Grid, Responsive
} from 'semantic-ui-react';
import familyIllustration from '../../images/family_illustration.png';
import googleLogo from '../../images/googleLogo.png';
import { Field, reduxForm } from 'redux-form';

import { login } from '../../actions/user';

import { IconTextField } from '../../utils/render-input';
import config from '../../config';
import './authentication.css'


const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Email address is required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }

  if (!values.password) {
    errors.password = 'Password is required';
  } else if (values.password.length < 8) {
    errors.password = 'Password must be at least 8 characters long';
  }

  return errors;
};

const btnStyle = {
  maxWidth: '120px',
  minHeight: '30px',
  marginBottom: '24px',
  marginTop: '5px',
  backgroundColor: '#1A659F',
};

const mediaBtnStyle = {
  color: '#323633',
  maxWidth: '270px',
  marginBottom: '20px',
  padding: '12px 0px'
};

const LoginForm = ({ style, error, handleSubmit, submitting, toggleHide, isHide }) => {

  useEffect(() => {
    if(!isHide) toggleHide()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
  <Grid style={style}>
    <Grid.Row>
      <Grid.Column computer={8} tablet={16} mobile={16}>
        <div style={window.innerWidth <= Responsive.onlyTablet.maxWidth? { width: '100%' } :{ width: '82%' }}>
          <Link to="/" style={{ color: 'rgba(0,0,0,.87)'}}><Icon link name='chevron left' />Back to Home </Link>
          <Header as='h4' hidden={window.innerWidth <= Responsive.onlyTablet.maxWidth ? false : true}>
            New to AssistList?  
            <a className='linkHover' href="#signup" style={{color: '#1A659F'}}> Sign Up</a>.
          </Header>
          <Header as='h1' style={{ color: '#1A659F', fontWeight: 'bold', marginTop: '40px' }}>
            Join Hundreds of Local Users
          </Header>
          <p style={{ color: 'black', fontSize: '18px', marginTop: '30px' }}>To create listings or contact someone with equipment listed, sign up for a free account.</p>
          <img style={{marginTop: '50px', width: '100%'}} hidden={window.innerWidth <= Responsive.onlyTablet.maxWidth ? true : false} src={familyIllustration} alt="Family Gathering"/>
        </div>
      </Grid.Column>

      <Grid.Column computer={8} tablet={16} mobile={16}>
        <Header hidden={window.innerWidth <= Responsive.onlyTablet.maxWidth ? true : false}as='h4'>
          New to AssistList?  
          <a className='linkHover' href="#signup" style={{color: '#1A659F'}}> Sign Up</a>.
        </Header>
        <Header as='h2' style={{ color: '#1A659F', fontWeight: 'bold', marginTop: '50px', marginBottom: '24px' }}>Log In</Header>
        <Form name="login" onSubmit={handleSubmit(login)}>
          <Field component={IconTextField} style={window.innerWidth <= Responsive.onlyMobile.maxWidth ? {marginBottom: '12px'} : {marginBottom: '12px',width: '80%'}}
                  label="Email address" name="email" type="email" placeholder="example@gmail.com" autoComplete="email" className=".fs-exclude" />

          <Field component={IconTextField} style={window.innerWidth <= Responsive.onlyMobile.maxWidth ? {marginBottom: '12px'} : {marginBottom: '12px',width: '80%'}}
                  label="Password" name="password"  icon={ isHide ? <Icon name ='hide' onClick={toggleHide}  link/> : <Icon name ='unhide' onClick={toggleHide} link/>} 
                  type={isHide ? "password" : "text"} autoComplete="current-password" />
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <br></br>
          
          <Form.Button
            primary
            id='submitButton'
            fluid
            type="submit"
            content="Log in"
            loading={submitting}
            style={btnStyle}
          />
        </Form>

        <br></br>
        <p style={{ marginBottom: '30px' }}>Continue with your social account</p>

        <Grid>
          <Grid.Row>
            <Grid.Column computer={7} tablet={6} mobile={16}>

              <Button
                fluid
                basic
                icon={<img src ={googleLogo} alt ='Google Icon' style={{ width: '19px', marginRight: '13px', verticalAlign: 'middle'}} />}
                content={<span style={{ verticalAlign: 'middle', display: 'inline-block', marginTop: '1px'}}>Continue with Google</span>}
                style={{...mediaBtnStyle,  display: 'flex', justifyContent: 'center', alignItems: 'center', height: '41px' }}
                onClick={() => window.open(config.googleOauthUrl, '_self')}
              />
            </Grid.Column>
            <Grid.Column computer={7} tablet={6} mobile={16}>
              <Button
                fluid
                basic
                icon={<Icon name="facebook f" className="social-icon-color" style={{ color: '#4881dc' }} />}
                style={mediaBtnStyle}
                content="Continue with Facebook"
                onClick={() => window.open(config.facebookOauthUrl, '_self')}
              />

            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div class="ui divider"></div>
        <p style= {{marginTop: '40px'}} >Forgot your password? <Link className='linkHover' to="/password/reset" style={{ color: '#1A659F', fontWeight: "bold"}}>Reset</Link></p>
        <p>Didn&#39;t receive a confirmation email? <Link className='linkHover' to="/confirmation/new" style={{ color: '#1A659F', fontWeight: "bold" }}>Resend</Link></p>
      </Grid.Column>
    </Grid.Row>
  </Grid>
)};


export default reduxForm({
  form: 'login',
  validate,
})(LoginForm);
