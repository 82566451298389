import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { AuthenticatedRoute, UnauthenticatedRoute } from '../utils/redirect';

import NavBar from '../components/NavBar';
import Blog from '../components/Blog';
import Donate from '../components/Donate';
import Resources from '../components/Resources';
import Partners from '../components/Partners';
import AboutUs from '../components/AboutUs';
import Policies from '../components/Policies';
import Profile from '../components/Profile';
import Listings from '../components/Listings';
import Post from '../components/Post';
import Authentication from '../components/Authentication';
import ResetPassword from '../components/ResetPassword';
import NewPassword from '../components/NewPassword';
import OAuth from '../utils/oauth';
import Wizard from '../components/Wizard';
import Confirmation from '../utils/confirmation';
import EmailFeedback from '../utils/email_feedback';
import EmailRenew from '../utils/email_renew';
import ResendConfirmation from '../components/ResendConfirmation';
import { PageNotFound } from '../components/PageNotFound/GeneralErrorPage/PageNotFound';
import Footer from '../components/Footer';

const desktopFooterHeight = 60;
const mobileFooterHeight = 160;

const Layout = () => {
  let isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <React.Fragment>
      <NavBar />

      <div
        style={{
          minHeight: `calc(100vh - ${
            isMobile ? mobileFooterHeight : desktopFooterHeight
          }px`,
        }}
      >
        <Switch>
          <Route exact path="/" render={(props) => <Listings {...props} />} />
          <Route path="/blog" component={Blog} />
          <Route path="/wizard" component={Wizard} />
          <Route path="/posts/:id" component={Post} />
          <Route path="/donate" component={Donate} />
          <Route path="/resources" component={Resources} />
          <Route path="/partners" component={Partners} />
          <Route path="/aboutus" component={AboutUs} />
          <Route path="/policies" component={Policies} />
          <UnauthenticatedRoute path="/auth" component={Authentication} />
          <UnauthenticatedRoute
            path="/password/reset"
            component={ResetPassword}
          />
          <UnauthenticatedRoute path="/password/new" component={NewPassword} />
          <Route
            exact
            path="/feedbacks/submit_email_feedback"
            component={EmailFeedback}
          />
          <Route exact path="/email_renew" component={EmailRenew} />
          <Route exact path="/confirmation" component={Confirmation} />
          <Route
            exact
            path="/confirmation/new"
            component={ResendConfirmation}
          />
          <Route path="/oauth" component={OAuth} />
          <AuthenticatedRoute path="/account" component={Profile} />
          <Route component={PageNotFound} />
        </Switch>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Layout;
