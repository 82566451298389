import React from "react";
import { Dropdown, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import ReactGA from "react-ga";
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  EmailIcon,
  TwitterIcon,
  FacebookIcon,
} from "react-share";
import './change-button.css';

const trigger = (
  <div style={{color: '#575757'}} className="grid-sm">
    <Icon size='large' name='share square' />
    <p style={{fontFamily: 'Karla'}}>Share Listing</p>
  </div>
);

const size = 20;
const borderRadius = 10;

const ShareButton = ({post, shareDisabled}) => {
  const shareUrl = window.location.href;

  const shareStyle = {
    display: "inline-block",
    cursor: shareDisabled ? "original" : "pointer",
    outline: "none",
  };

  const iconAndNoteStyle = {
    display: "inline-block",
    margin: "4px",
  };

  const noteStyle = {
    display: "inline-block",
    margin: "4px",
    position: "relative",
    bottom: "4px",
  };

  const handleOutbound = (eventLabel) => {
    if (eventLabel) {
      ReactGA.event({
        category: "Outbound",
        action: "click",
        label: eventLabel,
      });
    }
  };


  return (
    <div
      id="share-button"
    >
      <Dropdown
        trigger={trigger}
        icon={null}
        floating
        labeled
        pointing="top left"
        className="icon"
        disabled={shareDisabled}
      >
        <Dropdown.Menu>
          <Dropdown.Item>
            <FacebookShareButton
              style={shareStyle}
              url={shareUrl}
              quote={`AssistList Listing: ${post.title}`}
              beforeOnClick={() => handleOutbound(`${shareUrl}-facebook`)}
            >
              <div style={iconAndNoteStyle}>
                <FacebookIcon
                  size={size}
                  borderRadius={borderRadius}
                  style={iconAndNoteStyle}
                />
              </div>
              <div style={noteStyle}>Facebook</div>
            </FacebookShareButton>
          </Dropdown.Item>
          <Dropdown.Item>
            <TwitterShareButton
              style={shareStyle}
              title={`AssistList Listing: ${post.title}`}
              url={shareUrl}
              beforeOnClick={() => handleOutbound(`${shareUrl}-twitter`)}
            >
              <div style={iconAndNoteStyle}>
                <TwitterIcon
                  size={size}
                  borderRadius={borderRadius}
                  style={iconAndNoteStyle}
                />
              </div>
              <div style={noteStyle}>Twitter</div>
            </TwitterShareButton>
          </Dropdown.Item>
          <Dropdown.Item>
            <EmailShareButton
              style={shareStyle}
              url={shareUrl}
              subject={`AssistList Listing: ${post.title}`}
              beforeOnClick={() => handleOutbound(`${shareUrl}-email`)}
            >
              <div style={iconAndNoteStyle}>
                <EmailIcon
                  size={size}
                  borderRadius={borderRadius}
                  style={iconAndNoteStyle}
                />
              </div>
              <div style={noteStyle}>Email</div>
            </EmailShareButton>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
const mapStateToProps = (state) => ({
  post: state.post.details,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPost: (id) => dispatch(id),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareButton);
