import React from 'react';
import './wizard.css';

class CategoryDesc extends React.Component {
  render() {
    const { category } = this.props;
    let tooltip_text = category.tooltip && category.tooltip.text;
    return (
      <p className='equipmentCategoryDescriptions' style={{marginBottom: '39px'}}>
        { tooltip_text ? (<span key={tooltip_text}> {tooltip_text}</span>) : null }
      </p>
    );
  }
}

export default CategoryDesc
