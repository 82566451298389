import React, { Component } from "react";
import { List } from "semantic-ui-react";
import { HashLink as Link } from "react-router-hash-link";
import './post.css';

export class Guidelines extends Component {
  render() {
    return (
      <div className="subtext" style={{marginLeft: window.innerWidth > 600? null : '-16px' }}>
        <p style={{color: '#000000'}}><b>Guidelines</b></p>
        <p>
          When you contact a Seller using this form, you will be sharing your
          email address with them. We suggest that you view:
        </p>
        <List bulleted>
          <List.Item>
            Our <Link to="/resources#faq-safety"> Safety Tips</Link> for email and equipment exchanges
          </List.Item>
          <List.Item>
            Our list of <Link to="/resources/exchangezones"> Safe Exchange Zones</Link> offered by AssistList Partners
          </List.Item>
          <List.Item>
            Our list of <Link to="/resources/additionalservices"> Additional Services</Link> offered by AssistList Partners, such as equipment repair and
            delivery
          </List.Item>
        </List>

        <p style={{color: '#000000', marginTop: '32px'}}><b>COVID-19 guidelines</b></p>
        <p>For more <Link to='/resources/healthandsafety'>COVID-19 related questions</Link> refer to our FAQ</p>
        <List bulleted>

          <List.Item>
            Stay home if sick
          </List.Item>
          <List.Item>
            If selling or buying an item, clean it well first
          </List.Item>
          <List.Item>
            If meeting in person, consider an outdoor location
          </List.Item>
          <List.Item>
            Wash or sanitize hands before and after meeting up
          </List.Item>
          <List.Item>
            Practice physical distancing - stay 2m apart
          </List.Item>
          <List.Item>
            Wear a mask
          </List.Item>
        </List>

      </div>
    );
  }
}

export default Guidelines;