import React from "react";
import { Header, Icon, Accordion } from "semantic-ui-react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

// treats \n as <br>
const renderOptions = {
  renderText: (text) => {
    return text.split("\n").reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
};

class FaqCategory extends React.Component {
  state = { activeIndex: [] };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  openFirst = () => {
    this.setState({ activeIndex: 0 });
  };

  componentDidMount() {
    const { faq_category } = this.props;
    window.location.href.includes("covid-19-safety-precautions") &&
      faq_category.title.includes("COVID-19") &&
      this.openFirst();
    const elem = document.getElementById("covid-19-safety-precautions");
    if (elem) {
      elem.scrollIntoView();
    }
  }

  render() {
    const { activeIndex } = this.state;
    const { faq_category } = this.props;
    let accordionItemIndex = -1;

    return (
      <div>
        <div style={{ marginBottom: "20px" }}>
          <Header
            as="h5"
            id={
              faq_category.title.includes("COVID-19")
                ? "covid-19-safety-precautions"
                : faq_category.title.includes("Safety")
                ? "faq-safety"
                : "faq-assisting"
            }
            content={
              faq_category.title === "Contact Us FAQs" ? "" : faq_category.title
            }
          />
          <Accordion fluid styled>
            {faq_category.questions.map((item) => (
              <div key={item.title}>
                <Accordion.Title
                  index={++accordionItemIndex}
                  active={activeIndex === accordionItemIndex}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  {item.title}
                </Accordion.Title>

                <Accordion.Content active={activeIndex === accordionItemIndex}>
                  {
                    // FIXME: remove if else after moving health and safety Faqs to use Contentful
                    item.body ?
                    documentToReactComponents(item.body, renderOptions) :
                    <div dangerouslySetInnerHTML={{ __html: item.answer }}></div>
                  }
                </Accordion.Content>
              </div>
            ))}
          </Accordion>
        </div>
      </div>
    );
  }
}

export default FaqCategory;
