import React, { useEffect } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import {
  Form, Button, Icon, Header, Responsive, Grid
} from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';

import { IconTextField, CheckboxField, RecaptchaField } from '../../utils/render-input';

import familyIllustration from '../../images/family_illustration.png';
import googleLogo from '../../images/googleLogo.png';
import { signup } from '../../actions/user';

import config from '../../config';
import './authentication.css'


import { formValidator } from '../../utils/FormValidator';


const btnStyle = {
  maxWidth: '180px',
  minHeight: '30px',
  marginBottom: '24px',
  marginTop: '5px',
  backgroundColor: '#1A659F',
};



const mediaBtnStyle = {
  color: '#323633',
  maxWidth: '270px',
  marginBottom: '20px',
  padding: '12px 0px',
};

const SignupForm = ({ style, error, handleSubmit, submitting, toggleHide, isHide }) => {

  useEffect(() => {
    if (!isHide) toggleHide()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid style={style}>
      <Grid.Row>
        <Grid.Column computer={8} tablet={16} mobile={16}>
          <div style={window.innerWidth <= Responsive.onlyTablet.maxWidth ? { width: '100%' } : { width: '82%' }}>
            <Link to="/" style={{ color: 'rgba(0,0,0,.87)' }}><Icon link name='chevron left' />Back to Home </Link>
            <Header as='h4' style={{ marginTop: '40px' }} hidden={window.innerWidth <= Responsive.onlyTablet.maxWidth ? false : true}>
              Already a member?
              <a className='linkHover' href="#login" style={{ color: '#1A659F' }}> Log in</a>.
            </Header>
            <Header as='h1' style={{ color: '#1A659F', fontWeight: 'bold', marginTop: '40px' }}>
              Join Hundreds of Local Users
            </Header>
            <p style={{ color: 'black', fontSize: '18px', marginTop: '30px' }}>To create listings or contact someone with equipment listed, sign up for a free account.</p>
            <img style={{ marginTop: '50px', width: '100%' }} hidden={window.innerWidth <= Responsive.onlyTablet.maxWidth ? true : false} src={familyIllustration} alt="Family Gathering" />
          </div>
        </Grid.Column>

        <Grid.Column computer={8} tablet={16} mobile={16}>
          <Header hidden={window.innerWidth <= Responsive.onlyTablet.maxWidth ? true : false} as='h4'>
            Already a member?
            <a className='linkHover' href="#login" style={{ color: '#1A659F' }}> Log in</a>.
          </Header>
          <Header as='h2' style={{ color: '#1A659F', fontWeight: 'bold', marginTop: '50px', marginBottom: '24px' }}>Create Account </Header>
          <Form name="signup" onSubmit={handleSubmit(signup)}>
            <Field component={IconTextField} style={window.innerWidth <= Responsive.onlyMobile.maxWidth ? { marginBottom: '12px' } : { marginBottom: '12px', width: '80%' }}
              label="Email address" name="email" type="email" placeholder="example@gmail.com" autoComplete="email" className=".fs-exclude" />
            
            <Field component={IconTextField} style={window.innerWidth <= Responsive.onlyMobile.maxWidth ? { marginBottom: '12px' } : { marginBottom: '12px', width: '80%' }}
              label="Password" name="password" icon={isHide ? <Icon name='hide' onClick={toggleHide} link /> : <Icon name='unhide' onClick={toggleHide} link />}
              autoComplete="current-password" type={isHide ? "password" : "text"} />
            
            <Field component={IconTextField} style={window.innerWidth <= Responsive.onlyMobile.maxWidth ? { marginBottom: '12px' } : { marginBottom: '12px', width: '80%' }}
              label="Reconfirm password" name="password_confirmation" icon={isHide ? <Icon name='hide' onClick={toggleHide} link /> : <Icon name='unhide' onClick={toggleHide} link />}
              type={isHide ? "password" : "text"} autoComplete="new-password" />
            <p style={{ width: '80%', marginBottom: '48px' }}>Minimum 8 characters must include: a lower and uppercase letter, a number, and a symbol (!@#$&amp;*)</p>
            
            <Field component={RecaptchaField} name="recaptcha" />

            <Field
              component={CheckboxField}
              name="newsletter_subscribed"
              label={<label><strong>Yes, I would also like to receive e-newsletters (optional)</strong></label>}
            />
            <p style={{ marginLeft: '32px', marginBottom: '30px' }}>E-newsletter may include recent listings, tips on how to sell, donate and buy, and surveys to improve AssistList.</p>

            <Field
              component={CheckboxField}
              name="termsOfUse"
              label={<label style={{ marginBottom: '16px' }}>I agree to the <Link className='linkHover' style={{ color: '#1A659F', fontWeight: 'bold' }} to="/policies#terms-of-use" target="_blank">Terms of Use <span style={{ color: 'red' }}>*</span></Link></label>}
            />
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <br></br>

            <Form.Button
              primary
              id='submitButton'
              fluid
              type="submit"
              content="Create Account"
              loading={submitting}
              style={btnStyle}
            />
          </Form>

          <br></br>
          <div class="ui divider"></div>
          <p style={{ marginBottom: '30px' }}>Sign up with your social account</p>
          <Grid>
            <Grid.Row>
              <Grid.Column computer={7} tablet={6} mobile={16}>
                <Button
                  fluid
                  basic
                  icon={<img src ={googleLogo} alt ='Google Icon' style={{ width: '19px', marginRight: '13px', verticalAlign: 'middle'}} />}
                  content={<span style={{ verticalAlign: 'middle', display: 'inline-block', marginTop: '1px'}}>Continue with Google</span>}
                  style={{...mediaBtnStyle,  display: 'flex', justifyContent: 'center', alignItems: 'center', height: '41px' }}
                  onClick={() => window.open(config.googleOauthUrl, '_self')}
                />
              </Grid.Column>
              <Grid.Column computer={7} tablet={6} mobile={16}>
                <Button
                  fluid
                  basic
                  icon={<Icon name="facebook f" className="social-icon-color" style={{ color: '#4881dc' }} />}
                  style={mediaBtnStyle}
                  content={<span style={{ paddingRight: '7px' }}>
                    Sign up with Facebook</span>}
                  onClick={() => window.open(config.facebookOauthUrl, '_self')}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
};

export default reduxForm({
  form: 'signup',
  validate: formValidator,
})(SignupForm);
