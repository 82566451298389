import React from 'react'
import { Container, Form, Button, Input, Grid, Modal, Icon, Responsive, Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

class SearchBar extends React.Component {
	constructor() {
		super()
		this.state = {
			activeOption: null,
			filteredOptions: [],
			showOptions: false,
			userInput: '',
			openModal: false,
		}
	}

	clearOptions = () => {
		this.setState({
			activeOption: null,
			filteredOptions: [],
			showOptions: true,
		})
	}

	onChange = (e) => {
		const { categories, equipmentTypes } = this.props
		const autocomplete = [...categories, ...equipmentTypes]

		const userInput = e.currentTarget.value

		const filteredOptions = autocomplete.filter(
			(optionName) => optionName.toLowerCase().indexOf(userInput.toLowerCase()) > -1
		)

		this.setState({
			activeOption: null,
			filteredOptions,
			showOptions: true,
			userInput: e.currentTarget.value,
		})
	}

	onClick = (e) => {
		this.clearOptions()
		this.setState({
			userInput: e.currentTarget.innerText,
		})
	}

	onKeyDown = (e) => {
		const { activeOption, filteredOptions, userInput } = this.state

		if (e.keyCode === 13) {
			let searchTerm = ''
			if (activeOption !== null) {
				searchTerm = filteredOptions[activeOption]
			} else if (userInput.length > 0) {
				searchTerm = userInput
			}

			this.setState({
				activeOption: null,
				showOptions: false,
				userInput: searchTerm,
			})
		} else if (e.keyCode === 38) {
			if (activeOption === -1) {
				return
			}
			this.setState({ activeOption: activeOption - 1 })
		} else if (e.keyCode === 40) {
			if (activeOption === filteredOptions.length - 1) {
				return
			}
			if (activeOption === null) {
				this.setState({ activeOption: 0 })
				return
			}
			this.setState({ activeOption: activeOption + 1 })
		}
	}

	openModal = () => {
		this.setState({ openModal: true })
	}

	closeModal = () => {
		this.setState({ openModal: false })
	}

	render() {
		const { addSearchQuery } = this.props

		const {
			onChange,
			onClick,
			onKeyDown,
			state: { activeOption, filteredOptions, showOptions, userInput },
		} = this

		let equipmentOptionsList

		if (showOptions && userInput) {
			if (filteredOptions.length) {
				equipmentOptionsList = (
					<ul className="autocomplete-options">
						{filteredOptions.map((optionName, index) => {
							let className = 'dropdown-item'
							if (index === activeOption) {
								className += 'autocomplete-option-active'
							}
							return (
								<li className={className} key={optionName} onClick={onClick}>
									{optionName}
								</li>
							)
						})}
					</ul>
				)
			}
		}

		return (
			<Container>
				<Grid stackable>
					<Grid.Column computer={12} tablet={10} mobile={16}>
						<Responsive minWidth={Responsive.onlyComputer.minWidth}>
							<div
								style={{
									position: 'relative',
									top: '-85px',
									backgroundColor: 'white',
									borderRadius: '5px',
								}}
							>
								<Segment>
									<h3 style={{ marginBottom: '12px', fontWeight: 'bold' }}>Search for equipment</h3>
									<Form
										onSubmit={() => {
											addSearchQuery(this.state.userInput)
											this.clearOptions()
										}}
									>
										<Grid>
											<Grid.Row style={{ paddingLeft: '1rem' }}>
												<Grid.Column width={12} style={{ paddingRight: '0px', paddingLeft: '0px' }}>
													<Input
														style={{ height: '38px' }}
														fluid
														type="text"
														autoFocus
														placeholder="I am looking for..."
														onChange={onChange}
														onKeyDown={onKeyDown}
														value={userInput}
													/>
													{equipmentOptionsList}
												</Grid.Column>
												<Grid.Column width={4}>
													<Button fluid primary type="submit" content="Go" />
												</Grid.Column>
											</Grid.Row>
										</Grid>
									</Form>
								</Segment>
							</div>
						</Responsive>
						<Responsive maxWidth={Responsive.onlyTablet.maxWidth}>
							<div
								style={{
									position: 'relative',
									top: '-60px',
									backgroundColor: 'white',
									borderRadius: '5px',
								}}
							>
								<Segment>
									<h3 style={{ marginBottom: '12px', fontWeight: 'bold' }}>Search for equipment</h3>

									<Input
										type="text"
										fluid
										onClick={this.openModal}
										autoFocus
										placeholder="I am looking for..."
										action={
											<Button primary fluid type="submit" content="Go" style={{ width: '90px', paddingLeft: '34px' }} />
										}
									/>
									<Modal style={{ padding: '12px' }} closeOnDimmerClick={false} open={this.state.openModal}>
										<h3
											style={{
												color: 'black',
												paddingLeft: '17px',
												position: 'relative',
												top: '20px',
												fontWeight: 'bold',
											}}
										>
											Search for equipment
										</h3>
										<Icon name="close" size="large" onClick={this.closeModal} />
										<Modal.Content>
											<div className="autocomplete-container">
												<Form
													onSubmit={() => {
														addSearchQuery(this.state.userInput)
														this.clearOptions()
														this.closeModal()
													}}
												>
													<div className="autocomplete-container">
														<Input
															type="text"
															fluid
															autoFocus
															placeholder="Search for  equipment"
															onChange={onChange}
															onKeyDown={onKeyDown}
															value={userInput}
														/>
														{equipmentOptionsList}
													</div>
													<br />
													<Button
														primary
														fluid
														type="submit"
														content="Go"
														style={{ width: '90px', textAlign: 'center', margin: 'auto' }}
													/>{' '}
												</Form>
											</div>
										</Modal.Content>
									</Modal>
								</Segment>
							</div>
						</Responsive>
					</Grid.Column>
					<Grid.Column computer={4} tablet={6} mobile={16}>
						<Responsive minWidth={Responsive.onlyComputer.minWidth}>
							<div
								style={{
									position: 'relative',
									top: '-85px',
									backgroundColor: 'white',
									borderRadius: '5px',
									maxWidth: '100%',
								}}
							>
								<Segment>
									<h3 style={{ marginBottom: '12px', fontWeight: 'bold' }}>Sell equipment</h3>
									<Form>
										<Grid>
											<Grid.Row>
												<Grid.Column>
													<Button fluid primary as={Link} to="/wizard" content="Create Listing" />
												</Grid.Column>
											</Grid.Row>
										</Grid>
									</Form>
								</Segment>
							</div>
						</Responsive>
						<Responsive minWidth={Responsive.onlyTablet.minWidth} maxWidth={Responsive.onlyTablet.maxWidth}>
							<div
								style={{
									position: 'relative',
									top: '-60px',
									backgroundColor: 'white',
									borderRadius: '5px',
									maxWidth: '100%',
									marginBottom: '16px',
								}}
							>
								<Segment>
									<h3 style={{ marginBottom: '12px', fontWeight: 'bold' }}>Sell equipment</h3>
									<Button fluid primary as={Link} to="/wizard" content="Create Listing" />
								</Segment>
							</div>
						</Responsive>
						<Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
							<div
								style={{
									position: 'relative',
									top: '-70px',
									backgroundColor: 'white',
									borderRadius: '5px',
									maxWidth: '100%',
								}}
							>
								<Segment>
									<h3 style={{ marginBottom: '12px', fontWeight: 'bold' }}>Sell equipment</h3>
									<Button fluid primary as={Link} to="/wizard" content="Create Listing" />
								</Segment>
							</div>
						</Responsive>
					</Grid.Column>
				</Grid>
			</Container>
		)
	}
}

export default SearchBar
