import React from 'react';

import { Helmet } from 'react-helmet';
import { Button, Segment, Header, Message } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { resendCurrentUserConfirmation } from '../../actions/user';

import { reduxForm } from 'redux-form';

const modalHeaderText = {
  color: '#1A659F', 
  fontWeight: 'bold', 
  margin: '0 auto',
  marginTop: '45px'
};

const VerifyEmailModal = ({handleSubmit, handleClickContinue, user}) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Verify your email</title>
      </Helmet>

        <Segment style={{borderRadius: '10px'}} textAlign="center">
          <Header as='h1' style={modalHeaderText}>
            Verify your email
          </Header>

          <p style={{ 
            fontSize: '18px',
            marginTop: '40px',
            color: 'black',
            textAlign: 'left',
            paddingLeft: '30px',
            paddingRight: '30px'
          }}>
            We have sent you an email verification, please check 
            your email inbox and verify before continuing on 
            the AssistList website.
          </p>

          <p style={{ 
            fontSize: '18px',
            color: 'black',
            textAlign: 'left',
            paddingLeft: '30px',
            paddingRight: '30px'
          }}>
            If you have not received your email confirmation, 
            we can send another one.&nbsp;
            <span onClick={handleSubmit(resendCurrentUserConfirmation)}
            style={{textDecoration: 'underline', color: '#1A659F', cursor: 'pointer'}}>
              Resend email verification
            </span>.
          </p>

            {user.confirmationResent && (
              <Message style={{marginTop: '20px', marginBottom: '0px', marginLeft: '30px', marginRight: '30px'}} success content="Confirmation email sent." />
              )}

          <Button
            onClick={handleClickContinue}
            style={{
              backgroundColor: '#1A659F',
              color: 'white',
              borderRadius: '6px',
              marginBottom: '50px',
              marginTop: '22px'
            }}
          >
            Continue to AssistList
          </Button>

        </Segment>
        
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  user: state.user,
});


export default connect(mapStateToProps, null)(reduxForm({ form: 'resendConfirmationForm'})(VerifyEmailModal));
