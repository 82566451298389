const FETCH_SAFE_EXCHANGE_ZONES = 'safe_exchange_zones/FETCH_SAFE_EXCHANGE_ZONES';
const FETCH_SAFE_EXCHANGE_ZONES_SUCCESS = 'safe_exchange_zones/FETCH_SAFE_EXCHANGE_ZONES_SUCCESS';
const FETCH_SAFE_EXCHANGE_ZONES_FAILURE = 'safe_exchange_zones/FETCH_SAFE_EXCHANGE_ZONES_FAILURE';

const FETCH_SERVICES = 'services/FETCH_SERVICES';
const FETCH_SERVICES_SUCCESS = 'services/FETCH_SERVICES_SUCCESS';
const FETCH_SERVICES_FAILURE = 'services/FETCH_SERVICES_FAILURE';

export default {
  FETCH_SAFE_EXCHANGE_ZONES,
  FETCH_SAFE_EXCHANGE_ZONES_SUCCESS,
  FETCH_SAFE_EXCHANGE_ZONES_FAILURE,
  FETCH_SERVICES,
  FETCH_SERVICES_SUCCESS,
  FETCH_SERVICES_FAILURE,
};
