import React from 'react';
import { Switch } from 'react-router-dom';
import ResourcesLanding from './ResourcesLanding';
import ExchangeZones from './ExchangeZones';
import AdditionalServices from './AdditionalServices';
import HowItWorks from './HowItWorks';
import HealthAndSafety from './HealthAndSafety/index';


const Resources = () => {

  return (
    <div style={{ background: '#ffffff' }}>
      <Switch>
        <ResourcesLanding exact path='/resources' />
        <ExchangeZones exact path='/resources/exchangezones' />
        <AdditionalServices exact path='/resources/additionalservices' />
        <HowItWorks exact path='/resources/howitworks' />
        <HealthAndSafety exact path='/resources/healthandsafety' />
      </Switch>
    </div>
  )
};

export default Resources;
