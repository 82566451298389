import React from "react";
import { Button } from "semantic-ui-react";
import ReactGA from "react-ga";
import { connect } from 'react-redux';

import NotificationModal, { MODAL_ID } from "./index";
import { showModal } from '../../actions/modal';

const NotificationButton = (props) => {
  const clickEvents = () => {
    props._showModal(MODAL_ID);
    if (localStorage.user) {
      localStorage.setItem("gaEventFlow", "notificationBtnLoggedIn");
      ReactGA.event({
        category:
          "User with Account - Already logged in - Subscribe to Notification Btn clicked",
        action: "click",
        label: "Subscribe to Notification Btn clicked",
      });
    } else {
      localStorage.setItem("gaEventFlow", "notificationBtnNotLoggedIn");
    }
  };

  return (
    <div>
      <Button onClick={() => clickEvents()} {...props} />
      <NotificationModal initialValues={props.initialValues} />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  _showModal: (name) => dispatch(showModal(name)),
})

export default connect(null, mapDispatchToProps)(NotificationButton)