import React from 'react'
import { Link } from 'react-router-dom'
import { Segment, Item, Icon, Header, Loader, Divider } from 'semantic-ui-react'
import { InfiniteScroll } from 'react-simple-infinite-scroll'
import PostsListDropdown from '../PostsListDropdown'
import AddToHomeScreen from './AddToHomeScreen'
import NotificationSuggestion from './NotificationSuggestion'
import { timeElapseUTCDate } from '../../utils/date'

import './index.css'

import noImage from '../../images/no_image.png'

let editedPostList = []
const showNotifyMeEvery = 24

const displayAddToHomeScreen = () => {
	if (!localStorage.getItem('prompt')) {
		const timer = { value: 'addToHomeScreenCountdown', timestamp: new Date().getTime() }
		localStorage.setItem('prompt', JSON.stringify(timer))
		return true
	}
	const countdown = JSON.parse(localStorage.getItem('prompt')).timestamp
	const now = new Date().getTime()
	const oneDay = 86400000
	if (now - countdown > oneDay) {
		const newCountdownTime = { value: 'addToHomeScreenCountdown', timestamp: new Date().getTime() }
		localStorage.setItem('prompt', JSON.stringify(newCountdownTime))
		return true
	}
	return false
}

const createPostList = (posts, profile, user, onShowModal) => {
	editedPostList = []
	const now = new Date(Date.now())

	for (let index = 0; index < posts.length; index++) {
		const post = posts[index]

		if (!profile) {
			// insert Notify Me button, on 4th and every 24th
			if (index === 3 || (index >= showNotifyMeEvery && index % showNotifyMeEvery === 0)) {
				editedPostList.push(
					<Item style={{ minHeight: '193px' }} key={'notify_suggestion_' + index}>
						<NotificationSuggestion
							user={user}
							onShowModal={(e) => {
								onShowModal(e)
							}}
						/>
					</Item>
				)
			}
		}

		const imgAlt = `${post.category.name.toLowerCase()}-${post.title.toLowerCase()}`
		const postUpdatedAt = new Date(post.updated_at_utc)
		const timeDiffDisplay = timeElapseUTCDate(now, postUpdatedAt)

		editedPostList.push(
			<Item key={'post_' + post.id} as={Link} to={`/posts/${post.id}`}>
				<Item.Image style={{ width: '160px' }} src={post.images ? post.images.thumbnail : noImage} alt={imgAlt} />
				<Item.Content verticalAlign="top" className={'post-list-item-content'}>
					{profile && (
						<Item.Meta floated="right" computer={2} mobile={6} textAlign="right">
							<PostsListDropdown
								post={post}
								dropdown={{
									floating: true,
									color: 'blue',
									basic: true,
									button: true,
									pointing: 'right',
									text: ' ',
									className: 'icon',
									floated: 'right',
								}}
							/>
						</Item.Meta>
					)}
					<Item.Meta>
						<Icon style={{ color: 'rgba(0, 173, 216, 0.6)' }} disabled name="marker" />
						<span>{`${post.location.name}`}</span>
					</Item.Meta>
					<Item.Description style={{ marginTop: '4px' }}>
						<h3>
							<span>{post.title.length > 70 ? `${post.title.substring(0, 70)}...` : post.title}</span>
							<span className={'float-right'}>
								<h3 className={!post.price ? 'free' : 'price'}>{!post.price ? 'FREE' : `$${post.price}`}</h3>
							</span>
						</h3>
					</Item.Description>
					<Item.Description style={{ marginTop: '4px' }}>
						{post.description.length > 140 ? `${post.description.substring(0, 140)}...` : post.description}
					</Item.Description>
					<Divider hidden />
					<Item.Meta style={{ marginTop: '2px', marginBottom: '2px' }}>
						<span style={{ position: 'absolute', bottom: 0 }}>Condition: {post.condition.name}</span>
						<span style={{ position: 'absolute', bottom: 0, right: 0 }}>{timeDiffDisplay}</span>
					</Item.Meta>
				</Item.Content>
			</Item>
		)
	}
}

const PostsList = ({
	posts: { posts, pagination, error, loading },
	handlePageChange,
	profile = false,
	user,
	onShowModal,
}) => {
  createPostList(posts, profile, user, onShowModal)

	return (
		<div>
			{displayAddToHomeScreen() && <AddToHomeScreen />}
			<Segment basic disabled={!!error}>
				<Loader style={{ position: 'fixed', bottom: 0 }} key={0} size="large" active={loading} />
				<InfiniteScroll
					onLoadMore={() => handlePageChange(pagination.current_page + 1)}
					hasMore={!pagination.last_page}
					isLoading={loading}
					threshold={25}
				>
					<Item.Group divided>{editedPostList}</Item.Group>
				</InfiniteScroll>
				{error && <Header as="h1" content={error.message} />}
			</Segment>
		</div>
	)
}

export default PostsList
