import api from "../utils/api";
import types from "../types/faq";
import {
  getPageData,
  formatPageData,
  formatReferenceLink,
} from "../utils/contentful";

export const fetchQuestionsHealthSafety = () => (dispatch) => {
  dispatch({ type: types.FETCH_QUESTIONS_HEALTH_SAFETY });
  api
    .get("/faq_categories?type=health_safety")
    .then((response) => {
      return dispatch({
        type: types.FETCH_QUESTIONS_HEALTH_SAFETY_SUCCESS,
        payload: response.data.data,
      });
    })
    .catch((error) => {
      dispatch({ type: types.FETCH_QUESTIONS_FAILURE, payload: error });
    });
};

export const fetchFAQPageData = () => (dispatch) => {
  dispatch({ type: types.FETCH_FAQ_PAGE_DATA });
  let payload = { faqPageData: undefined };

  getPageData("Frequently Asked Questions", 2)
    .then((pageData) => {
      const data = formatPageData(pageData);
      delete data.title; // not needed

      const faqCategories = {};

      Object.entries(data).forEach(([key, cat]) => {
        const title = cat.title;
        const questions = formatReferenceLink(cat.questions, true);
        faqCategories[key] = { title, questions };
      });

      payload.faqPageData = faqCategories;

      dispatch({ type: types.FETCH_FAQ_PAGE_DATA_SUCCESS, payload: payload });
    })
    .catch((err) => {
      dispatch({ type: types.FETCH_FAQ_PAGE_DATA_FAILURE, payload: err });
    });
};

export const fetchFAQContactUsPageData = () => (dispatch) => {
  dispatch({ type: types.FETCH_FAQ_CONTACT_US_PAGE_DATA });
  let payload = { faqContactUsPageData: undefined };

  getPageData("Contact Us Faqs", 2)
    .then((pageData) => {
      const data = formatPageData(pageData);

      delete data.title; // not needed

      const faqCategories = {};

      Object.entries(data).forEach(([key, cat]) => {
        const title = cat.title;
        const questions = formatReferenceLink(cat.questions, true);
        faqCategories[key] = { title, questions };
      });

      payload.faqContactUsPageData = faqCategories;

      dispatch({
        type: types.FETCH_FAQ_CONTACT_US_PAGE_DATA_SUCCESS,
        payload: payload,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.FETCH_FAQ_CONTACT_US_PAGE_DATA_FAILURE,
        payload: err,
      });
    });
};
