const SIGNUP_SUCCESS = 'user/SIGNUP_SUCCESS';
const SIGNUP_FAILURE = 'user/SIGNUP_FAILURE';

const CONFIRMATION_SUCCESS = 'user/CONFIRMATION_SUCCESS';
const CONFIRMATION_FAILURE = 'user/CONFIRMATION_FAILURE';

const RESEND_CONFIRMATION_SUCCESS = 'user/RESEND_CONFIRMATION_SUCCESS';
const RESEND_CONFIRMATION_FAILURE = 'user/RESEND_CONFIRMATION_FAILURE';

const LOGIN_SUCCESS = 'user/LOGIN_SUCCESS';
const LOGIN_FAILURE = 'user/LOGIN_FAILURE';

const OAUTH_LOGIN_SUCCESS = 'user/OAUTH_LOGIN_SUCCESS';
const OAUTH_LOGIN_FAILURE = 'user/OAUTH_LOGIN_SUCCESS';

const ACCOUNT_EXISTS_ERROR = 'user/ACCOUNT_EXISTS_ERROR';
const UNKNOWN_AUTH_ERROR = 'user/UNKNOWN_AUTH_ERROR';

const LOGOUT_SUCCESS = 'user/LOGOUT_SUCCESS';
const LOGOUT_FAILURE = 'user/LOGOUT_FAILURE';

const FETCH_USER_SUCCESS = 'user/FETCH_USER_SUCCESS';
const FETCH_USER_FAILURE = 'user/FETCH_USER_FAILURE';

const SEND_PASSWORD_RESET_SUCCESS = 'user/SEND_PASSWORD_RESET_SUCCESS';
const SEND_PASSWORD_RESET_FAILURE = 'user/SEND_PASSWORD_RESET_FAILURE';

const SET_NEW_PASSWORD_SUCCESS = 'user/SET_NEW_PASSWORD_SUCCESS';
const SET_NEW_PASSWORD_FAILURE = 'user/SET_NEW_PASSWORD_FAILURE';

const CHANGE_PASSWORD_SUCCESS = 'user/CHANGE_PASSWORD_SUCCESS';
const CHANGE_PASSWORD_FAILURE = 'user/CHANGE_PASSWORD_FAILURE';

const SET_RESET_PASSWORD_TOKEN = 'user/SET_RESET_PASSWORD_TOKEN';

const SET_SUBSCRIBE_CLICKED = 'user/SET_SUBSCRIBE_CLICKED';
const REMOVE_SUBSCRIBE_CLICKED = 'user/REMOVE_SUBSCRIBE_CLICKED';

const SET_NEWSLETTER_SUCCESS = 'user/SET_NEWSLETTER_SUCCESS';
const SET_NEWSLETTER_FAILURE = 'user/SET_NEWSLETTER_FAILURE';

const SET_GEO_LOCATION = 'user/SET_GEO_LOCATION';
const SET_GEO_LOCATION_SUCCESS = 'user/SET_GEO_LOCATION_SUCCESS';
const SET_GEO_LOCATION_FAILURE = 'user/SET_GEO_LOCATION_FAILURE';

const SET_SHARING_LOCATION_PERMISSION = 'user/SET_SHARING_LOCATION_PERMISSION';
const SET_SHARING_LOCATION_RADIUS = 'user/SET_SHARING_LOCATION_RADIUS';
const SET_SHARING_LOCATION_MUNICIPAL = 'user/SET_SHARING_LOCATION_MUNICIPAL';

const TURN_OFF_INITIALIZING_LOCATION = 'user/TURN_OFF_INITIALIZING_LOCATION';
const USE_CURRENT_LOCATION = 'user/USE_CURRENT_LOCATION';

export default {
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,

  CONFIRMATION_SUCCESS,
  CONFIRMATION_FAILURE,

  RESEND_CONFIRMATION_SUCCESS,
  RESEND_CONFIRMATION_FAILURE,

  LOGIN_SUCCESS,
  LOGIN_FAILURE,

  OAUTH_LOGIN_SUCCESS,
  OAUTH_LOGIN_FAILURE,

  ACCOUNT_EXISTS_ERROR,
  UNKNOWN_AUTH_ERROR,

  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,

  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,

  SEND_PASSWORD_RESET_SUCCESS,
  SEND_PASSWORD_RESET_FAILURE,

  SET_RESET_PASSWORD_TOKEN,

  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,

  SET_NEW_PASSWORD_SUCCESS,
  SET_NEW_PASSWORD_FAILURE,

  SET_SUBSCRIBE_CLICKED,
  REMOVE_SUBSCRIBE_CLICKED,

  SET_NEWSLETTER_SUCCESS,
  SET_NEWSLETTER_FAILURE,

  SET_GEO_LOCATION,
  SET_GEO_LOCATION_SUCCESS,
  SET_GEO_LOCATION_FAILURE,

  SET_SHARING_LOCATION_PERMISSION,
  SET_SHARING_LOCATION_RADIUS,
  SET_SHARING_LOCATION_MUNICIPAL,

  TURN_OFF_INITIALIZING_LOCATION,

  USE_CURRENT_LOCATION,
};
