import types from '../types/partners';

const partnersReducer = (state = {
  loading: true,
  error: null,
  non_profits_partners: [],
  corporate_partners: [],
  funding_partners: [],
}, action) => {
  switch (action.type) {
    case types.FETCH_PARTNERS:
      return { ...state, loading: true };
    case types.FETCH_PARTNERS_SUCCESS:
      return {
        ...state,
        loading: false,
        non_profits_partners: action.payload.non_profits_partners,
        corporate_partners: action.payload.corporate_partners,
        funding_partners: action.payload.funding_partners,
      };
    case types.FETCH_PARTNERS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default partnersReducer;
