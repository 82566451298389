import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Grid,
  Modal,
  Dropdown,
  Responsive,
  Divider,
  Segment,
} from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import LoginModal from '../Authentication/LoginModal';
import EmailConfirmationBanner from '../EmailConfirmationBanner';
import Filter from './Filter';
import SearchBar from './SearchBar';
import PostsList from './PostsList';
import {
  setSubscribeClicked,
  removeSubscribeClicked,
  setSharingLocationPermission,
  setSharingLocationRadius,
  getCurrentGeoLocation,
} from '../../actions/user';
import NewsletterModal from '../Authentication/NewsletterModal';
import VerifyEmailModal from '../Authentication/VerifyEmailModal';
import BannerBackground from './BannerBackground';
import NotificationSuggestion from './NotificationSuggestion';
import NotificationModal from '../Notification/index';

import './index.css';

const Listings = ({
  addSearchQuery,
  autocompleteCategories,
  autocompleteEquipmentTypes,
  user,
  appliedFilters,
  clearFilter,
  posts,
  addFilter,
  updateAppliedFilters,
  addPriceFilters,
  removeFilter,
  fetchFilteredPosts,
  loadNewFilters,
  pageChange,
  location,
  _setSubscribeClicked,
  _removeSubscribeClicked,
}) => {
  const [open, setOpen] = useState(false);
  const [subscribeModal, setSubscribeModal] = useState(
    location.state === undefined ? false : location.state.subscribeModal,
  );

  const [verifyEmailModal, setVerifyEmailModal] = useState(false);
  const postList = useRef();

  useEffect(() => {
    fetchFilteredPosts(appliedFilters, 1);
    loadNewFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user.allowSharingLocation,
    user.geoLocation,
    user.locationRadius,
    appliedFilters,
  ]);

  const onShowModal = (e) => {
    e.preventDefault();
    localStorage.setItem('gaEventFlow', 'notificationBtnNotLoggedIn');
    setOpen(true);
    _setSubscribeClicked();
  };

  const onCloseModal = () => {
    _removeSubscribeClicked();
    setOpen(false);
    setSubscribeModal(false);
    setVerifyEmailModal(false);
    localStorage.removeItem('justSignedUp');
  };

  const handleClickNotNow = () => {
    setSubscribeModal(false);
  };

  const handleClickContinue = () => {
    setVerifyEmailModal(false);

    localStorage.removeItem('justSignedUp');
  };

  return (
    <div>
      <div>
        <Helmet>
          <title>AssistList - Buy, Sell & Donate Home Health Equipment</title>
          <meta
            name="description"
            content="AssistList is a non-profit organization whose mission is to help Greater Vancouver residents buy, sell and exchange home health equipment."
          />
        </Helmet>
        {user?.confirmed === false ? (
          <EmailConfirmationBanner />
        ) : null}

        <BannerBackground />
      </div>

      <SearchBar
        addSearchQuery={addSearchQuery}
        categories={autocompleteCategories}
        equipmentTypes={autocompleteEquipmentTypes}
      />

      <div className="listings-container">
        <Container>
          <Responsive minWidth={Responsive.onlyComputer.minWidth}>
            <h2>Equipment Listings</h2>
            <div ref={postList} />
            <Grid>
              <Grid.Row style={{ paddingBottom: '0px' }}>
                <Grid.Column style={{ paddingRight: '0px' }}>
                  <h4 style={{ fontWeight: 'bold', display: 'inline' }}>
                    Filters
                  </h4>
                  {appliedFilters.length > 0 ? (
                    <span
                      style={{
                        color: '#1A659F',
                        paddingTop: '9px',
                        marginLeft: '140px',
                        cursor: 'pointer',
                        fontSize: '16px',
                        fontWeight: 'bold',
                      }}
                      onClick={() => clearFilter()}
                    >
                      <u>Clear All</u>
                    </span>
                  ) : null}

                  <Dropdown
                    labeled
                    button
                    className="icon"
                    direction="left"
                    icon="sort"
                    text="Sort By"
                    style={{ float: 'right', top: '-0.5rem' }}
                  >
                    <Dropdown.Menu>
                      {posts.filters &&
                        posts.filters.sorted_by_options.map((item) => {
                          return (
                            <Dropdown.Item
                              key={item.value.toString()}
                              text={item.text}
                              className="dropdown-item"
                              onClick={() => {
                                addFilter({
                                  name: 'sorted_by',
                                  options: posts.filters.sorted_by_options,
                                  value: item.value,
                                });
                              }}
                            />
                          );
                        })}
                    </Dropdown.Menu>
                  </Dropdown>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid>
              <Grid.Row style={{ paddingTop: '0px' }}>
                <Grid.Column computer={4}>
                  <Filter
                    onShowModal={onShowModal}
                    filters={posts.filters}
                    appliedFilters={appliedFilters}
                    addFilter={addFilter}
                    setAppliedFilters={(filters) =>
                      updateAppliedFilters(filters)
                    }
                    addPriceFilters={addPriceFilters}
                    removeFilter={removeFilter}
                    clearFilter={clearFilter}
                    showPopup={
                      Object.keys(posts.posts).length === 0 && !posts.loading
                    }
                    style={{ position: 'relative', top: '30px' }}
                    fetchFilteredPosts={fetchFilteredPosts}
                    loadNewFilters={(isAllow, geoLocation, radius) =>
                      loadNewFilters(isAllow, geoLocation, radius)
                    }
                  />
                </Grid.Column>
                <Grid.Column
                  computer={12}
                  mobile={16}
                  style={{ padding: '0px' }}
                >
                  {Object.keys(posts.posts).length !== 0 && (
                    <PostsList
                      posts={posts}
                      handlePageChange={pageChange}
                      user={user}
                      onShowModal={onShowModal}
                    />
                  )}

                  {Object.keys(posts.posts).length === 0 && !posts.loading && (
                    <Segment>
                      <NotificationSuggestion
                        user={user}
                        onShowModal={onShowModal}
                      />
                    </Segment>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Responsive>

          <Responsive maxWidth={Responsive.onlyTablet.maxWidth}>
            <Grid stackable reversed="computer">
              <Grid.Column
                computer={16}
                mobile={16}
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                <h3>Equipment Listings</h3>
                <div ref={postList} />

                <Grid>
                  <Grid.Column floated="left" width={6}>
                    <Filter
                      onShowModal={onShowModal}
                      filters={posts.filters}
                      appliedFilters={appliedFilters}
                      addFilter={addFilter}
                      setAppliedFilters={(filters) =>
                        updateAppliedFilters(filters)
                      }
                      addPriceFilters={addPriceFilters}
                      removeFilter={removeFilter}
                      clearFilter={clearFilter}
                      showPopup={
                        Object.keys(posts.posts).length === 0 && !posts.loading
                      }
                      style={{ position: 'relative', top: '30px' }}
                      fetchFilteredPosts={fetchFilteredPosts}
                      loadNewFilters={(isAllow, geoLocation, radius) =>
                        loadNewFilters(isAllow, geoLocation, radius)
                      }
                    />
                  </Grid.Column>
                  <Grid.Column floated="right" width={6}>
                    <Dropdown
                      style={{ position: 'absolute', right: '0px' }}
                      labeled
                      button
                      className="icon"
                      direction="left"
                      icon="sort"
                      text="Sort"
                    >
                      <Dropdown.Menu>
                        {posts.filters &&
                          posts.filters.sorted_by_options.map((item) => {
                            return (
                              <Dropdown.Item
                                key={item.value.toString()}
                                text={item.text}
                                className="dropdowntem"
                                onClick={() => {
                                  addFilter({
                                    name: 'sorted_by',
                                    options: posts.filters.sorted_by_options,
                                    value: item.value,
                                  });
                                }}
                              />
                            );
                          })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Grid.Column>
                </Grid>
                <Divider hidden />
              </Grid.Column>

              <Grid.Column
                computer={10}
                mobile={16}
                style={{ padding: '0px', float: 'right' }}
              >
                {Object.keys(posts.posts).length !== 0 && (
                  <PostsList
                    posts={posts}
                    handlePageChange={pageChange}
                    user={user}
                    onShowModal={onShowModal}
                  />
                )}

                {Object.keys(posts.posts).length === 0 && !posts.loading && (
                  <Segment>
                    <NotificationSuggestion
                      user={user}
                      onShowModal={onShowModal}
                    />
                  </Segment>
                )}
              </Grid.Column>
            </Grid>
          </Responsive>
          <Modal
            closeIcon
            open={open && !user.success}
            onClose={onCloseModal}
            size="small"
          >
            <Modal.Content>
              <LoginModal />
            </Modal.Content>
          </Modal>
          <Modal
            open={subscribeModal && !user.newsletter_subscribed}
            onClose={onCloseModal}
            basic
            size="small"
          >
            <Modal.Content>
              <NewsletterModal handleClickNotNow={handleClickNotNow} />
            </Modal.Content>
          </Modal>
          <Modal
            open={verifyEmailModal}
            onClose={onCloseModal}
            basic
            size="small"
          >
            <Modal.Content>
              <VerifyEmailModal
                user={user}
                handleClickContinue={handleClickContinue}
              />
            </Modal.Content>
          </Modal>
          <NotificationModal initialValues={{}} />
        </Container>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  posts: state.listings,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  _setSubscribeClicked: () => dispatch(setSubscribeClicked()),
  _removeSubscribeClicked: () => dispatch(removeSubscribeClicked()),
  _setSharingLocationPermission: (isAllow, geoLocation) =>
    dispatch(setSharingLocationPermission(isAllow, geoLocation)),
  _setSharingLocationRadius: (radius) =>
    dispatch(setSharingLocationRadius(radius)),
  _getCurrentGeoLocation: () => dispatch(getCurrentGeoLocation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Listings);
