import api from '../utils/api';
import types from '../types/partners';

export const fetchPartners = () => (dispatch) => {
  dispatch({ type: types.FETCH_PARTNERS });
  api.get('/partners')
    .then(response => dispatch({ type: types.FETCH_PARTNERS_SUCCESS, payload: response.data.data }))
    .catch((error) => {
      dispatch({ type: types.FETCH_PARTNERS_FAILURE, payload: error });
    });
};
