import { Container, Message, Sticky, Transition } from "semantic-ui-react";

import React from "react";
import { connect } from "react-redux";
import { detectIE } from "../utils/browser-detection";
import { dismissAlert } from "../actions/alerts";

const transitionPeriod = 500;

class Alert extends React.Component {
  constructor() {
    super();

    this.state = { visible: true, copied: false };

    this.hideAlert = () => this.setState({ visible: false });
  }

  render() {
    const { id, type, header, flowId, message, dismissAfter } = this.props;

    const { visible, copied } = this.state;

    const typeProp = { [type]: true };

    const copyToClipboard = () => {
      let copyText = document.getElementById("errorCode").innerHTML;
      const el = document.createElement("input");
      el.value = copyText;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      this.setState({ copied: true });
    };

    if (dismissAfter) {
      setTimeout(() => this.hideAlert(), dismissAfter);
    }

    return (
      <Transition visible={visible}>
        <Message
          key={id}
          onDismiss={this.hideAlert}
          {...typeProp}
          header={header}
          content={() => {
            return (
              <React.Fragment>
                {message}
                {flowId && (
                  <React.Fragment>
                    <p style={{ fontWeight: 700 }}>
                      Error Code: <span id="errorCode">{flowId}</span>
                    </p>
                    <button
                      onClick={copyToClipboard}
                    >
                      Copy Error Code to clipboard
                    </button>
                    {copied && (
                      <span style={{ color: "red", marginLeft: "10px" }}>
                        Copied.
                      </span>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            );
          }}
        />
      </Transition>
    );
  }
}

const AlertsContainer = ({ alerts, _handleDismiss, context }) => (
  <Sticky context={context} offset={75}>
    <Transition.Group
      animation="slide down"
      duration={transitionPeriod}
      as={Container}
      fluid
      style={{ padding: "0px 50px", zIndex: 999, position: "fixed" }}
    >
      {detectIE() && (
        <Message
          error
          content="Please note, this website does not work on Internet Explorer. Please use Chrome/Firefox/Safari."
        />
      )}
      {alerts.map(alert => (
        <Alert {...alert} handleDismiss={_handleDismiss} />
      ))}
    </Transition.Group>
  </Sticky>
);

const mapStateToProps = state => ({
  alerts: state.alerts
});

const mapDispatchToProps = dispatch => ({
  _handleDismiss: id => dispatch(dismissAlert(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertsContainer);
