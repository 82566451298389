import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import {
  Tab, Segment, Form, Button, Icon, Divider, Header,
} from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';

import { IconTextField, CheckboxField, RecaptchaField } from '../../utils/render-input';

import { signup } from '../../actions/user';

import config from '../../config';

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Email address is required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }

  if (!values.password) {
    errors.password = 'Password is required';
  } else if (values.password.length < 8) {
    errors.password = 'Password must be at least 8 characters long';
  } else if (!/(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z])/.test(values.password)) {
    errors.password = 'Password must include: a lower and uppercase letter, a number, and a symbol (!@#$&*)';
  }

  if (values.password_confirmation !== values.password) {
    errors.password_confirmation = 'Passwords do not match';
  }

  if (!values.recaptcha) {
    errors.recaptcha = 'Please check the reCAPTCHA box';
  }

  if (!values.termsOfUse) {
    errors.termsOfUse = 'Please agree to the Terms of Use';
  }

  return errors;
};

const btnStyle = {
  maxWidth: '290px',
  minHeight: '40px',
  margin: '0 auto',
};

const formStyle = {
  maxWidth: '456px',
  margin: '0 auto',
};

const SimpleSignupForm = ({ error, handleSubmit, submitting }) => (
  <Tab.Pane>
    <Segment vertical style={formStyle}>
      <p style={{ color: 'rgba(0,0,0,.6)' }}>To create listings or contact someone with equipment listed, sign up for a free account.</p>
      <Form name="signup" onSubmit={handleSubmit(signup)}>

        <Field component={IconTextField} name="email" icon="user" placeholder="Email address" autoComplete="email" className=".fs-exclude" />

        <Field component={IconTextField} name="password" type="password" icon="lock" placeholder="Enter your password" autoComplete="new-password" />

        <Field component={IconTextField} name="password_confirmation" type="password" icon="repeat" placeholder="Confirm your password" autoComplete="new-password" />

        <Field component={RecaptchaField} name="recaptcha" />
        <Field
            component={CheckboxField}
            name="newsletter_subscribed"
            label={<label>Yes, I would also like to receive e-newsletters (optional)</label>}
        />
        <Field
          component={CheckboxField}
          name="termsOfUse"
          label={<label>I agree to the <Link to="/policies#terms-of-use" target="_blank"><strong>Terms of Use</strong></Link></label>}
        />

        {error && <p style={{ color: 'red' }}>{error}</p>}

        <Form.Button
          primary
          fluid
          type="submit"
          content="Sign up"
          loading={submitting}
          style={btnStyle}
        />
      </Form>

      <Divider horizontal>
        <Header as="h4" disabled>Or</Header>
      </Divider>

      <Button
        fluid
        color="facebook"
        icon={<Icon name="facebook f" className="social-icon-color" />}
        content="Sign up with Facebook"
        onClick={() => window.open(config.facebookOauthUrl, '_self')}
        style={btnStyle}
      />
      <br />
      <Button
        fluid
        color="google plus"
        icon={<Icon name="google" className="social-icon-color" />}
        content="Sign up with Google"
        onClick={() => window.open(config.googleOauthUrl, '_self')}
        style={btnStyle}
      />
    </Segment>
  </Tab.Pane>
);

export default reduxForm({
  form: 'signup',
  validate,
})(SimpleSignupForm);
