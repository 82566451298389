import types from '../types/relatedlistings';

const relatedlistingsReducer = (state = {
  loading: true,
  relatedlistings: [],
  error: null,
}, action) => {
  switch (action.type) {
    case types.FETCH_RELATED_LISTING:
      return {...state} ;
    case types.FETCH_RELATED_LISTING_SUCCESS:
      return{
        ...state,
        relatedlistings: action.payload
      };
    case types.FETCH_RELATED_LISTING_FAILURE:
    default:
      return state;
  }
};

export default relatedlistingsReducer;