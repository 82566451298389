import types from '../types/alerts';

const alertsReducer = (state = [], action) => {
  switch (action.type) {
    case types.ALERT_SHOW:
      return [...state, action.payload];
    case types.ALERT_DISMISS:
      return state.filter(alert => alert.id !== action.payload);
    default:
      return state;
  }
};

export default alertsReducer;
