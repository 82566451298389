import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Header, Icon, Grid, Message, Responsive, Modal } from 'semantic-ui-react';
  
import ChangePasswordForm from './ChangePasswordForm';
import NewsletterSubscriptionForm from './NewsletterSubscriptionForm'
import '../Authentication/authentication.css'

const accountSettingStyle = {
  header: {
    display: 'inline-block',
    margin: '0px',
  },
  headerSubheader: {
    fontWeight: 'bold', color: '#505050', marginBottom: '0.5rem',
  },
  subheader: {
    color: '#000000',
    fontWeight: 'bold'
  },
};

const closeIconStyle = {
  float: 'right',  
  position: 'relative', 
  right: '-48px', 
  top: '-44px', 
  cursor: 'pointer',
}

const mobileCloseIconStyle ={
  float: 'right',  
  position: 'relative', 
  right: '-26px', 
  top: '-40px',
}


const AccountSettings = ({
  user: {
    email, provider, confirmed, changedPassword, newsletter_subscribed, confirmationResent
  },
}) => {
  const [open, setOpen] = React.useState(false)

  return (

  <div style={{ backgroundColor: 'white', border: '1px solid rgba(34,36,38,.15)', padding: '48px' }}>
    <Grid divided="vertically" centered>
      <Grid.Row>
        <Grid.Column>
          <Header.Subheader style={accountSettingStyle.headerSubheader}>
            Manage your account information and settings.
          </Header.Subheader>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column computer={16} mobile={16}>
          <Header>
            Profile
          </Header>
              <Icon name="user"/> 
              <span style={{marginLeft: '10px', fontWeight: 'bold', }}>Email Address</span>
              {!confirmed && (
                <Link className='linkHover' to="/confirmation/new" hidden={window.innerWidth <= Responsive.onlyMobile.maxWidth ? true : false} style={{color: '#1A659F', fontWeight: 'bold', float: 'right'}}>Resend Confirmation</Link>
              )}
              <p style={{ marginLeft: '34px', marginBottom: '0px', color: '#1A659F', fontWeight: 'bold'}}>{email}</p>
               <p style={{ marginLeft: '34px'}}>Your email address is your identity on AssistList and is used to log in.</p>
            {!confirmed && (
                <Link className='linkHover' to="/confirmation/new" hidden={window.innerWidth > Responsive.onlyMobile.maxWidth ? true : false} style={{ marginLeft: '34px', color: '#1A659F', fontWeight: 'bold'}}>Resend Confirmation</Link>
              )}

          <br></br>
          <br hidden={window.innerWidth > Responsive.onlyMobile.maxWidth ? true : false}></br>
          <Icon name="lock"/> 
              <span style={{marginLeft: '10px', fontWeight: 'bold', }}>Password</span>
          <Modal
            closeOnDimmerClick = {false}
            onClose = {() => setOpen(false)}
            onOpen = {() => setOpen(true)}
            open = {open}
            trigger = {window.innerWidth <= Responsive.onlyMobile.maxWidth ? 
            <p className='linkHover' style={{marginTop: '20px', marginLeft: '34px', color: '#1A659F', fontWeight: 'bold',  cursor: 'pointer'}}>Change Password</p> :
            <span className='linkHover' style={{color: '#1A659F', fontWeight: 'bold', float: 'right', cursor: 'pointer'}}>Change Password</span>}
            size="tiny"
            style={window.innerWidth <= Responsive.onlyMobile.maxWidth ? {padding: '30px 8px'} : {padding: '40px', width: '700px'}}
          >
            <Modal.Content style={{padding: '10px'}}>
             <Icon name='close' size='large' onClick ={() => setOpen(false)} style={window.innerWidth <= Responsive.onlyMobile.maxWidth ? mobileCloseIconStyle : closeIconStyle} />
              {provider === 'assistlist' ? ( 
              <Grid.Row style={{clear: 'both'}}>
                <Grid.Column computer={6} mobile={16}>
                  <Header textAlign='centered' size='huge' style={{color: '#1A659F', fontSize: '36px'}}>
                    Change Password
                  </Header>
                  <Header.Subheader>
                    <p >
                       A confirmation email will be sent upon password change.
                    </p>
                  </Header.Subheader>
                </Grid.Column>
                <Grid.Column computer={10} mobile={16} style={{marginTop: '20px'}}>
                  {changedPassword && (
                    <Message success content="Password updated." />
                  )}
                  <ChangePasswordForm />
                </Grid.Column>
              </Grid.Row>
              ) : (
                <Grid.Row>
                  <Header.Subheader>
                    Password reset unavailable due to account being logged in through
                    social media.
                  </Header.Subheader>
                </Grid.Row>
              )}
            </Modal.Content>
          </Modal>
        </Grid.Column>
      </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={6} mobile={16}>
            <Header>
              Email Notifications
            </Header>
            <Header.Subheader>
              <span style={accountSettingStyle.headerSubheader}>
                E-Newsletter
              </span>
              <p>
                AssistList offers our members with the latest tips on how to buy, sell or donate home health equipment, and  useful advice from health professionals. Our e-newsletters may also include other materials such as surveys to improve your AssistList experience.
              </p>
            </Header.Subheader>
          </Grid.Column>
          <Grid.Column computer={10} mobile={16}>
            <NewsletterSubscriptionForm newsletterSubscribed={newsletter_subscribed} />
          </Grid.Column>
        </Grid.Row>
    </Grid>
  </div>
)};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(AccountSettings);
