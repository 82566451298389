import React from 'react'
import { Helmet } from 'react-helmet'

import { Tab, Responsive } from 'semantic-ui-react'
import { connect } from 'react-redux'

import ModalSignupForm from './ModalSignupForm'
import ModalLoginForm from './ModalLoginForm'

const menuConfig = {
	attached: true,
	pointing: true,
	borderless: true,
	style: {
		display: 'flex',
		justifyContent: 'center',
	},
}

const LoginModal = ({ defaultActiveIndex = 0 }) => {
	const panes = [
		{ menuItem: 'Log in', render: () => <ModalLoginForm /> },
		{ menuItem: 'Sign up', render: () => <ModalSignupForm /> },
	]

	return (
		<React.Fragment>
			<Helmet>
				<title>Login/Signup - AssistList</title>
			</Helmet>

			<Responsive minWidth={Responsive.onlyComputer.minWidth}>
				<Tab menu={menuConfig} panes={panes} defaultActiveIndex={defaultActiveIndex} />
			</Responsive>
			<Responsive minWidth={Responsive.onlyTablet.minWidth} maxWidth={Responsive.onlyTablet.maxWidth}>
				<Tab menu={menuConfig} panes={panes} defaultActiveIndex={defaultActiveIndex} />
			</Responsive>
			<Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
				<Tab menu={menuConfig} panes={panes} defaultActiveIndex={defaultActiveIndex} />
			</Responsive>
		</React.Fragment>
	)
}

const mapStateToProps = (state) => ({
	user: state.user,
})

export default connect(mapStateToProps, null)(LoginModal)
