import React from 'react';
import { Step, Responsive } from 'semantic-ui-react';
import './wizard.css';

const Steps = ({
  page,
  imagesUploaded,
  basicsFilled,
  detailsFilled,
  setPage,
}) => (
<div>
  <Responsive minWidth={990}>
    <Step.Group style={{marginBottom: '17px'}} fluid size="small" widths={5}>
      <Step active={page === 0}  title="1. Equipment Type" onClick={() => setPage(0)} />
      <Step active={page === 1}  disabled={page === 0 && !imagesUploaded} title="2. Images" onClick={() => setPage(1)} />
      <Step active={page === 2}  disabled={!imagesUploaded} title="3. Basic Information" onClick={() => setPage(2)} />
      <Step active={page === 3}  disabled={!basicsFilled} title="4. Additional Details" onClick={() => setPage(3)} />
      <Step active={page === 4}  disabled={!detailsFilled} title="5. Preview" onClick={() => setPage(4)} />
    </Step.Group>
  </Responsive>
  <Responsive maxWidth={989}>
    <Step.Group style={{marginBottom: '17px'}} fluid size="small" unstackable="true" widths={5}>
      <Step active={page === 0}  title="1" onClick={() => setPage(0)} />
      <Step active={page === 1}  disabled={page === 0 && !imagesUploaded} title="2" onClick={() => setPage(1)} />
      <Step active={page === 2}  disabled={!imagesUploaded} title="3" onClick={() => setPage(2)} />
      <Step active={page === 3}  disabled={!basicsFilled} title="4" onClick={() => setPage(3)} />
      <Step active={page === 4}  disabled={!detailsFilled} title="5" onClick={() => setPage(4)} />
    </Step.Group>
  </Responsive>

</div>
);

export default Steps;

