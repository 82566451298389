import React from 'react';
import { Icon, Modal } from 'semantic-ui-react';
import './wizard.css';

class DetailsTooltip extends React.Component {

  render() {
      const { field } = this.props;
      const tooltipText = field.tooltip && field.tooltip.text;

      return (
        <Modal style={{width: '346px', height: '144px'}}
        trigger={<Icon color='grey' name='question circle' style={{visibility:(tooltipText ? '': 'hidden'), position: 'absolute', right: '0px'}} />} closeIcon>
          <Modal.Content >
            <Modal.Description>
              <h2 className='modalHeader'>{field.field_name}</h2>
              <div className='simpleKarlaText'>
                 {tooltipText}
             </div>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      );
  }
}

export default DetailsTooltip