import React from 'react'
import { Router } from 'react-router-dom'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'
import Layout from './layouts/application'

import { history } from './store'

import 'antd/dist/antd.css'
import './semantic/dist/semantic.min.css'
import './stylesheets/index.css'

const tagManagerArgs = {
	gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
}
TagManager.initialize(tagManagerArgs)

/* https://github.com/react-ga/react-ga/issues/122 */

const enableGoogleAnalytics = process.env.REACT_APP_ENABLE_GOOGLE_ANALYTICS === 'true'
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_USERAUTH, {
	testMode: !enableGoogleAnalytics,
	titleCase: false,
})

const historyListener = (location) => {
	ReactGA.set({ page: location.pathname })
	ReactGA.pageview(location.pathname)
}
history.listen(historyListener)
historyListener(window.location)

window.dataLayer.push({
	event: 'pageview',
})

const App = () => (
	<Router history={history}>
		<Layout />
	</Router>
)

export default App
