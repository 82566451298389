import React from 'react';
import { Icon, Modal } from 'semantic-ui-react';
import './wizard.css';

const textStyling = {fontFamily: 'Karla, Arial, Helvetica, sans-serif', fontSize: '17px', lineHeight: '21px'}

class Tooltip extends React.Component {
  render() {
    const equipmentType = this.props.equipmentType;
    const tooltipText = equipmentType.tooltip && equipmentType.tooltip.text;

    return (
      <Modal style={{width: '346px', height: '144px'}}
      trigger={<Icon className='toolTip' color='grey' name='question circle' style={{visibility: (tooltipText ? '': 'hidden')}} />} closeIcon>
        <Modal.Content >
          <Modal.Description>
            <h2 style={{fontFamily: 'Karla, Arial, Helvetica, sans-serif', fontSize: '18px', lineHeight: '21px', fontWeight: 'bold', color: '#1B809B'}}>{equipmentType.name}</h2>
            <div style={textStyling}>
                {tooltipText}
            </div>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

export default Tooltip;
