/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchTeamMembers } from '../../actions/aboutus';
import './aboutus.css';


const InActiveTeamMember = props => (
  <div style={{ width: '140px', marginRight: '19%' }}><p>{props.name}</p></div>
);

const InActiveTeamMembers = ({ teamMembers, _fetchTeamMembers }) => {

  const sortedTeamMembers = sortTeamMembers(teamMembers);

  useEffect(() => {
    _fetchTeamMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div style={{
        display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start',
      }}
      >
        {(teamMembers && teamMembers.length > 0) ? sortedTeamMembers.map(
          (teamMember, i) => (
            (i === 0 && teamMember.end_date) ? (
              <div key={teamMember.name}>
                <InActiveTeamMember name={teamMember.name} />
              </div>
            ) : (teamMember.end_date) ? (
              <div key={teamMember.name}>
                <InActiveTeamMember name={teamMember.name} />
              </div>
            ) : (
                  <span key={teamMember.name} />
                )
          ),
        ) : <span />}
      </div>
    </div>
  );
}

function sortTeamMembers(teamMembers) {
  // Change sort by team to sort by name
  // TODO REFACTOR THIS TO BACK END CODE
  const membersByName = teamMembers.slice(0);
  membersByName.sort((a, b) => {
    const x = a.name.toLowerCase();
    const y = b.name.toLowerCase();
    return x < y ? -1 : x > y ? 1 : 0;
  });

  return membersByName;
}

const mapDispatchToProps = dispatch => ({
  _fetchTeamMembers: () => dispatch(fetchTeamMembers()),
});

const mapStateToProps = state => ({
  teamMembers: state.aboutus.teamMembers,
  loading: state.aboutus.loading,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InActiveTeamMembers);
