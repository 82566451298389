import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Container } from "semantic-ui-react";
import { fetchFAQContactUsPageData } from "../../actions/faq";
import FaqCategory from "../Resources/FaqCategory";
import WireframeLoader from "../WireframeLoader";

const ContactUsFaqs = ({
  faqContactUsPageData,
  openPanel,
  _fetchFAQContactUsPageData,
}) => {
  useEffect(() => {
    _fetchFAQContactUsPageData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container
      style={{
        margin: window.innerWidth > 760 ? "0 auto" : "0 5%",
        marginTop: "40px",
        float: "left",
        flexShrink: "0",
      }}
    >
      <h3 style={{ color: "#0275B3", marginBottom: "24px" }}>
        Frequently Asked Questions
      </h3>
      {faqContactUsPageData ? (
        Object.entries(faqContactUsPageData).map(([key, faq]) => {
          return (
            <FaqCategory
              key={faq.title}
              faq_category={faq}
              openPanel={openPanel}
            />
          );
        })
      ) : (
        <WireframeLoader />
      )}
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => ({
  _fetchFAQContactUsPageData: () => dispatch(fetchFAQContactUsPageData()),
});

const mapStateToProps = (state) => ({
  faqContactUsPageData: state.faq.faqContactUsPageData,
  openPanel: state.faq.openPanel,
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsFaqs);
