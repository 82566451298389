import React from 'react';
import { Link } from 'react-router-dom';
import {
  Icon, Image, Loader, Container, Button, Responsive, Grid
} from 'semantic-ui-react';
import { Topic } from './Topic';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import background from '../../images/backGround.png';
import { fetchBlogPosts, fetchBlogPageData } from '../../actions/blog'
import WireframeLoader from '../WireframeLoader'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import './blog.css'

import rectangle from '../../images/rectangle.png'

const blogLink = 'https://assistlist.medium.com'

const postTitleCutoff = 100

const postButtonText = 'Read article'
const topicButtonText = 'Read articles'
const topicButtonIconName = 'external alternate'

const blogContainerStyle = {
  margin: '0 auto',
  maxWidth: '1080px',
  padding: '0px 40px 40px 40px',
}

const desktopThumbnailStyle = {
  objectFit: 'cover',
  maxHeight: '175px',
}

const tabletThumbnailStyle = {
  height: '100%',
  objectFit: 'cover',
}

const mobileThumbnailStyle = {
  height: '215px',
  objectFit: 'cover',
}

const dateAuthorStyle = {
  paddingTop: '7px',
  color: 'grey',
  fontSize: '10pt',
}

class Blog extends React.Component {
  componentDidMount() {
    const { _fetchBlogPosts } = this.props
    const { _fetchBlogPageData } = this.props

    _fetchBlogPosts()
    _fetchBlogPageData()
  }

  render() {
    const { loading } = this.props.blog
    const { blog_posts } = this.props.blog
    const { page_data_loading } = this.props.blog
    const { blog_page_data } = this.props.blog
    const headerImage = blog_page_data ? blog_page_data.blogHeaderImage[0].url : '';

    return (
      <div>
        <div style={{ background: 'white', backgroundSize: 'cover', backgroundImage: `url(${background})` }}>
          <Helmet>
            <title>AssistList - Blog</title>
            <meta
              name="description"
              content="Written by students and occupational therapists, this blog is designed to help you navigate and understand how to select the home health equipment."
            />
          </Helmet>
          <div style={{ color: '#ffffff', height: '300px', background: '#087CB1', position: 'relative' }}>
            <div
              style={{
                backgroundImage: `url(${headerImage})`,
                opacity: '0.1',
                backgroundSize: 'cover',
                backgroundPosition: '50% 50%',
                height: '100%',
                width: '100%,',
              }}
            ></div>
            <h1
              style={{
                color: '#ffffff',
                margin: 'auto',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: '48px',
              }}
            >
              {!page_data_loading ? blog_page_data.title : <Loader active inline />}
            </h1>
          </div>

          <Container style={blogContainerStyle}>
            <img style={{ paddingTop: '80px' }} src={rectangle} alt="" />
            <h1 style={{ paddingBottom: '30px' }}>
              Latest Articles
            </h1>

            {/* Desktop */}
            <Responsive minWidth={996}>
              <Grid columns={3}>
                <Grid.Row>
                  <Loader active={loading}>Loading</Loader>
                  {blog_posts &&
                    blog_posts.map((blogpost) => (
                      <Grid.Column>
                        <div style={{ position: 'relative', height: '490px' }}>
                          <Image src={blogpost.thumbnail} fluid style={desktopThumbnailStyle}></Image>
                          <div style={dateAuthorStyle}>
                            By {blogpost.author} &nbsp;&nbsp;&nbsp; / &nbsp;&nbsp;&nbsp; {blogpost.dateString}
                          </div>
                          <h3> {blogpost.title.slice(0, postTitleCutoff)} </h3>
                          <div style={{ position: 'absolute', bottom: '0' }}>
                            <p> {blogpost.description} </p>
                            <Link to={{ pathname: blogpost.link }} target="_blank">
                              <Button basic color="blue">
                                {postButtonText} &nbsp; <Icon name={topicButtonIconName} />
                              </Button>
                            </Link>
                          </div>
                        </div>
                      </Grid.Column>
                    ))}
                </Grid.Row>
              </Grid>
            </Responsive>

            {/* Tablet */}
            <Responsive minWidth={601} maxWidth={995}>
              {blog_posts &&
                blog_posts.map((blogpost) => (
                  <Grid columns={2}>
                    <Grid.Row>
                      <Loader active={loading}>Loading</Loader>
                      <Grid.Column width={5}>
                        <Image src={blogpost.thumbnail} fluid style={tabletThumbnailStyle}></Image>
                      </Grid.Column>
                      <Grid.Column width={11}>
                        <div style={dateAuthorStyle}>
                          By {blogpost.author} &nbsp;&nbsp;&nbsp; / &nbsp;&nbsp;&nbsp; {blogpost.dateString}
                        </div>
                        <h3> {blogpost.title.slice(0, postTitleCutoff)} </h3>
                        <p> {blogpost.description} </p>
                        <Link to={{ pathname: blogpost.link }} target="_blank">
                          <Button basic color="blue">
                            {topicButtonText} &nbsp; <Icon name={topicButtonIconName} />
                          </Button>
                        </Link>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                ))}
            </Responsive>

            {/* Mobile */}
            <Responsive maxWidth={600}>
              {blog_posts &&
                blog_posts.map((blogpost) => (
                  <Grid columns={1}>
                    <Grid.Row>
                      <Loader active={loading}>Loading</Loader>
                      <Image src={blogpost.thumbnail} fluid style={mobileThumbnailStyle}></Image>
                      <div style={dateAuthorStyle}>
                        By {blogpost.author} &nbsp;&nbsp;&nbsp; / &nbsp;&nbsp;&nbsp; {blogpost.dateString}
                      </div>
                      <h3> {blogpost.title.slice(0, postTitleCutoff)} </h3>
                      <p> {blogpost.description} </p>
                      <Link to={{ pathname: blogpost.link }} target="_blank">
                        <Button basic color="blue">
                          {topicButtonText} &nbsp; <Icon name={topicButtonIconName} />
                        </Button>
                      </Link>
                    </Grid.Row>
                  </Grid>
                ))}
            </Responsive>
          </Container>
        </div>

        <div style={{ backgroundColor: '#f7f7f7', paddingBottom: '120px' }}>
          <Container style={{ margin: '0 auto', maxWidth: '1080px', padding: '0px 40px 40px 40px' }}>
            <img style={{ paddingTop: '80px' }} src={rectangle} alt="" />
            <h1 style={{ paddingBottom: '30px' }}>
              Topics We Cover
            </h1>

            { !page_data_loading ?
              blog_page_data.blogImages.map((image) => <Topic blogLink={blogLink} blogImage={image} />) :
              <WireframeLoader />	}

          </Container>
        </div>

        <div style={{ backgroundColor: '#ffffff', paddingBottom: '120px' }}>
          <Container style={{ margin: '0 auto', maxWidth: '1080px', padding: '0px 40px 40px 40px' }}>
            <img style={{ paddingTop: '80px' }} src={rectangle} alt="" />
            <h1 style={{ paddingBottom: '30px' }}>
              About the Blog
            </h1>
            <div>
              {!page_data_loading ? documentToReactComponents(blog_page_data.blogInfo.body) : <WireframeLoader />}
            </div>
          </Container>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  _fetchBlogPosts: () => dispatch(fetchBlogPosts()),
  _fetchBlogPageData: () => dispatch(fetchBlogPageData()),
})

const mapStateToProps = (state) => ({
  blog: state.blog,
})

export default connect(mapStateToProps, mapDispatchToProps)(Blog);