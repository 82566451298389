import React from 'react';
import { Message } from 'semantic-ui-react';

const DeactivatedMessage = () => (
  <Message warning>
    <Message.Header>This listing is hidden.</Message.Header>
    <p>Please renew it in order for it to be visible to others.</p>
  </Message>
);

export default DeactivatedMessage;
