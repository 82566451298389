import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import qs from 'qs';

import { confirmUser } from '../actions/user';

const confirmation = ({ _confirmUser, location }) => {
  const { confirmation_token } = qs.parse(location.search, { ignoreQueryPrefix: true });
  if (confirmation_token) {
    _confirmUser(confirmation_token);
  }
  return (
    <Redirect to="/" />
  );
};

const mapDispatchToProps = dispatch => ({
  _confirmUser: confirmation_token => dispatch(confirmUser(confirmation_token)),
});

export default withRouter(connect(null, mapDispatchToProps)(confirmation));
