import types from '../types/donate';
import { getPageData, formatPageData, formatReferenceLink } from '../utils/contentful';

export const fetchDonatePageData = () => (dispatch) => {
  dispatch({ type: types.FETCH_DONATE_PAGE_DATA });
  let payload = { donate_page_data: undefined };

  getPageData('Donate Page', 2)
    .then((pageData) => {
      const data = formatPageData(pageData);

      // special figures formatting sinces it comes from a reference
      data.donateFigures = formatReferenceLink(data.donateFigures.figures);

      payload.donate_page_data = data;

      dispatch({ type: types.FETCH_DONATE_PAGE_DATA_SUCCESS, payload: payload });
    })
    .catch((err) => {
      dispatch({ type: types.FETCH_DONATE_PAGE_DATA_FAILURE, payload: err });
    })
}
