import React from 'react';
import { Switch } from 'react-router-dom';
import OurStory from './OurStory';
import OurTeam from './OurTeam';
import LandingAboutUs from './LandingAboutUs';
import ContactUs from './ContactForm';

import './aboutus.css';

const AboutUs = () => (
  <div style={{ background: '#ffffff' }}>
    <Switch>
      <LandingAboutUs exact path="/aboutus" />
      <OurStory exact path="/aboutus/ourstory" />
      <OurTeam exact path="/aboutus/ourteam" />
      <ContactUs exact path="/aboutus/contactus" />
    </Switch>
  </div>
);

export default AboutUs;
