import React from 'react';
import { Header, Grid } from 'semantic-ui-react';
import noImg from '../../images/no_image.png'
import { connect } from "react-redux";
import { fetchRecentListing } from "../../actions/recentlistings";

class PostsRecent extends React.Component {

  componentDidMount() {

    let recent_listings_ids = []

    if(this.props.posts){
      recent_listings_ids = this.props.posts.map(x => x.id)
    }

    const { _fetchRecentListing } = this.props;
      _fetchRecentListing(recent_listings_ids, this.props.user.user_id,);
  }


render(){

  let recent_listings = []

  if(this.props.recentlistings.recentlistings.data){
    recent_listings = this.props.recentlistings.recentlistings.data;
    recent_listings = recent_listings.filter(element => element.id !== this.props.post.id);
    recent_listings.reverse();
  }

  if (recent_listings.length){
    return (
      <div style={{width: '100%', paddingBottom: '80px', paddingTop: '24px', borderTop: '0.5px solid #BDBDBD'}}>
        <h1 style={{paddingLeft: '16px', fontSize: '22px'}}>Recently Viewed Listings</h1>
        <Grid doubling columns={4} style={{paddingLeft: '50px'}}>
         {(recent_listings && recent_listings.length) ? recent_listings.map((listing, i) =>
          (
            <Grid.Column>
              <a href={`/posts/${listing.id}`} style={{textDecoration: 'none'}}><div key={i} style={{width: '160px', height: '309px', marginBottom: '32px', textAlign: 'center'}}>
                <img alt='listing' height='160' width='160' src={listing.images ? listing.images.thumbnail : noImg} />
                <p style={{color: '#575757', textAlign: 'left', marginBottom: '8px'}}>{listing.location.name}</p>
                <h5 style={{margin: '0', color: 'black', height: '3em', lineHeight: '1.5em', overflow: 'hidden', textAlign: 'left'}}>{listing.title}</h5>
                <Header className={!listing.price ? 'free' : 'price'} style={{textAlign: 'left', margin: '16px 0px', color: '#128747'}} size="small" content={!listing.price ? 'FREE' : `$${listing.price}`}
              />
              </div></a>
            </Grid.Column>
            )
          )
          : <div></div>}
        </Grid>
      </div>)
  } else {
      return (null)
    }
}
}

const mapStateToProps = (state) => ({
  recentlistings: state.recentlistings,
});

const mapDispatchToProps = (dispatch) => ({
  _fetchRecentListing: (post_ids, user_id) => dispatch(fetchRecentListing(post_ids, user_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostsRecent);