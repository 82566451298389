import types from '../types/feedback';
import api, { userHeaders } from '../utils/api';
import { showAlert } from './alerts';
import config from '../config';
import { fetchUserActivePosts, fetchUserInactivePosts, fetchUserExchangedPosts } from './profile';

export const submitFeedback = (post_id, selected_option) => (dispatch) => {
  dispatch({ type: types.SUBMIT_FEEDBACK });
  return (
    api.post('/feedbacks', {
      feedback: {
        post_id,
        selected_option,
      },
    }, userHeaders())
      .then((response) => {
        dispatch(fetchUserActivePosts());
        dispatch(fetchUserInactivePosts());
        dispatch(fetchUserExchangedPosts());
        dispatch({ type: types.SUBMIT_FEEDBACK_SUCCESS, payload: response.data });
        dispatch(showAlert({
          header: "We're happy to hear you sold or donated your item through AssistList!",
          message: "We've changed your listing to inactive so that it is no longer publicly visible.",
          type: 'success',
          dismissAfter: 3000,
        }));
      }).catch((error) => {
        dispatch(fetchUserActivePosts());
        dispatch(fetchUserInactivePosts());
        dispatch(fetchUserExchangedPosts());
        dispatch({ type: types.SUBMIT_FEEDBACK_FAILURE, payload: error });
        dispatch(showAlert({
          header: 'Feedback Submission Unsuccessful',
          flowId: error.response?.data?.data?.flow_id,
          message: 'Your feedback was not submitted. Please try again',
          type: 'error',
        }));
      })
  );
};

export const submitEmailFeedback = feedback_token => (dispatch) => {
  dispatch({ type: types.SUBMIT_EMAIL_FEEDBACK });
  return (
    api.post('/feedbacks/submit_email_feedback', {
      feedback_token,
    })
      .then((response) => {
        if (response.data.data.selected_option === config.FEEDBACK_OPTIONS.exchanged) {
          dispatch(showAlert({
            header: "We're happy to hear you sold or donated your item through AssistList!",
            message: "We've changed your listing to inactive so that it is no longer publicly visible.",
            type: 'success',
          }));
          dispatch({ type: types.SUBMIT_EMAIL_FEEDBACK_SUCCESS, payload: response.data.data });
        // declined to renew option
        } else {
          dispatch(showAlert({
            header: "We've changed your listing to inactive so it is no longer publicly visible.",
            message: 'If you have any feedback on how we can improve this service, fill out our Contact Form',
            type: 'success',
          }));
        }
      })
      .catch((error) => {
        dispatch(showAlert({
          header: 'Feedback Submission Unsuccessful',
          flowId: error.response?.data?.data?.flow_id,
          message: 'Your feedback was not submitted. Please try again',
          type: 'error',
        }));
        dispatch({ type: types.SUBMIT_EMAIL_FEEDBACK_FAILURE, payload: error });
      })
  );
};