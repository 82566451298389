import { withRouter, Link } from 'react-router-dom';
import {
  Dropdown,
  Icon,
  Image,
  Menu,
  Responsive,
  Button,
} from 'semantic-ui-react';
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Alerts from './Alerts';
import EmergencyBanner from './EmergencyBanner'; // Replace banner here
import logo from '../images/FullLogo_Noclearspace.svg';
import { logout } from '../actions/user';
import BackTopButton from './BackToTopButton';
import SocialMedia from './Listings/SocialMedia';
import UserIconImg from '../../src/images/user_icon.svg';

import '../stylesheets/navbar.css';
import '../stylesheets/index.css';

const mainContainerStyle = {
  display: 'block',
  position: 'relative',
};

const MobileMenuSlide = ({ open, items, user, handleLogout, onClick }) => {
  const node = useRef();

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    onClick();
  };

  useEffect(() => {
    // Add scroll eventListner to close slide menu by clicking outside
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Remove scroll eventListner when component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <React.Fragment>
      <nav
        ref={node}
        className="mobile-menu-content"
        style={{
          transform: open ? 'translateY(0)' : 'translateY(-100%)',
        }}
      >
        <Menu.Item
          as={Link}
          to="#"
          icon="close"
          onClick={onClick}
          style={{
            textAlign: 'right',
          }}
        />
        {items.map((item) => (
          <Menu.Item key={item.key} onClick={onClick} {...item} />
        ))}
        <Menu.Item as={Link} to="/aboutus" content="About" onClick={onClick} />
        <Menu.Item
          as={Link}
          to="/aboutus/contactus"
          content="Contact Us"
          onClick={onClick}
        />
        {user.auth_token ? (
          <React.Fragment>
            <Menu.Item
              as={Link}
              to="/account/listings"
              content="My Listings"
              onClick={onClick}
            />
            <Menu.Item
              as={Link}
              to="/account/notifications"
              content="Notification Settings"
              onClick={onClick}
            />
            <Menu.Item
              as={Link}
              to="/account/settings"
              content="Account Settings"
              onClick={onClick}
            />
            <Menu.Item
              as={Link}
              to="#"
              content="Log Out"
              onClick={() => {
                handleLogout();
                onClick();
              }}
            />
          </React.Fragment>
        ) : (
          <Menu.Item as={Link} to="/auth" content="Sign In" onClick={onClick} />
        )}
        <Menu.Item>
          <SocialMedia open />
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/donate"
          content="Make a Donation"
          onClick={onClick}
          style={{ color: '#0080BB', borderTop: '2px solid' }}
        />
      </nav>
      {/* Backdrop when opening mobile slide menu */}
      <div
        style={{
          zIndex: '3',
          position: 'fixed',
          right: '0px',
          bottom: '0px',
          top: '0px',
          left: '0px',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          visibility: open ? 'visible' : 'hidden',
        }}
      />
    </React.Fragment>
  );
};

const UserIcon = ({ handleLogout }) => {
  return (
    <Dropdown
      simple
      direction="left"
      item
      // This throws a warning but it works
      text={
        <span>
          <Image className="svg" src={UserIconImg} alt="user icon" />
        </span>
      }
    >
      <Dropdown.Menu>
        <Dropdown.Item as={Link} to="/account/listings" content="My Listings" />
        <Dropdown.Item
          as={Link}
          to="/account/notifications"
          content="Notifications"
        />
        <Dropdown.Item
          as={Link}
          to="/account/settings"
          content="Account Settings"
        />
        <Dropdown.Item content="Log Out" onClick={() => handleLogout()} />
      </Dropdown.Menu>
    </Dropdown>
  );
};

const NavBarMobile = ({
  items,
  onToggle,
  visible,
  user,
  handleLogout,
  clearPosts,
}) => (
  <React.Fragment>
    <Menu borderless style={{ margin: 0 }}>
      <Menu.Item as={Link} to="/" onClick={() => clearPosts()}>
        <Image size="small" src={logo} alt="assistlist-logo" />
      </Menu.Item>

      <Menu.Menu position="right">
        {user.auth_token ? (
          <UserIcon handleLogout={handleLogout} />
        ) : (
          <Menu.Item
            as={Link}
            to="/auth"
            content={
              <div>
                <Image
                  className="svg"
                  src={UserIconImg}
                  alt="user icon"
                  verticalAlign="middle"
                />{' '}
                <span>Sign In</span>
              </div>
            }
          />
        )}
        <Menu.Item onClick={onToggle}>
          <Icon name="sidebar" />
        </Menu.Item>
      </Menu.Menu>
    </Menu>
    <MobileMenuSlide
      open={visible}
      items={items}
      user={user}
      handleLogout={handleLogout}
      onClick={onToggle}
    />
  </React.Fragment>
);

const NavBarDesktop = ({
  items,
  user,
  handleLogout,
  clearPosts,
  activeLink,
}) => (
  <Menu borderless className="nav-bar" style={{ margin: 0 }}>
    <Menu.Item as={Link} to="/" onClick={() => clearPosts()}>
      <Image size="small" src={logo} alt="assistlist-logo" />
    </Menu.Item>
    <Menu.Menu position="right">
      {items.map((item) => (
        <Menu.Item
          key={item.key}
          className="nav-active-link"
          style={{ color: activeLink === item.to ? '#0080BB' : 'inherit' }}
          {...item}
        />
      ))}
      <Dropdown simple direction="left" item text="About Us">
        <Dropdown.Menu>
          <Dropdown.Item as={Link} to="/aboutus" content="About AssistList" />
          <Dropdown.Item as={Link} to="/aboutus/ourstory" content="Our Story" />
          <Dropdown.Item as={Link} to="/aboutus/ourteam" content="Our Team" />
          <Dropdown.Item
            as={Link}
            to="/aboutus/contactus"
            content="Contact Us"
          />
        </Dropdown.Menu>
      </Dropdown>
      {user.auth_token ? (
        <UserIcon handleLogout={handleLogout} />
      ) : (
        <Menu.Item
          as={Link}
          to="/auth"
          content={
            <div>
              <Image
                className="svg"
                src={UserIconImg}
                alt="user icon"
                verticalAlign="middle"
              />{' '}
              <span>Sign In</span>
            </div>
          }
        />
      )}
      <Menu.Item
        className="nav-active-link"
        style={{ color: '#0080BB', borderLeft: '2px solid' }}
      >
        <Button
          compact
          circular
          primary
          as={Link}
          to="/donate"
          content="Donate"
        ></Button>
      </Menu.Item>
    </Menu.Menu>
  </Menu>
);

class NavBar extends React.Component {
  constructor() {
    super();

    this.state = { visible: false };
    this.handleToggle = this.handleToggle.bind(this);
    this.handlePusher = this.handlePusher.bind(this);
    this.clearPosts = this.clearPosts.bind(this);

    this.handleContextRef = (contextRef) => this.setState({ contextRef });
  }

  handlePusher() {
    const { visible } = this.state;

    if (visible) this.setState({ visible: false });
  }

  handleToggle() {
    this.setState({ visible: !this.state.visible });
  }

  clearPosts() {
    window.location.reload(false);
  }

  render() {
    const { user, handleLogout, clearPosts } = this.props;
    const { visible, contextRef } = this.state;
    const { pathname } = this.props.location;

    const items = [
      {
        as: Link,
        to: '/',
        content: 'Listings',
        key: 'listings',
        onClick: () => {
          clearPosts();
        },
      },
      {
        as: Link,
        to: '/blog',
        content: 'Blog',
        key: 'blog',
      },
      {
        as: Link,
        to: '/resources',
        content: 'Resources',
        key: 'resources',
      },
      {
        as: Link,
        to: '/partners',
        content: 'Our Partners',
        key: 'partners',
      },
    ];

    return (
      <div style={mainContainerStyle} ref={this.handleContextRef}>
        <Alerts context={contextRef} />
        <Responsive maxWidth={Responsive.onlyTablet.maxWidth}>
          <NavBarMobile
            onPusherClick={this.handlePusher}
            onToggle={this.handleToggle}
            items={items}
            visible={visible}
            user={user}
            handleLogout={handleLogout}
          />
          <EmergencyBanner /> {/* Replace Banner here */}
          <BackTopButton />
        </Responsive>
        <Responsive minWidth={Responsive.onlyComputer.minWidth}>
          <NavBarDesktop
            items={items}
            activeLink={pathname}
            user={user}
            handleLogout={handleLogout}
          />
          <EmergencyBanner /> {/* Replace Banner here */}
          <BackTopButton />
        </Responsive>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  handleLogout: () => dispatch(logout()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar));
