import { DirectUpload } from 'activestorage';

import config from '../config';

class Upload {
  constructor(file, onFileChange) {
    this.file = file;

    this.preview = file.preview;

    this.upload = new DirectUpload(
      file,
      config.activeStorageUrl,
      this,
    );

    this.id = this.upload.id;
    this.handleFileChange = onFileChange;

    this.handleProgress = this.handleProgress.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleError = this.handleError.bind(this);

    return this.start();
  }

  start() {
    return new Promise((resolve, reject) => {
      this.upload.create((error, blob) => {
        if (error) {
          reject(error);
        }

        resolve(blob.signed_id);
      });
    })
      .then(this.handleSuccess)
      .catch(this.handleError);
  }

  directUploadWillStoreFileWithXHR(xhr) {
    xhr.upload.addEventListener('progress', this.handleProgress);
  }

  handleProgress({ loaded, total }) {
    this.progress = loaded / total * 100;

    this.handleFileChange({
      id: this.id,
      preview: this.preview,
      status: 'uploading',
      progress: this.progress,
    });
  }

  handleSuccess(blobId) {
    this.handleFileChange({
      id: this.id,
      order: this.id,
      preview: this.preview,
      status: 'success',
      progress: 100,
      blobId,
      signed_id: blobId,
      rotation: 0,

      // used for proper display of image rotation on the front-end only
      rotation_origin: 0,
      height: 165,
    });
  }

  handleError(error) {
    this.handleFileChange({
      id: this.id,
      preview: this.preview,
      status: 'error',
      error,
    });
  }
}

export default Upload;
