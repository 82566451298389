import React, { useEffect } from 'react'
import { Container, Grid, Segment, Responsive, Loader } from 'semantic-ui-react'
import { Helmet } from 'react-helmet'
import PageContainer from '../PageContainer'
import DonateForm from './DonateForm'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import WireframeLoader from '../WireframeLoader'
import { fetchDonatePageData } from '../../actions/donate'
import { connect } from 'react-redux'

const renderOptions = {
  renderText: text => {
    return text.split('\n').reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, [])
  }
}

const Donate = ({ donatepagedata, _fetchDonatePageData }) => {
  useEffect(() => {
    _fetchDonatePageData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  
  const { loading } = donatepagedata
  const { donate_page_data } = donatepagedata
  
  const websiteVisitors = loading ? undefined : donate_page_data.donateFigures.websiteVisitors;
  const assistlistListings = loading ? undefined : donate_page_data.donateFigures.assistlistListings;
  const hoursVolunteered = loading ? undefined : donate_page_data.donateFigures.hoursVolunteered;

  return (
    <PageContainer>
      <Helmet>
        <title>Donate - AssistList</title>
        <meta
          name="description"
          content="Help us change lives. By offering your support, you can enable your community to connect and access affordable home health equipment. "
        />
      </Helmet>

      <Container style={{ maxWidth: '950px' }}>
        <Segment>
          {!loading ? documentToReactComponents(donate_page_data.donateTopInfo.body) : <WireframeLoader short={true} />}
        </Segment>

        <Responsive as={Grid} relaxed="very" stackable columns={2} style={{ paddingBottom: '2rem' }}>
          <Grid.Column width={16} only="mobile tablet" style={{ paddingBottom: 0 }}>
            <Grid.Row>
              <Segment>{!loading ? documentToReactComponents(donate_page_data.donateInfo.body, renderOptions) : <WireframeLoader />}</Segment>
            </Grid.Row>
          </Grid.Column>

          <Grid.Column className="donationBox" computer={6} tablet={16}>
            <DonateForm />
          </Grid.Column>

          <Grid.Column computer={10} only="computer">
            <Grid.Row style={{ paddingBottom: '1rem' }}>
              <Segment>{!loading ? documentToReactComponents(donate_page_data.donateInfo.body, renderOptions) : <WireframeLoader />}</Segment>
            </Grid.Row>
            <Grid.Row>
              <Segment>
                <h2>AssistList Figures</h2>
                <Grid textAlign="center" columns={3} style={{ color: '#0073B2', padding: '.9rem' }}>
                  <Grid.Row style={{ fontSize: '2rem' }}>
                    <Grid.Column>{websiteVisitors ? websiteVisitors.value : <Loader active inline />}</Grid.Column>
                    <Grid.Column>{assistlistListings ? assistlistListings.value : <Loader active inline />}</Grid.Column>
                    <Grid.Column>{hoursVolunteered ? hoursVolunteered.value : <Loader active inline />}</Grid.Column>
                  </Grid.Row>
                  <Grid.Row style={{ fontSize: '1.5rem', paddingTop: 0 }}>
                    <Grid.Column>{websiteVisitors && websiteVisitors.label}</Grid.Column>
                    <Grid.Column>{assistlistListings && assistlistListings.label}</Grid.Column>
                    <Grid.Column>{hoursVolunteered && hoursVolunteered.label}</Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Grid.Row>
          </Grid.Column>

          <Grid.Column className="figure" width={16} only="mobile tablet">
            <Grid.Row>
              <Segment>
                <h2>AssistList Figures</h2>
                <Grid textAlign="center" columns={3} style={{ color: '#0073B2' }}>
                  <Grid.Row only="tablet" style={{ fontSize: '1.7rem' }}>
                    <Grid.Column>{websiteVisitors ? websiteVisitors.value : <Loader active inline />}</Grid.Column>
                    <Grid.Column>{assistlistListings ? assistlistListings.value : <Loader active inline />}</Grid.Column>
                    <Grid.Column>{hoursVolunteered ? hoursVolunteered.value : <Loader active inline />}</Grid.Column>
                  </Grid.Row>
                  <Grid.Row only="tablet" style={{ fontSize: '1.3rem', paddingTop: 0 }}>
                  <Grid.Column>{websiteVisitors && websiteVisitors.label}</Grid.Column>
                    <Grid.Column>{assistlistListings && assistlistListings.label}</Grid.Column>
                    <Grid.Column>{hoursVolunteered && hoursVolunteered.label}</Grid.Column>
                  </Grid.Row>
                  <Grid.Row only="mobile" style={{ fontSize: '1.5rem' }}>
                    <Grid.Column>{websiteVisitors ? websiteVisitors.value : <Loader active inline />}</Grid.Column>
                    <Grid.Column>{assistlistListings ? assistlistListings.value : <Loader active inline />}</Grid.Column>
                    <Grid.Column>{hoursVolunteered ? hoursVolunteered.value : <Loader active inline />}</Grid.Column>
                  </Grid.Row>
                  <Grid.Row only="mobile" style={{ fontSize: '.9rem', paddingTop: 0 }}>
                  <Grid.Column>{websiteVisitors && websiteVisitors.label}</Grid.Column>
                    <Grid.Column>{assistlistListings && assistlistListings.label}</Grid.Column>
                    <Grid.Column>{hoursVolunteered && hoursVolunteered.label}</Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Grid.Row>
          </Grid.Column>
        </Responsive>
      </Container>
    </PageContainer>
  )
}

const mapDispatchToProps = (dispatch) => ({
  _fetchDonatePageData: () => dispatch(fetchDonatePageData()),
})

const mapStateToProps = (state) => ({
  donatepagedata: state.donatepagedata,
})

export default connect(mapStateToProps, mapDispatchToProps)(Donate)
