const FETCH_DIRECTORS = 'directors/FETCH_DIRECTORS';
const FETCH_DIRECTORS_SUCCESS = 'directors/FETCH_DIRECTORS_SUCCESS';
const FETCH_DIRECTORS_FAILURE = 'directors/FETCH_DIRECTORS_FAILURE';

const FETCH_IMAGES = 'images/FETCH_IMAGES';
const FETCH_IMAGES_SUCCESS = 'images/FETCH_IMAGES_SUCCESS';
const FETCH_IMAGES_FAILURE = 'images/FETCH_IMAGES_FAILURE';

const FETCH_STATISTICS = 'statisics/FETCH_STATISTICS';
const FETCH_STATISTICS_SUCCESS = 'statisics/FETCH_STATISTICS_SUCCESS';
const FETCH_STATISTICS_FAILURE = 'statisics/FETCH_STATISTICS_FAILURE';

const FETCH_TEAM_MEMBERS = 'team_members/FETCH_TEAM_MEMBERS';
const FETCH_TEAM_MEMBERS_SUCCESS = 'team_members/FETCH_TEAM_MEMBERS_SUCCESS';
const FETCH_TEAM_MEMBERS_FAILURE = 'team_members/FETCH_TEAM_MEMBERS_FAILURE';

const FETCH_TEAM_STATS = 'team_stats/FETCH_TEAM_STATS';
const FETCH_TEAM_STATS_SUCCESS = 'team_stats/FETCH_TEAM_STATS_SUCCESS';
const FETCH_TEAM_STATS_FAILURE = 'team_stats/FETCH_TEAM_STATS_FAILURE';

const FETCH_TEAM_LEADS = 'team_leads/FETCH_TEAM_LEADS';
const FETCH_TEAM_LEADS_SUCCESS = 'team_leads/FETCH_TEAM_LEADS_SUCCESS';
const FETCH_TEAM_LEADS_FAILURE = 'team_leads/FETCH_TEAM_LEADS_FAILURE';

const FETCH_TESTIMONIALS = 'testimonials/FETCH_TESTIMONIALS';
const FETCH_TESTIMONIALS_SUCCESS = 'testimonials/FETCH_TESTIMONIALS_SUCCESS';
const FETCH_TESTIMONIALS_FAILURE = 'testimonials/FETCH_TESTIMONIALS_FAILURE';

const FETCH_TEAMS = 'teams/FETCH_TEAMS';
const FETCH_TEAMS_SUCCESS = 'teams/FETCH_TEAMS_SUCCESS';
const FETCH_TEAMS_FAILURE = 'teams/FETCH_TEAMS_FAILURE';

const FETCH_VOLUNTEER_INSIGHTS = 'volunteer_insights/FETCH_VOLUNTEER_INSIGHTS';
const FETCH_VOLUNTEER_INSIGHTS_SUCCESS = 'volunteer_insights/FETCH_VOLUNTEER_INSIGHTS_SUCCESS';
const FETCH_VOLUNTEER_INSIGHTS_FAILURE = 'volunteer_insights/FETCH_VOLUNTEER_INSIGHTS_FAILURE';

const FETCH_OUR_STORY_PAGE_DATA = 'our_story/FETCH_OUR_STORY_PAGE_DATA';
const FETCH_OUR_STORY_PAGE_DATA_SUCCESS = 'our_story/FETCH_OUR_STORY_PAGE_DATA_SUCCESS';
const FETCH_OUR_STORY_PAGE_DATA_FAILURE = 'our_story/FETCH_OUR_STORY_PAGE_DATA_FAILURE';

const FETCH_ABOUT_US_PAGE_DATA = 'about_us/FETCH_ABOUT_US_PAGE_DATA';
const FETCH_ABOUT_US_PAGE_DATA_SUCCESS = 'about_us/FETCH_ABOUT_US_PAGE_DATA_SUCCESS';
const FETCH_ABOUT_US_PAGE_DATA_FAILURE = 'about_us/FETCH_ABOUT_US_PAGE_DATA_FAILURE';

export default {
  FETCH_DIRECTORS,
  FETCH_DIRECTORS_SUCCESS,
  FETCH_DIRECTORS_FAILURE,
  FETCH_IMAGES,
  FETCH_IMAGES_SUCCESS,
  FETCH_IMAGES_FAILURE,
  FETCH_STATISTICS,
  FETCH_STATISTICS_SUCCESS,
  FETCH_STATISTICS_FAILURE,
  FETCH_TEAM_MEMBERS,
  FETCH_TEAM_MEMBERS_SUCCESS,
  FETCH_TEAM_MEMBERS_FAILURE,
  FETCH_TEAM_STATS,
  FETCH_TEAM_STATS_SUCCESS,
  FETCH_TEAM_STATS_FAILURE,
  FETCH_TEAM_LEADS,
  FETCH_TEAM_LEADS_SUCCESS,
  FETCH_TEAM_LEADS_FAILURE,
  FETCH_TEAMS,
  FETCH_TEAMS_SUCCESS,
  FETCH_TEAMS_FAILURE,
  FETCH_TESTIMONIALS,
  FETCH_TESTIMONIALS_SUCCESS,
  FETCH_TESTIMONIALS_FAILURE,
  FETCH_VOLUNTEER_INSIGHTS,
  FETCH_VOLUNTEER_INSIGHTS_SUCCESS,
  FETCH_VOLUNTEER_INSIGHTS_FAILURE,
  FETCH_OUR_STORY_PAGE_DATA,
  FETCH_OUR_STORY_PAGE_DATA_SUCCESS,
  FETCH_OUR_STORY_PAGE_DATA_FAILURE,
  FETCH_ABOUT_US_PAGE_DATA,
  FETCH_ABOUT_US_PAGE_DATA_SUCCESS,
  FETCH_ABOUT_US_PAGE_DATA_FAILURE
};
