import React, { useEffect } from 'react';
import _ from 'lodash';
import {
  Responsive, Header, Icon, Image, Modal,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { fetchDirectors } from '../../actions/aboutus';
import './aboutus.css';


const paragraphStyling = {
  fontFamily: 'Karla, Arial, Helvetica, sans-serif',
  fontWeight: 'normal',
  fontSize: '18px',
};

const directorImg = {
  maxWidth: '260px',
  paddingBottom: '0',
  paddingRight: '54px',
};

const directorImgMobile = {
  maxWidth: '320px',
  paddingBottom: '20px',
};

const directorContainerMobile = {
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  paddingBottom: '56px',
  alignItems: 'center',
};


const DirectorContainer = props => (

  <div style={{
    display: 'flex', flexFlow: 'row', justifyContent: 'center', paddingBottom: '50px', alignItems: 'flex-start',
  }}
  >
    <img style={directorImg} src={props.directorImg} alt="Director Profile" />
    <div className="text-container">
      <h1>{props.directorName}</h1>
      <p style={paragraphStyling}>{props.directorBio}</p>
    </div>
  </div>
);

const DirectorContainerMobile = props => (

  <Modal
    style={{ height: '90%', width: '90%' }}

    trigger={(
      <div style={{ paddingBottom: '35px' }}>
        <img style={directorImgMobile} src={props.directorImg} alt="Director Profile" />
        <div className="text-container">
          <h1 style={{ marginBottom: '16px' }}>{props.directorName}</h1>
        </div>
        <p style={paragraphStyling}> <u>Read More </u> </p>
      </div>
    )}
    closeIcon
  >

    <Modal.Content style={{ maxHeight: 'calc(80vh - -1rem)' }} image scrolling>
      <Image size="medium" src={props.directorImg} wrapped />
      <Modal.Description>
        <Header style={{ fontSize: '18px', textAlign: 'center' }}><b>{props.directorName}</b></Header>
        {_.times(1, i => (
          <p style={{ fontFamily: 'Karla, Arial, Helvetica, sans-serif', lineHeight: '21px', fontSize: '18px' }}>{props.directorBio}</p>
        ))}
      </Modal.Description>
    </Modal.Content>
    <div style={{ borderTop: '2px solid rgba(0, 0, 0, 0.3)', textAlign: 'center', paddingTop: '15px' }}>
      <Icon name="angle down" />
    </div>
  </Modal>
);

const Directors = ({ directors, _fetchDirectors }) => {

  useEffect(() => {
    _fetchDirectors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Responsive minWidth={750}>
        <div>
          {(directors && directors.length > 0) ? directors.map(
            (director, i) => (i === 0 ? (
              <div className="active" key={director.name}>
                <DirectorContainer
                  directorImg={director.picture.thumbnail}
                  directorName={director.name}
                  directorBio={director.narrative}
                />
              </div>
            ) : (
                <div key={director.name}>
                  <DirectorContainer
                    directorImg={director.picture.thumbnail}
                    directorName={director.name}
                    directorBio={director.narrative}
                  />
                </div>
              )),
          )
            : <div />}
        </div>
      </Responsive>
      <Responsive maxWidth={749}>
        <div style={directorContainerMobile}>
          {(directors && directors.length > 0) ? directors.map(
            (director, i) => (i === 0 ? (
              <div className="active" key={director.name}>
                <DirectorContainerMobile
                  directorImg={director.picture.thumbnail}
                  directorName={director.name}
                  directorBio={director.narrative}
                />
              </div>
            ) : (
                <div key={director.name}>
                  <DirectorContainerMobile
                    directorImg={director.picture.thumbnail}
                    directorName={director.name}
                    directorBio={director.narrative}
                  />
                </div>
              )),
          ) : <div />}
        </div>
      </Responsive>
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  _fetchDirectors: () => dispatch(fetchDirectors()),
});

const mapStateToProps = state => ({
  directors: state.aboutus.directors,
  loading: state.aboutus.loading,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Directors);
