const SUBMIT_FEEDBACK = 'feedbacks/SUBMIT_FEEDBACK';
const SUBMIT_FEEDBACK_SUCCESS = 'feedbacks/SUBMIT_FEEDBACK_SUCCESS';
const SUBMIT_FEEDBACK_FAILURE = 'feedbacks/SUBMIT_FEEDBACK_FAILURE';

const SUBMIT_EMAIL_FEEDBACK = 'feedbacks/SUBMIT_EMAIL_FEEDBACK';
const SUBMIT_EMAIL_FEEDBACK_SUCCESS = 'feedbacks/SUBMIT_EMAIL_FEEDBACK_SUCCESS';
const SUBMIT_EMAIL_FEEDBACK_FAILURE = 'feedbacks/SUBMIT_EMAIL_FEEDBACK_FAILURE';

export default {
  SUBMIT_FEEDBACK,
  SUBMIT_FEEDBACK_SUCCESS,
  SUBMIT_FEEDBACK_FAILURE,
  SUBMIT_EMAIL_FEEDBACK,
  SUBMIT_EMAIL_FEEDBACK_SUCCESS,
  SUBMIT_EMAIL_FEEDBACK_FAILURE,
};
