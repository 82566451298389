import React from 'react'
import { Link } from 'react-router-dom'
import { Tab, Segment, Form, Button, Message, Icon, Divider, Header } from 'semantic-ui-react'
import { Field, reduxForm } from 'redux-form'

import { login } from '../../actions/user'

import { IconTextField } from '../../utils/render-input'

import config from '../../config'

const validate = (values) => {
	const errors = {}

	if (!values.email) {
		errors.email = 'Email address is required'
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
		errors.email = 'Invalid email address'
	}

	if (!values.password) {
		errors.password = 'Password is required'
	} else if (values.password.length < 8) {
		errors.password = 'Password must be at least 8 characters long'
	}

	return errors
}

const btnStyle = {
	maxWidth: '290px',
	minHeight: '40px',
	margin: '0 auto',
}

const formStyle = {
	maxWidth: '456px',
	margin: '0 auto',
}

const SimpleLoginForm = ({ error, handleSubmit, submitting }) => (
	<Tab.Pane>
		<Segment vertical style={formStyle}>
			<p style={{ color: 'rgba(0,0,0,.6)' }}>
				To create listings or contact someone with equipment listed, sign up for a free account.
			</p>
			<Form name="login" onSubmit={handleSubmit(login)}>
				<Field
					component={IconTextField}
					name="email"
					type="email"
					icon="user"
					placeholder="Email address"
					autoComplete="email"
					className=".fs-exclude"
				/>

				<Field
					component={IconTextField}
					name="password"
					type="password"
					icon="lock"
					placeholder="Enter your password"
					autoComplete="current-password"
				/>

				{error && <p style={{ color: 'red' }}>{error}</p>}

				<Form.Button primary fluid type="submit" content="Log in" loading={submitting} style={btnStyle} />
			</Form>

			<Divider horizontal>
				<Header as="h4" disabled>
					Or
				</Header>
			</Divider>

			<Button
				fluid
				color="facebook"
				icon={<Icon name="facebook f" className="social-icon-color" />}
				content="Log in with Facebook"
				onClick={() => window.open(config.facebookOauthUrl, '_self')}
				style={btnStyle}
			/>
			<br />
			<Button
				fluid
				color="google plus"
				icon={<Icon name="google" className="social-icon-color" />}
				content="Log in with Google"
				onClick={() => window.open(config.googleOauthUrl, '_self')}
				style={btnStyle}
			/>
		</Segment>

		<Message warning>
			<p>
				Forgot your password? <Link to="/password/reset">Reset</Link>
			</p>
			<p>
				Didn&#39;t receive a confirmation email? <Link to="/confirmation/new">Resend</Link>
			</p>
		</Message>
	</Tab.Pane>
)

export default reduxForm({
	form: 'login',
	validate,
})(SimpleLoginForm)
