import types from '../types/profile';

const profileReducer = (state = {
  active_posts_loading: true,
  active_posts: [],
  active_posts_pagination: {},
  inactive_posts_loading: true,
  inactive_posts: [],
  inactive_posts_pagination: {},
  exchanged_posts_loading: true,
  exchanged_posts: [],
  exchanged_posts_pagination: {},
  error: null,
}, action) => {
  switch (action.type) {
    case types.FETCH_USER_ACTIVE_POSTS:
      return { ...state, active_posts_loading: true };
    case types.FETCH_USER_INACTIVE_POSTS:
      return { ...state, inactive_posts_loading: true };
    case types.FETCH_USER_EXCHANGED_POSTS:
      return { ...state, exchanged_posts_loading: true };
    case types.FETCH_USER_ACTIVE_POSTS_SUCCESS:
      return {
        ...state,
        active_posts_loading: false,
        active_posts: action.payload.data,
        active_posts_pagination: action.payload.pagination,
      };
    case types.FETCH_USER_INACTIVE_POSTS_SUCCESS:
      return {
        ...state,
        inactive_posts_loading: false,
        inactive_posts: action.payload.data,
        inactive_posts_pagination: action.payload.pagination,
      };
    case types.FETCH_USER_EXCHANGED_POSTS_SUCCESS:
      return {
        ...state,
        exchanged_posts_loading: false,
        exchanged_posts: action.payload.data,
        exchanged_posts_pagination: action.payload.pagination,
      };
    case types.FETCH_USER_ACTIVE_POSTS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case types.FETCH_USER_INACTIVE_POSTS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case types.FETCH_USER_EXCHANGED_POSTS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default profileReducer;
