import React from 'react';
import { Helmet } from 'react-helmet';
import qs from 'qs';

import { Responsive } from 'semantic-ui-react';
import { connect } from 'react-redux';

import SignupForm from './SignupForm';
import LoginForm from './LoginForm';

import { setAuthError } from '../../actions/user';
import circleBackground from '../../images/assistlist_background_circle.png'

const desktopDivStyle = {
  background: '#ffffff', 
  minHeight: '100vh', 
  backgroundSize: 'cover', 
  backgroundImage: `url(${circleBackground})`
};

const mobileTabletDivStyle = {
  background: '#ffffff', 
  minHeight: '100vh', 
};

const computerStyle = {
  marginBottom: '20px', 
  maxWidth: "1300px", 
  margin: '0 auto', 
  paddingTop: '24px', 
  paddingBottom: '100px',
}

const tabletStyle = {
  marginBottom: '20px',
  maxWidth: "750px", 
  margin: '0 auto', 
  padding: '24px 0px',   
}

const mobileStyle = {
  marginBottom: '120px', 
  maxWidth: "600px", 
  margin: '0 auto', 
  padding: '24px 0px',  
}


class Authentication extends React.Component {

  constructor(props){
    super(props);

    this.state ={
      isHide: true
    };
  }

  componentDidMount() {
    const { _setAuthError, location } = this.props;
    const { error } = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (typeof error !== "undefined" && error !== null) _setAuthError(error);
  }

  toggleHide = () => {
    this.setState({
      isHide: !this.state.isHide,
    })
  }
  
  render() {
    return (
      <div style={window.innerWidth <= Responsive.onlyTablet.maxWidth ? mobileTabletDivStyle : desktopDivStyle}>
        <Helmet>
          <title>Login/Signup - AssistList</title>
          <meta name="description" content="To create listings or contact someone with home health equipment listed, sign up for a free account. Already a member? Login here." />
        </Helmet>
        <Responsive minWidth={Responsive.onlyComputer.minWidth} >
          {window.location.href.includes('#login') ? (
            <LoginForm  toggleHide={this.toggleHide} isHide = {this.state.isHide} style={computerStyle}/> 
          ) : (
            <SignupForm  toggleHide={this.toggleHide} isHide = {this.state.isHide} style={computerStyle}/>
          )}
        </Responsive>
        <Responsive minWidth={Responsive.onlyTablet.minWidth} maxWidth={Responsive.onlyTablet.maxWidth} >
          {window.location.href.includes('#login') ? (
            <LoginForm toggleHide={this.toggleHide} isHide = {this.state.isHide} style={tabletStyle}/>
          ) : (
            <SignupForm  toggleHide={this.toggleHide} isHide = {this.state.isHide} style={tabletStyle}/>
          )}
        </Responsive>
        <Responsive maxWidth={Responsive.onlyMobile.maxWidth} >
          {window.location.href.includes('#login') ? (
            <LoginForm toggleHide={this.toggleHide} isHide = {this.state.isHide} style={mobileStyle}/>
          ) : (
            <SignupForm toggleHide={this.toggleHide} isHide = {this.state.isHide} style={mobileStyle}/>
          )}
        </Responsive>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  _setAuthError: error => dispatch(setAuthError(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Authentication);
