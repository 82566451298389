import React from 'react';
import { connect } from 'react-redux';
import { Container, Icon } from 'semantic-ui-react';
import ReactHtmlParser from 'react-html-parser';

import { fetchServices } from '../../actions/resources';
import './resources.css';


class AdditionalServices extends React.Component {

  componentDidMount() {
    const { _fetchServices } = this.props;
    _fetchServices();
  }

  render() {
    const { services } = this.props;
    return (

      <Container style={{margin: (window.innerWidth > 700? '0 auto': '0 5%'), maxWidth: '1120px', paddingBottom: '160px'}} >
        <div style={{paddingTop: '24px'}}>
          <a style={{textDecoration: 'none', color: '#575757', marginBottom: '40px'}} href='/resources'> <Icon name='angle left'/>Back to Resources </a>
          <h2 style={{color: '#0275B3', marginBottom: '16px'}}> Additional Services </h2>
          <p>Home health medical equipment is a valuable investment in one's health. Take advantage of these services and resources when exchanging used equipment on AssistList</p>

          <div style={{display: 'flex', flexDirection: 'column', marginTop: window.innerWidth > 800? '80px': ''}}>

          {(services && services.length > 0) ? services.map((service, i) =>
          (<Service image={service.logo.thumbnail}
                    sponsored={service.sponsored}
                    totalMapped={services.length}
                    counter={i}
                    name={service.name}
                    description={service.description}
                    address={service.address.address}
                    website={service.address.website}
                    phone={service.address.phone} />
          )) : null}

          </div>
        </div>
      </Container>
    )}
};

let imgDesktop = {width: '272px', height: '272px', objectFit: 'contain'}
let imgMobile = {width: '100%', height: '345px', objectFit: 'contain'}

const Service = (props) => (


  <div style={{padding: '40px 0px', borderBottom: (props.totalMapped-1 === props.counter? '':'0.5px solid #BDBDBD'), display: 'flex', flexWrap: window.innerWidth > 600? '' : 'wrap'}}>
    <div style={{float: 'left', border: '0.5px solid #BDBDBD', borderRadius: '5px', marginBottom: (window.innerWidth > 600? '': '32px'), width: (window.innerWidth > 600? '': '100%') }}>
      {props.sponsored ? <div className="sponsoredTag">Sponsored</div> : null}
      <img alt='step process' style={(window.innerWidth > 600? imgDesktop : imgMobile) } src={props.image}/>
    </div>

    <div style={{float:'right', marginLeft: (window.innerWidth > 600? '40px': '')}}>
      <h3 style={{marginBottom: '8px', color: '#0275B3'}}>{props.name}</h3>
      <p>{ ReactHtmlParser(props.description) }</p>
      {props.address && (<p><Icon color='grey' name='map marker alternate'/>&nbsp;&nbsp;{props.address}</p>)}
      {props.website && (<p><Icon color='grey' name='globe'/>&nbsp;&nbsp;<a target='_blank' rel="noopener noreferrer" href={`https://${props.website}`}>{props.website}</a></p>)}
      {props.phone && (<p><Icon color='grey' name='phone'/>&nbsp;&nbsp;{props.phone}</p>)}
    </div>
  </div>
)

const mapDispatchToProps = dispatch => ({
  _fetchServices: () => dispatch(fetchServices()),
});


const mapStateToProps = state => ({
  services: state.resources.services,
});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalServices);
