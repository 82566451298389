import React, { useState } from 'react';
import { Accordion, Icon } from 'semantic-ui-react';
import PriceFilterRenderer from './PriceFilterRenderer';
import FilterListRenderer from './FilterlistRenderer';

const FilterList = ({
  title,
  type,
  data,
  appliedFilters,
  addFilter,
  removeFilter,
  addPriceFilters,
  children,
}) => {
  const [show, setShow] = useState(getAccordionActive());

  function getAccordionActive() {
    const item = localStorage.getItem('filterActives');
    let filterActives = [];
    if(item) {
      filterActives = JSON.parse(item);
    }

    if(filterActives.some((e, index, array) => (e === title))) {
      return true;
    }

    return false;
  }  


  const setAccordionActive = () => {
    setShow(!show);

    let filterActives = [];
    const item = localStorage.getItem('filterActives');
    if( item) {
      filterActives = JSON.parse(item);
    }

    filterActives = filterActives.filter(item => item !== title); 


    if(!show) {
      filterActives.push(title);
    } 

    localStorage.setItem('filterActives', JSON.stringify(filterActives));
  }    

  return (
    <>
      <Accordion.Title
        style={{ fontSize: '1.05rem', paddingTop: '40px' }}
        onClick={setAccordionActive}
      >
        {title}
        <Icon
          style={{ float: 'right' }}
          name={show ? 'chevron down' : 'chevron right'}
        />
      </Accordion.Title>

      <Accordion.Content
        className={'filter-according-section'}
        active={show}
        style={{ padding: '0' }}
      >
        {children}
        {type === 'price' ? (
          <PriceFilterRenderer
            appliedFilters={appliedFilters}
            addPriceFilters={addPriceFilters}
            removeFilter={removeFilter}
          />
        ) : (
          <FilterListRenderer
            type={type}
            data={data}
            appliedFilters={appliedFilters}
            addFilter={addFilter}
            removeFilter={removeFilter}
          />
        )}
      </Accordion.Content>
    </>
  );
};

export default FilterList;
