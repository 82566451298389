import axios from 'axios';

import config from '../config';

export default axios.create({
  baseURL: config.apiUrl,
  timeout: 30000,
  headers: { Accept: 'application/json' },
});

export const userHeaders = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const auth_token = user && user["auth_token"];
  const email = user && user["email"];
  return { headers: { 'X-User-Token': auth_token, 'X-User-Email': email } };
};
