import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Icon, Responsive } from 'semantic-ui-react'

import ourTeam from '../../images/ourTeam.jpg'

const BannerBackground = () => (
	<div
		style={{
			color: '#ffffff',
			height: '250px',
			background: '#1A659F',
			position: 'relative',
			justifyContent: 'center',
		}}
	>
		<div
			style={
				window.innerWidth >= Responsive.onlyComputer.minWidth
					? {
							backgroundImage: `-webkit-linear-gradient(rgba(26, 101, 159, 0.7), rgba(80, 80, 80, 0.6)), url(${ourTeam})`,
							backgroundSize: 'cover',
							backgroundPosition: 'center',
							backgroundRepeat: 'no-repeat',
							height: '130%',
							width: '100%,',
					  }
					: {
							backgroundImage: `-webkit-linear-gradient(rgba(26, 101, 159, 0.7), rgba(80, 80, 80, 0.6)), url(${ourTeam})`,
							backgroundSize: 'cover',
							backgroundPosition: 'center',
							backgroundRepeat: 'no-repeat',
							height: '100%',
							width: '100%,',
					  }
			}
		>
			<Responsive minWidth={Responsive.onlyComputer.minWidth}>
				<Container>
					<h1 style={{ paddingTop: '50px', fontSize: '32px', fontWeight: 'bold', color: 'white' }}>
						Together, we can make a difference
					</h1>
					<Link to="/aboutus" style={{ fontSize: '18px', color: 'white' }}>
						How we support our community
						<Icon name="chevron right" size="small" />
					</Link>
				</Container>
			</Responsive>

			<Responsive minWidth={Responsive.onlyTablet.minWidth} maxWidth={Responsive.onlyTablet.maxWidth}>
				<Container>
					<h1 style={{ paddingTop: '50px', fontSize: '32px', fontWeight: 'bold', color: 'white' }}>
						Together, we can make a difference
					</h1>
					<Link to="/aboutus" style={{ fontSize: '18px', color: 'white' }}>
						How we support our community
						<Icon name="chevron right" size="small" />
					</Link>
				</Container>
			</Responsive>

			<Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
				<h1
					style={{
						paddingTop: '50px',
						paddingLeft: '18px',
						fontSize: '32px',
						fontWeight: 'bold',
						width: '300px',
						color: 'white',
					}}
				>
					Together, we can make a difference
				</h1>
				<Link to="/aboutus" style={{ paddingLeft: '18px', width: '300px', fontSize: '18px', color: 'white' }}>
					How we support our community
					<Icon name="chevron right" size="small" />
				</Link>
			</Responsive>
		</div>
	</div>
)

export default BannerBackground
