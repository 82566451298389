import React, { useState, useEffect } from 'react';
import { Form, Button } from 'semantic-ui-react';

const PriceFilterRender = ({
  appliedFilters,
  addPriceFilters,
  removeFilter,
}) => {
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);

  useEffect(() => {
    let minPriceFilter;
    let maxPriceFilter;

    appliedFilters.forEach(filter => {
      if (filter.type === 'min_price') {
        minPriceFilter = filter.value.value;
      }

      if (filter.type === 'max_price') {
        maxPriceFilter = filter.value.value;
      }
    });

    if (minPriceFilter) {
      setMinPrice(minPriceFilter);
    } else {
      setMinPrice('');
    }
    if (maxPriceFilter) {
      setMaxPrice(maxPriceFilter);
    } else {
      setMaxPrice('');
    }
  }, [appliedFilters]);

  const pricebox = type => {
    let typeLabel = type.charAt(0).toUpperCase() + type.slice(1) + ' Price';
    let placeholder = type === 'min' ? 'Free' : '$';

    return (
      <Form.Input
        value={type === 'min' ? minPrice : maxPrice}
        maxLength="6"
        label={<b style={{ fontSize: '12px' }}>{typeLabel}</b>}
        placeholder={placeholder}
        onChange={e => {
          let input = e.target.value.trim();

          if (input.length === 0) {
            removeFilter({ name: `${type}_price`, value: e.target.value });
          }

          if (type === 'min') {
            setMinPrice(input);
          } else {
            setMaxPrice(input);
          }
        }}
      />
    );
  };

  const handleApplyPriceFilter = () => {
    const integersOnly = /^[0-9]+$/;

    const priceFilters = [];

    if (maxPrice && maxPrice.match(integersOnly)) {
      priceFilters.push({ name: `max_price`, value: maxPrice });
    }
    if (minPrice && minPrice.match(integersOnly)) {
      priceFilters.push({ name: `min_price`, value: minPrice });
    }

    if (priceFilters) {
      addPriceFilters(priceFilters);
    }
  };

  return (
    <>
      <Form.Group unstackable widths={3}>
        {pricebox('min')}
        <hr
          style={{
            width: '5%',
            height: '1px',
            position: 'relative',
            top: '35px',
            backgroundColor: 'black',
          }}
        />
        {pricebox('max')}

        <div
          style={{
            position: 'relative',
            width: '61px',
          }}
        >
          <Button
            size="tiny"
            primary
            content="Apply"
            style={{
              width: '100%',
              position: 'absolute',
              bottom: '0',
              left: '0',
              padding: '0',
              height: '2.5rem',
            }}
            onClick={handleApplyPriceFilter}
          />
        </div>
      </Form.Group>
    </>
  );
};

export default PriceFilterRender;
