import types from '../types/modal';

const modalReducer = (state = [], action) => {
  switch (action.type) {
    case types.MODAL_SHOW:
      return { name: action.payload };
    case types.MODAL_HIDE:
      return action.payload === state.name ? {} : state;
    default:
      return state;
  }
};

export default modalReducer;
