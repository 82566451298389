import React from 'react';
import { Message } from 'semantic-ui-react';

const UnconfirmedUserMessage = () => (
  <Message warning>
    <Message.Header>This listing is hidden.</Message.Header>
    <p>Please confirm your account in order for it to be visible to others.</p>
  </Message>
);

export default UnconfirmedUserMessage;
