import types from '../types/resources';

const resourcesReducer = (
  state = {
    safeExchangeZones: [],
    services: [],
  },
  action,
) => {
  switch (action.type) {
    case types.FETCH_SAFE_EXCHANGE_ZONES:
    case types.FETCH_SERVICES:
      return { ...state} ;
    case types.FETCH_SAFE_EXCHANGE_ZONES_SUCCESS:
      return {
        ...state,
        safeExchangeZones: action.payload,
      };
    case types.FETCH_SERVICES_SUCCESS:
      return {
        ...state,
        services: action.payload,
      };
    case types.FETCH_SAFE_EXCHANGE_ZONES_FAILURE:
    case types.FETCH_SERVICES_FAILURE:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default resourcesReducer;
