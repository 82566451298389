import React from 'react';
import { Segment, Header, List } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import { HashLink as Link } from 'react-router-hash-link';
import PageContainer from '../PageContainer';


const Policies = () => (
  <PageContainer>
    <Helmet>
      <title>Policies - AssistList</title>
    </Helmet>
    <Segment>
      <Header as="h1" id="terms-of-use" content="Terms of Use" />
      <Header as="h3" content="WELCOME TO ASSISTLIST" />
      <p>
        Thank you for stopping by, we (AssistList Association) hope you find our site, services,
        applications and tools (collectively &quot;Services&quot;) helpful. These Terms of Use
        (&quot;TOU&quot;) (last updated January 3, 2018) posted on our site set out the terms on
        which we offer you access to and use our Services. By accessing or otherwise interacting
        with our servers, services, websites, or any associated content/listings, you agree to
        comply with the TOU. You acknowledge and agree AssistList is a private site owned and
        operated by AssistList Association. If you are accessing or using AssistList on behalf of a
        business, that business agrees to the TOU. If you do not agree to the TOU, you are not
        authorized to use AssistList. If you are dissatisfied with these terms or the AssistList
        website, your sole and unique remedy is to discontinue using and accessing AssistList. We
        may modify the TOU at any time in our sole discretion. You are responsible for periodically
        checking for changes and are bound by them if you continue to use AssistList. Our&nbsp;
        <a href="#privacy-policy">Privacy Policy</a>, <a href="#prohibited">Prohibited Content</a>
        &nbsp;list, and all other policies, site rules, and agreements referenced below or on
        AssistList, are fully incorporated into this TOU, and you agree to them as well.
      </p>
    </Segment>

    <Segment>
      <Header as="h3" content="LICENSE" />
      <p>
        If you agree to the TOU and (1) are of sufficient age and capacity to use AssistList and be
        bound by the TOU, or (2) use AssistList on behalf of a business, thereby binding that
        business to the TOU, we grant you a limited, revocable, non-exclusive, non-assignable
        license to use AssistList in compliance with the TOU; unlicensed use is unauthorized. You
        agree not to display, &quot;frame,&quot; make derivative works, distribute, license, or
        sell, content from AssistList, excluding listings you create. You grant us a perpetual,
        irrevocable, unlimited, worldwide, fully paid/sublicensable license to use, copy, display,
        distribute, and make derivative works from content you post.
      </p>
    </Segment>

    <Segment>
      <Header as="h3" content="USE" />
      <p>
        In order to access and use some or a portion of the Services, you are required to register
        with us and set up an account with your email address and a password (your “Account”). The
        email address you provide will be your email address, and you are solely responsible for
        maintaining the confidentiality of your password. You are solely responsible for all
        activities that occur under your Account. Therefore, you should protect your password and
        make your password difficult for others to guess. You may connect to the Services with a
        third-party service and you give us permission to access, store, and use your information
        from that service as permitted by that service and as may be described in our Privacy
        Policy. If you believe your Account may have been compromised or
        misused, <Link to="/aboutus#contact">Contact Us</Link> immediately.
      </p>
      <p>
        When using AssistList Services, you will post in the appropriate category or area and will
        not do any of the following bad things:
      </p>
      <List bulleted>
        <List.Item>
          post content that is prohibited by any of AssistList&apos;s policies or rules referenced
          above (&quot;Prohibited Content&quot;);
        </List.Item>
        <List.Item>
          create listings/communications/accounts on behalf of a for-profit corporation for the
          purpose of soliciting business or selling commercial goods
        </List.Item>
        <List.Item>
          create false, misleading, unsolicited, and/or unlawful listings/communications/accounts
        </List.Item>
        <List.Item>
          distribute or contain spam, chain letters, or pyramid schemes;
        </List.Item>
        <List.Item>
          infringe any third-party right;
        </List.Item>
        <List.Item>
          distribute viruses or any other technologies that may harm AssistList or the interests or
          property of AssistList users;
        </List.Item>
        <List.Item>
          impose an unreasonable load on our infrastructure or interfere with the proper working of
          the AssistList;
        </List.Item>
        <List.Item>
          use or provide software (except general purpose web browsers and email clients) or
          services that interact or interoperate with AssistList, e.g. for downloading, uploading,
          creating/accessing/using an account, creating a listing, flagging, emailing, searching,
          or mobile use;
        </List.Item>
        <List.Item>
          access/copy/collect AssistList content via use of any robot, spider, script, scraper,
          crawler, or automated or manual equivalent for any purpose without our express written
          permission;
        </List.Item>
        <List.Item>
          copy, modify, distribute, harvest or otherwise collect any other person&apos;s content or
          user information without their consent;
        </List.Item>
        <List.Item>
          buy or sell AssistList accounts;
        </List.Item>
        <List.Item>
          abuse AssistList&apos;s flagging or reporting processes;
        </List.Item>
        <List.Item>
          bypass measures used to prevent or restrict access to AssistList
        </List.Item>
      </List>
      <p>
        You agree that AssistList Association may moderate the AssistList website access/use in our
        sole discretion, e.g., by blocking, filtering, re-categorizing, re-ranking, deleting,
        delaying, holding, omitting, verifying, or terminating your access/license/account. You
        further agree that:
      </p>
      <List bulleted>
        <List.Item>
          you will not bypass said moderation;
        </List.Item>
        <List.Item>
          AssistList Association is not liable for moderating or not moderating;
        </List.Item>
        <List.Item>
          nothing AssistList Association says or does waives our right to moderate, or not moderate.
        </List.Item>
      </List>
    </Segment>

    <Segment>
      <Header as="h3" content="DAMAGES" />
      <p>
        You further agree that if you violate the USE section, or you encourage, support, benefit
        from, or induce others to do so, you will be jointly and severally liable to us for
        damages, including but not limited to the following activities: (A) collecting/harvesting
        AssistList users&apos; information, including personal or identifying information; (B)
        publishing/misusing personal or identifying information of a third party in connection with
        your use of AssistList without that party&apos;s express written consent; (C)
        misrepresenting your identity or affiliation to anyone in connection with your use of
        AssistList; (D) listing or attempting to list Prohibited Content; (E) sending an
        unauthorized/unsolicited email to an email address obtained from AssistList; (F) using
        AssistList user information to make/send an unauthorized/unsolicited text message, call, or
        communication to a AssistList user; (G) creating a misleading or unlawful AssistList
        account or buying/selling a AssistList account; (H) abusing or attempting to abuse
        AssistList&apos;s flagging or reporting processes; (I) distributing any software to
        facilitate violations of the USE Section; (J) aggregating, displaying, copying,
        duplicating, reproducing, distributing, or exploiting AssistList content for any purpose
        without our express written consent; (K) requesting, viewing, or accessing more than 1,000
        pages of AssistList in any 24-hour period; (L) bypassing or attempting to bypass our
        moderation efforts. You agree that repeated violations of the USE section will irreparably
        harm and entitle us to injunctive or equitable relief, in addition to monetary damages.
      </p>
    </Segment>

    <Segment>
      <Header as="h3" content="DISCLAIMER AND LIABILITY" />
      <p>
        To the full extent permitted by law, AssistList Association, and its officers, directors,
        employees, agents, licensors, affiliates, and successors in interest (&quot;AssistList
        Entities&quot;) (1) make no promises, warranties, or representations as to AssistList,
        including its completeness, accuracy, availability, timeliness, propriety, security or
        reliability; (2) provide AssistList on an &quot;AS IS&quot; and &quot;AS AVAILABLE&quot;
        basis and any risk of using AssistList is assumed by you; (3) disclaim all warranties,
        express or implied, including as to accuracy, merchantability, fitness for a particular
        purpose, and non-infringement; and (4) disclaim any liability or responsibility for acts,
        omissions, or conduct of you or any party in connection with AssistList. AssistList
        Entities are NOT liable for any direct, indirect, consequential, incidental, special,
        exemplary, punitive, or other losses, including lost profits, revenues, data, goodwill,
        etc., arising out of or in connection with the use of the AssistList website, links with
        other websites or social media, the products, or the transactions conducted on or from the
        AssistList website or social media, even if AssistList Association or any of its lawful
        agents, contractors, employees or mandatories have been previously advised of the
        possibility of such damages or claim.
      </p>
      <p>
        AssistList Association is not responsible for any content provided by its users, external
        links or other third parties to the AssistList website. The contributions of third parties
        do not necessarily represent the view or opinions of AssistList. Users can be held liable
        for any illegal or prohibited content they provide to the site, including among other
        things, infringing, defamatory or offensive materials. If you discover this kind of
        material on the site, please <Link to="/aboutus#contact">Contact Us</Link> to tell us about
        any problems so that together we can keep AssistList working properly. We will take
        appropriate action, such as limiting or terminating our Services, removing hosted content
        and taking technical and legal steps to keep users off AssistList if we think they are
        creating problems or acting inconsistently with the letter or spirit of our policies.
        However, whether we decide to take any of these steps, remove hosted content or keep a user
        off AssistList or not, we do not accept any liability for monitoring AssistList or for
        unauthorized or unlawful content on AssistList or use of AssistList by users. Without
        limiting the foregoing, in no event will AssistList Association be liable for damages or
        losses due to misuse by other AssistList users, their respective employees, agents,
        mandataries or subcontractors, or other events beyond the reasonable control of AssistList
        Association, including but not limited to the following activities: (i) scams, mislabeled
        or incorrectly labelled products and/or equipment sold by sellers through the AssistList
        website; (ii) viruses including computer or web based viruses, data corruption, failed
        messages, damages arising as a result of same or transmission errors or problems,
        telecommunications service providers, AssistList Association’s Partners, the internet
        backbone, third-party suppliers of products or services.
      </p>
    </Segment>

    <Segment>
      <Header as="h3" content="CLAIMS AND INDEMNITY" />
      <p>
        Any claim, cause of action, demand, or dispute arising from or related to AssistList
        Association (&quot;Claims&quot;) will be governed by the internal laws of British Columbia,
        without regard to conflict of law provisions, except to the extent governed by Canadian
        federal law. Any Claims will be exclusively resolved by courts in Vancouver, BC (except we
        may seek preliminary or injunctive relief anywhere). You agree to (1) submit to the
        personal jurisdiction of courts in Vancouver, BC; (2) indemnify and hold AssistList
        Entities harmless from any Claims, losses, liability, or expenses (including
        attorneys&apos; fees) that arise from a third party and relate to your use of AssistList;
        and (3) be liable and responsible for any Claims we may have against your officers,
        directors, employees, agents, affiliates, or any other party, directly or indirectly, paid,
        directed or controlled by you, or acting for your benefit.
      </p>
    </Segment>

    <Segment>
      <Header as="h3" content="PRIVACY POLICY" id="privacy-policy" />
      <p>
        This policy details how data about you is used when you access our websites and services or
        interact with us.
      </p>
      <Header as="h4" content="General" />
      <p>
        By using AssistList, you agree to the collection, transfer, storage and use of your
        personal information by AssistList Association on servers located in the United States.
      </p>
      <Header as="h4" content="Protecting your privacy" />
      <List bulleted>
        <List.Item>
          We take precautions to prevent unauthorized access to or misuse of data about you.
        </List.Item>
        <List.Item>
          We do not share your data with third parties for marketing purposes.
        </List.Item>
        <List.Item>
          We do not employ tracking devices for marketing purposes.
        </List.Item>
        <List.Item>
          Please review privacy policies of any third party sites linked to from AssistList.
        </List.Item>
      </List>
      <Header as="h4" content="Data we use to provide/improve our services and/or combat fraud/abuse:" />
      <List bulleted>
        <List.Item>
          data you post on or send via AssistList, and/or send us directly or via other sites
        </List.Item>
        <List.Item>
          credit card data, which is transmitted to payment processors via a security protocol
          (e.g. SSL).
        </List.Item>
        <List.Item>
          data you submit or provide (e.g. name, address, email, phone, fax, photos, tax ID).
        </List.Item>
        <List.Item>
          web log data (e.g. web pages viewed, access times, IP address, HTTP headers).
        </List.Item>
        <List.Item>
          data collected via cookies (e.g. search data and &quot;favorites&quot; lists).
        </List.Item>
        <List.Item>
          data about your device(s) (e.g. screen size, DOM local storage, plugins).
        </List.Item>
        <List.Item>
          data from 3rd parties (e.g. phone type, geo-location via IP address).
        </List.Item>
      </List>
      <Header as="h4" content="Data we store" />
      <List bulleted>
        <List.Item>
          We retain data as needed for our business purposes and/or as required by law.
        </List.Item>
        <List.Item>
          We make good faith efforts to store data securely, but can make no guarantees.
        </List.Item>
        <List.Item>
          You may access and update certain data about you via your account login.
        </List.Item>
      </List>
      <Header as="h4" content="Circumstances in which we may disclose user data:" />
      <List bulleted>
        <List.Item>
          with your consent (e.g. if you authorize us to share data with other users);
        </List.Item>
        <List.Item>
          to respond to subpoenas, search warrants, court orders, or other legal process;
        </List.Item>
        <List.Item>
          to protect our rights, property, or safety, or that of users of AssistList or the general
          public;
        </List.Item>
        <List.Item>
          in connection with a merger, bankruptcy, or sale/transfer of assets;
        </List.Item>
        <List.Item>
          in aggregate/summary form, where it cannot reasonably be used to identify you.
        </List.Item>
      </List>
    </Segment>

    <Segment>
      <Header as="h3" content="PROHIBITED CONTENT" id="prohibited" />
      <p>
        Users must comply with all applicable laws, the AssistList Association Terms of Use, and
        all posted site rules. Here is a partial list of goods, services, and content prohibited on
        AssistList:
      </p>
      <List bulleted>
        <List.Item>
          <a href="http://www.hc-sc.gc.ca" target="_blank" rel="noopener noreferrer">
            recalled items
          </a>
          , prohibited list; hazardous materials; body parts/fluids; unsanitized bedding/clothing
        </List.Item>
        <List.Item>
          prescription drugs, medical devices; controlled substances and related items
        </List.Item>
        <List.Item>
          alcohol or tobacco; unpackaged or adulterated food or cosmetics
        </List.Item>
        <List.Item>
          false, misleading, deceptive, or fraudulent content; bait and switch; keyword spam
        </List.Item>
        <List.Item>
          offensive, obscene, defamatory, threatening, or malicious listings or email
        </List.Item>
        <List.Item>
          anyone’s personal, identifying, confidential or proprietary information
        </List.Item>
        <List.Item>
          food stamps, WIC vouchers, SNAP or WIC goods, governmental assistance
        </List.Item>
        <List.Item>
          stolen property, property with serial number removed/altered, burglary tools, etc
        </List.Item>
        <List.Item>
          counterfeit, replica, or pirated items; tickets or gift cards that restrict transfer
        </List.Item>
        <List.Item>
          spam; miscategorized, overposted, cross-posted, or nonlocal content
        </List.Item>
        <List.Item>
          listings or email posted by a for-profit corporation soliciting business or selling
          commercial goods
        </List.Item>
        <List.Item>
          listings or email the primary purpose of which is to drive traffic to a website
        </List.Item>
        <List.Item>
          listings or email offering, promoting, or linking to unsolicited products or services
        </List.Item>
        <List.Item>
          affiliate marketing; network, or multi-level marketing; pyramid schemes
        </List.Item>
        <List.Item>
          any good, service, or content that violates the law or legal rights of others
        </List.Item>
      </List>
    </Segment>

    <Segment style={{ marginBottom: '20px' }}>
      <Header as="h3" content="MISC" />
      <p>Users complying with prior written licenses may access AssistList thereby until authorization is terminated. Otherwise this is the exclusive and entire agreement between us and you, and our actions or silence toward you or anyone else does not waive, modify, or limit the TOU or our ability to enforce it. The USE, LIQUIDATED DAMAGES, and CLAIMS AND INDEMNITY sections survive termination of the TOU, and you will remain bound by those sections. If a TOU term is unenforceable, it shall be limited to the least extent possible and supplemented with a valid provision that best embodies the intent of the parties. If you have any questions, please <Link to="/aboutus#contact">Contact Us</Link>.</p>
    </Segment>

  </PageContainer>
);

export default Policies;
