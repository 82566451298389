import React from 'react';

import {Grid, Responsive } from 'semantic-ui-react';

const textStyling = {fontFamily: 'Karla, Arial, Helvetica, sans-serif', fontSize: '16px', lineHeight: '19px', color: '#757575'}

const WaitingPeople = ({
  waitingPeople,
}) => (waitingPeople !== 0 && (
<Grid.Column>
  <Responsive {...Responsive.onlyMobile}>
    <p style={textStyling}>
      {waitingPeople}{' '}{waitingPeople === 1 ? 'person is' : 'people are'} subscribed to notifications for this item
    </p>
  </Responsive>
  <Responsive
    minWidth={Responsive.onlyTablet.minWidth}
  >

    <p style={textStyling}>
       {waitingPeople}{' '}{waitingPeople === 1 ? 'person is' : 'people are'} subscribed to notifications for this item
    </p>
  </Responsive>
</Grid.Column>
));

export default WaitingPeople;
