import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';


import aboutUsReducer from './aboutus';
import alertsReducer from './alerts';
import faqReducer from './faq';
import recentListingsReducer from './recentlistings';
import relatedListingsReducer from './relatedlistings';
import listingsReducer from './listings';
import modalReducer from './modal';
import notificationsReducer from './notifications';
import partnersReducer from './partners';
import postReducer from './post';
import profileReducer from './profile';
import resourcesReducer from './resources';
import userReducer from './user';
import blogReducer from './blog'
import donateReducer from './donate'
import bannerReducer from './banner';

export default combineReducers({
  donatepagedata: donateReducer,
  blog: blogReducer,
  aboutus: aboutUsReducer,
  alerts: alertsReducer,
  form: formReducer,
  faq: faqReducer,
  listings: listingsReducer,
  modal: modalReducer,
  notifications: notificationsReducer,
  partners: partnersReducer,
  post: postReducer,
  profile: profileReducer,
  user: userReducer,
  recentlistings: recentListingsReducer,
  relatedlistings: relatedListingsReducer,
  resources: resourcesReducer,
  banners: bannerReducer
})
