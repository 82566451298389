import React from 'react'
import { Loader, Container, Menu, Button, Dropdown, Responsive } from 'semantic-ui-react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import './partners.css'
import background from '../../images/backGround.png'
import groupHandImage from '../../images/groupHand.png'
import { fetchPartners } from '../../actions/partners'
import { Partner, MobilePartner } from './Partner'

class Partners extends React.Component {
	state = { activeItem: 'All' }

	handleItemClick = (e, { name }) => this.setState({ activeItem: name })

	componentDidMount() {
		const { _fetchPartners } = this.props
		_fetchPartners()
	}

	render() {
		const { activeItem } = this.state

		const non_profits_partners = this.props.partners.non_profits_partners
		const funding_partners = this.props.partners.funding_partners
		const corporate_partners = this.props.partners.corporate_partners
		const loading = this.props.partners.loading
		const all_partners = non_profits_partners.concat(funding_partners, corporate_partners)

		return (
			<div style={{ background: 'white', backgroundSize: 'cover', backgroundImage: `url(${background})` }}>
				<Container
					style={{
						margin: window.innerWidth > 700 ? '0 auto' : '0 5%',
						maxWidth: '1120px',
						paddingTop: '40px',
						paddingBottom: '160px',
					}}
				>
					{' '}
					<Helmet>
						<title>AssistList - Community Partners</title>
						<meta
							name="description"
							content="AssistList collaborates with governments, non-profit organizations, and businesses to strengthen public health services."
						/>
					</Helmet>
					<div>
						<h1 style={{ color: '#0275B3' }}>Our Partners</h1>
						<p style={{ margin: '40px 0px 40px 0px' }}>
							AssistList is proud to collaborate with governments, non-profit organizations and businesses to strengthen
							public health services. Like us, our community partners envision a safe platform for seniors and persons
							with disabilities to exchange home health medical equipment across Metro Vancouver.
						</p>
					</div>
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<Responsive minWidth={600}>
							<Menu className="partnersMenu" fluid widths={4}>
								<Menu.Item name="All" active={activeItem === 'All'} onClick={this.handleItemClick}>
									All
								</Menu.Item>
								<Menu.Item name="Funding" active={activeItem === 'Funding'} onClick={this.handleItemClick}>
									Funding
								</Menu.Item>
								<Menu.Item name="Non-Profits" active={activeItem === 'Non-Profits'} onClick={this.handleItemClick}>
									Non-Profits
								</Menu.Item>
								<Menu.Item name="Corporate" active={activeItem === 'Corporate'} onClick={this.handleItemClick}>
									Corporate
								</Menu.Item>
							</Menu>
						</Responsive>
						<Responsive maxWidth={599}>
							<Dropdown text={activeItem}>
								<Dropdown.Menu>
									<Dropdown.Item name="All" text="All" active={activeItem === 'All'} onClick={this.handleItemClick} />
									<Dropdown.Item
										name="Funding"
										text="Funding"
										active={activeItem === 'Funding'}
										onClick={this.handleItemClick}
									/>
									<Dropdown.Item
										name="Non-Profits"
										text="Non-Profits"
										active={activeItem === 'Non-Profits'}
										onClick={this.handleItemClick}
									/>
									<Dropdown.Item
										name="Corporate"
										text="Corporate"
										active={activeItem === 'Corporate'}
										onClick={this.handleItemClick}
									/>
								</Dropdown.Menu>
							</Dropdown>
						</Responsive>
					</div>
					<div style={{ marginTop: '62px', marginBottom: '96px' }}>
						{all_partners !== undefined && all_partners.length !== 0 && this.state.activeItem === 'All' && (
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									flexWrap: 'wrap',
									justifyContent: window.innerWidth > 600 ? 'center' : 'space-between',
								}}
							>
								<Loader active={loading}>Loading</Loader>
								{all_partners.map((partner) =>
									window.innerWidth > 1025 ? <Partner partner={partner} /> : <MobilePartner partner={partner} />
								)}
							</div>
						)}
						{non_profits_partners !== undefined &&
							non_profits_partners.length !== 0 &&
							this.state.activeItem === 'Non-Profits' && (
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										flexWrap: 'wrap',
										justifyContent: window.innerWidth > 600 ? 'center' : 'space-between',
									}}
								>
									<Loader active={loading}>Loading</Loader>
									{non_profits_partners.map((partner) =>
										window.innerWidth > 1025 ? <Partner partner={partner} /> : <MobilePartner partner={partner} />
									)}
								</div>
							)}
						{funding_partners !== undefined && funding_partners.length !== 0 && this.state.activeItem === 'Funding' && (
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									flexWrap: 'wrap',
									justifyContent: window.innerWidth > 600 ? 'center' : 'space-between',
								}}
							>
								<Loader active={loading}>Loading</Loader>
								{funding_partners.map((partner) =>
									window.innerWidth > 1025 ? <Partner partner={partner} /> : <MobilePartner partner={partner} />
								)}
							</div>
						)}
						{corporate_partners !== undefined &&
							corporate_partners.length !== 0 &&
							this.state.activeItem === 'Corporate' && (
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										flexWrap: 'wrap',
										justifyContent: window.innerWidth > 600 ? 'center' : 'space-between',
									}}
								>
									<Loader active={loading}>Loading</Loader>
									{corporate_partners.map((partner) =>
										window.innerWidth > 1025 ? <Partner partner={partner} /> : <MobilePartner partner={partner} />
									)}
								</div>
							)}
					</div>
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<img
							alt="group hand illustration"
							style={{ maxWidth: window.innerWidth > 500 ? '522px' : '100%' }}
							src={groupHandImage}
						/>
					</div>
					<div style={{ textAlign: 'center', margin: '56px 27%' }}>
						<h1 style={{ color: '#0280BB' }}> Interested in partnering with us? </h1>
						<p>
							We’re always looking to make an impact in the communities we serve. Connect with us to learn more about
							our partnership opportunities.
						</p>
						<Button primary basic id="connectButton" href="/aboutus/contactus">
							{' '}
							Let's Connect{' '}
						</Button>
					</div>
				</Container>
			</div>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	_fetchPartners: () => dispatch(fetchPartners()),
})

const mapStateToProps = (state) => ({
	partners: state.partners,
})

export default connect(mapStateToProps, mapDispatchToProps)(Partners)
