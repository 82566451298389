import React, { useEffect } from 'react';
import { Responsive, Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { fetchTeamLeads } from '../../actions/aboutus';


const TeamLeadContainer = props => (

  <div style={{ textAlign: 'center', padding: '0px 30px 16px 0px' }}>
    <img
      style={{
        width: '172px', height: '189px', paddingBottom: '16px', borderRadius: '60%', objectFit: 'cover',
      }}
      src={props.teamLeadPic}
      alt="Twinky profile"
    />
    <p style={{
      fontFamily: 'Karla, Arial, Helvetica, sans-serif', marginBottom: '7px', fontSize: '18px', lineHeight: '21px',
    }}
    >{props.teamLeadName}
    </p>
    <div style={{ height: '38px', width: '160px' }}>
      <p style={{
        fontFamily: 'Karla, Arial, Helvetica, sans-serif', fontWeight: 'bold', fontSize: '16px', lineHeight: '19px',
      }}
      >{props.teamLeadTitle}
      </p>
    </div>
  </div>
);


const TeamLeadContainerMobile = props => (

  <Modal
    style={{ height: '344px', width: '231px' }}
    trigger={(
      <div style={{ height: '168px', width: '155px', margin: '0px 6px 6px 6px' }}>
        <img
          style={{ height: '168px', width: '155px', borderRadius: '10px' }}
          src={props.teamLeadPic}
          alt="Team lead"
        />
      </div>
    )}
    closeIcon
  >

    <Modal.Content>

      <img
        style={{
          marginTop: '35px', marginLeft: '20px', height: '165px', width: '166px', borderRadius: '50%',
        }}
        src={props.teamLeadPic}
        alt="Kevin profile"
      />

      <Modal.Description>
        <div style={{ textAlign: 'center' }}>
          <p><b>{props.teamLeadName}</b></p>
          <p style={{ fontFamily: 'Karla, Arial, Helvetica, sans-serif', lineHeight: '21px', fontSize: '18px' }}>{props.teamLeadTitle}</p>
        </div>

      </Modal.Description>
    </Modal.Content>
  </Modal>

);

const TeamLeads = ({ teamLeads, _fetchTeamLeads }) => {

  useEffect(() => {
    _fetchTeamLeads();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Responsive minWidth={820}>
        <div style={{ display: 'flex', flexFlow: 'row', flexWrap: 'wrap' }}>
          {(teamLeads && teamLeads.length > 0) ? teamLeads.map((teamLeads, i) => (i === 0 ? (
            <div key={teamLeads.name} className="active">
              <TeamLeadContainer
                teamLeadPic={teamLeads.picture.thumbnail}
                teamLeadName={teamLeads.name}
                teamLeadTitle={teamLeads.title}
              />
            </div>
          ) : (
              <div key={teamLeads.name}>
                <TeamLeadContainer
                  teamLeadPic={teamLeads.picture.thumbnail}
                  teamLeadName={teamLeads.name}
                  teamLeadTitle={teamLeads.title}
                />
              </div>
            ))) : <div />}
        </div>
      </Responsive>
      <Responsive maxWidth={819}>
        <div style={{ display: 'flex', flexFlow: 'row', flexWrap: 'wrap' }}>
          {(teamLeads && teamLeads.length > 0) ? teamLeads.map((tLead, i) => (i === 0 ? (
            <div className="active" key={tLead.name}>
              <TeamLeadContainerMobile
                teamLeadPic={tLead.picture.thumbnail}
                teamLeadName={tLead.name}
                teamLeadTitle={tLead.title}
              />
            </div>
          ) : (
              <div key={tLead.name}>
                <TeamLeadContainerMobile
                  teamLeadPic={tLead.picture.thumbnail}
                  teamLeadName={tLead.name}
                  teamLeadTitle={tLead.title}
                />
              </div>
            ))) : <div />}
        </div>
      </Responsive>
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  _fetchTeamLeads: () => dispatch(fetchTeamLeads()),
});

const mapStateToProps = state => ({
  teamLeads: state.aboutus.teamLeads,
  loading: state.aboutus.loading,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TeamLeads);
