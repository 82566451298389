import React from 'react';
import {
  Grid,
  Label,
  Segment,
  Header
} from 'semantic-ui-react';
import './post.css';
import '../../stylesheets/postcomponent.css';

import ShareButton from "./LayoutButtons/ShareButton.js";
import ReportButton from "./LayoutButtons/ReportButton.js";
import PostImages from './PostImages';

const PostDetail = ({ post, shareDisabled, reportActive }) => {

  const {
    equipment_type, location, category, title, properties,
    description, user, condition, id, updated_at, price,
  } = post;

  //error handling for when a user enters 0 into the price field instead of checking free
  (!price ? post.free = true : post.free = false)

  return (
    <div>
      <Grid style={{ margin: 'auto 0' }}>
        <Grid.Row only="computer tablet" style={{paddingTop: '0px'}}>
          <Grid.Column
            floated="right"
            width={8}
            textAlign="left"
            verticalAlign="middle"
          >
            {location && [
              <span
                className="subtext"
                style={{
                  fontSize: '1em',
                  lineHeight: '1em',
                }}
              >
                {`${location.name} `}
              </span>,
            ]}
          </Grid.Column>

          <Grid.Column
            floated="left"
            width={8}
            textAlign="right"
            verticalAlign="middle"
          >
            {id && (
              <span
                className="subtext"
                style={{
                  fontSize: '1em',
                  lineHeight: '1em',
                }}
              >
                &nbsp;Last Updated&nbsp;
              </span>
            )}
            {updated_at && (
              <span
                className="subtext"
                style={{
                  fontSize: '1em',
                  lineHeight: '1em',
                }}
              >
                {`${updated_at}`}
              </span>
            )}
          </Grid.Column>
          <br />

          <Grid.Column floated="left" width={11} style={{marginLeft: '-2px', marginTop: '16px'}} as="h1">
            {title.length > 140
              ? `${title.substring(0, 140)}...`
              : title}
          </Grid.Column>
          <Grid.Column />
        </Grid.Row>
        <Grid.Row only="computer tablet">
          <Grid.Column floated="left" width={1} as="h4">
            <Header
              size="medium"
              className={post.free ? 'free' : 'price'}
              content={post.free ? 'FREE' : `$${price}`}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <PostImages post={post} />
      <Segment vertical>
      <Grid className="postTitle" style={{paddingTop: '32px'}}>
        <Grid.Row only="mobile" style={{ padding: '16px' }}>
            <Grid.Row style={{display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', paddingTop: 0, margin: 0 }}>
              {location && [
                <p
                  style={{
                    margin: '0.5em 0em 0.5em',
                    fontSize: '1em',
                    lineHeight: '1em',
                    color: 'rgba(0, 0, 0, 0.6)',
                  }}
                >
                  {`${location.name} `}
                </p>,
              ]}
              {location && updated_at && (
                <p
                  style={{
                    margin: '0.5em 0em 0.5em',
                    fontSize: '1em',
                    lineHeight: '1em',
                    color: 'rgba(0, 0, 0, 0.6)',
                  }}
                >
                </p>
              )}
              {updated_at && (
                <p
                  style={{
                    margin: '0.5em 0em 0.5em',
                    fontSize: '1em',
                    lineHeight: '1em',
                    color: 'rgba(0, 0, 0, 0.6)',
                  }}
                >
                  {`${updated_at}`}
                </p>
              )}
            </Grid.Row>
            <Grid.Row only="mobile" style={{ fontSize: '22px', marginTop: '8px'}}>
            <h3>
            {title.length > 140
              ? `${title.substring(0, 140)}...`
              : title}
            </h3>
            </Grid.Row>
          </Grid.Row>

          <Grid.Row only="mobile" style={{ padding: '0px 16px 24px 16px' }}>
            <h3 style={{fontSize: '20px'}} className={post.free ? 'free' : 'price'}>
              {post.free ? 'FREE' : `$${price}`}
            </h3>
          </Grid.Row>
      </Grid>
      </Segment>

        <Header
            as="h3"
            style={{marginTop: '24px', fontSize: '18px'}}
            content={'Description'}
          />
        <p style={{ whiteSpace: 'pre-line', wordWrap: 'break-word' }}>
          {description}
        </p>

      {properties && Object.keys(properties).length > 0 && (
        <div style={{marginTop: '16px'}}>
          <Header
            as="h3"
            style={{marginTop: '24px', fontSize: '18px'}}
            content={`${equipment_type.name} Properties`}
          />
          {Object.keys(properties).map(property => (
            <p style={{marginBottom: '8px'}}>
              <strong>{property}</strong>: {properties[property]}
            </p>
          ))}
        </div>
      )}

      <div style={{marginTop: '24px'}}>
        {condition && (<p style={{color: '#575757', marginBottom: '24px'}}>Condition: {condition.name}</p>)}
        {category && (<p style={{color: '#575757'}}>Category: {category.name}, {equipment_type.name}</p>)}
      </div>
        {user.partner && user.partner.name && (
          <Label
            color="green"
            icon="check"
            content={user.partner.name}
          />
        )}
      <div style={{marginTop:'32px', display: 'flex', flexDirection: 'row', width: '320px', justifyContent: 'space-between'}}>
        <ShareButton shareDisabled={shareDisabled}/>
        <ReportButton reportActive={reportActive}/>
      </div>
    </div>
  );
};

export default PostDetail;
