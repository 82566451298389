import React from "react";
import { Container, Form, Grid } from "semantic-ui-react";
import { reduxForm, reset, Field, SubmissionError } from "redux-form";
import { Helmet } from "react-helmet";
import { showAlert } from "../../actions/alerts";
import api from "../../utils/api";
import {
  InputField,
  TextAreaField,
  RecaptchaField,
  CheckboxField,
} from "../../utils/render-input";
import AboutUsHeader from "./AboutUsHeader";
import ContactUsFaqs from "./ContactUsFaqs";
import rectangle from "../../images/rectangle.png";
import facebook from "../../images/facebookGray.svg";
import twitter from "../../images/twitterGray.svg";
import instagram from "../../images/igGray.svg";
import linkedin from "../../images/linkedinGray.svg";

const submitForm = (values, dispatch) =>
  api
    .post("/contact_us", values)
    .then(() => {
      dispatch(reset("generalContact"));
      window.grecaptcha.reset();
      dispatch(
        showAlert({
          header: "Message sent",
          message: "Your message has been sent successfully",
          type: "success",
          dismissAfter: 5000,
        })
      );
    })
    .catch((error) => {
      dispatch(
        showAlert({
          header: "Message not sent",
          flowId: error.response?.data?.data?.flow_id,
          message: "Your message was not sent",
          type: "error",
        })
      );
      throw new SubmissionError({
        _error: error.response.data.error,
      });
    });

const validate = (values) => {
  const errors = {};

  if (!values.sender_email) {
    errors.sender_email = "Email address is required";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.sender_email)
  ) {
    errors.sender_email = "Invalid email address";
  }

  if (!values.body) {
    errors.body = "Message is required";
  }

  if (!values.recaptcha) {
    errors.recaptcha = "Recaptcha required";
  }

  if (!values.termsOfUse) {
    errors.termsOfUse = "You must agree to the Terms of Use";
  }

  return errors;
};

const ContactUsHeader = () => {
  return (
    <div>
      <AboutUsHeader picture="Contact Us" />
      <Helmet>
        <title>Contact Us - AssistList</title>
        <meta
          name="description"
          content="Have questions or feedback? Want to work with us? Find out how you can contact us."
        />
      </Helmet>
    </div>
  );
};

const ContactUsDescription = () => {
  return (
    <div>
      <img src={rectangle} alt="" />
      <h1
        style={{
          fontFamily: "'Nunito', Arial, Helvetica, sans-serif",
          fontWeight: "bold",
          fontSize: "32px",
          marginTop: "10px",
          paddingBottom: "25px",
        }}
      >
        Contact Us
      </h1>
      <p
        style={{
          width: "80%",
          fontFamily: "Karla",
          fontStyle: "normal",
          fontWeight: "normal",
          fontSize: "18px",
          lineHeight: "21px",
        }}
      >
        We want to hear from you! Let us know if you have questions or feedback,
        or if you'd like to support our charity through volunteering or
        donations.
      </p>
      <div style={{ marginTop: "50px" }}>
        <a
          href="https://www.facebook.com/AssistList/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          <img src={facebook} alt="facebook_link" />
        </a>
        <a
          style={{ marginLeft: "2.5em" }}
          href="https://www.instagram.com/assistlist/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          <img src={instagram} alt="instagram_link" />
        </a>
        <a
          style={{ marginLeft: "2.5em" }}
          href="https://twitter.com/assistlistca"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          <img src={twitter} alt="twitter_link" />
        </a>
        <a
          style={{ marginLeft: "2.5em" }}
          href="https://www.linkedin.com/company/assistlist/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          <img src={linkedin} alt="linkedin_link" />
        </a>
      </div>
    </div>
  );
};

const ContactForm = ({ error, handleSubmit, submitting }) => (
  <div>
    <ContactUsHeader />
    <Container
      style={{
        margin: "0 30",
        maxWidth: "1080px",
        paddingTop: "80px",
        paddingBottom: "120px",
      }}
    >
      <Grid stackable="computer">
        <Grid.Column computer={8}>
          <ContactUsDescription />
          <ContactUsFaqs />
        </Grid.Column>
        <Grid.Column computer={8} style={{ marginTop: "20px" }}>
          <Form name="generalContact" onSubmit={handleSubmit(submitForm)}>
            <Field
              component={InputField}
              name="sender_name"
              className=".fs-exclude"
              placeholder="Name"
            />
            <Field
              component={InputField}
              name="sender_email"
              className=".fs-exclude"
              autoComplete="email"
              placeholder="Email Address"
            />
            <Field
              component={TextAreaField}
              name="body"
              rows={5}
              placeholder="Message"
            />
            <Field component={RecaptchaField} name="recaptcha" />
            <Field
              component={CheckboxField}
              name="termsOfUse"
              label={
                <label
                  style={{
                    color: "#646466",
                    fontFamily: "Karla",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "18px",
                    lineHeight: "21px",
                  }}
                >
                  I agree with the Privacy Policy and Terms & Conditions
                </label>
              }
            />
            {error && <p style={{ color: "red" }}>{error}</p>}
            <Form.Button
              type="submit"
              primary
              content="Send Message"
              loading={submitting}
            />
          </Form>
        </Grid.Column>
      </Grid>
    </Container>
  </div>
);

export default reduxForm({
  form: "generalContact",
  validate,
})(ContactForm);
