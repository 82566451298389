import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';

export const AuthenticatedRoute = connect(state => ({
  user: state.user,
}))(({ component: C, user, ...rest }) => (
  <Route
    {...rest}
    render={props => (user.auth_token ? (
      <C {...props} />
    ) : (
      <Redirect
        to={{
          pathname: '/auth',
          search: `?redirect=${props.location.pathname}`,
        }}
      />
    ))}
  />
));

export const UnauthenticatedRoute = connect(state => ({
  user: state.user,
}))(({ component: C, user, ...rest }) => (
  <Route
    {...rest}
    render={props => (!user.auth_token ? (
      <C {...props} />
    ) : (
      <Redirect to="/" />
    ))}
  />
));

export const WizardRoute = connect(state => ({
  values: getFormValues('newPost')(state),
}))(({
  component: C,
  props: cProps,
  values,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => (values ? (
      <C {...props} {...cProps} />
    ) : (
      <Redirect to="/wizard" />
    ))}
  />
));
