import React, { Component } from 'react';
import { string } from 'prop-types';

export class BackTopButton extends Component {
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    const backTopButton = document.getElementById('back-top-button');

    if (window.scrollY <= 600) {
      backTopButton.style.visibility = 'hidden';
    } else {
      backTopButton.style.visibility = 'visible';
    }
  }

  handleHover() {
    if (window.innerWidth > 660) {
      const svgTag = document.getElementById('svgTag');
      svgTag.style.fill = '#0181BB';
      const circleTag = document.getElementById('Oval');
      circleTag.style.fillOpacity = '0.6';
    }
  }

  handleOut() {
    const svgTag = document.getElementById('svgTag');
    svgTag.style.fill = '#646569';
    const circleTag = document.getElementById('Oval');
    circleTag.style.fillOpacity = '0.3';
  }


  render() {
    return (
      <div
        style={{
          margin: '0px 5px 0px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <div
          id="back-top-button"
          onMouseOver={this.handleHover}
          onMouseOut={this.handleOut}
          onScroll={this.handleScroll}
          onClick={() => window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          })
      }
          style={window.innerWidth > 660 ? {
            visibility: 'hidden',
            zIndex: 1,
            position: 'fixed',
            right: '32px',
            bottom: '75px',
            cursor: 'pointer',
          } : {
            visibility: 'hidden',
            zIndex: 1,
            position: 'fixed',
            right: '15px',
            bottom: '152px',
            cursor: 'pointer',
          }}
        >
          <svg
            id="svgTag"
            width="48"
            height="48"
            viewBox="0 0 55 55"
            fill="#646569"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle id="Oval" cx="24" cy="24" r="24" fillOpacity="0.3" />
            <path d="M24.3868299,17.7804822 L33.4256165,27.2214895 C33.65708,27.4632287 33.6582616,27.8565206 33.428334,28.0998747 C33.1984065,28.3432288 32.8243312,28.344471 32.5928677,28.1027318 L24.5612369,19.7136998 L24.5612369,36.1788819 C24.5612369,36.5218633 24.2967375,36.8 23.9704673,36.8 C23.6441971,36.8 23.3796977,36.5218633 23.3796977,36.1788819 L23.3796977,19.7206315 L15.4085062,28.1013654 C15.1777965,28.3438499 14.8037424,28.3438499 14.5730327,28.1013654 C14.3423224,27.8587567 14.3423224,27.4654647 14.5730327,27.222856 L23.5527341,17.7819232 C23.7829098,17.5399232 24.1559099,17.5392772 24.3868299,17.7804822 Z M32.9986928,12 C33.3307346,12 33.6,12.1790872 33.6,12.4000008 C33.6,12.6209152 33.3307346,12.8 32.9986928,12.8 L15.8013072,12.8 C15.4692148,12.8 15.2,12.6209136 15.2,12.4 C15.2,12.1790864 15.4692148,12 15.8013072,12 L32.9986928,12 Z" id="Combined-Shape" fill="#FFFFFF" />

          </svg>
        </div>
      </div>
    );
  }
}

BackTopButton.defaultProps = {
  color: '#00ADD8',
};

BackTopButton.propTypes = {
  color: string,
};

export default BackTopButton;
