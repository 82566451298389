export const HEADER_EXCHANGE_GUIDELINE = 'Health & Safety Guidelines';
export const HEADER_ALWAYS_REMEMBER = 'Always Remember:';
export const HEADER_FAQ = 'Frequently Asked Questions';

export const PARA_HEALTH_SAFETY = 'The health and safety of our community is of paramount importance to us. In conducting the equipment exchange process, please be aware of your personal safety and respect the safety of the others involved in the exchange. Please keep apprised of the current recommended practices as advised by the health authority, specifically on current COVID-19 protocols.';
export const PARA_PLEASE_NOTE = 'Our team of dedicated volunteers are monitoring our website and will continue to update our guidelines as new information and recommendations become available, to aid our community in staying safe.';
export const PARA_WASH_HANDS = '<p><span style="font-weight:bold; color: #0280bb">Wash your hands</span> often</p>';
export const PARA_DISTANCING = '<p>Practice safe <span style="font-weight:bold; color: #0280bb">physical distancing of 2 meters</span></p>';
export const PARA_OUTDOORS = '<p>Stick to the <span style="font-weight:bold; color: #0280bb">outdoors<span> whenever possible</p>';
export const PARA_CLEAN = '<p><span style="font-weight:bold; color: #0280bb">Clean</span> spaces, surfaces, and equipment often</p>';
export const PARA_TRAVEL = '<p>Travel in a car <span style="font-weight:bold; color: #0280bb">alone or with members of your household</span></p>';

export const BTN_MORE_INFO_RESTRICTIONS = 'More Info on Province-wide Restrictions';
export const BTN_MORE_INFO_COVID = 'More Information on Covid-19';
export const BTN_TO_GET_INVOLVED = 'I Want To Get Involved';
export const BTN_TO_DONATE = 'I Wish To Donate';

export const LINK_MORE_INFO_RESTRICTIONS = 'https://www2.gov.bc.ca/gov/content/safety/emergency-preparedness-response-recovery/covid-19-provincial-support/restrictions';
export const LINK_MORE_INFO_COVID = 'http://www.bccdc.ca/health-info/diseases-conditions/covid-19';
export const LINK_TO_DONATE = '/donate';