import React from 'react'

import aboutUs from '../../images/aboutus_hero.png'
import ourTeam from '../../images/ourTeam.jpg'
import ourStory from '../../images/ourStory.jpg'
import contactUs from '../../images/contactUs.jpg'

function getPicture(token) {
	switch (token) {
		case 'About Us':
			return aboutUs
		case 'Our Team':
			return ourTeam
		case 'Our Story':
			return ourStory
		case 'Contact Us':
			return contactUs
		default:
			return null
	}
}

function backgroundPos(token) {
	switch (token) {
		case 'About Us':
			return '75% 0%'
		case 'Our Team':
			return '0% 50%'
		case 'Our Story':
			return '75% 42%'
		case 'Contact Us':
			return '75% 25%'
		default:
			return null
	}
}

const AboutUsHeader = ({ picture }) => (
	<div
		style={{
			color: '#ffffff',
			height: '300px',
			background: '#00ADD8',
			position: 'relative',
			justifyContent: 'center',
		}}
	>
		<div
			style={{
				backgroundImage: `url(${getPicture(picture)})`,
				opacity: '0.3',
				backgroundSize: 'cover',
				backgroundPosition: `${backgroundPos(picture)}`,
				backgroundRepeat: 'no-repeat',
				height: '100%',
				width: '100%,',
			}}
		/>
		<h1 className={'header-with-bg'}>{picture}</h1>
	</div>
)

export default AboutUsHeader
