import React from 'react'
import { connect } from 'react-redux'

import { Segment, Grid, Header, Icon, List, Button, Label, Loader } from 'semantic-ui-react'

import { fetchUserNotifications, deleteNotification } from '../../actions/notifications'
import NotificationButton from '../Notification/NotificationButton'

import notificationIcon from '../../images/notification.png'

class NotificationSettings extends React.Component {
	componentDidMount() {
		const { _fetchUserNotifications } = this.props
		_fetchUserNotifications()
	}

	handleDelete(id) {
		this.props._deleteNotification(id)
	}

	render() {
		const {
			notifications: { notifications, notificationsLoading },
		} = this.props
		return (
			<div>
				<Segment style={{ marginTop: '1rem' }}>
					<Header as="h2" icon textAlign="center" style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
						Notification Settings
						<Header.Subheader>
							Manage the notifications you receive when there are new AssistList listings.
						</Header.Subheader>
					</Header>
				</Segment>

				<Segment attached="top">
					<Label as="a" color="blue" ribbon>
						Current Notifications
					</Label>
					<Grid>
						<Grid.Row>
							<Grid.Column>
								<Loader active={notificationsLoading}>Loading</Loader>
								<List divided relaxed>
									{notifications.map((notification) => (
										<List.Item style={{ padding: '0.8rem' }}>
											<List.Content floated="right">
												<Button icon style={{ padding: 0 }} onClick={() => this.handleDelete(notification.id)}>
													<span className="trash-icon" />
												</Button>
											</List.Content>
											<List.Content>
												<List.Header as="h3" content={notification.equipment_type.name} />
											</List.Content>
										</List.Item>
									))}
								</List>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Segment>

				<NotificationButton
					attached="bottom"
					align
					primary
					initialValues={{}}
					style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'nowrap' }}
				>
					<Icon name="add" size="small" style={{ margin: 0, opacity: 1 }} />
					<span style={{ padding: '0.5rem' }}>Add Notification</span>
					<img src={notificationIcon} style={{ width: '24px', height: '24px' }} alt="" />
				</NotificationButton>
			</div>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	_fetchUserNotifications: () => dispatch(fetchUserNotifications()),
	_deleteNotification: (id) => dispatch(deleteNotification(id)),
})

const mapStateToProps = (state) => ({
	user: state.user,
	notifications: state.notifications,
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettings)
