import React from 'react';
import { Link } from 'react-router-dom';
import { Image, Grid, Button, Icon } from 'semantic-ui-react';
import './blog.css';

export const Topic = ({ blogLink, blogImage }) => {
  return (
    <Grid columns={2} stackable>
      <Grid.Column width={6}>
        <Image src={blogImage.url} fluid /> 
      </Grid.Column>
      <Grid.Column width={10} style={{ paddingLeft: '50px', marginTop: '50px' }}>
        <h3>{blogImage.title}</h3>
        <div style={{ paddingTop: '20px' }}>
          <Link to={{ pathname: blogLink }} target="_blank">
            <Button basic color="blue">
              Read articles &nbsp; <Icon name='external alternate' />
            </Button>
          </Link>
        </div>
      </Grid.Column>
    </Grid>
  )
};
