import React, { useState } from 'react';
import {
  Icon,
  Radio,
  Popup,
  Grid,
  Input,
  Button,
  Label,
} from 'semantic-ui-react';
import api from '../../utils/api';
import GeoRangerSlider from './GeoRangerSlider';

function postalFilter(postalCode) {
  if (!postalCode) {
    return null;
  }

  postalCode = postalCode.toString().trim();
  var ca = new RegExp(
    /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
  );

  if (ca.test(postalCode.toString().replace(/\W+/g, ''))) {
    return postalCode;
  }
  return null;
}

const GeoLocationFilter = ({
  allowSharingLocation,
  locationRadius,
  municipal,
  handleLocationPermission,
  setSharingLocationPermission,
  setSharingLocationRadius,
  loadPosts,
  loadFilters,
  setSharingLocationMunicipal,
  useCurrentLocation,
  setUseCurrentLocation,
}) => {
  const [postCode, setPostCode] = useState(
    localStorage.getItem('locationPostCode') || '',
  );
  const [postCodeError, setPostCodeError] = useState('');

  const setErrorPostCode = () => {
    setPostCodeError('error');
    setPostCode('');
    localStorage.removeItem('locationPostCode');
  };

  const postalCodeHandle = () => {
    setPostCodeError('');
    const postcode = postalFilter(`${postCode}`);
    if (!postcode) {
      setErrorPostCode();
      return;
    }
    api
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${postcode}&key=${process.env.REACT_APP_GEOCODE_API_KEY}`,
      )
      .then((response) => {
        if (
          response.data === null ||
          response.data.results === null ||
          response.data.results.length === 0
        ) {
          setErrorPostCode();
        } else {
          const { lat, lng } = response.data.results[0].geometry.location;
          setSharingLocationPermission(true, { lat, long: lng });
          loadFilters(lat, lng);
          loadPosts();

          localStorage.setItem('locationPostCode', postCode);
          setUseCurrentLocation(false);

          let municipal = response.data.results[0].address_components.filter(
            (add) =>
              add.types.includes('locality') && add.types.includes('political'),
          );
          if (municipal.length > 0) {
            setSharingLocationMunicipal(municipal[0].short_name);
          }
        }
      })
      .catch((error) => {
        setErrorPostCode();
      });
  };

  const updateRadius = (value) => {
    if (allowSharingLocation) {
      setSharingLocationRadius(value);
    }
    loadPosts();
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
          gap: '10px',
          alignItems: 'center',
        }}
      >
        <Radio
          toggle
          checked={useCurrentLocation}
          onChange={() => {
            handleLocationPermission();
          }}
          label="Current location"
        />

        <Popup
          content="Switch “On” to use your current location! Ensure your settings enable location services."
          trigger={<Icon name="question circle" color="blue" />}
        />
      </div>
      <div style={{ marginBottom: '10px' }}>
        <p style={{ marginTop: '20px', marginBottom: '10px' }}>
          or Enter your Postal Code
        </p>
        <Grid>
          <Grid.Column width={16} style={{ padding: '10px 15px 0px 15px' }}>
            <div className="ui action input" style={{ width: '100%' }}>
              <Input
                style={{ height: '38px', width: '100%' }}
                fluid
                type="text"
                autoFocus
                placeholder="Post Code"
                value={postCode}
                onChange={(e) => {
                  let input = e.target.value;
                  setPostCode(input);
                }}
                disabled={useCurrentLocation}
              />

              <Button
                primary
                type="submit"
                style={{ textAlign: 'center' }}
                content="Go"
                onClick={postalCodeHandle}
                disabled={useCurrentLocation}
              />
            </div>
          </Grid.Column>

          {postCodeError && (
            <Grid.Column
              width={16}
              style={{ marginTop: '0px', padding: '0px 10px' }}
            >
              <Label
                style={{
                  color: '#000000',
                  backgroundColor: '#FDD1D0',
                  fontFamily: 'Karla, Arial, Helvetica, sans-serif',
                  fontStyle: 'Bold',
                  fontSize: '14px',
                  lineHeight: '16px',
                }}
                pointing
              >
                Oops! The postal code is invalid, please use A1A 1A1 format
              </Label>
            </Grid.Column>
          )}
        </Grid>
        <GeoRangerSlider
          updateRadius={(value) => updateRadius(value)}
          radius={locationRadius}
          municipal={municipal}
        />
      </div>
    </div>
  );
};

export default GeoLocationFilter;
