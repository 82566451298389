/**
 * Methods for formatting data
 */


/**
 * https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
 * 
 * Answer by: Elias Zamaria
 */
export const numberWithCommas = (val) => {
	return Number.isInteger(val) ? val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : val;
}

/**
 * https://stackoverflow.com/questions/2970525/converting-any-string-into-camel-case
 * 
 * Answer by: smilyface
 */
export const toCamelCase = (str) => {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
}

/**
 * camel to normal case
 */
export const toNormalCase = (str) => {
  return str.replace(/([A-Z])/g, (m, char) => ` ${char.toLowerCase()}`);
}