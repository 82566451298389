// @flow
import React, { useEffect } from "react";
import { Grid, Image, Icon, Container, Responsive } from "semantic-ui-react";
import { errorPageStyle } from "../assets/errorPageStyle";
import t from "../assets/t.png";
import { Link } from "react-router-dom";
import { ListItem } from "./ListItem";
import { connect } from "react-redux";
import { fetchRelatedListing } from "../../../../actions/relatedlistings";

const ListingNotFound = ({
  post,
  user,
  history,
  relatedlistings,
  _fetchRelatedListing,
}) => {
  let related_listings = [];

  useEffect(() => {
    _fetchRelatedListing(post.category.id, user.user_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (relatedlistings.relatedlistings.data) {
    related_listings = relatedlistings.relatedlistings.data;
  }

  const items = related_listings.map((listing) => {
    return (
      <ListItem
        id={listing.id}
        image={listing.images.thumbnail}
        city={listing.location.name}
        price={listing.price}
        title={listing.title}
      />
    );
  });

  return (
    <Container
      style={{
        backgroundColor: "white",
        maxWidth: "1119px",
        margin: window.innerWidth > 600 ? "0 auto" : "0 5%",
      }}
    >
      <Grid>
        <Grid.Row
          onClick={() =>
            history.location.key ? history.goBack() : history.push("/")
          }
          floated="left"
          style={{ paddingLeft: "0px" }}
        >
          <Icon disabled name="chevron left" />
          <span className="subtext" style={{ cursor: "pointer" }}>
            Back to Results
          </span>
        </Grid.Row>

        <Grid.Row style={errorPageStyle.imageContainer} centered>
          <Image style={errorPageStyle.image} src={t} />
        </Grid.Row>
        <Grid.Row style={errorPageStyle.headingContainer} centered>
          <h1 style={errorPageStyle.heading}>Oops!</h1>
        </Grid.Row>
        <Grid.Row style={errorPageStyle.secondaryHeadingContainer} centered>
          <h1 style={errorPageStyle.secondaryHeading}>
            Looks like you're on a lost page
          </h1>
        </Grid.Row>
        <Grid.Row style={errorPageStyle.textContainer} centered>
          <h4 style={errorPageStyle.text}>
            We're sorry, but the listing you are looking for has either been
            sold or removed. We have more great items for you to explore!
          </h4>
        </Grid.Row>
        <Grid.Row style={errorPageStyle.viewAllContainer} right>
          {related_listings.length ? (
            <div
              style={errorPageStyle.blueTextContainer}
              onClick={() =>
                history.push(`/?category=${related_listings[0].category.name}`)
              }
            >
              <h4 style={errorPageStyle.viewAll}>
                View all {related_listings[0].category.name}
              </h4>
            </div>
          ) : (
            <div></div>
          )}
        </Grid.Row>
        <Grid.Row style={{ width: "100%" }}>
          {/* here is where I will put the list of components representing the search items */}
          <Responsive
            minWidth={Responsive.onlyComputer.minWidth}
            style={{ width: "100%" }}
          >
            <Grid relaxed columns={4} style={errorPageStyle.list}>
              {items}
            </Grid>
          </Responsive>
          <Responsive
            minWidth={Responsive.onlyTablet.minWidth}
            maxWidth={Responsive.onlyTablet.maxWidth}
          >
            <Grid relaxed columns={2} style={errorPageStyle.list}>
              {items}
            </Grid>
          </Responsive>
          <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
            <Grid relaxed columns={1} style={errorPageStyle.list}>
              {items}
            </Grid>
          </Responsive>
        </Grid.Row>
        <Grid.Row style={errorPageStyle.viewAllContainerContactUs} centered>
          <h4 style={errorPageStyle.helpText}>Need more help? </h4>
          <Link
            style={errorPageStyle.blueTextContainer}
            to={"/aboutus/contactus"}
          >
            <h4 style={errorPageStyle.blueText}>Contact Us</h4>
          </Link>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  relatedlistings: state.relatedlistings,
});

const mapDispatchToProps = (dispatch) => ({
  _fetchRelatedListing: (id, user_id) =>
    dispatch(fetchRelatedListing(id, user_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListingNotFound);
