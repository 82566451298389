const FETCH_POST = 'post/FETCH_POST';
const FETCH_POST_SUCCESS = 'post/FETCH_POST_SUCCESS';
const FETCH_POST_FAILURE = 'post/FETCH_POST_FAILURE';

const FETCH_WIZARD = 'post/FETCH_WIZARD';
const FETCH_WIZARD_SUCCESS = 'post/FETCH_WIZARD_SUCCESS';
const FETCH_WIZARD_FAILURE = 'post/FETCH_WIZARD_FAILURE';

const FETCH_EDIT_FORM = 'post/FETCH_EDIT_FORM';
const FETCH_EDIT_FORM_SUCCESS = 'post/FETCH_EDIT_FORM_SUCCESS';
const FETCH_EDIT_FORM_FAILURE = 'post/FETCH_EDIT_FORM_FAILURE';

const FETCH_CATEGORIES_SUCCESS = 'post/FETCH_CATEGORIES_SUCCESS';
const FETCH_CATEGORIES_FAILURE = 'post/FETCH_CATEGORIES_FAILURE';

const FETCH_CONDITIONS_SUCCESS = 'post/FETCH_CONDITIONS_SUCCESS';
const FETCH_CONDITIONS_FAILURE = 'post/FETCH_CONDITIONS_FAILURE';

const FETCH_LOCATIONS_SUCCESS = 'post/FETCH_LOCATIONS_SUCCESS';
const FETCH_LOCATIONS_FAILURE = 'post/FETCH_LOCATIONS_FAILURE';

const FETCH_PROP_FIELDS = 'post/FETCH_PROP_FIELDS';
const FETCH_PROP_FIELDS_SUCCESS = 'post/FETCH_PROP_FIELDS_SUCCESS';
const FETCH_PROP_FIELDS_FAILURE = 'post/FETCH_PROP_FIELDS_FAILURE';

const ADD_IMAGE = 'post/ADD_IMAGE';
const REMOVE_IMAGE = 'post/REMOVE_IMAGE';

const DELETE_IMAGE = 'post/DELETE_IMAGE';

const ORDER_IMAGE_LEFT = 'post/ORDER_IMAGE_LEFT';
const ORDER_IMAGE_RIGHT = 'post/ORDER_IMAGE_RIGHT';

const ROTATE_IMAGE_LEFT = 'post/ROTATE_IMAGE_LEFT';
const ROTATE_IMAGE_RIGHT = 'post/ROTATE_IMAGE_RIGHT';

const POST_SUBMIT = 'post/POST_SUBMIT';
const POST_SUBMIT_SUCCESS = 'post/POST_SUBMIT_SUCCESS';
const POST_SUBMIT_FAILURE = 'post/POST_SUBMIT_FAILURE';

const POST_EDIT = 'post/POST_EDIT';
const POST_EDIT_SUCCESS = 'post/POST_EDIT_SUCCESS';
const POST_EDIT_FAILURE = 'post/POST_EDIT_FAILURE';

const POST_RENEW = 'post/POST_RENEW';
const POST_RENEW_SUCCESS = 'post/POST_RENEW_SUCCESS';
const POST_RENEW_FAILURE = 'post/POST_RENEW_FAILURE';

const POST_REPORT = 'post/POST_REPORT';
const POST_REPORT_SUCCESS = 'post/POST_REPORT_SUCCESS';
const POST_REPORT_FAILURE = 'post/POST_REPORT_FAILURE';

const POST_DELETE = 'post/POST_DELETE';
const POST_DELETE_SUCCESS = 'post/POST_DELETE_SUCCESS';
const POST_DELETE_FAILURE = 'post/POST_DELETE_FAILURE';

export default {
  FETCH_POST,
  FETCH_POST_SUCCESS,
  FETCH_POST_FAILURE,

  FETCH_WIZARD,
  FETCH_WIZARD_SUCCESS,
  FETCH_WIZARD_FAILURE,

  FETCH_EDIT_FORM,
  FETCH_EDIT_FORM_SUCCESS,
  FETCH_EDIT_FORM_FAILURE,

  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAILURE,

  FETCH_CONDITIONS_SUCCESS,
  FETCH_CONDITIONS_FAILURE,

  FETCH_LOCATIONS_SUCCESS,
  FETCH_LOCATIONS_FAILURE,

  FETCH_PROP_FIELDS,
  FETCH_PROP_FIELDS_SUCCESS,
  FETCH_PROP_FIELDS_FAILURE,

  ADD_IMAGE,
  REMOVE_IMAGE,
  DELETE_IMAGE,
  ORDER_IMAGE_LEFT,
  ORDER_IMAGE_RIGHT,
  ROTATE_IMAGE_LEFT,
  ROTATE_IMAGE_RIGHT,

  POST_SUBMIT,
  POST_SUBMIT_SUCCESS,
  POST_SUBMIT_FAILURE,

  POST_EDIT,
  POST_EDIT_SUCCESS,
  POST_EDIT_FAILURE,

  POST_RENEW,
  POST_RENEW_SUCCESS,
  POST_RENEW_FAILURE,

  POST_REPORT,
  POST_REPORT_SUCCESS,
  POST_REPORT_FAILURE,

  POST_DELETE,
  POST_DELETE_SUCCESS,
  POST_DELETE_FAILURE,
};
