import React from 'react';
import { connect } from 'react-redux';
import {
  Segment, Dimmer, Header, Grid, Image, Label, Card, Progress, Button, Icon,
} from 'semantic-ui-react';
import Dropzone from 'react-dropzone';

import {
  addImage,
  removeImage,
  deleteImage,
  rotateImageLeft,
  rotateImageRight,
  orderImageLeft,
  orderImageRight,
} from '../actions/post';

import Upload from '../utils/upload';

class ImageDropzone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accept: 'image/jpeg, image/png',
      files: props.images,
      dropzoneActive: false,
      width: 0,
      defaultImgBoxSize: '265px' // image box height and width
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    this.onDragEnter = () => this.setState({ dropzoneActive: true });
    this.onDragLeave = () => this.setState({ dropzoneActive: false });

    this.onDrop = (files) => {
      const { setUploaded } = this.props;

      setUploaded(false);

      this.setState({ dropzoneActive: false });

      return Promise.all(files.map(file => new Upload(file, this.handleUpload)))
        // replaces Promise.prototype.finally for compatibility with older Safari versions
        .then(() => setUploaded(true))
        .catch(() => setUploaded(true));
    };

    this.handleUpload = (upload) => {
      const { files: uploadedFiles } = this.state;
      const { _addImage, input } = this.props;

      let updatedFiles = uploadedFiles;

      const fileIndex = uploadedFiles.findIndex(f => f.id === upload.id);

      if (fileIndex > -1) {
        updatedFiles[fileIndex] = upload;
      } else {
        updatedFiles = [...updatedFiles, upload];
      }

      this.setState({ files: updatedFiles });

      if (upload.status === 'success') {
        _addImage(upload);
        input.onChange([...input.value, { signed_id: upload.blobId, rotation: upload.rotation }]);
      } else if (upload.status === 'error') {
        console.log(upload.error);
      }
    };

    this.handleRemove = (e, { imageId, id }) => {
      const { _removeImage, _deleteImage, input: { onChange } } = this.props;

      e.stopPropagation();

      const { files: updatedFiles } = this.state;

      let fileIndex;

      if (imageId) {
        _deleteImage(imageId);
        fileIndex = updatedFiles.findIndex(f => f.image_id === imageId);
        updatedFiles.splice(fileIndex, 1);
      } else {
        _removeImage(id);
        fileIndex = updatedFiles.findIndex(f => f.id === id);
        updatedFiles.splice(fileIndex, 1);
        onChange(updatedFiles.map(f => (
          { signed_id: f.blobId || f.signed_id, rotation: f.rotation }
        )));
      }

      this.setState({ files: updatedFiles });
    };
    this.handleOrderLeft = (e, { imageId, id }) => {
      e.stopPropagation();
      e.preventDefault();

      const { _orderImageLeft, input: { onChange } } = this.props;
      const { files: updatedFiles } = this.state;

      const fileIndex = imageId ? updatedFiles.findIndex(f => f.image_id === imageId)
        : updatedFiles.findIndex(f => f.id === id);

      // only shift left if it's not the first image
      if (fileIndex > 0) {
        const temp = updatedFiles[fileIndex];
        updatedFiles[fileIndex] = updatedFiles[fileIndex - 1];
        updatedFiles[fileIndex - 1] = temp;
      }

      _orderImageLeft(updatedFiles);
      onChange(updatedFiles.map(f => (
        { signed_id: f.signed_id, rotation: f.rotation })));
      this.setState({ files: updatedFiles });
    };

    this.handleOrderRight = (e, { imageId, id }) => {
      e.stopPropagation();
      e.preventDefault();

      const { _orderImageRight, input: { onChange } } = this.props;
      const { files: updatedFiles } = this.state;

      const fileIndex = imageId ? updatedFiles.findIndex(f => f.image_id === imageId)
        : updatedFiles.findIndex(f => f.id === id);

      // only shift right if it's not the last image
      if (fileIndex < (updatedFiles.length - 1)) {
        const temp = updatedFiles[fileIndex];
        updatedFiles[fileIndex] = updatedFiles[fileIndex + 1];
        updatedFiles[fileIndex + 1] = temp;
      }

      _orderImageRight(updatedFiles);
      onChange(updatedFiles.map(f => (
        { signed_id: f.signed_id, rotation: f.rotation })));
      this.setState({ files: updatedFiles });
    };

    this.handleRotateLeft = (e, { imageId, id }) => {
      e.stopPropagation();
      e.preventDefault();

      const { _rotateImageLeft, input: { onChange } } = this.props;
      const { files: updatedFiles } = this.state;

      let fileIndex;

      if (imageId) {
        fileIndex = updatedFiles.findIndex(f => f.image_id === imageId);
      } else {
        fileIndex = updatedFiles.findIndex(f => f.id === id);
      }
      const newRotation = updatedFiles[fileIndex].rotation - 90;

      if (newRotation <= -360) {
        updatedFiles[fileIndex].rotation = 0;
        updatedFiles[fileIndex].rotation_origin -= 90;
      } else {
        updatedFiles[fileIndex].rotation -= 90;
        updatedFiles[fileIndex].rotation_origin -= 90;
      }

      _rotateImageLeft(updatedFiles);
      onChange(updatedFiles.map(f => (
        { signed_id: f.blobId || f.signed_id, rotation: f.rotation })));
      this.setState({ files: updatedFiles });
    };


    this.handleRotateRight = (e, { imageId, id }) => {
      e.stopPropagation();
      e.preventDefault();

      const { _rotateImageRight, input: { onChange } } = this.props;
      const { files: updatedFiles } = this.state;

      let fileIndex;

      if (imageId) {
        fileIndex = updatedFiles.findIndex(f => f.image_id === imageId);
      } else {
        fileIndex = updatedFiles.findIndex(f => f.id === id);
      }
      const newRotation = updatedFiles[fileIndex].rotation + 90;

      if (newRotation >= 360) {
        updatedFiles[fileIndex].rotation = 0;
        updatedFiles[fileIndex].rotation_origin += 90;
      } else {
        updatedFiles[fileIndex].rotation += 90;
        updatedFiles[fileIndex].rotation_origin += 90;
      }

      _rotateImageRight(updatedFiles);
      onChange(updatedFiles.map(f => (
        { signed_id: f.blobId || f.signed_id, rotation: f.rotation })));
      this.setState({ files: updatedFiles });
    };

    this.calculateFileHeightScale = () => {
      // computer
      if (window.innerWidth > 992) { return 1; }
      // tablet
      if (window.innerWidth <= 991 && window.innerWidth >= 768) { return 1.25; }
      // mobile
      return 0.75;
    };

    this.calculateViewPort = (widthCalc) => {
      if (widthCalc <= 370) { return true; }
      // viewport breaks buttons
      return false;
    };

    this.getNumCols = (widthCalc) => {
      // viewport breakpoints for number of columns
      if(widthCalc > 992) {
        return 3;
      } else if (widthCalc < 670) {
        return 1;
      } else {
        return 2;
      }
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  render() {
    const {
      accept, files, dropzoneActive, width, defaultImgBoxSize
    } = this.state;
    const { input } = this.props;
    const specialPadding = this.calculateViewPort(width);
    const numCols = this.getNumCols(width);
    let imgBoxSize = defaultImgBoxSize;
    if(window.innerWidth < 355) {
      imgBoxSize = '230px';
    }
    return (
      <Segment basic style={{ background: 'white ', marginTop: '0px', marginBottom: '0px'}}>
        <Label style={{marginBottom: '16px'}} as="a" color="red" ribbon>Required</Label>
        <input type="hidden" {...input} />
        <Dimmer.Dimmable
          as={Dropzone}
          dimmed={dropzoneActive}
          accept={accept}
          onDrop={this.onDrop}
          onDragEnter={this.onDragEnter}
          onDragLeave={this.onDragLeave}
          style={{
            cursor: 'pointer',
            minHeight: '400px',
            border: '5px dashed #CCC',
          }}
        >
          <Dimmer active={dropzoneActive}>
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <Header as="h1" inverted content="Drop here!" />
            </div>
          </Dimmer>

          <Grid padded columns={numCols}>
            {files.map((file, index) => (
              <Grid.Column
                key={file.image_id + '-' + file.id}
                style={specialPadding ? { padding: 5 } : {}}
              >
                <div>
                  {(file.status === 'success' || file.small) && (
                    <Card name='hymn' style={{marginTop: '10px', minWidth: imgBoxSize, maxWidth: imgBoxSize, minHeight: imgBoxSize, maxHeight: imgBoxSize}}>
                      <Label
                        id={file.id}
                        imageId={file.image_id}
                        content="x"
                        title="Remove the image"
                        circular
                        floating
                        color="red"
                        onClick={this.handleRemove}
                      />
                      <Image
                        style={{ transform: `rotate(${file.rotation_origin}deg)`,
                        objectFit: 'contain',
                        height: imgBoxSize,
                        maxHeight: imgBoxSize,
                        maxWidth: imgBoxSize }}
                        src={file.status ? file.preview : file.small}
                      />
                      {(index === 0) && (
                      <Label
                        id={file.id}
                        imageId={file.image_id}
                        content="Cover Image"
                        title="Cover Image"
                        attached="top"
                        style={{marginTop: -1}}
                      />
                      )}
                      <Button.Group size="mini" attached="bottom" basic>
                        <Button
                          icon
                          floated="left"
                          title="Changes order of image"
                          id={file.id}
                          imageId={file.image_id}
                          signed_id={file.signed_id}
                          onClick={this.handleOrderLeft}
                        >
                          <Icon name="angle left" />
                        </Button>
                        <Button
                          icon
                          title="Rotates image left"
                          id={file.id}
                          imageId={file.image_id}
                          onClick={this.handleRotateLeft}
                        >
                          <Icon name="redo" flipped="horizontally" />
                        </Button>
                        <Button
                          icon
                          title="Rotates image right"
                          id={file.id}
                          imageId={file.image_id}
                          onClick={this.handleRotateRight}
                        >
                          <Icon name="redo" />
                        </Button>
                        <Button
                          icon
                          floated="right"
                          title="Changes order of image"
                          id={file.id}
                          imageId={file.image_id}
                          onClick={this.handleOrderRight}
                        >
                          <Icon name="angle right" />
                        </Button>
                      </Button.Group>
                    </Card>
                  )}
                  {file.progress && (
                    <Progress
                      percent={file.progress}
                      active={file.status === 'uploading'}
                      success={file.status === 'success'}
                      error={file.status === 'error'}
                      size="tiny"
                      style={{maxWidth: imgBoxSize, marginTop: '40px'}}
                    />
                  )}
                </div>
              </Grid.Column>
            ))}
          </Grid>
          <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: (document.getElementsByName('hymn').length? '20px' : ''),
            marginTop: (document.getElementsByName('hymn').length? '' : '17%')
          }}
        >
          <Header as="h3" disabled content="Click or drop images here" />
        </div>
        </Dimmer.Dimmable>
      </Segment>
    );
  }
}

const mapStateToProps = state => ({
  images: state.post.postForm.images,
});

const mapDispatchToProps = dispatch => ({
  _addImage: image => dispatch(addImage(image)),
  _removeImage: id => dispatch(removeImage(id)),
  _deleteImage: id => dispatch(deleteImage(id)),
  _orderImageLeft: images => dispatch(orderImageLeft(images)),
  _orderImageRight: images => dispatch(orderImageRight(images)),
  _rotateImageLeft: images => dispatch(rotateImageLeft(images)),
  _rotateImageRight: images => dispatch(rotateImageRight(images)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageDropzone);
